import React from 'react';
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/elements/BasicTable/BasicTable.component';
import useTranslation from 'src/hooks/useTranslation';

const Associates = ({ data }) => {
  const { t } = useTranslation();

  return (
    <BasicTable layout="auto">
      <TableHead sticky="75.5px">
        <TableRow>
          <TableHeader>{t('Korisničko ime')}</TableHeader>
          <TableHeader>{t('Naziv korisnika')}</TableHeader>
          <TableHeader>{t('Uloga')}</TableHeader>
          <TableHeader>{t('Status')}</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((associate) => (
          <TableRow key={associate.id}>
            <TableCell>{associate.username}</TableCell>
            <TableCell>{associate.name}</TableCell>
            <TableCell>{associate.role}</TableCell>
            <TableCell>{associate.status}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </BasicTable>
  );
};

export default Associates;
