import gql from "graphql-tag";

const ACCEPT_ORDER = gql`
  mutation acceptOrder(
    $orderId: String!
    $physicalOrderNumber: String
    $acceptedOffers: [String]!
  ) {
    acceptOrder(
      orderId: $orderId
      physicalOrderNumber: $physicalOrderNumber
      acceptedOffers: $acceptedOffers
    )
  }
`;

export default ACCEPT_ORDER;
