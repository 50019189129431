import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChip } from 'react-icons/bi';
import LATEST_BUYERS_ORDERS from 'src/apollo/queries/dashboard/latestBuyersOrders';
import ChartStats from 'src/components/ChartStats/ChartStats';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import { IconWrapper, Number } from './Dashboard';

const LatestBuyersOrdersChart = () => {
  const { t } = useTranslation();
  // latest buyers orders
  const [lbo, setLbo] = useState([]);

  const {
    loading,
    data: { latestBuyersOrders } = {},
    error: latestBuyersOrdersError,
  } = useQuery(LATEST_BUYERS_ORDERS, {
    fetchPolicy: 'cache-first',
  });

  // TODO: dodaj primljene ponude i od dobavljača za administratora

  useEffect(() => {
    if (latestBuyersOrders?.length) {
      setLbo(groupBy(latestBuyersOrders, 'dateCreated'));
    }
  }, [latestBuyersOrders]);

  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t('Primljene ponude')}</h4>
          {loading ? (
            <Spinner />
          ) : (
            <Number>{latestBuyersOrders?.length}</Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats data={lbo} title={t('Broj narudžbi')} />
        )}
      </Segment>
    </Distributor>
  );
};

export default LatestBuyersOrdersChart;
