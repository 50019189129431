import gql from "graphql-tag";

const CREATE_TECHNOLOGY_PARAMETER = gql`
  mutation createTechnologyParameter(
    $name: String!
    $minValueLabel: String
    $maxValueLabel: String
    $replaceParameterId: ID
  ) {
    createTechnologyParameter(
      name: $name
      minValueLabel: $minValueLabel
      maxValueLabel: $maxValueLabel
      replaceParameterId: $replaceParameterId
    ) {
      id
      name
    }
  }
`;

export default CREATE_TECHNOLOGY_PARAMETER;
