import gql from "graphql-tag";

const GET_CONDITIONS = gql`
  query getConditions {
    getConditions {
      paymentAndTransport {
        shipmentAddress
        transportExpenses
        paymentSchedule
        paymentMethod
        paymentTimeframe
        currency
      }
      taxPercentage
    }
  }
`;

export default GET_CONDITIONS;
