import React, { useContext, useEffect, useState } from "react";
import Input from "../../../elements/Input/Input.component";
import Label from "../../../elements/Label/Label.component";
import FormField from "../../wrappers/FormField/FormField.component";
import Distributor from "../../wrappers/Distributor/Distributor.component";
import Button from "../../../elements/Button/Button.component";
import Select from "../../../elements/Select/Select.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addManufacturingFormSchema } from "../../../../validation/schema";
import { useMutation, useQuery } from "@apollo/client";
import CREATE_MANUFACTURING from "../../../../apollo/mutations/createManufacturing";
import GET_MANUFACTURING_CATEGORIES from "../../../../apollo/queries/getManufacturingCategories";
import { ModalContext } from "../../Modal/modalContext";
import toast from "react-hot-toast";
import { pickBy } from "lodash";
import GET_ALL_MANUFACTURING from "../../../../apollo/queries/getAllManufacturing";

const AddManufacturingForm = ({ prefillData }) => {
  const { handleModal } = useContext(ModalContext);

  const {
    loading: getCategoriesLoading,
    error: getCategoriesError,
    data,
  } = useQuery(GET_MANUFACTURING_CATEGORIES, { fetchPolicy: "no-cache" });
  const [createMaterialMutation, { loading, error }] = useMutation(
    CREATE_MANUFACTURING,
    {
      refetchQueries: ["getAllManufacturing"],
      onError: () => {},
    }
  );
  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(addManufacturingFormSchema),
  });
  const onSubmit = (values) => {
    const sanitizedValues = pickBy(values, (value) => value.length > 0);
    createMaterialMutation({
      variables: {
        ...sanitizedValues,
        replaceManufacturingId: prefillData?.id,
      },
    });
    if (!loading) {
      toast.success("Proizvodnja uspješno dodana");
      handleModal();
    }
  };

  /* prefill */

  useEffect(() => {
    if (prefillData) {
      setValue("name", prefillData.name);
      setValue("category", prefillData.category.id);
    }
  }, [prefillData, setValue, data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Dodaj novu proizvodnju</h2>
      <FormField>
        <Label error={errors.name?.message}>Naziv proizvodnje</Label>
        <Input name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.category?.message}>Kategorija proizvodnje</Label>
        <Select name="category" loading={loading} ref={register}>
          {data?.getManufacturingCategories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button>Sačuvaj proizvodnju</Button>
      </Distributor>
    </form>
  );
};

export default AddManufacturingForm;
