import gql from "graphql-tag";

const LATEST_MANUFACTURERS_ORDERS = gql`
  query latestManufacturersOrders {
    latestManufacturersOrders {
      dateCreated
    }
  }
`;

export default LATEST_MANUFACTURERS_ORDERS;
