import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import image from '../../../../assets/images/inno-logo.png';
import imageLight from '../../../../assets/images/inno-logo.png';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  & a img {
    height: ${({ height }) => (height ? height : '35px')};
  }
`;

const Logo = ({ light, height }) => {
  return (
    <Wrapper height={height}>
      <NavLink to="/">
        <img src={light ? imageLight : image} alt="Project Solutions logo" />
      </NavLink>
    </Wrapper>
  );
};

export default Logo;
