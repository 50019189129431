import gql from "graphql-tag";

const UPDATE_TECHNICAL_QUALIFICATIONS = gql`
  mutation updateTechnicalQualifications(
    $companyId: String!
    $manufacturing: [String]
    $technologies: [TechnologyValuesInput]
    $materials: [String]
    $complexityLevel: Int
    $toleranceLevel: Int
    $restrictions: CompanyRestrictionsInput
    $exclusiveDirectives: CompanyExclusiveDirectivesInput
  ) {
    updateTechnicalQualifications(
      companyId: $companyId
      manufacturing: $manufacturing
      technologies: $technologies
      materials: $materials
      complexityLevel: $complexityLevel
      toleranceLevel: $toleranceLevel
      restrictions: $restrictions
      exclusiveDirectives: $exclusiveDirectives
    )
  }
`;

export default UPDATE_TECHNICAL_QUALIFICATIONS;
