import React from "react";
import styled from "styled-components";

/* FIXME: Convert stack to grid too */

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${({ gap }) => (gap ? gap : "1rem")};
  ${({ spread }) => spread && `flex: 1`};
  ${({ cols }) => cols && `grid-template-columns: ${cols}`};
  ${({ rows }) => rows && `grid-template-rows: ${rows}`};
  ${({ repeat }) => repeat && `grid-template-columns: repeat(${repeat}, 1fr)`};
  ${({ align }) =>
    align
      ? align !== "center"
        ? `align-items: flex-${align}`
        : `align-items: center`
      : null};
  ${({ height }) => height && `height: ${height}`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ spaceBetween }) =>
    spaceBetween &&
    `
    display: flex; 
    justify-content: space-between;
    `};
  ${({ reverse }) =>
    reverse &&
    `
    display: flex;
    flex-direction: column-reverse;
  `}
  ${({ submitButtons }) => submitButtons && `margin-top: 60px`};
  ${({ sticky, theme }) =>
    sticky &&
    `
      position: sticky;
      top: 0;
      z-index: 2;
      background: ${theme.colors.white};
  `};
  ${({ autoFill }) =>
    autoFill && `grid-template-columns: repeat(auto-fill, minmax(100px, 1fr))`};
  ${({ stack }) =>
    stack &&
    `
    display: flex;
    justify-content: flex-${stack};
    `};
  ${({ printStyle }) => `
      @media print {
        display: block;
        margin: 0;
        padding: 0;
      }
    `}
`;

const Distributor = ({ col, row, children, ...otherProps }) => {
  const cols =
    col &&
    col
      .split("-")
      .map((c) => `${c}fr`)
      .join(" ");

  const rows =
    row &&
    row
      .split("-")
      .map((r) => `${r}fr`)
      .join(" ");

  return (
    <Wrapper cols={cols} rows={rows} {...otherProps}>
      {children}
    </Wrapper>
  );
};

export default Distributor;
