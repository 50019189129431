import React, { useContext, useEffect, useState } from "react";
import useTranslation from "src/hooks/useTranslation";
import styled from "styled-components";
import { currentUserContext } from "../../Auth/currentUserContext/currentUser.context";
import Button from "../Button/Button.component";

const Wrapper = styled.div``;

const Tab = styled.div`
  padding: 1rem 2rem;
  border-bottom: 2px solid transparent;
  :hover {
    cursor: pointer;
  }
  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.colors.accent};
    ${
      "" /* background: ${({ theme }) => theme.colors.highlight};
    color: ${({ theme }) => theme.colors.highlightForeground}; */
    }
  }
  display: flex;
  align-items: center;
  font-weight: 600;
  span {
    background: ${({ theme }) => theme.colors.menuHighlight};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    font-weight: normal;
    letter-spacing: -1px;
  }
`;

const TabItemWrapper = styled.div``;

const TabItem = ({ children }) => {
  return <TabItemWrapper>{children}</TabItemWrapper>;
};

const TabContent = styled.div`
  padding-top: 30px;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const TopControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
`;

const Tabs = ({ children, onEdit }) => {
  const { t } = useTranslation();
  const {
    user: { user },
  } = useContext(currentUserContext);

  const [tabContent, setTabContent] = useState({});

  useEffect(() => {
    if (Array.isArray(children)) {
      const index = children.findIndex((child) => child.props.active);
      const initialContent = { activeId: index, content: children[index] };
      setTabContent(initialContent);
    } else {
      setTabContent({ activeId: 1, content: children });
    }
  }, [setTabContent, children]);

  const handleClick = (id, content) => {
    setTabContent({ activeId: id, content });
  };

  const handleOnEdit = () => {
    onEdit && onEdit();
  };

  const isAuthorized = (onlyFor) => {
    if (onlyFor) {
      return onlyFor?.includes(user?.role?.toUpperCase());
    } else {
      return true;
    }
  };

  return (
    <Wrapper>
      <TopControls>
        <TabsWrapper>
          {Array.isArray(children) ? (
            children.map((child, index) => {
              return (
                child &&
                isAuthorized(child.props.onlyFor) && (
                  <Tab
                    key={index}
                    onClick={() => handleClick(index, child.props.children)}
                    className={index === tabContent.activeId && "active"}
                  >
                    {child.props.label}{" "}
                    {child.props.count && <span>{child.props.count}</span>}
                  </Tab>
                )
              );
            })
          ) : (
            <Tab
              key={1}
              onClick={() => handleClick(1, children.props.children)}
              className="active"
            >
              {children.props.label}{" "}
              {children.props.count && <span>{children.props.count}</span>}
            </Tab>
          )}
        </TabsWrapper>
        {onEdit && (
          <Button layout="hollow" onClick={handleOnEdit}>
            {t("Uređivanje")}
          </Button>
        )}
      </TopControls>
      <TabContent>{tabContent.content}</TabContent>
    </Wrapper>
  );
};

export { TabItem, Tabs as default };
