import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1.2rem 1.2rem;
  border-radius: 3px;
  overflow: hidden;
  margin: 1rem 0;
  font-size: 0.8rem;
  &.notice {
    background: #deffde;
    border-left: 5px solid #7de67d;
  }
  &.warning {
    background: ${({ theme }) => theme.colors.highlight};
    border-left: 5px solid ${({ theme }) => theme.colors.accent};
  }
  &.information {
    background: #fff1d7;
    border-left: 5px solid #ffb938;
  }
  :hover {
    cursor: pointer;
  }
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;
const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;
const Body = styled.div`
  white-space: pre-wrap;
`;

const Acknowledge = ({ children, title, layout }) => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <Wrapper
      className={layout}
      visible={isVisible}
      onClick={() => setIsVisible(!isVisible)}
    >
      {title && <Title>{title}</Title>}
      <Body>{children}</Body>
    </Wrapper>
  );
};

export default Acknowledge;
