import { get } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import useTranslation from "../../../hooks/useTranslation";
import CheckerBox from "../CheckerBox/CheckerBox";

const Wrapper = styled.div``;
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AdditionalLabel = styled.div`
  background: ${({ theme }) => theme.colors.menuHighlight};
  font-size: 0.8rem;
  padding: 0 0.2rem;
  border-radius: 2px;
  font-weight: 600;
`;

const AdditionalLabels = styled.div`
  display: flex;
  ${AdditionalLabel}:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const CheckList = ({
  data,
  value,
  name,
  onCheck,
  checked,
  loadSubItems,
  additionalLabels,
}) => {
  const { t } = useTranslation();

  const loadItems = (data) => {
    if (!data) return;
    return data?.map((d, i) => {
      const parsedName = get(d, name);
      const parsedValue = get(d, value);
      return (
        <React.Fragment>
          <ContentWrapper>
            <CheckerBox
              key={parsedName + i}
              value={t(parsedValue)}
              name={parsedName}
              onChange={onCheck}
              checked={checked?.indexOf(parsedName) !== -1 || false}
            />
            <AdditionalLabels>
              {additionalLabels?.map((l) => (
                <AdditionalLabel>{get(d, l)}</AdditionalLabel>
              ))}
            </AdditionalLabels>
          </ContentWrapper>
          {loadSubItems &&
            checked?.indexOf(parsedName) !== -1 &&
            handleLoadSubItems(parsedName)}
        </React.Fragment>
      );
    });
  };

  const handleLoadSubItems = (i) => {
    const itemsToLoad = loadSubItems(i);
    return loadItems(itemsToLoad);
  };

  return <Wrapper>{loadItems(data)}</Wrapper>;
};

export default CheckList;
