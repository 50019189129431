import gql from "graphql-tag";

const GET_ACTIVE_PROJECTS_COUNT = gql`
  query getActiveProjectsCount {
    getActiveProjectsCount
  }
`;
const GET_TOTAL_PROJECTS_COUNT = gql`
  query getTotalProjectsCount {
    getTotalProjectsCount
  }
`;

const GET_LATEST_PROJECTS = gql`
  query getLatestProjects {
    getLatestProjects {
      id
      name
      projectCode
      published {
        date
      }
      positions {
        _id
        files {
          jpg
        }
        revisedInformations {
          files {
            jpg
          }
        }
      }
    }
  }
`;

const GET_TOTAL_PROJECTS_VALUE = gql`
  query getTotalProjectsValue {
    getTotalProjectsValue
  }
`;

export {
  GET_ACTIVE_PROJECTS_COUNT,
  GET_TOTAL_PROJECTS_COUNT,
  GET_LATEST_PROJECTS,
  GET_TOTAL_PROJECTS_VALUE,
};
