import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { pick } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import CONFIRM_DELIVERY from 'src/apollo/mutations/confirmDelivery';
import DELIVER_CORRECTION from 'src/apollo/mutations/deliveryCorrection';
import CheckerBox from 'src/components/elements/CheckerBox/CheckerBox';
import Textarea from 'src/components/elements/Textarea/Textarea.component';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import SEND_OFFER_TO_BUYER from '../../../apollo/mutations/sendOfferToBuyer';
import Acknowledge from '../../../components/Acknowledge/Acknowledge';
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/elements/BasicTable/BasicTable.component';
import Button from '../../../components/elements/Button/Button.component';
import Input from '../../../components/elements/Input/Input.component';
import Label from '../../../components/elements/Label/Label.component';
import Select from '../../../components/elements/Select/Select.component';
import { ModalContext } from '../../../components/structure/Modal/modalContext';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import FormField from '../../../components/structure/wrappers/FormField/FormField.component';
import {
  CURRENCIES,
  DELIVERY_CONDITIONS,
  PAYMENT_TIMEFRAME,
} from '../../../constants';
import { approveOrderSchema } from '../../../validation/schema';
import { HeaderInfo } from './ApproveOfferForBuyer';
import Table from 'src/components/elements/Table/Table.component';

const Wrapper = styled.div``;

const NoteEmailPreview = styled.div`
  white-space: pre-wrap;
  margin-top: 1rem;
  border-radius: 5px;
  > strong {
    display: block;
    margin-bottom: 1rem;
  }
`;

const AdditionalInfo = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  > div {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid ${({ theme }) => theme.colors.menuHighlight};
    border-radius: 5px;
    padding: 0.5rem 1rem;
    border-left: 1px solid ${({ theme }) => theme.colors.menuHighlight};
  }
`;

const ApproveOrder = ({ data, readOnly, delivered }) => {
  const { t } = useTranslation();
  const [marges, setMarges] = useState({});
  const { handleModal } = useContext(ModalContext);
  const { handleSubmit, register, errors, setValue, watch, getValues } =
    useForm({
      resolver: yupResolver(approveOrderSchema),
    });
  const [sendOfferToBuyer, { loading }] = useMutation(SEND_OFFER_TO_BUYER, {
    onCompleted: (data) => {
      toast.success('Ponuda poslata kupcu');
      handleModal();
    },
    onError: () => {},
  });

  const [confirmDeliveryMutation, { loading: confirmDeliveryLoading }] =
    useMutation(CONFIRM_DELIVERY, {
      onCompleted: () =>
        toast.success(
          'Status isporuke izmijenjen. Ukoliko se stanje odmah ne promijeni, osvježite stranicu'
        ),
    });

  const [deliveryCorrectionMutation, { loading: deliveryCorrectionLoading }] =
    useMutation(DELIVER_CORRECTION, {
      onCompleted: () =>
        toast.success(
          'Status isporuke izmijenjen. Ukoliko se stanje odmah ne promijeni, osvježite stranicu'
        ),
    });

  /* prefill data */
  useEffect(() => {
    if (data) {
      const conditions = data?.project?.createdBy?.company?.paymentAndTransport;
      conditions &&
        Object.keys(conditions).map((key) => setValue(key, conditions[key]));

      setValue('note', data?.note?.message);

      data?.acceptedOffers.map((offer) => {
        console.log(offer);
        setValue(offer._id, offer.refinedPrice);
        setValue(`physicalOrderNumber_${offer._id}`, offer.physicalOrderNumber);
        setValue(
          `deliveredByManufacturer_${offer._id}`,
          !!offer?.deliveryStatus?.deliveredByManufacturer?.status
        );
        setValue(
          `confirmedDeliveryByAdmin_${offer._id}`,
          !!offer?.deliveryStatus?.deliveredByManufacturer?.confirmedDelivery
            ?.status
        );
        return true;
      });
    }
  }, [data, setValue]);

  const handleMarge = (e, offerId, acceptedPrice) => {
    e.preventDefault();
    const marge = acceptedPrice + (marges[offerId] / 100) * acceptedPrice;
    setValue(offerId, parseFloat(marge).toFixed(2));
    calculateTotals();
  };

  const handleMargeChange = (e) => {
    const { name, value } = e.target;
    setMarges({ ...marges, [name]: value });
  };

  const handleConfirmDelivery = (orderId, acceptedOfferId) => {
    confirmDeliveryMutation({
      variables: {
        orderId,
        acceptedOfferId,
        delivered: !!getValues(`confirmedDeliveryByAdmin_${acceptedOfferId}`),
      },
      refetchQueries: ['getAllOrders'],
    });
  };

  const handleDeliveryCorrection = (orderId, acceptedOfferId) => {
    deliveryCorrectionMutation({
      variables: {
        orderId,
        acceptedOfferId,
        delivered: !!getValues(`deliveredByManufacturer_${acceptedOfferId}`),
      },
      refetchQueries: ['getAllOrders'],
    });
  };

  const onSubmit = (values) => {
    const conditions = pick(values, [
      'shipmentAddress',
      'transportExpenses',
      'paymentSchedule',
      'paymentMethod',
      'paymentTimeframe',
      'currency',
    ]);
    const prices = data.acceptedOffers.map((offer) => ({
      _id: offer._id,
      user: offer.user.id,
      userEmail: offer.user.email,
      position: offer.position.id,
      quantity: offer.quantity,
      aimedPrice: offer.aimedPrice,
      acceptedPrice: offer.acceptedPrice,
      administratorAimedPrice: offer.administratorAimedPrice || 0,
      realisticDeliveryTime: offer.realisticDeliveryTime,
      physicalOrderNumber: values[`physicalOrderNumber_${offer._id}`],
      refinedPrice:
        parseFloat(values[offer._id]) || parseFloat(offer.acceptedPrice),
    }));

    sendOfferToBuyer({
      variables: {
        orderId: data._id,
        refinedAcceptedOffers: prices,
        conditions,
        note: values?.note,
      },
      refetchQueries: ['getAllOrders'],
    });
  };

  const [totals, setTotals] = useState({
    total: 0,
    totalWithMarge: 0,
    tax: 0,
    taxPercentage: 0,
    totalPositions: 0,
    totalQuantity: 0,
  });

  const calculateTotals = () => {
    if (!data || !data?.project || !data?.acceptedOffers?.length) return;

    let total = 0;
    let totalWithMarge = 0;
    const taxPercentage = data.project.createdBy.company.taxPercentage;

    data.acceptedOffers.forEach((offer) => {
      total += offer.acceptedPrice * offer.quantity;
      totalWithMarge +=
        (parseFloat(getValues(offer._id)) || offer.acceptedPrice) *
        offer.quantity;
    });

    const tax = totalWithMarge * (taxPercentage / 100);

    const totalPositions = data.acceptedOffers.length;
    const totalQuantity = data.acceptedOffers.reduce(
      (acc, offer) => acc + offer.quantity,
      0
    );

    setTotals({
      total: total.toLocaleString('de-DE', {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'EUR',
      }),
      totalWithMarge: totalWithMarge.toLocaleString('de-DE', {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'EUR',
      }),
      tax: tax.toLocaleString('de-DE', {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'EUR',
      }),
      totalWithTax: (totalWithMarge + tax).toLocaleString('de-DE', {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'EUR',
      }),
      taxPercentage,
      totalPositions,
      totalQuantity,
    });
  };

  useEffect(() => {
    if (data?.acceptedOffers) {
      calculateTotals();
    }
  }, [data]);

  const [notePreview, setNotePreview] = useState(null);
  const noteWatcher = watch('note');

  useEffect(() => {
    setNotePreview(noteWatcher);
  }, [noteWatcher]);

  return (
    <Wrapper>
      <h3>
        Projekt: {data.project.name} | Šifra projekta:{' '}
        {data.project.projectCode}
      </h3>
      <HeaderInfo>
        <div>
          <strong>Naziv kupca:</strong>
          {data.project.createdBy.company.name}
        </div>
        <div>
          <strong>Odgovorna osoba:</strong>
          {data.project.createdBy.name}
        </div>
      </HeaderInfo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicTable>
          <TableHead>
            <TableRow>
              <TableHeader>Proizvođač</TableHeader>
              <TableHeader>Vrijeme plaćanja</TableHeader>
              <TableHeader>Realno vrijeme isporuke</TableHeader>
              <TableHeader>Pozicija</TableHeader>
              <TableHeader>Količina</TableHeader>
              <TableHeader>Ciljana cijena</TableHeader>
              <TableHeader>Odabrana cijena</TableHeader>
              {!readOnly && <TableHeader>Marža</TableHeader>}
              <TableHeader>Vaša cijena</TableHeader>
              <TableHeader>Broj ponude</TableHeader>
              {delivered && (
                <React.Fragment>
                  <TableHeader>Isporučeno</TableHeader>
                  <TableHeader>Potvrđena isporuka</TableHeader>
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.acceptedOffers.map((offer) => (
              <TableRow key={offer._id}>
                <TableCell>{offer.user.company?.name}</TableCell>
                <TableCell>
                  {offer.user?.company?.paymentAndTransport?.paymentTimeframe}
                </TableCell>
                <TableCell>{offer?.realisticDeliveryTime || '-'}</TableCell>
                <TableCell>
                  {offer.position.name}
                  <br />
                  {offer.position.positionCode}
                </TableCell>
                <TableCell>{offer.quantity}</TableCell>
                <TableCell>
                  {`${offer?.aimedPrice?.toFixed(
                    2
                  )} / ${offer?.administratorAimedPrice?.toFixed(2)}`}
                </TableCell>
                <TableCell
                  title={offer.position.quantities
                    ?.find(({ quantity }) => quantity === offer.quantity)
                    ?.offers?.map((v) => v)
                    ?.sort(
                      (a, b) => a.offeredQuantityPrice - b.offeredQuantityPrice
                    )
                    ?.map(
                      ({
                        offeredQuantityPrice,
                        userId: {
                          name,
                          company: { name: companyName },
                        },
                      }) => `${companyName} | ${name} - ${offeredQuantityPrice}`
                    )
                    ?.join('\n')}
                >
                  {offer.acceptedPrice?.toFixed(2)}
                </TableCell>
                {!readOnly && (
                  <TableCell>
                    <Distributor col="3-1">
                      <Input
                        width="3rem"
                        name={offer._id}
                        value={marges[offer._id]}
                        onChange={handleMargeChange}
                      />
                      <Button
                        onClick={(e) =>
                          handleMarge(e, offer._id, offer.acceptedPrice)
                        }
                      >
                        %
                      </Button>
                    </Distributor>
                  </TableCell>
                )}
                <TableCell>
                  <Input
                    type="number"
                    step="0.01"
                    name={offer._id}
                    ref={register}
                    readOnly={readOnly}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type="text"
                    name={`physicalOrderNumber_${offer._id}`}
                    ref={register}
                    readOnly={readOnly}
                    width="200px"
                  />
                </TableCell>
                {delivered && (
                  <React.Fragment>
                    <TableCell>
                      {offer?.acceptedByBuyer ? (
                        <CheckerBox
                          name={`deliveredByManufacturer_${offer._id}`}
                          onChange={() =>
                            handleDeliveryCorrection(data._id, offer._id)
                          }
                          ref={register}
                        />
                      ) : (
                        'Nije naruč.'
                      )}
                    </TableCell>
                    <TableCell>
                      {offer?.acceptedByBuyer ? (
                        <CheckerBox
                          name={`confirmedDeliveryByAdmin_${offer._id}`}
                          onChange={() =>
                            handleConfirmDelivery(data._id, offer._id)
                          }
                          ref={register}
                        />
                      ) : (
                        'Nije naruč.'
                      )}
                    </TableCell>
                  </React.Fragment>
                )}
              </TableRow>
            ))}
            {!readOnly ? (
              <>
                <TableRow>
                  <TableHeader></TableHeader>
                  <TableHeader></TableHeader>
                  <TableHeader></TableHeader>
                  <TableHeader>Ukupno stavki</TableHeader>
                  <TableHeader>Broj komada</TableHeader>
                  <TableHeader></TableHeader>
                  <TableHeader>Zbirna dobavna cijena</TableHeader>
                  <TableHeader>Zbirna prodajna cijena</TableHeader>
                  <TableHeader>PDV</TableHeader>
                  <TableHeader>Ukupna cijena + PDV</TableHeader>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell> {totals.totalPositions}</TableCell>
                  <TableCell> {totals.totalQuantity}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{totals.total}</TableCell>
                  <TableCell>{totals.totalWithMarge}</TableCell>
                  <TableCell>
                    {totals.tax} ({totals.taxPercentage}%)
                  </TableCell>
                  <TableCell>{totals.totalWithTax}</TableCell>
                </TableRow>
              </>
            ) : null}
          </TableBody>
        </BasicTable>

        <FormField>
          <h3>{t('Napomena')}</h3>
        </FormField>
        <FormField>
          <Textarea
            name="note"
            ref={register}
            placeholder={t('Bitna napomena uz ponudu')}
            rows="7"
            readOnly={readOnly}
          ></Textarea>
        </FormField>

        <Acknowledge layout="information">
          <NoteEmailPreview>
            <strong>
              {t('Ovako će izgledati na emailu. Klikni da zatvoris')}:
            </strong>
            <div>{notePreview}</div>
          </NoteEmailPreview>
        </Acknowledge>

        <FormField>
          <h3>{t('Plaćanje i transport')}</h3>
        </FormField>
        <FormField>
          <Label error={errors.shipmentAddress?.message}>
            {t('Adresa isporuke')}
          </Label>
          <Input
            type="text"
            name="shipmentAddress"
            ref={register}
            readOnly={readOnly}
          />
        </FormField>
        <Distributor col="1-1">
          <FormField>
            <Label error={errors.paymentSchedule?.message}>
              {t('Način isporuke')}
            </Label>
            <Select name="paymentSchedule" ref={register} disabled={readOnly}>
              {Object.keys(DELIVERY_CONDITIONS).map((val, i) => (
                <option key={`${val}_${i}`} value={val}>
                  {`${val} - ${DELIVERY_CONDITIONS[val]}`}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField>
            <Label error={errors.paymentMethod?.message}>
              {t('Način plaćanja')}
            </Label>
            <Input
              type="text"
              name="paymentMethod"
              ref={register}
              readOnly={readOnly}
            />
          </FormField>
        </Distributor>
        <Distributor col="1-1-1">
          <FormField>
            <Label error={errors.transportExpenses?.message}>
              {t('Troškovi transporta')}
            </Label>
            <Input
              type="number"
              step="0.01"
              name="transportExpenses"
              ref={register}
              readOnly={readOnly}
            />
          </FormField>
          <FormField>
            <Label error={errors.paymentTimeframe?.message}>
              {t('Vrijeme za plaćanje')}
            </Label>
            <Select name="paymentTimeframe" ref={register} disabled={readOnly}>
              {Object.keys(PAYMENT_TIMEFRAME).map((p, i) => (
                <option key={i} value={p}>
                  {PAYMENT_TIMEFRAME[p]}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField>
            <Label error={errors.currency?.message}>{t('Valuta')}</Label>
            <Select name="currency" ref={register} disabled>
              {Object.keys(CURRENCIES).map((p, i) => (
                <option key={i} value={CURRENCIES[p].code}>
                  {CURRENCIES[p].code}
                </option>
              ))}
            </Select>
          </FormField>
        </Distributor>

        {!readOnly && (
          <React.Fragment>
            <Acknowledge layout="notice">
              Nakon revidiranja cijena, ponuda se šalje kupcu, nakon čega
              očekujete narudžbenicu od kupca koju ćete morati potvrditi da bi
              se ista proslijedila i proizvođačima.
            </Acknowledge>
            <Distributor stack="end" submitButtons>
              <FormField>
                <Button loading={loading}>Pošalji ponudu kupcu</Button>
              </FormField>
            </Distributor>
          </React.Fragment>
        )}
      </form>
    </Wrapper>
  );
};

export default ApproveOrder;
