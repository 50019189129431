import gql from 'graphql-tag';

const CREATE_MESSAGE = gql`
  mutation createMessage(
    $conversationId: ID!
    $receiver: ID!
    $message: String!
    $otherInfo: OtherInfoInput!
  ) {
    createMessage(
      conversationId: $conversationId
      receiver: $receiver
      message: $message
      otherInfo: $otherInfo
    )
  }
`;

export default CREATE_MESSAGE;
