import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  overflow: hidden;
  transition: all 0.3s ease;
  background: ${({ theme }) => theme.colors.white};
  ${({ noBg, theme }) => noBg && `background: ${theme.colors.white}`};
  padding: ${({ padding }) => (padding ? padding : "2rem")};
  border-radius: 3px;
  ${({ shadow }) => shadow && `box-shadow: ${shadow}`};
  height: 100%;
  ${({ border, theme }) =>
    border &&
    `border-width: ${border}; border-color: ${theme.colors.almostWhite}; border-style: solid`};
  ${({ radius }) => radius && `border-radius: ${radius}`};
  ${({ hoverable }) =>
    hoverable &&
    `
      box-shadow: 0px 3px 6px rgb(181 181 181 / 58%);
      background: linear-gradient(
144deg, rgb(255 255 255) 0%, rgb(239 239 239) 100%) rgb(255 255 255);
      border: 1px solid #fdfbfb;
    :hover {
      box-shadow: 0 10px 10px 0px rgb(165 165 165 / 37%);
      transform: translateY(-10px);
      cursor: pointer;
      border-color: #eff1f1;
    }
  `}
  ${({ verticalAlign }) =>
    verticalAlign &&
    `
    display: flex;
    flex-direction: column;
    justify-content: ${verticalAlign}
  `};
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

const Segment = ({ children, ...otherProps }) => {
  return <Wrapper {...otherProps}>{children}</Wrapper>;
};

export default Segment;
