import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const Heading = ({ title, subtitle }) => {
  return (
    <Wrapper>
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </Wrapper>
  );
};

export default Heading;
