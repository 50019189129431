import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import toast from 'react-hot-toast';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HTTP_LINK,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    toast.error(graphQLErrors[0].message);
    // TODO: handle these errors
    //graphQLErrors.map(({ message }) => toast.error(message));
  }
  if (networkError) {
    // TODO: Redirect to login
    toast.error('Greška u mreži');
    localStorage.removeItem('user');
  }
});

const authLink = setContext((_, { headers }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  return {
    headers: {
      ...headers,
      authorization: user ? user.token : '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    getSinglePosition: {
      fields: {
        Files: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: cache,
  connectToDevTools: process.env.NODE_ENV === 'development' && true,
});

export default client;
