import gql from "graphql-tag";

const DELIVER_CORRECTION = gql`
  mutation deliveryCorrection(
    $orderId: ID!
    $acceptedOfferId: ID!
    $delivered: Boolean
  ) {
    deliveryCorrection(
      orderId: $orderId
      acceptedOfferId: $acceptedOfferId
      delivered: $delivered
    )
  }
`;

export default DELIVER_CORRECTION;
