import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import ProjectItem from './ProjectItem.component';
import Spinner from '../elements/Spinner/Spinner.component';
import { NavLink } from 'react-router-dom';
// import { RiGridFill } from "react-icons/ri";
// import { FaListUl } from "react-icons/fa";
import ProtectedContent from '../Auth/ProtectedContent/ProtectedContent';
import Button from '../elements/Button/Button.component';
import { filterContext } from './filterContext';
import useTranslation from '../../hooks/useTranslation';
import Distributor from '../structure/wrappers/Distributor/Distributor.component';

const Wrapper = styled.div``;

const ListingWrapper = styled.div`
  flex: 1;
  ${({ layout }) =>
    layout === 'grid'
      ? `
      display: grid;
      grid-template-columns: repeat(4, 250px);
      grid-gap: 2rem;
      justify-content: start;
  `
      : `
      display: block;
  `}
`;

const TopControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  :after {
    ${({ theme }) => theme.shadow.depth.sticky}
  }
`;

const ButtonChecker = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  background: ${({ theme }) => theme.colors.menuHighlight};
  border-radius: 3px;
  > svg {
    margin-right: 0.5rem;
    color: #a6a9ac;
  }
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &.active {
    background: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Filters = styled.div`
  display: flex;
  > ${ButtonChecker}:not(:last-child) {
    margin-right: 1rem;
  }
`;

const FilterButton = styled(NavLink)`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  background: ${({ theme }) => theme.colors.menuHighlight};
  border-radius: 3px;
  text-decoration: none;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }
  &.active {
    background: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ProjectList = () => {
  const [layout] = useState('list');
  const { setFilterPaneVisible, loading, data } = useContext(filterContext);
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Distributor sticky>
        <TopControls>
          <Filters>
            <FilterButton exact to="/projects">
              {t('Svi projekti')}
            </FilterButton>
            <ProtectedContent onlyFor={['BUYER']}>
              <FilterButton to="/projects/drafts">
                {t('Projekti u pripremi')}
              </FilterButton>
            </ProtectedContent>
            <ProtectedContent onlyFor={['BUYER']}>
              <FilterButton to="/projects/processed-by-buyer">
                {t('Obrađeni projekti')}
              </FilterButton>
            </ProtectedContent>
            <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
              <FilterButton exact to="/projects/unapproved">
                {t('Neobrađeni projekti')}
              </FilterButton>
            </ProtectedContent>
            <FilterButton exact to="/projects/active">
              {t('Aktivni projekti')}
            </FilterButton>
            <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR', 'BUYER']}>
              <FilterButton exact to="/projects/has-offers">
                {t('Sa_Ponudama')}
              </FilterButton>
            </ProtectedContent>
            <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
              <FilterButton exact to="/projects/sent-to-buyers">
                {t('Projekti s poslanim ponudama')}
              </FilterButton>
            </ProtectedContent>
            <ProtectedContent onlyFor={['MANUFACTURER']}>
              <FilterButton to="/projects/applied">
                {t('Aplicirani_Projekti')}
              </FilterButton>
            </ProtectedContent>
            <FilterButton to="/projects/archive">
              {t('Arhiva - istekli projekti')}
            </FilterButton>
            <ProtectedContent onlyFor={['ADMINISTRATOR']}>
              <FilterButton to="/projects/disabled">
                {t('Onemogućeni projekti')}
              </FilterButton>
            </ProtectedContent>
          </Filters>
          <Button onClick={() => setFilterPaneVisible(true)}>
            {t('Dodatni filteri')}
          </Button>
        </TopControls>
      </Distributor>
      {!loading ? (
        <ListingWrapper layout={layout}>
          {data?.getProjects.length ? (
            data?.getProjects.map((project) => (
              <ProjectItem layout={layout} data={project} key={project.id} />
            ))
          ) : (
            <p>{t('Nema_projekata_po_navedenom_filteru')}</p>
          )}
        </ListingWrapper>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default ProjectList;
