const theme = {
  colors: {
    accent: '#FF5D38',
    secondary: '#1948DF',
    secondaryHighlight: '#c7d4ff',
    green: '#60d260',
    lime: '#23e2a6',
    limeLight: '#e7fcf5',
    infoIcons: '#a5a5a5',
    highlight: '#FFDED7',
    greenHighlight: '#a6ffa0',
    highlightForeground: '#ab3920',
    white: '#FFF',
    almostWhite: '#e8e8e8',
    menuHighlight: '#ececec',
    menuHighlightBorder: '#c1c7cd',
    bluishWhite: '#e1e1e1',
    black: '#2d2f31',
    darkerGrey: '#a7abb0',
    sidebarNavigationBorder: '#eaeaea',
    sidebarBorder: '#D8E0E8',
    sidebarBackground: '#FAFBFC',
    sidebarIconFaded: '#bbc2ca',
    chatMessageBackground: '#F5F5F5',
    breadcrumbsBackground: '#fbfbfb',
    formPlaceholder: '#A2B4C5',
    quantitiesTableLabel: '#7b828c',
    backdrop: 'rgb(0, 60, 149, 0.5)',
    segmentBorder: '#e6e6e6',
    segmentBackground: '#f7f7f7',
    segmentIcon: '#5a5a5a',
    lightBlue: '#38DAFF',
    yellowish: '#ffc138',
  },
  sizes: {
    elementHeight: '2.3rem',
  },
  shadow: {
    depth: {
      half: '0 0 5px 0px rgb(0 0 0 / 15%)',
      projectItem: '0px 5px 10px 0px rgb(0 0 0 / 9%)',
      one: '0 0 5px 0px rgb(0 0 0 / 0.3)',
      modal: '0 0 20px 0px rgb(0 0 0 / 11%)',
      popupMenu: '0 10px 20px 2px rgb(0 0 0 / 20%)',
      pale: '0 2px 6px 0px rgb(0 0 0 / 10%);',
      sticky: {
        content: `""`,
        width: '95%',
        height: '5px',
        background: 'rgb(0 0 0 / 70%)',
        position: 'absolute',
        display: 'block',
        left: '2.5%',
        bottom: '2px',
        filter: 'blur(15px)',
        zIndex: '-1',
      },
    },
  },
  gradients: {
    titlebar: `
    background: rgb(103,179,255);
    background: linear-gradient(-90deg, rgba(103,179,255,1) 0%, rgba(25,72,223,1) 100%);
    `,
  },
};

export default theme;
