import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaEllipsisH } from 'react-icons/fa';
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionHeader,
  ActionCell,
} from '../../../components/elements/BasicTable/BasicTable.component';
import { ModalContext } from '../../../components/structure/Modal/modalContext';

import ViewBuyersOrder from './ViewBuyersOrder';
import Button from '../../../components/elements/Button/Button.component';
import ACCEPT_ORDER from '../../../apollo/mutations/acceptOrder';
import GET_BUYERS_ORDERS from '../../../apollo/queries/getBuyersOrders';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Spinner from '../../../components/elements/Spinner/Spinner.component';
import Container from '../../../components/structure/wrappers/Container/Container.component';
import Main from '../../../components/structure/wrappers/Main/Main.component';
import useTranslation from '../../../hooks/useTranslation';
import { PAYMENT_TIMEFRAME } from 'src/constants';
import Input from 'src/components/elements/Input/Input.component';
import { useForm } from 'react-hook-form';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Space from 'src/components/elements/Space/Space.component';
import { roundDown } from 'src/util/math';
import SEEN_ORDER from 'src/apollo/mutations/seenOrder';
import ButtonDropdown from 'src/components/elements/ButtonDropdown/ButtonDropdown.component';
import ManufacturerDetailsPreview from './ManufacturerDetailsPreview';

const TopControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoOffers = styled.div`
  text-decoration: line-through;
  opacity: 0.5;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.quantitiesTableLabel};
  border-radius: 3px;
`;
const Wrapper = styled.div``;
const ContentWrapper = styled.div`
  margin-bottom: 4rem;
`;

const OrderedWrapper = styled.div`
  ${({ border, theme }) =>
    border && `border-${border}: 2px solid ${theme.colors.black}`};
  table {
    border-spacing: 40px 0;
    td,
    th {
      padding: 0.3rem;
      text-align: right;
    }
  }
`;

const BuyerOrderDetails = () => {
  const { t } = useTranslation();
  const { register, getValues } = useForm();
  const { id } = useParams();
  const { data, loading } = useQuery(GET_BUYERS_ORDERS, {
    fetchPolicy: 'network-only',
  });
  const [seenOrderMutation] = useMutation(SEEN_ORDER);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [order, setOrder] = useState();
  const { handleModal } = useContext(ModalContext);
  const [orderSent, setOrderSent] = useState(false);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [acceptOrderMutation, { loading: acceptOrderLoading }] = useMutation(
    ACCEPT_ORDER,
    {
      onError: () => {},
      onCompleted: () => {
        setOrderSent(true);
        toast.success(t('Cijene prihvaćene'));
      },
    }
  );

  useEffect(() => {
    const ordersData = data?.getBuyersOrders;
    if (ordersData) {
      const currentOrder = ordersData?.find((o) => o._id === id);
      setOrder(currentOrder);

      /* if order is not seen set it as user has already opened it */
      if (!currentOrder?.seen?.byBuyer?.status) {
        seenOrderMutation({
          variables: {
            orderId: id,
          },
          refetchQueries: ['getBuyersOrders'],
        });
      }
    }
  }, [data, id]);

  /* get totals from order */

  useEffect(() => {
    if (order) {
      setTax(order?.project?.createdBy?.company?.taxPercentage);
      setTotal(
        order?.acceptedOffers?.reduce((acc, curr) => {
          if (curr.acceptedByBuyer) {
            return (
              parseInt(curr.quantity) * parseFloat(curr.refinedPrice) + acc
            );
          }
          return acc;
        }, 0)
      );
    }
  }, [order]);

  const handleChange = (e) => {
    const { name, checked } = e.target;

    /* recalculate totals */
    console.log(order, name);
    const offerIndex = order?.acceptedOffers?.findIndex((o) => o._id === name);

    const quantity = order?.acceptedOffers[offerIndex].quantity;
    const price = order?.acceptedOffers[offerIndex].refinedPrice;

    const val = selectedPositions.find((p) => p === name);
    if (!val && checked) {
      setSelectedPositions((p) => [...p, name]);

      setTotal(total + quantity * price);
    } else {
      setSelectedPositions((p) => p.filter((r) => r !== name));
      setTotal(total - quantity * price);
    }
  };

  useEffect(() => {}, [selectedPositions]);

  const handleBuyerApprovesOrder = (orderId) => {
    if (!selectedPositions.length)
      return toast.error('Niste odabrali nijednu poziciju');

    // enter physical order number
    const physicalOrderNumber = getValues('physicalOrderNumber');
    if (!physicalOrderNumber) {
      return toast.error(t('Morate unijeti broj narudžbe'));
    }

    if (
      prompt(t('Da biste prihvatili cijene upišite POTVRDA')).trim() ===
      t('POTVRDA')
    ) {
      acceptOrderMutation({
        variables: {
          orderId,
          physicalOrderNumber,
          acceptedOffers: selectedPositions,
        },
        refetchQueries: ['getBuyersOrders'],
      });
    }
  };

  return order ? (
    <Wrapper>
      <Container>
        <Main>
          <ContentWrapper>
            <TopControls>
              <h1>{order.project.name}</h1>
              {!order.accepted?.byBuyer && !orderSent ? (
                <Distributor col="1-1">
                  <Button
                    disabled={!selectedPositions.length}
                    loading={acceptOrderLoading}
                    onClick={() => handleBuyerApprovesOrder(order._id)}
                  >
                    {t('Naruči odabrane pozicije')}
                    {selectedPositions.length > 0 &&
                      `(${selectedPositions.length})`}
                  </Button>
                  <Input
                    type="text"
                    name="physicalOrderNumber"
                    ref={register}
                    placeholder={t('Broj narudžbe')}
                  />
                </Distributor>
              ) : (
                <OrderedWrapper>
                  <table>
                    <thead>
                      <th>{t('Status')}</th>
                      <th>{t('Broj narudžbe')}</th>
                    </thead>
                    <tbody>
                      <td>{t('Naručeno')}</td>
                      <td>{order.accepted?.physicalOrderNumber}</td>
                    </tbody>
                  </table>
                </OrderedWrapper>
              )}
            </TopControls>
            <Space vertical="2rem" />
            <BasicTable loading={loading}>
              <TableHead>
                <TableRow>
                  <ActionHeader></ActionHeader>
                  <TableHeader>{t('Pozicija')}</TableHeader>
                  <TableHeader>{t('Šifra pozicije')}</TableHeader>
                  <TableHeader>{t('Realno vrijeme isporuke')}</TableHeader>
                  <TableHeader>{t('Datum kreiranja ponude')}</TableHeader>
                  <TableHeader>{t('Broj ponude')}</TableHeader>
                  <TableHeader>{t('Količina')}</TableHeader>
                  <TableHeader>{t('Cijena')}</TableHeader>
                  <TableHeader>{t('Ukupna cijena (Eur)')}</TableHeader>
                  <TableHeader>{t('U tranzitu')}</TableHeader>
                  <ActionHeader></ActionHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.acceptedOffers.map((o) => (
                  <TableRow key={o._id} activeRow={o?.acceptedByBuyer}>
                    <TableCell>
                      {!order.accepted?.byBuyer && (
                        <input
                          type="checkbox"
                          name={o._id}
                          onChange={handleChange}
                        />
                      )}
                    </TableCell>
                    <TableCell>{o.position.name}</TableCell>
                    <TableCell>{o.position.positionCode}</TableCell>
                    <TableCell>{o.realisticDeliveryTime}</TableCell>
                    <TableCell>{order.dateCreated}</TableCell>
                    <TableCell>{o.physicalOrderNumber}</TableCell>
                    <TableCell>{o.quantity}</TableCell>
                    <TableCell>{o.refinedPrice?.toFixed(2)}</TableCell>
                    <TableCell>
                      {(
                        parseInt(o.quantity) * parseFloat(o.refinedPrice)
                      ).toLocaleString('de', {
                        minimumFractionDigits: 2,
                      })}{' '}
                      EUR
                    </TableCell>
                    <TableCell>
                      {o.deliveryStatus?.deliveredByManufacturer
                        ?.confirmedDelivery?.status
                        ? t('Da')
                        : ''}
                    </TableCell>
                    <ActionCell>
                      <ButtonDropdown
                        actions={[
                          {
                            label: t('Detalji pozicije'),
                            action: () =>
                              handleModal(
                                <ViewBuyersOrder
                                  orderId={order._id}
                                  positionId={o.position.id}
                                  data={o}
                                />
                              ),
                          },
                          {
                            showIf: o.user.attributes.revealManufacturersInfo,
                            label: 'Kvalifikacije proizvođača',
                            action: () =>
                              handleModal(
                                <ManufacturerDetailsPreview
                                  manufacturersId={o.user.id}
                                />
                              ),
                          },
                        ]}
                      />
                    </ActionCell>
                  </TableRow>
                ))}
                {order.project?.positions?.map(
                  (o) =>
                    !order.acceptedOffers.some(
                      (_o) => _o.position.id === o.id
                    ) && (
                      <TableRow key={o.id}>
                        <TableCell></TableCell>
                        <TableCell>
                          <NoOffers>{o.name}</NoOffers>
                        </TableCell>
                        <TableCell>{t('Nema ponuda')}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </BasicTable>
            <Space vertical="2rem" />
            <Distributor stack="end">
              <OrderedWrapper border="top">
                <table>
                  <thead>
                    <tr>
                      <th>{t('PDV')}</th>
                      <th>{t('Ukupno bez PDV-a')}</th>
                      <th>{t('Ukupno s PDV-om')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {parseFloat(total * (tax / 100))?.toLocaleString('de', {
                          // TODO: nema valute
                          minimumFractionDigits: 2,
                          currency: 'EUR',
                        })}
                      </td>
                      <td>
                        {total?.toLocaleString('de', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        EUR
                      </td>
                      <td>
                        {tax
                          ? (total * (tax / 100) + total)?.toLocaleString(
                              'de',
                              {
                                minimumFractionDigits: 2,
                              }
                            )
                          : total?.toLocaleString('de', {
                              minimumFractionDigits: 2,
                            })}{' '}
                        EUR
                      </td>
                    </tr>
                  </tbody>
                </table>
              </OrderedWrapper>
            </Distributor>
          </ContentWrapper>
          <ContentWrapper>
            <h3>{t('Napomena')}</h3>
            {order?.note?.message || t('Nema napomene')}
          </ContentWrapper>
          <ContentWrapper>
            <h3>{t('Plaćanje i transport')}</h3>
            <BasicTable>
              <TableHead>
                <TableRow>
                  <TableHeader>{t('Adresa isporuke')}</TableHeader>
                  <TableHeader>{t('Troškovi isporuke')}</TableHeader>
                  <TableHeader>{t('Način isporuke')}</TableHeader>
                  <TableHeader>{t('Metoda plaćanja')}</TableHeader>
                  <TableHeader>{t('Vrijeme za plaćanje')}</TableHeader>
                  <TableHeader>{t('Valuta')}</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{order.conditions.shipmentAddress}</TableCell>
                  <TableCell>{order.conditions.transportExpenses}</TableCell>
                  <TableCell>
                    {order.conditions.paymentSchedule?.toUpperCase()}
                  </TableCell>
                  <TableCell>{order.conditions.paymentMethod}</TableCell>
                  <TableCell>
                    {t(PAYMENT_TIMEFRAME[order.conditions.paymentTimeframe])}
                  </TableCell>
                  <TableCell>{order.conditions.currency}</TableCell>
                </TableRow>
              </TableBody>
            </BasicTable>
          </ContentWrapper>
        </Main>
      </Container>
    </Wrapper>
  ) : (
    <Spinner />
  );
};

export default BuyerOrderDetails;
