import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  ${({ vertical }) => vertical && `height: ${vertical}`};
  ${({ horizontal }) => horizontal && `width: ${horizontal}`};
`;

const Space = (props) => {
  return <Wrapper {...props} />;
};

export default Space;
