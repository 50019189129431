import React from "react";
import styled from "styled-components";
import Distributor from "../../structure/wrappers/Distributor/Distributor.component";
import Button from "../Button/Button.component";
import { ImMinus } from "react-icons/im";

const Wrapper = styled.div``;
const ValueGrid = styled.div`
border-width: 1px 0;
border-style: solid;
border-color ${({ theme }) => theme.colors.sidebarBorder};
margin: 0.5rem 0.5rem 0 0.5rem;
padding: 0.5rem 0;
`;
const Value = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
const Row = styled.div`
  :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
const Icon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.menuHighlight};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px;
    > svg {
      color: ${({ theme }) => theme.colors.infoIcons};
    }
    :hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.highlight};
      > svg {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`;

const SelectWrapper = styled.div`
  display: flex;
`;

const SelectField = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const ParametersTable = ({ children, data, label, addData, removeData }) => {
  const handleClick = (e) => {
    e.preventDefault();
    addData();
  };

  const handleRemoveData = (id) => {
    removeData(id);
  };

  return (
    <Wrapper>
      <Distributor>
        <SelectWrapper>
          <SelectField>{children}</SelectField>
          <Button onClick={handleClick}>Dodaj</Button>
        </SelectWrapper>
      </Distributor>
      {data.length > 0 && (
        <ValueGrid>
          {data.map((param) => (
            <Row key={param.parameter}>
              <Distributor col="4-2-2-2-1">
                <Value>{param.label}</Value>
                <Value>{param.minValue}</Value>
                <Value>{param.maxValue}</Value>
                <Value>{param.units}</Value>
                <Icon>
                  <span onClick={() => handleRemoveData(param.parameter)}>
                    <ImMinus />
                  </span>
                </Icon>
              </Distributor>
            </Row>
          ))}
        </ValueGrid>
      )}
    </Wrapper>
  );
};

export default ParametersTable;
