import gql from "graphql-tag";

const DELIVERED_MANUFACTURERS_ORDERS_VALUE = gql`
  query deliveredManufacturersOrdersValue($undelivered: Boolean) {
    deliveredManufacturersOrdersValue(undelivered: $undelivered) {
      dateCreated
      value
    }
  }
`;

export default DELIVERED_MANUFACTURERS_ORDERS_VALUE;
