/* eslint-disable no-template-curly-in-string */

import { ADDITIONAL_CHARACTERISTICS_CATEGORIES } from 'src/constants';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'Neophodno polje',
    oneOf: 'Morate odabrati unos iz liste',
  },
  string: {
    min: 'Minimalno ${min} znakova',
    matches: 'Nedozvoljeni znakovi',
  },
  number: {
    default: 'Mora biti broj',
  },
});

const addUserFormSchema = yup.object().shape({
  username: yup
    .string()
    .required()
    .matches(/^[A-Za-z]+$/)
    .min(5),
  email: yup.string().email().required(),
  name: yup.string().required().min(5),
  companyName: yup.string(),
  password: yup.string().required().min(5),
  reseller: yup.boolean(),
});

const approveOrderSchema = yup.object().shape({
  shipmentAddress: yup.string().required(),
  transportExpenses: yup
    .number()
    .required()
    .typeError('Neophodan numerički unos'),
  paymentSchedule: yup.string().required(),
  paymentMethod: yup.string().required(),
  paymentTimeframe: yup.string().required(),
});

const updateCompanyFormSchema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
  street: yup.string(),
  city: yup.string(),
  country: yup.string().required(),
  shipmentAddress: yup.string().required(),
  transportExpenses: yup.number().required(),
  paymentSchedule: yup.string().required(),
  paymentMethod: yup.string().required(),
  paymentTimeframe: yup.string().required(),
  currency: yup.string().required(),
  taxPercentage: yup.number().required(),
});

const addTechnologyFormSchema = yup.object().shape({
  name: yup.string().required(),
  category: yup.string().required(),
});
const addTechnologyParameterSchema = yup.object().shape({});
const addTechnologyCategoryFormSchema = yup.object().shape({
  name: yup.string().required(),
});

const addMaterialCategoryFormSchema = yup.object().shape({
  name: yup.string().required(),
});

const addMaterialFormSchema = yup.object().shape({
  name: yup.string().required(),
  category: yup.string().required(),
});

const addManufacturingCategoryFormSchema = yup.object().shape({
  name: yup.string().required(),
});

const addManufacturingFormSchema = yup.object().shape({
  name: yup.string().required(),
  category: yup.string().required(),
});

const addProjectFormSchema = yup.object().shape({
  name: yup.string().required(),
  deliveryDeadline: yup.date().required(),
  offerDeadline: yup.date().required(),
});

const addPositionFormSchema = yup.object().shape({
  name: yup.string().required(),
  positionCode: yup.string().required(),
  // technology: yup.string().required(),
  // manufacturing: yup.string().required(),
  note: yup.string().max(5000),
});

const loginSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const sendInquiryFormSchema = yup.object().shape({
  title: yup.string().required(),
  message: yup.string().required(),
});

const updateUserFormSchema = yup.object().shape({
  username: yup
    .string()
    .matches(/^[A-Za-z]+$/)
    .min(5),
  reseller: yup.boolean(),
});

const changeUserPasswordFormSchema = yup.object().shape({
  password: yup.string().required().min(5),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Nisu identični unosi'),
});

const toggleActiveProjectFormSchema = yup.object().shape({
  toggleActive: yup.boolean().required(),
});

const addMachineFormSchema = yup.object().shape({
  name: yup.string().required().max(40),
  manufacturer: yup.string().required().max(40),
  control: yup.string().max(40),
  manufacturedYear: yup.number(),
  quantity: yup.number(),
  drivenTools: yup.boolean(),
  materialAdder: yup.boolean(),
});

const authorizeUserSchema = yup.object().shape({
  password: yup.string().required(),
});

const additionalFiltersSchema = yup.object().shape({
  quantityMin: yup.number(),
  quantityMax: yup.number(),
});

const additionalCharacteristicsSchema = yup.object().shape({
  name: yup.string().required(),
  category: yup
    .string()
    .oneOf(Object.keys(ADDITIONAL_CHARACTERISTICS_CATEGORIES)),
});

export {
  approveOrderSchema,
  addMachineFormSchema,
  toggleActiveProjectFormSchema,
  changeUserPasswordFormSchema,
  updateUserFormSchema,
  sendInquiryFormSchema,
  loginSchema,
  addUserFormSchema,
  addTechnologyFormSchema,
  addTechnologyParameterSchema,
  addTechnologyCategoryFormSchema,
  addMaterialCategoryFormSchema,
  addMaterialFormSchema,
  addManufacturingCategoryFormSchema,
  addManufacturingFormSchema,
  addProjectFormSchema,
  addPositionFormSchema,
  updateCompanyFormSchema,
  authorizeUserSchema,
  additionalFiltersSchema,
  additionalCharacteristicsSchema,
};
