import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  ${({ width }) => width && `width: ${width}`};
  ${({ transparent }) =>
    transparent &&
    `
    input { 
      background-color: transparent;
      border: none;
      outline: none;
    }
    `}
  position: relative;
  display: flex;
  align-items: center;
  :focus-within {
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  > svg {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Input = React.forwardRef(
  ({ icon, width, transparent, ...otherProps }, ref) => {
    return (
      <Wrapper width={width} transparent={transparent}>
        <input {...otherProps} ref={ref} maxLength="200" />
        <IconWrapper>{icon}</IconWrapper>
      </Wrapper>
    );
  }
);

export default Input;
