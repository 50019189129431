import React, { useContext } from 'react';
import styled from 'styled-components';
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
} from '../../../components/elements/BasicTable/BasicTable.component';
import FormField from '../../../components/structure/wrappers/FormField/FormField.component';
import Button from '../../../components/elements/Button/Button.component';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import { ModalContext } from '../../../components/structure/Modal/modalContext';
import Acknowledge from '../../../components/Acknowledge/Acknowledge';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import APPROVE_ORDER from '../../../apollo/mutations/approveOrder';
import { roundDown } from '../../../util/math';
import useTranslation from 'src/hooks/useTranslation';
import Input from 'src/components/elements/Input/Input.component';
import { useForm } from 'react-hook-form';

const Wrapper = styled.div``;
const HeaderInfo = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 2rem;
  }
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.menuHighlight};
  & strong {
    margin-right: 1rem;
  }
`;

const ApproveOfferForBuyer = ({ data }) => {
  const { register, setValue, getValues } = useForm();
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const [approveOrderMutation, { loading }] = useMutation(APPROVE_ORDER, {
    onCompleted: () => {
      handleModal();
      toast.success('Narudžbenica poslana');
    },
  });

  const handleApproveOrder = () => {
    const manufacturersOrderNumbers = data?.acceptedOffers
      ?.map((offer) => {
        const manufacturersOrderNumber = getValues(
          `manufacturersOrderNumber_${offer._id}`
        );
        if (!manufacturersOrderNumber) return false;
        return {
          offerId: offer._id,
          manufacturersOrderNumber,
        };
      })
      .filter(Boolean);
    if (
      manufacturersOrderNumbers?.length !==
      data?.acceptedOffers?.reduce(
        (acc, curr) => (curr.acceptedByBuyer ? acc + 1 : acc),
        0
      )
    )
      return toast.error('Morate unijeti brojeve narudžbi proizvođača');

    approveOrderMutation({
      variables: {
        orderId: data._id,
        manufacturersOrderNumbers,
      },
      refetchQueries: ['getAllOrders'],
    });
  };

  return (
    <Wrapper>
      <h3>
        Projekt: {data.project.name} | Šifra projekta:{' '}
        {data.project.projectCode}
      </h3>
      <HeaderInfo>
        <div>
          <strong>Naziv kupca:</strong>
          {data.project.createdBy.company.name}
        </div>
        <div>
          <strong>Odgovorna osoba:</strong>
          {data.project.createdBy.name}
        </div>
      </HeaderInfo>
      <BasicTable>
        <TableHead>
          <TableRow>
            <TableHeader>Proizvođač</TableHeader>
            <TableHeader>Vrijeme plaćanja</TableHeader>
            <TableHeader>Realno vrijeme isporuke</TableHeader>
            <TableHeader>Pozicija</TableHeader>
            <TableHeader>Količina</TableHeader>
            <TableHeader>Ciljana cijena</TableHeader>
            <TableHeader>Odabrana cijena</TableHeader>
            <TableHeader>Vaša cijena</TableHeader>
            <TableHeader>Broj narudžbe</TableHeader>
            <TableHeader>Br. nar. kupca</TableHeader>
            <TableHeader>Br. nar. prema dob.</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.acceptedOffers.map(
            (offer) =>
              offer?.acceptedByBuyer && (
                <TableRow key={offer._id}>
                  <TableCell>{offer.user.company?.name}</TableCell>
                  <TableCell>
                    {offer.user?.company?.paymentAndTransport?.paymentTimeframe}
                  </TableCell>
                  <TableCell>{offer?.realisticDeliveryTime || '-'}</TableCell>
                  <TableCell>
                    <a
                      href={
                        process.env.REACT_APP_S3_BUCKET +
                        offer?.position?.files?.jpg
                      }
                    >
                      {offer?.position.name}
                      <br />
                      {offer?.position.positionCode}
                    </a>
                  </TableCell>
                  <TableCell>{offer?.quantity}</TableCell>
                  <TableCell>
                    {`${offer?.aimedPrice?.toFixed(
                      2
                    )} / ${offer?.administratorAimedPrice?.toFixed(2)}`}
                  </TableCell>
                  <TableCell
                    title={offer.position.quantities
                      ?.find(({ quantity }) => quantity === offer.quantity)
                      ?.offers?.map((v) => v)
                      ?.sort(
                        (a, b) =>
                          a.offeredQuantityPrice - b.offeredQuantityPrice
                      )
                      ?.map(
                        ({
                          offeredQuantityPrice,
                          userId: {
                            name,
                            company: { name: companyName },
                          },
                        }) =>
                          `${companyName} | ${name} - ${offeredQuantityPrice}`
                      )
                      ?.join('\n')}
                  >
                    {roundDown(offer?.acceptedPrice)}
                  </TableCell>
                  <TableCell>{roundDown(offer?.refinedPrice)}</TableCell>
                  <TableCell>{offer?.physicalOrderNumber || '-'}</TableCell>
                  <TableCell>
                    {data.accepted?.physicalOrderNumber || '-'}
                  </TableCell>
                  <TableCell>
                    <Input
                      ref={register}
                      name={`manufacturersOrderNumber_${offer._id}`}
                    />
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </BasicTable>

      <FormField>
        <h3>{t('Plaćanje i transport')}</h3>
      </FormField>
      <BasicTable>
        <TableHead>
          <TableRow>
            <TableHeader>{t('Adresa isporuke')}</TableHeader>
            <TableHeader>{t('Troškovi isporuke')}</TableHeader>
            <TableHeader>{t('Način isporuke')}</TableHeader>
            <TableHeader>{t('Metoda plaćanja')}</TableHeader>
            <TableHeader>{t('Vrijeme za plaćanje')}</TableHeader>
            <TableHeader>{t('Valuta')}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{data?.conditions?.shipmentAddress}</TableCell>
            <TableCell>{data?.conditions?.transportExpenses}</TableCell>
            <TableCell>
              {data?.conditions?.paymentSchedule?.toUpperCase()}
            </TableCell>
            <TableCell>{data?.conditions?.paymentMethod}</TableCell>
            <TableCell>{data?.conditions?.paymentTimeframe}</TableCell>
            <TableCell>{data?.conditions?.currency}</TableCell>
          </TableRow>
        </TableBody>
      </BasicTable>
      <Acknowledge layout="notice">
        Potvrdom slanja narudžbe će biti distribuirane svim proizvođačima,
        kompletna narudžba kupcu i kopija administratoru.
      </Acknowledge>
      <Distributor stack="end" submitButtons>
        <FormField>
          <Button loading={loading} onClick={handleApproveOrder}>
            Zaključi narudžbu
          </Button>
        </FormField>
      </Distributor>
    </Wrapper>
  );
};

export { HeaderInfo, ApproveOfferForBuyer as default };
