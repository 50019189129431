import gql from 'graphql-tag';

const TOGGLE_CONVERSATION_PRIORITY = gql`
  mutation toggleConversationPriority(
    $conversationId: String!
    $priority: Int!
  ) {
    toggleConversationPriority(
      conversationId: $conversationId
      priority: $priority
    )
  }
`;

export default TOGGLE_CONVERSATION_PRIORITY;
