import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChip } from 'react-icons/bi';
import DELIVERED_MANUFACTURERS_ORDERS_VALUE from 'src/apollo/queries/dashboard/deliveredManufacturersOrdersValue';
import ChartStats from 'src/components/ChartStats/ChartStats';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import { IconWrapper, Number } from './Dashboard';

const ManufacturerUndeliveredOrdersValueChart = () => {
  const { t } = useTranslation();
  // latest manufacturers undelivered orders value
  const [lmudov, setLmudov] = useState([]);

  const {
    loading,
    data: {
      deliveredManufacturersOrdersValue: undeliveredManufacturersOrdersValue,
    } = {},
    error: undeliveredManufacturersOrdersValueError,
  } = useQuery(DELIVERED_MANUFACTURERS_ORDERS_VALUE, {
    variables: { undelivered: true },
  });

  useEffect(() => {
    if (undeliveredManufacturersOrdersValue?.length) {
      setLmudov(groupBy(undeliveredManufacturersOrdersValue, 'dateCreated'));
    }
  }, [undeliveredManufacturersOrdersValue]);
  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t('Neisporučene narudžbe (30 dana)')}</h4>
          {loading ? (
            <Spinner />
          ) : (
            <Number>{undeliveredManufacturersOrdersValue}</Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats
            data={lmudov}
            title={t('Ukupna vrijednost')}
            sumField="value"
            yAxisLabel={t('Vrijednost (€)')}
          />
        )}
      </Segment>
    </Distributor>
  );
};

export default ManufacturerUndeliveredOrdersValueChart;
