import { useMutation } from '@apollo/client';
import { pick } from 'lodash';
import React, { useContext } from 'react';
import toast from 'react-hot-toast';
import { MdClose } from 'react-icons/md';
import CREATE_ORDER from 'src/apollo/mutations/createOrder';
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from 'src/components/elements/BasicTable/BasicTable.component';
import Button from 'src/components/elements/Button/Button.component';
import ButtonDropdown from 'src/components/elements/ButtonDropdown/ButtonDropdown.component';
import Space from 'src/components/elements/Space/Space.component';
import { CloseButton } from 'src/components/structure/Modal/Modal.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import useTranslation from 'src/hooks/useTranslation';
import styled, { keyframes } from 'styled-components';
import { projectDetailsContext } from '../../projectDetailsContext';

const animation = keyframes`
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 -10px 20px 0px rgb(0 0 0 / 15%);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  max-height: 600px;
  overflow-y: scroll;
  transition: ${animation} 0.5s ease;
`;
const TableWrapper = styled.div`
  flex: 1;
`;

const Prices = ({ data }) => {
  const { t } = useTranslation();
  const { prices, setPrices, setPricePanelVisible } = useContext(
    projectDetailsContext
  );
  /* persist prices with Redux */
  const [createOrderMutation, { loading }] = useMutation(CREATE_ORDER, {
    onCompleted: () => {
      toast.success('Ponuda uspješno poslana');
    },
    onError: () => {},
  });

  const handleClearOrder = () => {
    setPrices([]);
    setPricePanelVisible(false);
  };

  const handleCreateOrder = () => {
    const project = data.id;
    const acceptedOffers = prices.map((v) =>
      pick(v, [
        'user',
        'position',
        'acceptedPrice',
        'aimedPrice',
        'administratorAimedPrice',
        'quantity',
        'realisticDeliveryTime',
      ])
    );

    // check if all positions have offer
    if (acceptedOffers.length !== data.positions.length) {
      const positionsWithoutOffer = data.positions
        .filter(
          (position) =>
            acceptedOffers.findIndex(
              (offer) => offer.position === position.id
            ) === -1
        )
        .map((position) => position.name)
        .join('\r\n');
      if (
        !window.confirm(
          'Niste odabrali ponude za navedene pozicije, da li želite nastaviti?\r\n\r\n' +
            positionsWithoutOffer
        )
      ) {
        return;
      }
    }

    const values = {
      project,
      acceptedOffers,
    };

    console.log(values);

    createOrderMutation({
      variables: { ...values },
      refetchQueries: ['getSingleProject'],
    });
  };

  const handleRemovePrice = (id) => {
    setPrices((currentPrices) => {
      return currentPrices.filter((p) => p.offerId !== id);
    });
  };

  return (
    <Wrapper>
      <CloseButton onClick={() => setPricePanelVisible(false)}>
        <MdClose size="20px" />
      </CloseButton>
      <TableWrapper>
        <BasicTable>
          <TableHead>
            <TableRow>
              <TableHeader>Firma</TableHeader>
              <TableHeader>Korisnik</TableHeader>
              <TableHeader>Pozicija</TableHeader>
              <TableHeader>Šifra pozicije</TableHeader>
              <TableHeader>Količina</TableHeader>
              <TableHeader>Cijena</TableHeader>
              <ActionHeader></ActionHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {prices?.map((price) => (
              <TableRow>
                <TableCell>{price.companyName}</TableCell>
                <TableCell>{price.userName}</TableCell>
                <TableCell>{price.positionName}</TableCell>
                <TableCell>{price.positionCode}</TableCell>
                <TableCell>{price.quantity}</TableCell>
                <TableCell>{price.acceptedPrice.toFixed(2)}</TableCell>
                <ActionCell>
                  <ButtonDropdown
                    id={price.offerId}
                    actions={[
                      {
                        label: 'Ukloni cijenu',
                        action: handleRemovePrice,
                      },
                    ]}
                  />
                </ActionCell>
              </TableRow>
            ))}
          </TableBody>
        </BasicTable>
      </TableWrapper>
      <Space vertical="2rem" />
      <Distributor stack="end">
        <Button layout="hollow" onClick={handleClearOrder}>
          Poništi
        </Button>
        <Button
          disabled={!prices.length}
          onClick={handleCreateOrder}
          loading={loading}
        >
          {t('Prihvati najpovoljnije cijene')}
        </Button>
      </Distributor>
    </Wrapper>
  );
};

export default Prices;
