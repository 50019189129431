import React from "react";
import styled from "styled-components";
import Distributor from "../../structure/wrappers/Distributor/Distributor.component";
import Input from "../Input/Input.component";
import Label from "../Label/Label.component";
import Button from "../Button/Button.component";
import { ImMinus } from "react-icons/im";
import FormField from "../../structure/wrappers/FormField/FormField.component";
import useTranslation from "src/hooks/useTranslation";

const Wrapper = styled.div``;
const ValueGrid = styled.div`
border-width: 1px 0;
border-style: solid;
border-color ${({ theme }) => theme.colors.sidebarBorder};
margin: 0.5rem 0.5rem 0 0.5rem;
padding: 0.5rem 0;
`;
const Value = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
const Row = styled.div`
  :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
const Icon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.menuHighlight};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px;
    > svg {
      color: ${({ theme }) => theme.colors.infoIcons};
    }
    :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.highlight};
    > svg {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

const Control = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const TopControls = styled.div`
  display: flex;
  align-items: flex-end;
  > *:last-child {
    display: flex;
    height: 100%;
`;

const InputTable = ({ children, data, addData, removeData, disabled }) => {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    addData && addData();
  };

  const handleRemoveData = (id) => {
    removeData && removeData(id);
  };

  const childrenData = children.length ? children : [children];

  return (
    <Wrapper>
      <TopControls>
        {!disabled &&
          childrenData.map(({ props: { label, ...otherProps } }, index) => (
            <Control key={index}>
              <Label>{label}</Label>
              <Input {...otherProps} />
            </Control>
          ))}
        {!disabled && <Button onClick={handleClick}>{t("Dodaj")}</Button>}
      </TopControls>
      {data && data.length > 0 && (
        <ValueGrid>
          {data.map((val, i) => (
            <Row key={i}>
              <Distributor col="4-4-1">
                {children.map((child, i) => (
                  <Value key={i}>
                    {val[child.props.name] || t("bez ciljane cijene")}
                  </Value>
                ))}
                {!disabled && (
                  <Icon>
                    <span onClick={() => handleRemoveData(i)}>
                      <ImMinus />
                    </span>
                  </Icon>
                )}
              </Distributor>
            </Row>
          ))}
        </ValueGrid>
      )}
    </Wrapper>
  );
};

export default InputTable;
