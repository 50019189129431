import React from "react";
import AdditionalFilters from "src/components/Projects/AdditionalFilters";
import styled from "styled-components";
import Heading from "../../components/elements/Heading/Heading.component";
import { FilterProvider } from "../../components/Projects/filterContext";
import ProjectList from "../../components/Projects/ProjectList.component";
import Container from "../../components/structure/wrappers/Container/Container.component";
import Main from "../../components/structure/wrappers/Main/Main.component";
import useTranslation from "../../hooks/useTranslation";

const Wrapper = styled.div``;

const Projects = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <FilterProvider>
        <Container>
          <Main>
            <Heading title={t("ProjectsPage__Title")} />
            <ProjectList />
            <AdditionalFilters />
            {/* <Pagination /> */}
          </Main>
        </Container>
      </FilterProvider>
    </Wrapper>
  );
};

export default Projects;
