import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Input from "../elements/Input/Input.component";
import { debounce } from "lodash";
import Label from "../elements/Label/Label.component";
import { get } from "lodash";
import { propertyByString } from "../../util/util";

const Wrapper = styled.div`
  position: relative;
`;

const Results = styled.div`
  position: absolute;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadow.depth.popupMenu};
  background: ${({ theme }) => theme.colors.white};
`;

const ResultItem = styled.div`
  padding: 0.5rem 1rem;
  :hover {
    background: ${({ theme }) => theme.colors.menuHighlight};
    cursor: pointer;
  }
  span {
    display: block;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AdditionalInfo = styled.div`
  font-size: 0.8rem;
  span {
    font-weight: 700;
  }
`;

const AutoComplete = ({
  values,
  label,
  data,
  minchars,
  onSelect,
  names,
  handler, // which field to use as main label
  loading,
  additionalInfo,
  additionalInfoLabel,
  singleitem,
  ...otherProps
}) => {
  const [inputValue, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [selected, setSelected] = useState({});

  const handleSearch = (query) => {
    // if there are multiple search parameters
    if (Array.isArray(names)) {
      setResults(
        data.filter((v) => {
          // check if either of the parameters from array "names" matches the query
          return names.some((param) => {
            return (
              get(v, param)?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1
            );
          });
        })
      );
    } else {
      setResults(
        data.filter(
          (v) => v[names].toLowerCase().indexOf(query.toLowerCase()) !== -1
        )
      );
    }
  };

  const handleSelect = (e, value, name) => {
    e.stopPropagation();
    setSelected({ value, name });
    onSelect({ value, name });
    setResults([]);
    setValue("");
  };

  const debouncedSearch = useCallback(
    debounce((val) => handleSearch(val), 500),
    []
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    if (value.length >= minchars) {
      debouncedSearch(value);
    } else {
      setResults([]);
    }
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      {!(singleitem?.length === 1) && (
        <Input
          {...otherProps}
          onChange={handleChange}
          value={inputValue}
          autoComplete="off"
        />
      )}
      {results.length > 0 && (
        <Results>
          {results.map((r) => (
            <ResultItem
              key={r[values]}
              onClick={(e) => handleSelect(e, r[values], r[handler])}
            >
              <span>{r[handler]}</span>
              <AdditionalInfo>
                <span>{get(r, additionalInfo)}</span>
                {additionalInfoLabel}
              </AdditionalInfo>
            </ResultItem>
          ))}
        </Results>
      )}
    </Wrapper>
  );
};

export default AutoComplete;
