import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { FaChevronDown } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import REMOVE_MACHINE from 'src/apollo/mutations/removeMachine';
import ProtectedContent from 'src/components/Auth/ProtectedContent/ProtectedContent';
import ButtonDropdown from 'src/components/elements/ButtonDropdown/ButtonDropdown.component';
import Space from 'src/components/elements/Space/Space.component';
import { ModalContext } from 'src/components/structure/Modal/modalContext';
import styled from 'styled-components';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import AddMachineForm from './AddMachineForm';
import Photos from './Photos';
import { orderBy, uniqBy } from 'lodash';
import useTranslation from 'src/hooks/useTranslation';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

const Image = styled.div`
  position: relative;
  height: 282px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Info = styled.div`
  padding: 2rem 2rem 0 2rem;
  flex: 1;
`;

const Param = styled.div``;
const Value = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FloatingButton = styled.div`
  display: none;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
`;

const Item = styled.div`
  position: relative;
  box-shadow: 0 5px 18px 0px rgb(0 0 0 / 15%);
  border: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  ${Row}:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  :hover {
    & ${FloatingButton} {
      display: block;
    }
  }
`;

const DetailsWrapper = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: ${({ theme }) => theme.colors.menuHighlight};
  }
  svg {
    transition: transform 0.2s ease;
    transform: rotate(${({ active }) => (active ? '180deg' : 0)});
  }
`;

const DetailsContent = styled.div``;
const DetailsChildren = styled.div`
  transition: max-height 0.5s ease;
  max-height: ${({ active }) => (active ? '2000px' : 0)};
  overflow: hidden;
`;

const Details = ({ children }) => {
  const [active, setActive] = useState(false);
  const toggleDetails = () => setActive(!active);

  return (
    <DetailsContent>
      <DetailsChildren active={active}>{children}</DetailsChildren>
      <DetailsWrapper onClick={toggleDetails} active={active}>
        <FaChevronDown size="20px" />
      </DetailsWrapper>
    </DetailsContent>
  );
};

const CategoryWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Category = styled.div`
  font-weight: bold;
  ${({ theme }) => `border-bottom: 1px solid ${theme.colors.sidebarBorder}`};
  margin-bottom: 0.5rem;
`;

const TechnologiesList = styled.div``;
const Tech = styled.div`
  & > div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
  }
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
`;

const MachineList = ({ data, companyId }) => {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const [removeMachineMutation] = useMutation(REMOVE_MACHINE, {
    onCompleted: () => {
      toast.success('Mašina uspješno obrisana');
    },
    onError: () => {},
  });

  const getTechnologiesFromCategory = (technologies, categoryId) => {
    const allTechs = orderBy(
      technologies.filter(
        (v) => v.category._id.toString() === categoryId.toString()
      ),
      'category.name',
      ['asc']
    );
    return allTechs;
  };

  const technologiesList = (technologies) => {
    if (!technologies) return;

    const categories = orderBy(
      uniqBy(technologies, 'category._id'),
      'category.name',
      ['asc']
    );

    return categories
      .map((technology) => {
        // put hedaer on every category
        const techsFromCat = getTechnologiesFromCategory(
          technologies,
          technology.category._id
        );
        return techsFromCat?.length > 0 ? (
          <CategoryWrapper>
            <Category>{technology.name}</Category>
            <TechnologiesList>
              {techsFromCat.map((v) => (
                <Tech title={v.name}>
                  <div>{v.name}</div>
                  <div>
                    {v?.parameters[0]?.minValue} - {v?.parameters[0]?.maxValue}
                  </div>
                </Tech>
              ))}
            </TechnologiesList>
          </CategoryWrapper>
        ) : (
          false
        );
      })
      .filter(Boolean);
  };

  const handleRemoveMachine = (machineId) => {
    if (prompt('Upišite POTVRDA za brisanje mašine').trim() === 'POTVRDA') {
      removeMachineMutation({
        variables: {
          machineId,
          companyId,
        },
        refetchQueries: ['getSingleUser'],
      });
    }
  };
  return (
    <Wrapper>
      {data?.length ? (
        data.map((machine) => (
          <Item key={machine._id}>
            <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
              <FloatingButton>
                <ButtonDropdown
                  icon={<FiChevronDown />}
                  label="Opcije"
                  id={machine._id}
                  layout="hollow"
                  actions={[
                    {
                      label: 'Uređivanje',
                      action: () =>
                        handleModal(
                          <AddMachineForm
                            prefillData={machine}
                            companyId={companyId}
                          />
                        ),
                    },
                    {
                      label: 'Obriši',
                      action: () => handleRemoveMachine(machine._id),
                    },
                  ]}
                />
              </FloatingButton>
            </ProtectedContent>
            <Image>
              <img
                src={process.env.REACT_APP_S3_BUCKET + machine.image}
                alt="machine"
              />
            </Image>
            <Distributor col="1-1" spread>
              <Info>
                <Row>
                  <Param>Naziv</Param>
                  <Value>{machine.name}</Value>
                </Row>
                <Row>
                  <Param>Proizvođač</Param>
                  <Value>{machine.manufacturer}</Value>
                </Row>
                <Row>
                  <Param>Godina proizvodnje</Param>
                  <Value>{machine.manufacturedYear}</Value>
                </Row>
                <Row>
                  <Param>Tip upravljanja</Param>
                  <Value>{machine.control}</Value>
                </Row>
                <Row>
                  <Param>Broj mašina</Param>
                  <Value>{machine.quantity}</Value>
                </Row>
                <Row>
                  <Param>ID broj</Param>
                  <Value>{machine.idNumber}</Value>
                </Row>
                <Row>
                  <Param>Broj alata</Param>
                  <Value>{machine.numberOfTools}</Value>
                </Row>
                <Row>
                  <Param>Broj radnih vretena</Param>
                  <Value>{machine?.numberOfWorkSpindles}</Value>
                </Row>
              </Info>
              <Info>
                <Row>
                  <Param>Broj revolver glava</Param>
                  <Value>{machine?.numberOfRevolverHeads}</Value>
                </Row>
                <Row>
                  <Param>Pogonjeni alati</Param>
                  <Value>{machine?.drivenTools}</Value>
                </Row>
                <Row>
                  <Param>Broj pogonjenih alata</Param>
                  <Value>{machine?.numberOfDrivenTools}</Value>
                </Row>
                <Row>
                  <Param>Snaga vretena</Param>
                  <Value>{machine?.spindlePowerKW}</Value>
                </Row>
                <Row>
                  <Param>Broj osa ({machine?.numberOfAxis})</Param>
                  <Value>
                    X: {machine.processingDimension?.X}, Y:{' '}
                    {machine.processingDimension?.Y}, Z:{' '}
                    {machine.processingDimension?.Z}, D:{' '}
                    {machine.processingDimension?.D}, L:{' '}
                    {machine.processingDimension?.L}
                  </Value>
                </Row>
                <Row>
                  <Param>Dodavač materijala</Param>
                  <Value>{machine.materialAdder ? 'Da' : 'Ne'}</Value>
                </Row>
                <Row>
                  <Param>Tip mašine</Param>
                  <Value>{machine.type}</Value>
                </Row>
                <Row>
                  <Param>Svrha mašine</Param>
                  <Value>{machine.machinePurpose}</Value>
                </Row>
              </Info>
            </Distributor>
            <Details>
              <Info>
                <Photos gallery={machine?.gallery} />
              </Info>
              <Info>
                <Param>Tehnologije ({machine?.technologies?.length})</Param>
                <Space vertical="10px" />
                {technologiesList(machine?.technologies)}
              </Info>
            </Details>
          </Item>
        ))
      ) : (
        <p>{t('Nema dodanih mašina')}.</p>
      )}
    </Wrapper>
  );
};

export default MachineList;
