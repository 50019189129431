import gql from 'graphql-tag';

const GET_SINGLE_USER = gql`
  query getSingleUser($userId: String!) {
    getSingleUser(userId: $userId) {
      username
      email
      phoneNumber
      name
      role
      status
      language
      dateCreated
      rating
      attributes {
        reseller
        revealManufacturersInfo
      }
      company {
        _id
        name
        initials
        logo
        photos
        sendCopy
        documents {
          _id
          filename
          fileDescription
        }
        dateFounded
        aboutCompany
        employeesCount
        references
        contactInfo {
          phone
          email
          website
          street
          city
          country
          zipCode
        }
        paymentAndTransport {
          shipmentAddress
          transportExpenses
          paymentSchedule
          paymentMethod
          paymentTimeframe
          currency
        }
        manufacturing {
          id
          name
        }
        technologies {
          technology {
            _id
            name
            category {
              id
              name
            }
            parameters {
              minValue
              maxValue
              parameter {
                id
              }
            }
          }
          parameters {
            parameter {
              _id
            }
            minValue
            maxValue
          }
        }
        materials {
          id
          name
          materialStandard
          materialDesignation
          category {
            id
            name
          }
        }
        machines {
          _id
          name
          idNumber
          type
          image
          gallery
          manufacturer
          manufacturedYear
          quantity
          control
          technologies {
            _id
            name
            category {
              _id
              name
            }
            parameters {
              minValue
              maxValue
            }
          }
          numberOfWorkSpindles
          spindlePowerKW
          numberOfRevolverHeads
          numberOfTools
          drivenTools
          numberOfDrivenTools
          numberOfAxis
          processingDimension {
            D
            L
            X
            Y
            Z
          }
          materialAdder
          machinePurpose
        }
        dateCreated
        complexityLevel
        toleranceLevel
        taxPercentage
        restrictions {
          maxProjectsRestriction
          projectCountryRestriction
        }
        exclusiveDirectives {
          exclusiveManufacturersGroup {
            _id
            name
          }
          exclusiveCountriesGroup
        }
        other {
          notes
          documents {
            filename
            fileDescription
          }
        }
      }
    }
  }
`;

export default GET_SINGLE_USER;
