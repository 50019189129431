import { useQuery } from '@apollo/client';
import Tabs, { TabItem } from 'src/components/elements/Tabs/Tabs.component';
import styled from 'styled-components';
import GET_SINGLE_POSITION from '../../../apollo/queries/getSinglePosition';
import Spinner from '../../../components/elements/Spinner/Spinner.component';
import useTranslation from '../../../hooks/useTranslation';
import { TopControls } from '../../Projects/tabsContent/Orders.tab';
import {
  Module,
  PositionHeading,
  Preview,
  Row,
  RowItem,
  TableLabel,
  Value,
} from '../../Projects/tabsContent/PositionDetails';
import PositionDeliveryProgress from './PositionDeliveryProgress';

const Wrapper = styled.div`
  ${Preview} {
    img {
      height: 300px;
      width: 600px;
    }
  }
`;

const ViewBuyersOrder = ({ orderId, data, positionId, accepted }) => {
  const { t } = useTranslation();
  const {
    data: positionData,
    loading,
    error,
  } = useQuery(GET_SINGLE_POSITION, {
    variables: { positionId },
  });

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Tabs>
        <TabItem key="1" label={`${t('Detalji pozicije')}`}>
          <TopControls>
            <PositionHeading>
              <h2>{positionData.getSinglePosition.name}</h2>
              <span>{t('Naziv pozicije')}</span>
            </PositionHeading>
          </TopControls>
          <Preview>
            <img
              src={
                process.env.REACT_APP_S3_BUCKET +
                positionData.getSinglePosition.files.jpg
              }
              alt="Pozicija"
            />
          </Preview>
          <Module>
            <h3>{t('Prihvaćena cijena')}</h3>
            <Row>
              <RowItem>
                <TableLabel>{t('Količina')}</TableLabel>
                <Value>{data.quantity}</Value>
              </RowItem>
              <RowItem>
                <TableLabel>{t('Cijena')}</TableLabel>
                <Value>{data?.refinedPrice?.toFixed(2)}</Value>
              </RowItem>
            </Row>
          </Module>
          <Module>
            <h3>{t('Detalji izrade')}</h3>
            <Row>
              <RowItem>
                <TableLabel>{t('Proizvodnja')}</TableLabel>
                <Value>
                  {positionData.getSinglePosition.manufacturing.name}
                </Value>
              </RowItem>
              <RowItem>
                <TableLabel>{t('Tehnologija')}</TableLabel>
                <Value>{positionData.getSinglePosition.technology.name}</Value>
              </RowItem>
              <RowItem>
                <TableLabel>{t('Materijal')}</TableLabel>
                <Value>{positionData.getSinglePosition.material.name}</Value>
              </RowItem>
            </Row>
          </Module>
        </TabItem>
        <TabItem key="2" label={t('Detalji isporuke')} active>
          <PositionDeliveryProgress
            orderId={orderId}
            positionId={positionId}
            data={data}
          />
        </TabItem>
      </Tabs>
    </Wrapper>
  );
};

export default ViewBuyersOrder;
