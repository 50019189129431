import { useQuery } from '@apollo/client';
import React from 'react';
import GET_ASSOCIATES from 'src/apollo/queries/getAssociates';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Tabs, { TabItem } from 'src/components/elements/Tabs/Tabs.component';
import Container from 'src/components/structure/wrappers/Container/Container.component';
import Main from 'src/components/structure/wrappers/Main/Main.component';
import styled from 'styled-components';
import Associates from './Associates.tab';
import useTranslation from 'src/hooks/useTranslation';

const Wrapper = styled.div``;

const CompanyAssociates = () => {
  const { loading, data } = useQuery(GET_ASSOCIATES);
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <Main>
          <h1>{t('Saradnici unutar firme')}</h1>
          {loading ? (
            <Spinner />
          ) : (
            <Tabs>
              <TabItem key="1" active label={t('Saradnici')}>
                <Associates data={data?.getAssociates} />
              </TabItem>
            </Tabs>
          )}
        </Main>
      </Container>
    </Wrapper>
  );
};

export default CompanyAssociates;
