import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import ADD_DOCUMENT from "../../../apollo/mutations/addDocument";
import Button from "../../../components/elements/Button/Button.component";
import Input from "../../../components/elements/Input/Input.component";
import Label from "../../../components/elements/Label/Label.component";
import UploadFile from "../../../components/elements/UploadFile/UploadFile.component";
import Distributor from "../../../components/structure/wrappers/Distributor/Distributor.component";
import FormField from "../../../components/structure/wrappers/FormField/FormField.component";
import DocumentList from "./DocumentList";

const Wrapper = styled.div``;

const Documents = ({ companyId, documents }) => {
  const [fileDescription, setFileDescription] = useState("");
  const [error, setError] = useState(null);
  const [filename, setFilename] = useState(null);
  const [files, setFiles] = useState([]);
  const [addDocumentMutation, { loading }] = useMutation(ADD_DOCUMENT, {
    onCompleted: () => toast.success("Dokument dodan"),
    onError: () => {},
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setFileDescription(value);
  };

  const handleSubmit = () => {
    if (!fileDescription || !filename) setError("Popunite sva polja");
    addDocumentMutation({
      variables: {
        companyId,
        filename,
        fileDescription,
      },
      refetchQueries: ["getSingleUser"],
    });
  };

  useEffect(() => {
    if (documents) {
      setFiles(documents);
    }
  }, [documents]);

  return (
    <Wrapper>
      <h2>Uređivanje dokumentacije firme</h2>
      <FormField>
        <Label error={error}>Šta predstavlja fajl koji uploadate</Label>
        <Input
          type="text"
          name="fileDescription"
          onChange={handleChange}
          value={fileDescription}
        />
      </FormField>
      <FormField>
        <Label>Odaberite dokument s računara i kliknite upload</Label>
        <UploadFile
          noReplace
          directory="documents"
          name="document"
          acceptedformats={[
            "image/png",
            "image/jpeg",
            "application/pdf",
            "application/msword",
            "application/docx",
            "text/plain",
          ]}
          maxMB="50"
          onSuccess={(filename) => setFilename(filename)}
        />
      </FormField>
      <Distributor submitButtons stack="end">
        <Button onClick={handleSubmit} loading={loading}>
          Dodaj dokument
        </Button>
      </Distributor>
      <h3>Dodani fajlovi</h3>
      {files ? <DocumentList files={files} /> : <p>Nema dodanih fajlova.</p>}
    </Wrapper>
  );
};

export default Documents;
