import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import GET_SINGLE_POSITION from "../../../apollo/queries/getSinglePosition";
import Spinner from "../../../components/elements/Spinner/Spinner.component";
import {
  PositionHeading,
  Preview,
  Row,
  RowItem,
  Module,
  TableLabel,
  Value,
} from "../../Projects/tabsContent/PositionDetails";

import { TopControls } from "../../Projects/tabsContent/Orders.tab";
import useTranslation from "../../../hooks/useTranslation";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import Input from "src/components/elements/Input/Input.component";
import Button from "src/components/elements/Button/Button.component";
import { useForm } from "react-hook-form";
import DELIVERED_POSITION from "src/apollo/mutations/deliveredPosition";
import toast from "react-hot-toast";
import Acknowledge from "src/components/Acknowledge/Acknowledge";
import { ModalContext } from "src/components/structure/Modal/modalContext";
import MANUFACTURER_SEEN_OFFER from "src/apollo/mutations/manufacturerSeenOffer";

const Wrapper = styled.div`
  ${Preview} {
    img {
      height: 300px;
      width: 600px;
    }
  }
`;

const ViewManufacturerOrder = ({ seen, offerId, data, positionId }) => {
  const { handleModal } = useContext(ModalContext);
  const { register, getValues } = useForm();
  const { t } = useTranslation();
  const { data: positionData, loading } = useQuery(GET_SINGLE_POSITION, {
    variables: { positionId },
  });

  const [seenOfferMutation] = useMutation(MANUFACTURER_SEEN_OFFER);

  const [deliveredPositionMutation, { loading: deliveredPositionLoading }] =
    useMutation(DELIVERED_POSITION, {
      onCompleted: () => {
        handleModal();
        toast.success(t("Pozicija označena kao isporučena"));
      },
      onError: () => {},
    });

  const handleDeliveredPosition = () => {
    if (getValues("date") && positionId) {
      deliveredPositionMutation({
        variables: {
          orderId: data._id,
          positionId,
          quantity: data.acceptedOffers.quantity,
          date: getValues("date"),
        },
        refetchQueries: ["getManufacturersOrders"],
      });
    }
  };

  useEffect(() => {
    if (!seen) {
      seenOfferMutation({
        variables: {
          orderId: data._id,
          offerId,
        },
        refetchQueries: ["getManufacturersOrders"],
      });
    }
  }, [data, seen, offerId]);

  const image =
    positionData?.getSinglePosition?.revisedInformations?.files.jpg ||
    positionData?.getSinglePosition?.files?.jpg;

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <TopControls>
        <PositionHeading>
          <h2>{positionData.getSinglePosition.name}</h2>
          <span>{t("Naziv pozicije")}</span>
        </PositionHeading>
      </TopControls>
      <Preview>
        <img src={process.env.REACT_APP_S3_BUCKET + image} alt="Pozicija" />
      </Preview>
      <Module>
        <h3>{t("Prihvaćena cijena")}</h3>
        <Row>
          <RowItem>
            <TableLabel>{t("Količina")}</TableLabel>
            <Value>{data.acceptedOffers.quantity}</Value>
          </RowItem>
          <RowItem>
            <TableLabel>{t("Cijena")}</TableLabel>
            <Value>{data.acceptedOffers?.refinedPrice}</Value>
          </RowItem>
        </Row>
      </Module>
      <Module>
        <h3>{t("Detalji izrade")}</h3>
        <Row>
          <RowItem>
            <TableLabel>{t("Proizvodnja")}</TableLabel>
            <Value>{positionData.getSinglePosition.manufacturing.name}</Value>
          </RowItem>
          {positionData.getSinglePosition.technology.name ? (
            <RowItem>
              <TableLabel>{t("Tehnologija")}</TableLabel>
              <Value>{positionData.getSinglePosition.technology.name}</Value>
            </RowItem>
          ) : null}
          <RowItem>
            <TableLabel>{t("Materijal")}</TableLabel>
            <Value>{positionData.getSinglePosition.material.name}</Value>
          </RowItem>
        </Row>
      </Module>
      {data.manufacturer.recievedOrder &&
        (!data.acceptedOffers?.deliveryStatus?.deliveredByManufacturer
          ?.status ? (
          <Distributor col="1-1" stack="end">
            <Input type="date" name="date" width="100%" ref={register} />
            <Button
              onClick={handleDeliveredPosition}
              loading={deliveredPositionLoading}
            >
              Potvrdi isporuku
            </Button>
          </Distributor>
        ) : (
          <Acknowledge layout="notice">
            {t("Pozicija naznačena kao dostavljena.")}
          </Acknowledge>
        ))}
    </Wrapper>
  );
};

export default ViewManufacturerOrder;
