import gql from "graphql-tag";

const GET_TECHNOLOGY_HIERARCHY = gql`
  query getTechnologyHierarchy {
    getTechnologyHierarchy {
      _id
      name
      parent
      technologies {
        _id
        name
        parameters {
          minValue
          maxValue
        }
      }
      subcategories {
        _id
        name
      }
      parameters {
        _id
        minValueLabel
        maxValueLabel
        name
      }
    }
  }
`;

export default GET_TECHNOLOGY_HIERARCHY;
