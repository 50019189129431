import React from "react";
import styled from "styled-components";
import Container from "../../components/structure/wrappers/Container/Container.component";
import image from "../../assets/images/404/solving.svg";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 400px;
    flex: 1;
  }
`;
const Content = styled.div`
  flex: 1;
`;
const NotFound = () => {
  return (
    <Wrapper>
      <Container size="lg">
        <Wrapper>
          <img src={image} alt="Missing page" />
          <Content>
            <h1>Nepostojeća stranica</h1>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
            <NavLink to="/">Povratak na naslovnu</NavLink>
          </Content>
        </Wrapper>
      </Container>
    </Wrapper>
  );
};

export default NotFound;
