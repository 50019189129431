import React from "react";
import styled from "styled-components";
import Tabs, {
  TabItem,
} from "../../../components/elements/Tabs/Tabs.component";
import Container from "../../../components/structure/wrappers/Container/Container.component";
import Main from "../../../components/structure/wrappers/Main/Main.component";
import useTranslation from "../../../hooks/useTranslation";
import BuyersOrdersList from "./BuyersOrdersList";

const Wrapper = styled.div``;

const BuyersOrders = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <Main>
          <h1>{t("Ponude")}</h1>
          <Tabs>
            <TabItem key="1" active label={t("Primljene ponude")}>
              <BuyersOrdersList />
            </TabItem>
            <TabItem key="2" label={t("Naručene")}>
              <BuyersOrdersList delivered />
            </TabItem>
          </Tabs>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default BuyersOrders;
