import gql from "graphql-tag";

const BUYERS_UNDELIVERED_ORDERS_VALUE = gql`
  query buyersUndeliveredOrdersValue {
    buyersUndeliveredOrdersValue {
      dateCreated
      value
    }
  }
`;

export default BUYERS_UNDELIVERED_ORDERS_VALUE;
