import React, { useContext, useEffect, useState } from 'react';
import Input from '../../../elements/Input/Input.component';
import Label from '../../../elements/Label/Label.component';
import FormField from '../../wrappers/FormField/FormField.component';
import Distributor from '../../wrappers/Distributor/Distributor.component';
import Button from '../../../elements/Button/Button.component';
import Select from '../../../elements/Select/Select.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addTechnologyFormSchema } from '../../../../validation/schema';
import { useMutation, useQuery } from '@apollo/client';
import CREATE_TECHNOLOGY from '../../../../apollo/mutations/createTechnology';
import GET_TECHNOLOGY_CATEGORIES from '../../../../apollo/queries/getTechnologyCategories';
import GET_TECHNOLOGY_PARAMETERS from '../../../../apollo/queries/getTechnologyParameters';
import { ModalContext } from '../../Modal/modalContext';
import toast from 'react-hot-toast';
import ParametersTable from '../../../elements/ParametersTable/ParametersTable.component';
import { pick } from 'lodash';
import useTranslation from 'src/hooks/useTranslation';

const AddTechnologyForm = ({ prefillData }) => {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const {
    loading: catsLoading,
    error: catsError,
    data,
  } = useQuery(GET_TECHNOLOGY_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });
  const {
    loading: paramsLoading,
    error: paramsError,
    data: paramsData,
  } = useQuery(GET_TECHNOLOGY_PARAMETERS);

  const [parameters, setParameters] = useState([]);
  const [values, setValues] = useState({
    label: '',
    parameter: '',
    minValue: '',
    maxValue: '',
    units: 'mm',
  });
  const [createTechnologyMutation, { loading, error }] = useMutation(
    CREATE_TECHNOLOGY,
    {
      refetchQueries: ['getTechnologies'],
      onError: () => {},
    }
  );
  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(addTechnologyFormSchema),
  });
  const onSubmit = (values) => {
    const parseObj = parameters.map((param) =>
      pick(param, ['parameter', 'minValue', 'maxValue', 'units'])
    );
    const sanitizedValues = parseObj.map((param) => ({
      ...param,
      minValue: parseFloat(param.minValue),
      maxValue: parseFloat(param.maxValue),
    }));

    createTechnologyMutation({
      variables: {
        ...values,
        parameters: sanitizedValues,
        replaceTechnologyId: prefillData?.id,
      },
    });
    if (!loading && !error) {
      toast.success('Tehnologija uspješno dodana');
      handleModal();
    }
  };

  const handleAddData = () => {
    /* if some empty values don't let user add parameter */
    if (Object.values(values).some((v) => !v)) return;

    /* is parameter already in the list */
    if (parameters.find((param) => param.parameter === values.parameter))
      return;

    setParameters([...parameters, { ...values }]);
    setValues({
      label: '',
      parameter: '',
      minValue: '',
      maxValue: '',
      units: 'mm',
    });
  };

  const handleParameterValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleRemoveData = (id) => {
    setParameters(parameters.filter((param) => param.parameter !== id));
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const param = paramsData.getTechnologyParameters.find(
      (param) => param.id === value
    );
    setValues({ ...values, label: param.name, parameter: value });
  };

  /* prefill */

  useEffect(() => {
    if (prefillData) {
      console.log(prefillData);
      setValue('name', prefillData.name);
      setValue('category', prefillData.category?.id);
      setParameters(
        prefillData?.parameters.map((p) => ({
          label: p?.parameter?.name,
          parameter: p?.id,
          minValue: p?.minValue,
          maxValue: p?.maxValue,
          units: p?.units,
        }))
      );
    }
  }, [prefillData, setValue, data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Dodaj novu tehnologiju</h2>
      <FormField>
        <Label error={errors.name?.message}>{t('Naziv tehnologije')}</Label>
        <Input name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.category?.message}>Kategorija tehnologije</Label>
        <Select name="category" ref={register} loading={catsLoading}>
          {catsLoading ? (
            <option>Loading...</option>
          ) : (
            data?.getTechnologyCategories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))
          )}
        </Select>
      </FormField>
      <FormField>
        <Label error={errors.parameters?.message}>Parametri</Label>
        <ParametersTable
          data={parameters}
          addData={handleAddData}
          removeData={handleRemoveData}
        >
          <Distributor col="4-2-2-2">
            <Select
              onChange={handleChange}
              loading={paramsLoading}
              value={values.parameter}
            >
              {paramsData?.getTechnologyParameters.map((param) => (
                <option value={param.id} key={param.id}>
                  {param.name}
                </option>
              ))}
            </Select>
            <Input
              name="minValue"
              placeholder="min."
              value={values.minValue}
              onChange={handleParameterValues}
              type="number"
              step="1"
            />
            <Input
              name="maxValue"
              placeholder="max."
              value={values.maxValue}
              onChange={handleParameterValues}
              type="number"
              step="1"
            />
            <Input
              name="units"
              placeholder="jed."
              value={values.units}
              onChange={handleParameterValues}
            />
          </Distributor>
        </ParametersTable>
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button>Sačuvaj tehnologiju</Button>
      </Distributor>
    </form>
  );
};

export default AddTechnologyForm;
