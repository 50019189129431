import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import Spinner from "../../../components/elements/Spinner/Spinner.component";
import GET_BUYERS_ORDERS from "../../../apollo/queries/getBuyersOrders";
import { Link, useRouteMatch } from "react-router-dom";
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/elements/BasicTable/BasicTable.component";
import {
  CounterWrapper as TotalsWrapper,
  Total,
} from "../Administrator/OrdersList";
import useTranslation from "../../../hooks/useTranslation";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import { Search } from "src/pages/Materials/Materials.page";
import Input from "src/components/elements/Input/Input.component";
import Button from "src/components/elements/Button/Button.component";
import Space from "src/components/elements/Space/Space.component";
import { HiOutlineCash } from "react-icons/hi";
import { AiOutlineTag } from "react-icons/ai";

const Wrapper = styled.div``;

const BuyersOrdersList = ({ delivered }) => {
  const [basicFields, setBasicFields] = useState(true);
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { data, loading } = useQuery(GET_BUYERS_ORDERS);
  const [orderData, setOrderData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [filter, setFilter] = useState("");
  const clearFilter = () => setFilter("");
  const handleChange = ({ target: { value } }) => {
    setFilter(value);
  };
  const [orderTotal, setOrderTotal] = useState(0);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchString(filter);
  };

  useEffect(() => {
    if (data) {
      // filter by search string first
      const filteredData = data?.getBuyersOrders?.filter((order) => {
        return (
          order?.project?.name
            ?.toLowerCase()
            .indexOf(searchString?.toLowerCase()) !== -1
        );
      });

      if (delivered) {
        setBasicFields(true);
        setOrderData(filteredData?.filter((order) => order.accepted.byBuyer));
      } else {
        setBasicFields(false);
        setOrderData(filteredData);
      }
    }
  }, [data, delivered, searchString]);

  useEffect(() => {
    // set order total value
    setOrderTotal(
      orderData
        ?.reduce(
          (acc, currPosition) =>
            currPosition.acceptedOffers.reduce(
              (accOffer, currOffer) =>
                acc + currOffer.quantity * currOffer.refinedPrice,
              0
            ),
          0
        )
        .toLocaleString("de", {
          minimumFractionDigits: 2,
        })
    );
  }, [orderData]);

  return (
    <Wrapper>
      <Distributor sticky stack="end">
        <Search onSubmit={handleSearch}>
          <Input
            placeholder="Pretraga..."
            onChange={handleChange}
            value={filter}
          />
          <Button onClick={handleSearch}>Pretraga</Button>
          <Button onClick={clearFilter} layout="hollow">
            Poništi pretragu
          </Button>
        </Search>
      </Distributor>
      <Space vertical="2rem" />
      {loading ? (
        <Spinner />
      ) : orderData.length ? (
        <Wrapper>
          <TotalsWrapper>
            <Total>
              <AiOutlineTag size="20px" />
              {t("Ukupno")}: {orderData.length}
            </Total>
            <Total>
              <HiOutlineCash size="20px" />
              {t("Ukupna vrijednost")}: {orderTotal} EUR
            </Total>
          </TotalsWrapper>
          <BasicTable loading={loading}>
            <TableHead>
              <TableRow>
                <TableHeader>{t("Projekt")}</TableHeader>
                {!basicFields && (
                  <TableHeader>{t("Šifra projekta")}</TableHeader>
                )}
                <TableHeader>{t("Datum kreiranja projekta")}</TableHeader>
                <TableHeader>{t("Rok za ponudu")}</TableHeader>
                <TableHeader>{t("Datum primanja ponude")}</TableHeader>
                <TableHeader>{t("Naručeno")}</TableHeader>
                <TableHeader>{t("Br. narudžbe")}</TableHeader>
                <TableHeader>{t("Datum narudžbe")}</TableHeader>
                {!basicFields && <TableHeader>{t("U tranzitu")}</TableHeader>}
                {!basicFields && (
                  <TableHeader>{t("Isporučene pozicije")}</TableHeader>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderData.map((order) => (
                <TableRow
                  key={order._id}
                  activeRow={!order?.seen?.byBuyer?.status}
                >
                  <TableCell>
                    <Link to={`${match.path}/${order._id}`}>
                      {order.project.name}
                    </Link>
                  </TableCell>
                  {!basicFields && (
                    <TableCell>{order?.project.projectCode}</TableCell>
                  )}
                  <TableCell>{order?.project?.published?.date}</TableCell>
                  <TableCell>{order?.project.offerDeadline}</TableCell>
                  <TableCell>{order?.dateCreated}</TableCell>
                  <TableCell>
                    {order?.accepted?.byBuyer ? t("Da") : ""}
                  </TableCell>

                  <TableCell>
                    {order?.accepted?.physicalOrderNumber || "-"}
                  </TableCell>

                  <TableCell>{order?.accepted.date}</TableCell>
                  {!basicFields && (
                    <TableCell>{`${order.acceptedOffers.reduce(
                      (a, c) =>
                        c.deliveryStatus?.deliveredByManufacturer
                          ?.confirmedDelivery?.status
                          ? a + 1
                          : a,
                      0
                    )}/${order.acceptedOffers.reduce(
                      (a, c) => (c.acceptedByBuyer ? a + 1 : a),
                      0
                    )}`}</TableCell>
                  )}
                  {!basicFields && (
                    <TableCell>{`${order.acceptedOffers.reduce(
                      (a, c) =>
                        c.deliveryStatus?.recievedByBuyer?.status ? a + 1 : a,
                      0
                    )}/${order.acceptedOffers.reduce(
                      (a, c) => (c.acceptedByBuyer ? a + 1 : a),
                      0
                    )}`}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Wrapper>
      ) : (
        t("Nemate ponuda")
      )}
    </Wrapper>
  );
};

export default BuyersOrdersList;
