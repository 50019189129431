import { createGlobalStyle } from "styled-components";

/* TODO: https://styled-components.com/docs/advanced#security  */

export default createGlobalStyle`
    html {
        box-sizing: border-box;
    };

    *, *:before, *:after {
    box-sizing: inherit;
    };

    body, html {
        margin: 0;
        padding: 0;
        height: 100vh;
        width: 100vw;
    };

    body {
        font-family: "Open Sans";
        font-size: 14px;
        color: ${({ theme }) => theme.colors.black};
    };

    a {
        color: inherit;
    };

    p {
        line-height: 1.6;
    };

    h1 {
        letter-spacing: -1px;
    }

    h1,h2,h3,h4,h5,h6 {
        font-weight: 600;
    }

    ::-webkit-scrollbar {
        width: 5px;
    };

    ::-webkit-scrollbar-track {
        background:  ${({ theme }) => theme.colors.sidebarBorder};
    };

    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.formPlaceholder};
    };

    ::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.colors.accent};
    };

    label {
        display: block;
        margin: 0 0 0.3rem 0;
        font-size: 12px;
        font-weight: 600;
    }

    input, textarea, select {
        background-color: ${({ theme }) => theme.colors.menuHighlight};
        border: none;
        font-family: inherit;
        font-size: inherit;
        border-radius: 3px;
        padding: 0.5rem 0.7rem;
        ::placeholder {
            color: ${({ theme }) => theme.colors.formPlaceholder};
        }
        ::-webkit-input-placeholder {
            color: ${({ theme }) => theme.colors.formPlaceholder};
        }
        :-ms-input-placeholder {
            color: ${({ theme }) => theme.colors.formPlaceholder};
        }
        width: 100%;
    };

    textarea {
        resize: auto;
        padding-top: 0.5rem;
    };

    button {
    font-family: inherit;
    font-size: inherit;
    border-radius: 3px;
    border: none;
    outline: none;
    };

      .CalendarDay__selected_span {
    color: ${({ theme }) => theme.colors.accent};
    background: ${({ theme }) => theme.colors.highlight};
    border: none;
  };
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${({ theme }) => theme.colors.accent};
    border: none;
  };

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: ${({ theme }) => theme.colors.accent};
    border: none;
};

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    color: ${({ theme }) => theme.colors.accent};
    background: ${({ theme }) => theme.colors.highlight};
    border: none;
  };
  .DateInput_input__focused {
    border-color: ${({ theme }) => theme.colors.accent};
  };
  .input.DateInput_input,
  .DateInput_input {
    background-color: ${({ theme }) => theme.colors.menuHighlight};
    font-size: inherit;
    ::placeholder {
      color: ${({ theme }) => theme.colors.formPlaceholder};
    };
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.formPlaceholder};
    };
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.formPlaceholder};
    };
  };
  .DateRangePickerInput {
    background-color: ${({ theme }) => theme.colors.menuHighlight};
    border: none;
  };
  .DateInput_input__small {
    padding: 0.5rem 0.7rem;
  };
  .DateRangePicker {
    border-radius: 3px;
  };
  .DateRangePicker_picker {
    z-index: 10 !important;
  };

  .Toastify__toast {
  border-radius: 3px;
  font-family: inherit;
  padding-left: 1rem;
  };

  .stats-tooltip{
    text-align: center;
    font-size: 0.8rem;
    padding: 0.5rem;
      span {
        display: block;
      }
  }

  .stats-price {
    font-size: 1rem;
    font-weight: 600;
  }

    .print-style {
        @media print {
            @page {
                size: landscape;
            }
            .page-break {
                display: none;
            }
            .full-page {
                height: 100%;
                width: 100%;
            }
        }
    }
    
`;
