import gql from "graphql-tag";

const SUBMIT_OFFER = gql`
  mutation submitOffer(
    $positionId: String!
    $quantityId: String!
    $userId: String!
    $offeredQuantityPrice: Float!
    $realisticDeliveryTime: Date
    $comment: String
  ) {
    submitOffer(
      positionId: $positionId
      quantityId: $quantityId
      userId: $userId
      offeredQuantityPrice: $offeredQuantityPrice
      realisticDeliveryTime: $realisticDeliveryTime
      comment: $comment
    )
  }
`;

export default SUBMIT_OFFER;
