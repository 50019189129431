import gql from "graphql-tag";

const GET_ALL_MANUFACTURING = gql`
  query getAllManufacturing {
    getAllManufacturing {
      id
      name
      category {
        id
        name
      }
    }
  }
`;

export default GET_ALL_MANUFACTURING;
