import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ModalContext } from "../../structure/Modal/modalContext";
import CheckerBox from "../CheckerBox/CheckerBox";
import Button from "../Button/Button.component";
import Distributor from "../../structure/wrappers/Distributor/Distributor.component";

const Wrapper = styled.div``;
const Title = styled.div`
  padding: 0.5rem 0.8rem;
  ${({ parent, theme }) =>
    parent &&
    `
    font-size:1rem;
    font-weight: 600;
    display: block;
    width: auto;
    position: relative;
    padding-left: 1.5rem;
    :before {
      content: '';
      background: ${theme.colors.sidebarBorder};
      width: 11px;
      height: 11px;
      position: absolute;
      left: -5px;
      top: 15px;
      border-radius: 50%;
    }
  `};
  ${({ child }) =>
    child &&
    `
    margin-top: 1rem;
    font-weight: 600;
  `};
`;
const ParentDivider = styled.div`
  margin-bottom: 2rem;
  border-left: 1px dashed ${({ theme }) => theme.colors.sidebarBorder};
  padding-left: 1rem;
`;
const ChildDivider = styled.div``;

const ParentWrapper = styled.div``;

const TechnologiesContainer = ({ values }) => {
  const { setData, data, handleModal } = useContext(ModalContext);

  const handleSubmit = () => {
    setData(data);
    handleModal();
  };

  const handleChange = (e) => {
    const { name: _id, value: name, checked } = e.target;

    // toggle value in parsedData array

    const val = data.find((c) => c._id === _id);

    if (!val && checked) {
      setData((c) => [...c, { _id, name }]);
    } else {
      setData((c) => c.filter((r) => r._id !== _id));
    }
  };

  /* only load IDs, parameters will be loaded in parent component onClick */
  return (
    <Wrapper>
      <p>Prikazane su samo kategorije u koje su dodane tehnologije</p>
      {values.map(
        (parent) =>
          !parent.parent &&
          parent.technologies.length > 0 && (
            <ParentWrapper key={parent._id}>
              <Title parent>{parent.name}</Title>
              <ParentDivider>
                <ChildDivider>
                  {parent.technologies.map((technology) => (
                    <CheckerBox
                      key={technology._id}
                      value={`${technology.name} (${technology.parameters[0].minValue} - ${technology.parameters[0].maxValue})`}
                      name={technology._id}
                      onChange={handleChange}
                      checked={
                        data.find((val) => val._id === technology._id) || false
                      }
                    />
                  ))}
                </ChildDivider>
                {parent.subcategories.map(
                  (subcategory) =>
                    values.find((cat) => cat._id === subcategory._id)
                      .technologies.length > 0 && (
                      <ChildDivider key={subcategory._id}>
                        <Title child>{subcategory.name}</Title>
                        <ChildDivider>
                          {values
                            .find((cat) => cat._id === subcategory._id)
                            ?.technologies.map((tech) => (
                              <CheckerBox
                                key={tech._id}
                                value={`${tech.name} (${tech.parameters[0].minValue} - ${tech.parameters[0].maxValue})`}
                                name={tech._id}
                                onChange={handleChange}
                                checked={
                                  data.find((val) => val._id === tech._id) ||
                                  false
                                }
                              />
                            ))}
                        </ChildDivider>
                      </ChildDivider>
                    )
                )}
              </ParentDivider>
            </ParentWrapper>
          )
      )}
      <Distributor stack="end" submitButtons>
        <Button onClick={handleSubmit}>Dodaj</Button>
      </Distributor>
    </Wrapper>
  );
};

export { ParentDivider, Title, ChildDivider, TechnologiesContainer as default };
