import React, { useRef } from "react";
import styled from "styled-components";
import SliderItem from "./SliderItem";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import Glide from "react-glidejs";

const Wrapper = styled.div``;
const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;

  span {
    :not(:first-child) {
      margin-left: 0.5rem;
    }
    display: block;
    :hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

const SliderWrapper = ({ data }) => {
  const gliderRef = useRef(null);
  const leftControl = useRef(null);
  const rightControl = useRef(null);

  const settings = {
    ref: gliderRef,
    throttle: 0,
    type: "slider",
    customSlideAnimation: {
      timeout: 500,
      classNames: "fade",
    },
    perView: 5,
    startAt: 0,
    gap: "20px",
    rewind: false,
  };

  return (
    <Wrapper>
      {data?.length ? (
        <Glide {...settings}>
          {data?.map((itemData) => (
            <SliderItem key={itemData.id} data={itemData} />
          ))}
        </Glide>
      ) : (
        "Nema projekata"
      )}
      {/* <Controls>
        <span ref={leftControl}>
          <FiArrowLeftCircle size="25px" />
        </span>
        <span ref={rightControl}>
          <FiArrowRightCircle size="25px" />
        </span>
      </Controls> */}
    </Wrapper>
  );
};

export default SliderWrapper;
