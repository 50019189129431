import gql from 'graphql-tag';

const CREATE_CONVERSATION = gql`
  mutation createConversation(
    $projectId: String!
    $position: ID!
    $title: String!
    $receiver: String
    $message: String!
    $contactBuyer: Boolean
    $attachAllPositions: Boolean
    $contactManufacturersIds: [ID]
    $sendAsEmail: Boolean
    $files: FilesInput
    $attachFileTable: Boolean
  ) {
    createConversation(
      projectId: $projectId
      position: $position
      title: $title
      receiver: $receiver
      message: $message
      contactBuyer: $contactBuyer
      contactManufacturersIds: $contactManufacturersIds
      attachAllPositions: $attachAllPositions
      sendAsEmail: $sendAsEmail
      files: $files
      attachFileTable: $attachFileTable
    ) {
      _id
    }
  }
`;

export default CREATE_CONVERSATION;
