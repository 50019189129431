import gql from "graphql-tag";

const SIGN_S3 = gql`
  mutation signS3($filename: String!, $filetype: String!) {
    signS3(filename: $filename, filetype: $filetype) {
      signedRequest
      url
    }
  }
`;

export default SIGN_S3;
