import gql from 'graphql-tag';

const UPDATE_POSITION = gql`
  mutation updatePosition(
    $positionId: ID!
    $name: String!
    $positionCode: String
    $drawingNumber: String
    $quantities: [QuantityInput]
    $manufacturing: ID
    $material: ID
    $technology: [ID]
    $files: FilesInput
    $note: String
    $revisedInformations: RevisedInformationInput
    $additionalManufacturing: [ID]
    $additionalCharacteristics: [ID]
  ) {
    updatePosition(
      positionId: $positionId
      name: $name
      positionCode: $positionCode
      drawingNumber: $drawingNumber
      quantities: $quantities
      manufacturing: $manufacturing
      material: $material
      technology: $technology
      files: $files
      note: $note
      revisedInformations: $revisedInformations
      additionalManufacturing: $additionalManufacturing
      additionalCharacteristics: $additionalCharacteristics
    )
  }
`;

export default UPDATE_POSITION;
