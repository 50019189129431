import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
${({ width }) => width && `width: ${width}`};
  > * {
    ${({ size }) => (size ? `padding: 0 calc((100% - ${size}) / 2)` : "100%")};
    ${({ size }) => !size && `padding: 0 3rem`};
    ${({ left, size }) => left && `width: ${size}`};
`;

const Container = ({ size, children, ...otherProps }) => {
  const sizes = {
    sm: "800px",
    md: "1000px",
    lg: "1200px",
    xl: "1600px",
  };

  return (
    <Wrapper size={sizes[size]} {...otherProps}>
      {children}
    </Wrapper>
  );
};

export default Container;
