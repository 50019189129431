import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { BiHourglass } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { currentUserContext } from "src/components/Auth/currentUserContext/currentUser.context";
import Space from "src/components/elements/Space/Space.component";
import styled from "styled-components";

const Wrapper = styled.div``;

const Content = styled(NavLink)`
  text-decoration: inherit;
  background: white;
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.depth.projectItem};
  display: block;
  border: 2px solid ${({ theme }) => theme.colors.sidebarNavigationBorder};
  border-radius: 5px;
  :hover {
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

const Image = styled.div`
  margin-bottom: 1rem;
  overflow: hidden;
  img {
    width: 100%;
    height: 140px;
    object-fit: contain;
  }
`;
const Name = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const SliderItem = ({ data }) => {
  const [image, setImage] = useState(null);
  const {
    user: { user },
  } = useContext(currentUserContext);

  useEffect(() => {
    if (user?.role && data) {
      switch (user?.role) {
        case "MANUFACTURER":
          if (data?.positions[0]?.revisedInformations?.files?.jpg) {
            setImage(data?.positions[0]?.revisedInformations?.files?.jpg);
          } else {
            setImage(data?.positions[0]?.files?.jpg);
          }
          break;
        default:
          if (data?.positions[0]?.files?.jpg) {
            setImage(data?.positions[0]?.files?.jpg);
          } else {
            setImage(data?.positions[0]?.revisedInformations?.files?.jpg);
          }
      }
    }
  }, [user, data]);

  return (
    <Wrapper>
      <Content
        to={{
          pathname: `/projects/${data.id}/details`,
          state: { positionIds: data?.positions.map((p) => p._id) },
        }}
      >
        <Row>
          <BiHourglass />
          {dayjs(data?.published?.date).format("DD.MM.YYYY")}
        </Row>
        <Space vertical="1rem" />
        <Image>
          <img src={process.env.REACT_APP_S3_BUCKET + image} alt={data.name} />
        </Image>
        <Name>
          <span>{data.name}</span>
        </Name>
      </Content>
    </Wrapper>
  );
};

export default SliderItem;
