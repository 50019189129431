import { useQuery } from "@apollo/client";
import React from "react";
import ACTIVE_PROJECTS from "src/apollo/queries/dashboard/activeProjects";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import Slider from "./Slider";

const ActiveProjectsSlider = () => {
  const { t } = useTranslation();
  const {
    loading: activeProjectsLoading,
    data: { activeProjects } = {},
    error: activeProjectsError,
  } = useQuery(ACTIVE_PROJECTS, {
    fetchPolicy: "cache-first",
  });

  return (
    <Segment padding="0" noBg>
      <h2>{t("Neodgovoreni upiti")}</h2>
      {activeProjectsLoading ? (
        <Spinner />
      ) : (
        <Slider
          data={activeProjects}
          slides={4}
          fixedWidth="300px"
          skipRow={true}
        />
      )}
    </Segment>
  );
};

export default ActiveProjectsSlider;
