import dayjs from 'dayjs';
import { get } from 'lodash';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useTranslation from 'src/hooks/useTranslation';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  height: 100%;
`;

const TooltipWrapper = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  > div:first-child {
    padding: 0.3rem 0.8rem;
    font-size: 0.8rem;
    background-color: ${({ theme }) => theme.colors.sidebarBackground};
    border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  }
  > div:last-child {
    padding: 0.5rem 0.8rem;
  }
`;

const CustomTooltip = ({ active, payload, customTitle, parseValue, label }) => {
  const additionalInfo = payload[0]?.payload?.additionalData;
  const title = payload[0]?.payload?.title;

  if (active && payload && payload.length) {
    const parsedValue = parseValue ? parseValue : (value) => value;

    return (
      <TooltipWrapper>
        <div>{title ? title : label}</div>
        {additionalInfo ? (
          <div>
            {additionalInfo.map((info, i) => (
              <div key={i}>{`${info.label}: ${info.value}`}</div>
            ))}
          </div>
        ) : (
          <div>{`${customTitle || 'Ukupno'}: ${parsedValue(
            payload[0].value
          )}`}</div>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fontSize="0.7rem"
        fill="#666"
      >
        {payload.value}
      </text>
    </g>
  );
};

const ChartStats = ({
  data,
  title,
  sumField,
  formatValue,
  sorter,
  yAxisLabel,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (!Object.keys(data).length) return <Wrapper>Nema podataka.</Wrapper>;

  const formattedData = Object.keys(data)
    .filter((d) => dayjs(d).isValid()) // Filter out invalid date keys
    .map((d) => ({
      ...data[d],
      name: d,
      value: sumField
        ? data[d].reduce((a, c) => a + get(c, sumField), 0)
        : data[d].length,
    }));

  const sortedData = sorter
    ? sorter(formattedData)
    : formattedData
        .sort((a, b) => {
          const dateA = dayjs(a.name, 'DD/MM/YY');
          const dateB = dayjs(b.name, 'DD/MM/YY');

          // Ensure parsed dates are valid
          if (!dateA.isValid() || !dateB.isValid()) {
            return 0; // Return 0 to maintain original order if dates are invalid
          }

          // Compare parsed dates
          return dateA - dateB;
        })
        .map((v) => ({
          ...v,
          name: dayjs(v.name).format('DD/MM/YY'),
        }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={sortedData}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.colors.accent}
              stopOpacity={0.3}
            />
            <stop
              offset="95%"
              stopColor={theme.colors.accent}
              stopOpacity={0.6}
            />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={<CustomXAxisTick />} />
        <YAxis
          label={{ value: yAxisLabel || t('Ukupno'), angle: -90 }}
          tick={false}
        />
        <Tooltip
          content={
            <CustomTooltip customTitle={title} parseValue={formatValue} />
          }
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke={theme.colors.accent}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ChartStats;
