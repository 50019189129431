import React, { useRef, useState } from "react";
import styled, { withTheme } from "styled-components";
import { FaEllipsisH } from "react-icons/fa";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";

const Wrapper = styled.div`
  position: relative;
  :hover {
    cursor: pointer;
  }
`;
const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 0 0.5rem;
  ${({ layout }) => layout};
  ${({ size }) => size && `font-size: ${size}`};
  ${({ round }) =>
    round &&
    `
    padding: 0.5rem;
    border-radius: 50%;
  `}
  > span {
    padding: 0.5rem;
    display: block;
    border-radius: 3px;
  }
`;

const Section = styled.div`
  margin: 0 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  color: ${({ theme }) => theme.colors.accent};
  letter-spacing: 1px;
`;

const Dropdown = styled.div`
  padding: 7px 0;
  position: absolute;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.depth.popupMenu};
  z-index: 999;
  width: 240px;
  border-radius: 3px;
  ${Section}:not(:first-child) {
    margin-top: 2rem;
  }
`;

const DropdownItem = styled.div`
  display: block;
  text-decoration: none;
  padding: 0.6rem 1rem;
  color: ${({ theme }) => theme.colors.black};
  :hover {
    color: inherit;
    background: ${({ theme }) => theme.colors.menuHighlight};
  }
`;

const ButtonDropdown = ({
  actions,
  id,
  label,
  icon,
  layout,
  noIcon,
  theme,
  ...otherProps
}) => {
  const layouts = {
    primary: `
      background-color: ${theme.colors.accent}; 
      color: ${theme.colors.white};
      svg {
        color: ${theme.colors.white};
      }
    `,
    hollow: `
      border: 1px solid ${theme.colors.sidebarBorder};
      background-color: ${theme.colors.white}; 
    `,
    "white-shadow": `
      background: ${theme.colors.white};
      box-shadow: 0 3px 4px #d2d2d2;
      color: ${theme.colors.black};
      svg {
        color: ${theme.colors.black}
      }
      border: 2px solid ${theme.colors.black};
      :hover {
        border-color: ${theme.colors.accent};
        background: ${theme.colors.highlight};
        svg {
          color: ${theme.colors.accent};
        }
      }
    `,
  };
  const dropdown = useRef();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };
  const removeDropdown = () => {
    setDropdownVisible(false);
  };
  useDetectOutsideClick(dropdown, removeDropdown);
  return (
    <Wrapper onClick={toggleDropdown}>
      <ActionButton layout={layouts[layout]} {...otherProps}>
        {label && <span>{label}</span>}
        {icon ? icon : !noIcon && <FaEllipsisH size="20px" />}
      </ActionButton>
      {dropdownVisible && (
        <Dropdown ref={dropdown}>
          {actions.map((action) => {
            if (action.showIf !== undefined && action.showIf !== true) {
              return null;
            }
            return action.section ? (
              <Section key={Math.random().toString(36).substr(2, 9)}>
                {action.section}
              </Section>
            ) : (
              <DropdownItem
                key={Math.random().toString(36).substr(2, 9)}
                onClick={() => action.action(id)}
              >
                {action.label}
              </DropdownItem>
            );
          })}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default withTheme(ButtonDropdown);
