import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import ADD_PHOTO_TO_GALLERY from "../../../apollo/mutations/addPhotoToGallery";
import { Divider } from "../../../components/elements/DynamicNavigation/DynamicNavigation.component";
import Label from "../../../components/elements/Label/Label.component";
import UploadFile from "../../../components/elements/UploadFile/UploadFile.component";
import FormField from "../../../components/structure/wrappers/FormField/FormField.component";
import Photos from "./Photos";

const Wrapper = styled.div``;

const Gallery = ({ companyId, images }) => {
  const [addPhotoMutation] = useMutation(ADD_PHOTO_TO_GALLERY, {
    onCompleted: () => toast.success("Fotografija dodana"),
    onError: () => {},
  });
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    if (images) {
      setGallery(images);
    }
  }, [images]);

  const handleAddToGallery = (filename) => {
    addPhotoMutation({
      variables: { companyId, filename },
      refetchQueries: ["getSingleUser"],
    });
  };
  return (
    <Wrapper>
      <h2>Uređivanje galerije firme</h2>
      <FormField>
        <Label>Odaberite fotografiju s računara i kliknite upload</Label>
        <UploadFile
          noReplace
          directory="jpg"
          name="gallery"
          acceptedformats={["image/png", "image/jpeg"]}
          maxMB="50"
          onSuccess={(filename) => handleAddToGallery(filename)}
        />
      </FormField>
      <Divider vertical="2rem" />
      <Photos gallery={gallery} companyId={companyId} />
    </Wrapper>
  );
};

export default Gallery;
