import { useQuery } from "@apollo/client";
import { groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import { BiChip } from "react-icons/bi";
import LATEST_BUYERS_ORDERS_VALUE from "src/apollo/queries/dashboard/latestBuyersOrdersValue";
import ChartStats from "src/components/ChartStats/ChartStats";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import { IconWrapper, Number } from "./Dashboard";

const BuyersOrdersValueChart = () => {
  const { t } = useTranslation();
  // latest buyers orders value
  const [lbov, setLbov] = useState([]);

  const {
    loading,
    data: { latestBuyersOrdersValue } = {},
    error: latestBuyersOrdersValueError,
  } = useQuery(LATEST_BUYERS_ORDERS_VALUE, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (latestBuyersOrdersValue?.length) {
      setLbov(groupBy(latestBuyersOrdersValue, "dateCreated"));
    }
  }, [latestBuyersOrdersValue]);

  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t("Vrijednost poslatih narudžbi")}</h4>
          {loading ? (
            <Spinner />
          ) : (
            <Number>
              {latestBuyersOrdersValue
                ?.reduce((acc, curr) => acc + curr.value, 0)
                ?.toLocaleString("de", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                })}
            </Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats
            data={lbov}
            title={t("Ukupna vrijednost")}
            sumField="value"
            yAxisLabel={t("Vrijednost (€)")}
          />
        )}
      </Segment>
    </Distributor>
  );
};

export default BuyersOrdersValueChart;
