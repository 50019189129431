import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FiChevronDown } from "react-icons/fi";
import { currentUserContext } from "../../../Auth/currentUserContext/currentUser.context";
import ProtectedContent from "../../../Auth/ProtectedContent/ProtectedContent";
import useTranslation from "../../../../hooks/useTranslation";

const SubMenuWrapper = styled.div`
  top: 50px;
  z-index: 9;
  display: none;
  flex-direction: column;
  position: absolute;
  border-radius: 3px;
  width: 250px;
  padding: 7px 0;
  font-weight: normal;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.depth.one};
  a {
    width: 100%;
    display: block;
    text-decoration: none;
    padding: 0.8rem 1rem;
    color: ${({ theme }) => theme.colors.black};
    :hover {
      color: inherit;
      background: ${({ theme }) => theme.colors.menuHighlight};
    }
    &.active:after {
      display: none !important;
    }
  }
`;

const SubmenuDivider = styled.div`
  margin: 0.5rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
`;

const NavItemWrapper = styled.div`
  margin: 0 5px;
  font-weight: 600;
  position: relative;
  color: inherit;
  display: flex;
  align-items: center;
  a {
    align-items: center;
    & span {
      padding: 0.5rem 0.7rem;
      display: flex;
      align-items: center;
      border-radius: 3px;
      svg {
        margin-left: 0.5rem;
      }
      :hover {
        background: ${({ theme }) => theme.colors.highlight};
        color: ${({ theme }) => theme.colors.accent};
        & svg {
          color: ${({ theme }) => theme.colors.accent};
        }
      }
    }
  }
  & a.active:after {
    content: "";
    background: ${({ theme }) => theme.colors.accent};
    height: 2px;
    width: 100%;
    display: ${({ to }) => (to === "#" ? "none" : "block")};
    position: absolute;
    bottom: 0;
  }
`;

const Wrapper = styled.nav`
  display: flex;
  height: 100%;
  padding: 0 30px 0 90px;
  a {
    text-decoration: none;
  }
  ${NavItemWrapper} {
    :hover ${SubMenuWrapper} {
      display: flex;
    }
  }
`;

const Navigation = () => {
  const { t } = useTranslation();
  const {
    user: { user },
  } = useContext(currentUserContext);
  return (
    <Wrapper>
      <NavItemWrapper>
        <NavLink to="/" exact>
          <span>{t("Naslovna")}</span>
        </NavLink>
      </NavItemWrapper>
      <NavItemWrapper>
        <NavLink to="/projects">
          <span>{t("Projekti")}</span>
        </NavLink>
      </NavItemWrapper>
      <ProtectedContent onlyFor={["BUYER"]}>
        <NavItemWrapper>
          <NavLink to="/buyers-orders" exact>
            <span>{t("Ponude")}</span>
          </NavLink>
        </NavItemWrapper>
      </ProtectedContent>
      <ProtectedContent onlyFor={["MANUFACTURER"]}>
        <NavItemWrapper>
          <NavLink to="/manufacturer-orders" exact>
            <span>{t("Ponude")}</span>
          </NavLink>
        </NavItemWrapper>
      </ProtectedContent>
      <ProtectedContent onlyFor={["ADMINISTRATOR", "MODERATOR"]}>
        <NavItemWrapper>
          <NavLink to="/orders" exact>
            <span>{t("Ponude")}</span>
          </NavLink>
        </NavItemWrapper>
      </ProtectedContent>
      <ProtectedContent onlyFor={["ADMINISTRATOR", "MODERATOR"]}>
        <NavItemWrapper>
          <NavLink to="/users">
            <span>
              {t("Administracija")}
              <FiChevronDown />
            </span>
          </NavLink>
          <SubMenuWrapper>
            <NavLink to="/users">{t("Korisnici")}</NavLink>
            <SubmenuDivider />
            <NavLink to="/materials">{t("Materijali")}</NavLink>
            <NavLink to="/material-categories">
              {t("Kategorije materijala")}
            </NavLink>
            <SubmenuDivider />
            <NavLink to="/technologies">{t("Tehnologije")}</NavLink>
            <NavLink to="/technology-categories">
              {t("Kategorije tehnologije")}
            </NavLink>
            <NavLink to="/technology-parameters">
              {t("Parametri tehnologije")}
            </NavLink>
            <SubmenuDivider />
            <NavLink to="/manufacturing">{t("Proizvodnja")}</NavLink>
            <NavLink to="/manufacturing-categories">
              {t("Kategorije proizvodnje")}
            </NavLink>
          </SubMenuWrapper>
        </NavItemWrapper>
      </ProtectedContent>
    </Wrapper>
  );
};

export default Navigation;
