import Container from 'src/components/structure/wrappers/Container/Container.component';
import Main from 'src/components/structure/wrappers/Main/Main.component';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import PasswordChangeRequest from '../Users/sidebarItems/PasswordChangeRequest';

const Wrapper = styled.div``;

const OtherSettings = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container size="sm">
        <Main>
          <h1>{t('Ostale postavke')}</h1>
          <PasswordChangeRequest />
        </Main>
      </Container>
    </Wrapper>
  );
};

export default OtherSettings;
