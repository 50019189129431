import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
//import Footer from "../Footer/Footer.component";
import Header from '../Header/Header.component';
import SideNavigation from '../Header/Navigation/SideNavigation.component';
import ContentWrapper from '../wrappers/ContentWrapper/ContentWrapper.component';
import { useLocation } from 'react-router-dom';
import { currentUserContext } from 'src/components/Auth/currentUserContext/currentUser.context';
import TagManager from 'react-gtm-module';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  min-width: 1320px;
`;

const MainWrapper = styled.div`
  display: flex;
`;

const Layout = ({ children }) => {
  const location = useLocation();
  const {
    user: { user },
  } = useContext(currentUserContext);

  useEffect(() => {
    if (user?.name && user?.id) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          username: user.name,
          userId: user.id,
          pathname: location.pathname,
        },
      });
    }
  }, [location.pathname, user?.id, user?.name]);
  return (
    <Wrapper>
      <Header />
      <MainWrapper>
        <SideNavigation />
        <ContentWrapper>{children}</ContentWrapper>
      </MainWrapper>
      {/*<Footer />*/}
    </Wrapper>
  );
};

export default Layout;
