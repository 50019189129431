import React, { createContext, useState } from "react";
import useModal from "./useModal";
import Modal from "./Modal.component";

const ModalContext = createContext();
const { Provider } = ModalContext;

const ModalProvider = ({ children }) => {
  const { modal, handleModal, modalContent } = useModal();
  const [data, setData] = useState([]);
  return (
    <Provider value={{ modal, handleModal, modalContent, data, setData }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
