import gql from "graphql-tag";

const EXTEND_OFFER_DEADLINE = gql`
  mutation extendOfferDeadline(
    $projectId: String!
    $newOfferDeadline: String!
  ) {
    extendOfferDeadline(
      projectId: $projectId
      newOfferDeadline: $newOfferDeadline
    )
  }
`;

export default EXTEND_OFFER_DEADLINE;
