import gql from "graphql-tag";

const CREATE_MANUFACTURING_CATEGORY = gql`
  mutation createManufacturingCategory(
    $name: String!
    $parent: ID
    $replaceManufacturingCategoryId: ID
  ) {
    createManufacturingCategory(
      name: $name
      parent: $parent
      replaceManufacturingCategoryId: $replaceManufacturingCategoryId
    ) {
      id
      name
      parent {
        name
      }
    }
  }
`;

export default CREATE_MANUFACTURING_CATEGORY;
