import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { FaUser } from "react-icons/fa";
import { SiGooglemessages } from "react-icons/si";
import IconButton from "../../../elements/IconButton/IconButton.component";
import { currentUserContext } from "../../../Auth/currentUserContext/currentUser.context";
import { NavLink } from "react-router-dom";
import Spinner from "../../../elements/Spinner/Spinner.component";
import { useQuery } from "@apollo/client";
import GET_USER_CONVERSATIONS from "../../../../apollo/queries/getUserConversations";

const Wrapper = styled.div`
  margin-left: 1rem;
  display: flex;
`;

const MessagesWrapper = styled.div`
  position: relative;
`;

const User = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  border-left: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  padding: 0 1rem;
  text-decoration: none;
`;

const Name = styled.div`
  margin-left: 0.5rem;
`;

const Unread = styled.div`
  background: ${({ theme }) => theme.colors.accent};
  padding: 0.3rem;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 7px;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: 700;
  border: 2px solid ${({ theme }) => theme.colors.white};
  right: 0;
  top: -3px;
`;

const UserMenu = () => {
  const {
    unreadMessages,
    setUnreadMessages,
    user: { user },
  } = useContext(currentUserContext);

  const { data } = useQuery(GET_USER_CONVERSATIONS, {});

  useEffect(() => {
    setUnreadMessages(() =>
      data?.getUserConversations?.reduce(
        (acc, curr) => (!curr.read ? acc + 1 : acc),
        0
      )
    );
  }, [data?.getUserConversations, setUnreadMessages]);

  return !user ? (
    <Spinner button />
  ) : (
    <Wrapper>
      <MessagesWrapper>
        <IconButton
          offsetx="-2px"
          to={data?.getUserConversations?.length ? "/messages" : "#"}
        >
          <SiGooglemessages size="20px" />
        </IconButton>
        {parseInt(unreadMessages) > 0 && <Unread>{unreadMessages}</Unread>}
      </MessagesWrapper>
      <User to={user.role === "BUYER" ? "#" : `/users/${user.id}/details`}>
        <FaUser size="20px" />
        <Name>{user.name}</Name>
      </User>
    </Wrapper>
  );
};

export default UserMenu;
