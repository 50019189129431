import gql from 'graphql-tag';

const GET_MANUFACTURERS_OFFERS_BY_QUANTITY = gql`
  query getManufacturersOffersByQuantity {
    getManufacturersOffersByQuantity {
      projectName
      quantitiesCount
      offers {
        user
        offers
      }
      datePublished
    }
  }
`;

export default GET_MANUFACTURERS_OFFERS_BY_QUANTITY;
