import gql from "graphql-tag";

const CREATE_MANUFACTURING = gql`
  mutation createManufacturing(
    $name: String!
    $replaceManufacturingId: ID
    $category: ID!
  ) {
    createManufacturing(
      name: $name
      replaceManufacturingId: $replaceManufacturingId
      category: $category
    )
  }
`;

export default CREATE_MANUFACTURING;
