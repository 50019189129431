import React, { useContext, useEffect, useState } from 'react';
import Input from '../../../elements/Input/Input.component';
import Label from '../../../elements/Label/Label.component';
import FormField from '../../wrappers/FormField/FormField.component';
import Distributor from '../../wrappers/Distributor/Distributor.component';
import Button from '../../../elements/Button/Button.component';
import Select from '../../../elements/Select/Select.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMaterialFormSchema } from '../../../../validation/schema';
import { useMutation, useQuery } from '@apollo/client';
import CREATE_MATERIAL from '../../../../apollo/mutations/createMaterial';
import GET_MATERIAL_CATEGORIES from '../../../../apollo/queries/getMaterialCategories';
import { ModalContext } from '../../Modal/modalContext';
import toast from 'react-hot-toast';
import { pickBy } from 'lodash';
import { ISO_DESIGNATIONS } from '../../../../constants';
import Textarea from 'src/components/elements/Textarea/Textarea.component';
import GET_COMPANIES from 'src/apollo/queries/getCompanies';
import DataSelector from 'src/components/DataSelector/DataSelector';
import { DataSelectorContext } from 'src/components/DataSelector/dataSelectorContext';
import UploadFile from 'src/components/elements/UploadFile/UploadFile.component';
import REMOVE_FILE from 'src/apollo/mutations/removeFile';
import GET_MATERIAL_COMPANIES from 'src/apollo/queries/getMaterialCompanies';
import { useMemo } from 'react';
import { sortNaturally } from 'src/util/util';

const AddMaterialCategoryForm = ({ prefillData }) => {
  const { handleModal } = useContext(ModalContext);
  const { dataSelectorValues } = useContext(DataSelectorContext);

  const [file, setFile] = useState(null);

  const { loading: companiesLoading, data: { getCompanies } = {} } =
    useQuery(GET_COMPANIES);

  const {
    loading: getCategoriesLoading,
    error: getCategoriesError,
    data,
  } = useQuery(GET_MATERIAL_CATEGORIES, { fetchPolicy: 'no-cache' });

  const [createMaterialMutation, { loading, error }] = useMutation(
    CREATE_MATERIAL,
    {
      onCompleted: () => {
        toast.success('Materijal uspješno dodan');
        handleModal();
      },
      onError: () => {},
      refetchQueries: ['getAllMaterials'],
    }
  );

  const {
    loading: companiesUsingThisMaterialLoading,
    error: companiesUsingThisMaterialError,
    data: companiesUsingThisMaterial,
  } = useQuery(GET_MATERIAL_COMPANIES, {
    variables: {
      materialId: prefillData?.id,
    },
  });

  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(addMaterialFormSchema),
  });
  const onSubmit = (values) => {
    const sanitizedValues = pickBy(
      values,
      (value) => value.toString().length > 0
    );
    createMaterialMutation({
      variables: {
        ...sanitizedValues,
        replaceMaterialId: prefillData?.id,
        technicalSpecifications: file,
        companies: dataSelectorValues.companies,
      },
    });
  };

  const [removeFileMutation] = useMutation(REMOVE_FILE, {
    onError: () => {},
  });

  const handleFileUpload = (filename) => {
    // check if file exists in the database and is preloaded
    if (file) {
      // remove file from aws and db
      removeFileMutation({ variables: { filename: file } });
    }
    setFile(filename);
  };

  /* prefill */

  useEffect(() => {
    if (prefillData) {
      setValue('category', prefillData?.category?.id);
      setValue('materialDesignation', prefillData?.materialDesignation);
      setValue('name', prefillData?.name);
      setValue('materialStandard', prefillData?.materialStandard);
      setValue('tensileStrength', prefillData?.tensileStrength);
      setValue('hardnessHB', prefillData?.hardnessHB);
      setValue('hardnessHRC', prefillData?.hardnessHRC);
      setValue('materialUsage', prefillData?.materialUsage);
      setFile(prefillData?.technicalSpecifications);
    }
  }, [prefillData, setValue, data]);

  // companies using this material

  const companiesUsing = useMemo(
    () => companiesUsingThisMaterial?.getMaterialCompanies?.map((m) => m._id),
    [companiesUsingThisMaterial?.getMaterialCompanies]
  );

  console.log(data?.getMaterialCategories);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Dodaj novi materijal</h2>
      <FormField>
        <Label error={errors.category?.message}>Kategorija materijala</Label>
        <Select name="category" loading={loading} ref={register}>
          {sortNaturally(data?.getMaterialCategories, 'ISOdesignation')?.map(
            (category) => (
              <option key={category.id} value={category.id}>
                {category?.ISOdesignation}&emsp;{category?.name}
              </option>
            )
          )}
        </Select>
      </FormField>
      <FormField>
        <Label error={errors.materialDesignation?.message}>
          Oznaka materijala
        </Label>
        <Input name="materialDesignation" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.name?.message}>Naziv materijala</Label>
        <Input name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.materialStandard?.message}>
          Standard materijala
        </Label>
        <Select name="materialStandard" ref={register}>
          {ISO_DESIGNATIONS.map((i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </Select>
      </FormField>
      <Distributor col="1-1-1">
        <FormField>
          <Label error={errors.tensileStrength?.message}>
            Zatezna čvrstoća (N/mm^2)
          </Label>
          <Input type="text" name="tensileStrength" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.hardnessHB?.message}>Tvrdoća (HB)</Label>
          <Input name="hardnessHB" type="text" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.hardnessHRC?.message}>Tvrdoća (HRC)</Label>
          <Input name="hardnessHRC" type="text" ref={register} />
        </FormField>
      </Distributor>
      <FormField>
        <Label error={errors.materialUsage?.message}>Primjena materijala</Label>
        <Textarea name="materialUsage" height="10rem" ref={register}></Textarea>
      </FormField>
      <FormField>
        <Label>Tehničke specifikacije materijala</Label>
        <UploadFile
          directory="pdf"
          name="pdf"
          acceptedformats={['application/pdf']}
          maxMB="50"
          onSuccess={(filename) => handleFileUpload(filename)}
          current={file}
          removeHandler={setFile}
        />
      </FormField>
      <FormField>
        <Label error={errors.companies?.message}>
          Dodijeli dodani materijal navedenim firmama
        </Label>
        <FormField>
          <DataSelector
            params={{
              data: getCompanies,
              loading: companiesLoading && companiesUsingThisMaterialLoading,
              populateFrom: companiesUsing,
              chooseButtonLabel: 'Odaberi firme',
              name: 'companies',
              valuesField: '_id',
              inline: true,
              handlerField: 'name',
              selectAllButton: true,
              noHeaders: true,
              sortBy: {
                dataField: 'name',
                name: 'name',
                compare: '_id',
              },
            }}
          />
        </FormField>
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button>Sačuvaj materijal</Button>
      </Distributor>
    </form>
  );
};

export default AddMaterialCategoryForm;
