import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ButtonDropdown from "../ButtonDropdown/ButtonDropdown.component";

const Body = styled.tbody`
  tr:hover td {
    background-color: ${({ theme }) => theme.colors.menuHighlight};
    cursor: pointer;
  }
  tr:not(:last-child) td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  }
  td {
    text-align: left;
    svg {
      color: ${({ theme }) => theme.colors.formPlaceholder};
    }
  }
`;

const Header = styled.thead`
  th {
    text-align: left;
    border-bottom: 2px solid ${({ theme }) => theme.colors.sidebarBorder};
    font-size: 0.8rem;
  }
`;

const ActionWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  :active {
    background: ${({ theme }) => theme.colors.sidebarBorder};
  }
`;

const Wrapper = styled.div`
  > table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    th,
    td {
      padding: 0.8rem;
      line-height: 1;
    }
    ${({ actions }) =>
      actions &&
      `
      th:last-child, td:last-child {
        width: 4rem;
      }
    `}
  }
`;

const Actions = ({ children }) => {
  return <ActionWrapper>{children}</ActionWrapper>;
};

const Table = ({ headers, data, actions, hideColumns, ...otherProps }) => {
  const history = useHistory();

  const hiddenColumnsIndexes = Object.keys(headers).reduce(
    (acc, curr, index) => {
      hideColumns.includes(curr) && acc.push(index);
      return acc;
    },
    []
  );

  const prepareHeaders = Object.values(headers).filter(
    (_, i) => !hiddenColumnsIndexes.includes(i)
  );

  return (
    <Wrapper actions={!!actions.toString()}>
      <table>
        <Header>
          <tr>
            {prepareHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
            {actions && <th>Akcije</th>}
          </tr>
        </Header>
        <Body>
          {data.map((row) => (
            <tr
              onClick={() =>
                history.push(`${history.location.pathname}/${row.id}/details`)
              }
              key={row.id}
            >
              {Object.keys(headers).map(
                (headerKey, cellIndex) =>
                  !hiddenColumnsIndexes.includes(cellIndex) && (
                    <td key={cellIndex}>{row[headerKey]}</td>
                  )
              )}
              <td>
                <Actions>
                  <ButtonDropdown id={row.id} actions={actions} />
                </Actions>
              </td>
            </tr>
          ))}
        </Body>
      </table>
    </Wrapper>
  );
};

export default Table;
