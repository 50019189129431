import gql from "graphql-tag";

const GET_TECHNOLOGIES = gql`
  query getTechnologies {
    getTechnologies {
      id
      name
      category {
        id
        name
      }
      parameters {
        id
        minValue
        maxValue
        units
        parameter {
          name
          minValueLabel
          maxValueLabel
        }
      }
    }
  }
`;

export default GET_TECHNOLOGIES;
