import React from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > span {
    display: block;
  }
  > div {
    display: block;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Ring = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 8px;
    border: 5px solid ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    animation: ${rotate} 0.5s linear infinite;
    border-color: ${({ theme }) => theme.colors.black} transparent transparent
      transparent;
  }
  ${({ button }) =>
    button &&
    `
    height: auto;
    margin: 0 1rem;
    > div {
      border-width: 3px;
      height: 1.3rem;
      width: 1.3rem;
    }
  `}
`;

const Spinner = ({ button }) => {
  return (
    <Wrapper>
      {!button && <span>Učitavam...</span>}
      <div>
        <Ring button={button}>
          <div></div>
        </Ring>
      </div>
    </Wrapper>
  );
};

export default Spinner;
