import { useQuery } from "@apollo/client";
import { groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import { BiChip } from "react-icons/bi";
import LATEST_ORDERS_VALUE from "src/apollo/queries/dashboard/latestOrdersValue";
import ChartStats from "src/components/ChartStats/ChartStats";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import { IconWrapper, Number } from "./Dashboard";

const OrdersCountChart = () => {
  // administrator orders total value
  const [lov, setLov] = useState([]);

  const {
    loading,
    data: { latestOrdersValue } = {},
    error: latestOrdersValueError,
  } = useQuery(LATEST_ORDERS_VALUE, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (latestOrdersValue?.length) {
      setLov(groupBy(latestOrdersValue, "dateCreated"));
    }
  }, [latestOrdersValue]);
  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>Broj narudžbi (30 dana)</h4>
          {loading ? <Spinner /> : <Number>{latestOrdersValue?.length}</Number>}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats data={lov} title="Broj narudžbi" />
        )}
      </Segment>
    </Distributor>
  );
};

export default OrdersCountChart;
