import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  AiOutlineCodeSandbox,
  AiOutlineFileImage,
  AiOutlineFilePdf,
  AiOutlinePrinter,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import { MdLocalOffer } from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';
import ProtectedContent from 'src/components/Auth/ProtectedContent/ProtectedContent';
import Inquiry from 'src/components/Chat/Inquiry';
import DownloadFile from 'src/components/DownloadFile/DownloadFile';
import Space from 'src/components/elements/Space/Space.component';
import Textarea from 'src/components/elements/Textarea/Textarea.component';
import { ModalContext } from 'src/components/structure/Modal/modalContext';
import { pastDue } from 'src/util/util';
import styled from 'styled-components';
import SUBMIT_OFFER from '../../../apollo/mutations/sendOffer';
import { currentUserContext } from '../../../components/Auth/currentUserContext/currentUser.context';
import Button from '../../../components/elements/Button/Button.component';
import Input from '../../../components/elements/Input/Input.component';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import useTranslation from '../../../hooks/useTranslation';
import { roundDown } from '../../../util/math';
import ModalWrapper from './Other/ModalWrapper';

const PreviewWrapper = styled.div``;
const InfoWrapper = styled.div``;
const InfoHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const QuantityTable = styled.div``;

const Preview = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    object-fit: contain;
    box-shadow: 0 0 20px hsl(0deg 0% 0% / 18%);
    overflow: hidden;
  }
`;

const PositionHeading = styled.div`
  h2 {
    margin: 0;
  }
  h3 {
    margin: 0;
    padding: 0;
  }
`;

const Module = styled.div`
  margin: 3rem 0;
  ${({ preWrap }) => preWrap && `white-space: pre-wrap;`};
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ layout }) => (layout ? layout : 'auto-fill')},
    minmax(120px, 1fr)
  );
  margin-bottom: 1rem;
  align-items: center;
  grid-gap: 1rem;
  &.withButton {
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
    align-items: flex-end;
  }
`;
const RowItem = styled.div``;
const TableLabel = styled.div`
  font-size: 0.7rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.quantitiesTableLabel};
  ${({ clickable, theme }) =>
    clickable && `&:hover { cursor: pointer; color: ${theme.colors.accent}`} };
`;
const Value = styled.div`
  height: 32px;
  align-items: center;
  display: flex;
  align-items: center;
`;

export const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 0.5rem;
  }
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const DownloadButtons = styled.div`
  margin-bottom: 1rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  > div:not(:last-child) {
    margin-right: 1rem;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  > div:last-child {
    flex: 1;
  }
`;

const PositionIndex = styled.div`
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.accent};
  margin-right: 2rem;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.white};
`;

const AdditionalCharacteristic = styled.div`
  > div {
    border: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    :hover {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.accent};
      color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

const PositionDetails = ({
  contacted,
  data,
  orders,
  deliveredToBuyers,
  ownedBy,
  offerDeadline,
  deliveryDeadline,
  projectId,
  project,
  commentsEnabled,
  index,
}) => {
  const [commentsDisabled, setCommentsDisabled] = useState(false);
  const { handleModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const {
    user: { user },
  } = useContext(currentUserContext);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { register, getValues, errors, setValue, trigger } = useForm();

  const [offerButtons, setOfferButtons] = useState([]);

  const toggleOfferButton = (rowId) => {
    const btnIndex = offerButtons.findIndex((btn) => btn === rowId);
    if (btnIndex !== -1) {
      /* remove from list*/
      setOfferButtons(offerButtons.filter((btn) => btn !== rowId));
    } else {
      /* add to list */
      setOfferButtons([...offerButtons, rowId]);
    }
  };

  const isButtonDisabled = (rowId) => {
    return offerButtons.findIndex((btn) => btn === rowId) !== -1;
  };

  const [sendOfferMutation] = useMutation(SUBMIT_OFFER, {
    onCompleted: () => {
      return toast.success(
        'Cijena postavljena! Nemojte zaboraviti poslati ponudu'
      );
    },
    onError: () => {},
  });

  useEffect(() => {
    const commentIndex = data?.comments?.findIndex(
      (u) => u?.user?.id === user?.id
    );
    if (commentIndex !== -1) {
      const comment = data?.comments[commentIndex]?.comment;
      setValue('comment', comment);
      if (!!commentsEnabled) {
        setCommentsDisabled(true);
      }
    }
    if (data?.quantities) {
      for (const quantity of data.quantities) {
        const offeredPrice = quantity?.offers.find(
          (offer) => offer.userId.id === user.id
        );
        if (offeredPrice) {
          setValue(quantity.id, offeredPrice?.offeredQuantityPrice);
          setValue(
            `${quantity.id}_realisticDeliveryTime`,
            offeredPrice?.realisticDeliveryTime
          );
        }
      }
    }
  }, [data, setValue, user.id, commentsEnabled]);

  const handleSendOffer = (e, quantityId) => {
    e.preventDefault();
    const positionId = data.id;
    const userId = user.id;
    const offeredQuantityPrice = parseFloat(getValues(quantityId));
    const comment = getValues('comment');

    if (!offeredQuantityPrice) return toast.error('Niste unijeli cijenu');

    // if comment is present freeze textarea
    if (getValues('comment')) setCommentsDisabled(true);

    const realisticDeliveryTime = getValues(
      `${quantityId}_realisticDeliveryTime`
    );

    if (!realisticDeliveryTime)
      return toast.error(t('Unesite realistično vrijeme isporuke'));
    sendOfferMutation({
      variables: {
        positionId,
        quantityId,
        userId,
        offeredQuantityPrice,
        realisticDeliveryTime,
        comment,
      },
      refetchQueries: ['getSingleProject'],
    });
    toggleOfferButton(quantityId);
    toast.success('Sending..');
  };

  // if there's revised image show it instead of regular one
  let image = data?.revisedInformations?.files?.jpg
    ? data?.revisedInformations?.files?.jpg
    : data?.files?.jpg;

  // if user is owner show regular image
  if (ownedBy === user.id) image = data?.files?.jpg;

  if (['ADMINISTRATOR', 'MODERATOR'].includes(user.role)) {
    image = data?.files?.jpg;
  }

  return (
    <div ref={componentRef} className="print-style">
      <Distributor printStyle col="1-1" gap="3rem">
        <InfoWrapper className="full-page">
          <Space vertical="2rem" />
          <HeaderWrapper>
            {index ? <PositionIndex>{index}</PositionIndex> : null}
            <Distributor col="1-1-1" spaceBetween>
              <PositionHeading>
                <h2>{data.name}</h2>
                <span>{t('Naziv pozicije')}</span>
              </PositionHeading>
              <PositionHeading>
                <h2>{data.positionCode}</h2>
                <span>{t('Šifra pozicije')}</span>
              </PositionHeading>
              {data.drawingNumber && (
                <PositionHeading>
                  <h2>{data.drawingNumber}</h2>
                  <span>{t('Šifra crteža')}</span>
                </PositionHeading>
              )}
            </Distributor>
          </HeaderWrapper>
          <Module>
            <h3>{t('Količine')}</h3>
            <QuantityTable>
              {data.quantities.map((row) =>
                !deliveredToBuyers && ownedBy !== user.id ? (
                  <Row className="withButton" key={row.id}>
                    <RowItem>
                      <TableLabel>{t('Količina')}</TableLabel>
                      <Value>{row.quantity}</Value>
                    </RowItem>
                    {row?.administratorAimedPrice &&
                    !['ADMINISTRATOR', 'MODERATOR'].includes(user.role) ? (
                      <RowItem>
                        <TableLabel>{t('Cilj. cijena')} (€)</TableLabel>
                        <Value>{row?.administratorAimedPrice || '-'}</Value>
                      </RowItem>
                    ) : (
                      <RowItem>
                        <TableLabel>{t('Cilj. cijena')} (€)</TableLabel>
                        <Value>{row?.aimedPrice || '-'}</Value>
                      </RowItem>
                    )}
                    {!['ADMINISTRATOR', 'MODERATOR'].includes(user.role) && (
                      <RowItem>
                        <TableLabel>
                          {t('Vaša ponuda')}
                          {t(errors?.[row.id]?.message)}
                        </TableLabel>
                        <Value>
                          <Input
                            name={row.id}
                            type="number"
                            placeholder="0.00"
                            step="0.01"
                            min="0.00"
                            ref={register}
                            readOnly={!(pastDue(offerDeadline) > 0)}
                          />
                          {errors[row.id] && (
                            <span>{errors[row.id]?.message}</span>
                          )}
                        </Value>
                      </RowItem>
                    )}
                    <RowItem>
                      <TableLabel>{t('Ciljani termin')}</TableLabel>
                      <Value>{deliveryDeadline}</Value>
                    </RowItem>
                    {!['ADMINISTRATOR', 'MODERATOR'].includes(user.role) ? (
                      <RowItem>
                        <TableLabel>{t('Realno vrijeme isporuke')}</TableLabel>
                        <Value>
                          <Input
                            name={`${row.id}_realisticDeliveryTime`}
                            type="date"
                            ref={register}
                            readOnly={!(pastDue(offerDeadline) > 0)}
                          />
                        </Value>
                      </RowItem>
                    ) : (
                      <React.Fragment>
                        <RowItem>
                          <TableLabel>
                            {t('Realno vrijeme isporuke')}
                          </TableLabel>
                          <Value>
                            {orders?.acceptedOffers?.find(
                              (offer) =>
                                offer.position.id === data.id &&
                                offer.quantity === row.quantity
                            )?.realisticDeliveryTime || '-'}
                          </Value>
                        </RowItem>
                        <RowItem>
                          <TableLabel>{t('Odabrani dobavljač')}</TableLabel>
                          <Value>
                            {orders?.acceptedOffers?.find(
                              (offer) =>
                                offer.position.id === data.id &&
                                offer.quantity === row.quantity
                            )?.user?.company?.name || '-'}
                          </Value>
                        </RowItem>
                        <RowItem>
                          <TableLabel>{t('Odabrana cijena')}</TableLabel>
                          <Value>
                            {orders?.acceptedOffers?.find(
                              (offer) =>
                                offer.position.id === data.id &&
                                offer.quantity === row.quantity
                            )?.acceptedPrice || '-'}
                          </Value>
                        </RowItem>
                      </React.Fragment>
                    )}
                    {pastDue(offerDeadline) > 0 &&
                      !['ADMINISTRATOR', 'MODERATOR'].includes(user.role) && (
                        <RowItem>
                          <Value>
                            <Button
                              disabled={isButtonDisabled(row.id)}
                              layout="hollow"
                              onClick={(e) => handleSendOffer(e, row.id)}
                            >
                              <MdLocalOffer />
                              {t('Potvrdi cijenu')}
                            </Button>
                          </Value>
                        </RowItem>
                      )}
                  </Row>
                ) : (
                  <Row className="withButton" key={row.id}>
                    <RowItem>
                      <TableLabel>{t('Količina')}</TableLabel>
                      <Value>{row.quantity}</Value>
                    </RowItem>
                    {ownedBy === user.id && row?.aimedPrice ? (
                      <RowItem>
                        <TableLabel>{t('Ciljana cijena')}</TableLabel>
                        <Value>{roundDown(row?.aimedPrice)}</Value>
                      </RowItem>
                    ) : row?.administratorAimedPrice ? (
                      <React.Fragment>
                        <RowItem>
                          <TableLabel>{t('Ciljana cijena')}</TableLabel>
                          <Value>
                            {roundDown(row?.administratorAimedPrice)}
                          </Value>
                        </RowItem>
                        {user.role === 'MANUFACTURER' && (
                          <RowItem>
                            <TableLabel>{t('Vaša ponuda')}</TableLabel>
                            <Value>
                              {
                                row?.offers.find(
                                  (offer) => offer.userId.id === user.id
                                )?.offeredQuantityPrice
                              }
                            </Value>
                          </RowItem>
                        )}
                        {user.role === 'BUYER' && (
                          <RowItem>
                            <TableLabel>{t('Vaša ponuda')}</TableLabel>
                            <Value>
                              {roundDown(row?.administratorAimedPrice)}
                            </Value>
                          </RowItem>
                        )}
                      </React.Fragment>
                    ) : null}
                    {user?.role === 'BUYER' && (
                      <RowItem>
                        <TableLabel>{t('Cijena')} (€)</TableLabel>
                        <Value>
                          {roundDown(
                            orders?.acceptedOffers?.find(
                              (offer) =>
                                offer.position.id === data.id &&
                                offer.quantity === row.quantity
                            )?.refinedPrice
                          ) || '-'}
                        </Value>
                      </RowItem>
                    )}
                    <RowItem>
                      <TableLabel>{t('Dostava')}</TableLabel>
                      <Value>
                        {dayjs(deliveryDeadline).format('DD-MM-YYYY')}
                      </Value>
                    </RowItem>
                    <RowItem>
                      <TableLabel>{t('Realno vrijeme isporuke')}</TableLabel>
                      {user.role === 'MANUFACTURER' ? (
                        <Value>
                          {row?.offers.find(
                            (offer) => offer.userId.id === user.id
                          )?.realisticDeliveryTime || '-'}
                        </Value>
                      ) : (
                        <Value>
                          {orders?.acceptedOffers?.find(
                            (offer) =>
                              offer.position.id === data.id &&
                              offer.quantity === row.quantity
                          )?.realisticDeliveryTime || '-'}
                        </Value>
                      )}
                    </RowItem>
                  </Row>
                )
              )}
            </QuantityTable>
          </Module>
          {user?.role === 'MANUFACTURER' && (
            <Module>
              <h3>{t('Komentar')}</h3>
              <Textarea
                name="comment"
                ref={register}
                placeholder={t('Bitna napomena uz ponudu')}
                height="7rem"
                readOnly={commentsDisabled}
              ></Textarea>
            </Module>
          )}
          <Module>
            <h3>{t('Detalji izrade')}</h3>
            <Row layout="auto-fit">
              <RowItem>
                <TableLabel>{t('Proizvodnja')}</TableLabel>
                <Value>{data.manufacturing.name}</Value>
              </RowItem>
              {data?.technology[0]?.name && (
                <RowItem>
                  <TableLabel>{t('Tehnologija')}</TableLabel>
                  <Value>{data.technology[0].name}</Value>
                </RowItem>
              )}
            </Row>
            <Module>
              <Row layout="auto-fit">
                <RowItem>
                  <TableLabel>{t('Materijal')}</TableLabel>
                  <Value>{data.material.name}</Value>
                </RowItem>
                <RowItem>
                  <TableLabel>{t('Standard materijala')}</TableLabel>
                  <Value>{data.material.materialStandard}</Value>
                </RowItem>
                <RowItem>
                  <TableLabel>{t('Oznaka materijala')}</TableLabel>
                  <Value>{data.material.materialDesignation}</Value>
                </RowItem>
                {data.material?.technicalSpecifications ? (
                  <RowItem>
                    <TableLabel>{t('Specifikacija')}</TableLabel>
                    <Value>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_S3_BUCKET +
                          data.material?.technicalSpecifications
                        }
                      >
                        Otvori
                      </a>
                    </Value>
                  </RowItem>
                ) : null}
              </Row>
            </Module>
            <Module>
              <TableLabel>{t('Kategorija materijala')}</TableLabel>
              <Value>{data.material.category.name}</Value>
            </Module>
            {/* <Row>
              {data.technology?.parameters.map((p) => (
                <RowItem key={p.id}>
                  <TableLabel>{p.parameter.name}</TableLabel>
                  <Value>
                    {p.minValue} - {p.maxValue} {p.units}
                  </Value>
                </RowItem>
              ))}
            </Row> */}
          </Module>
          <Module>
            <h3>{t('Dodatne karakteristike')}</h3>
            {data?.additionalCharacteristics?.map((c) => (
              <AdditionalCharacteristic key={c.id}>
                <div
                  onClick={() =>
                    handleModal(
                      <ModalWrapper
                        title={c.name}
                        content={c.description}
                        project={project}
                        position={data}
                      />
                    )
                  }
                >
                  {c.category} | {c.name}
                </div>
              </AdditionalCharacteristic>
            ))}
          </Module>
          <Module preWrap>
            <h3>{t('Napomena')}</h3>
            {user?.role === 'MANUFACTURER'
              ? data?.revisedInformations?.note
              : data?.note}
          </Module>
        </InfoWrapper>
        <PreviewWrapper className="full-page">
          <InfoHeading className="page-break">
            <DownloadButtons>
              <DownloadButton onClick={() => handlePrint()}>
                <AiOutlinePrinter size="20px" />
                <span>PRINT</span>
              </DownloadButton>
              {['MODERATOR', 'ADMINISTRATOR', 'MANUFACTURER'].includes(
                user?.role
              )
                ? data?.revisedInformations?.files?.pdf && (
                    <DownloadFile
                      fileURL={
                        process.env.REACT_APP_S3_BUCKET +
                        data?.revisedInformations?.files?.pdf
                      }
                      filename={data.name + '.pdf'}
                    >
                      <DownloadButton>
                        <AiOutlineFilePdf size="20px" />
                        <span>PDF</span>
                      </DownloadButton>
                    </DownloadFile>
                  )
                : data?.files?.pdf && (
                    <DownloadFile
                      fileURL={process.env.REACT_APP_S3_BUCKET + data.files.pdf}
                      filename={data.name + '.pdf'}
                    >
                      <DownloadButton>
                        <AiOutlineFilePdf size="20px" />
                        <span>PDF</span>
                      </DownloadButton>
                    </DownloadFile>
                  )}
              {image && (
                <DownloadFile
                  fileURL={process.env.REACT_APP_S3_BUCKET + image}
                  filename={data.name + '.jpg'}
                >
                  <DownloadButton>
                    <AiOutlineFileImage size="20px" />
                    <span>JPG</span>
                  </DownloadButton>
                </DownloadFile>
              )}
              {data.files.step && (
                <DownloadFile
                  fileURL={process.env.REACT_APP_S3_BUCKET + data.files.step}
                  filename={data.name + '.step'}
                >
                  <DownloadButton>
                    <AiOutlineCodeSandbox size="20px" />
                    <span>STEP</span>
                  </DownloadButton>
                </DownloadFile>
              )}
              {!(pastDue(offerDeadline) <= 0) && (
                <ProtectedContent onlyFor={['MANUFACTURER', 'ADMINISTRATOR']}>
                  <DownloadButton
                    onClick={() =>
                      handleModal(
                        <Inquiry
                          receiver={ownedBy}
                          positionData={data}
                          projectId={projectId}
                          contacted={contacted}
                        />
                      )
                    }
                  >
                    <AiOutlineQuestionCircle size="20px" />
                    <span>{t('ProjectDetails__tabs__SendInquiry')}</span>
                  </DownloadButton>
                </ProtectedContent>
              )}
            </DownloadButtons>
          </InfoHeading>
          <Preview>
            <img src={process.env.REACT_APP_S3_BUCKET + image} alt="Pozicija" />
          </Preview>
        </PreviewWrapper>
      </Distributor>
    </div>
  );
};

export {
  PositionHeading,
  PositionIndex,
  Module,
  Row,
  TableLabel,
  Value,
  RowItem,
  Preview,
  PositionDetails as default,
};
