import gql from "graphql-tag";

const GET_COMPANIES = gql`
  query getCompanies {
    getCompanies {
      _id
      name
      logo
    }
  }
`;

export default GET_COMPANIES;
