import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import React from "react";
import toast from "react-hot-toast";
import GET_MANUFACTURERS_OFFERS from "src/apollo/queries/getManufacturersOffers";
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/elements/BasicTable/BasicTable.component";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import useTranslation from "src/hooks/useTranslation";
import styled from "styled-components";

const Wrapper = styled.div``;

const ManufacturersOffersList = () => {
  const {
    data: { getManufacturersOffers: data } = {},
    loading,
    error,
  } = useQuery(GET_MANUFACTURERS_OFFERS, {
    onError: () => toast.error(t("Nemoguće učitati ponude")),
  });
  const { t } = useTranslation();
  return (
    <Wrapper>
      {loading ? (
        <Spinner />
      ) : data && data.length ? (
        <Wrapper>
          {/* <CounterWrapper>
            <Total>
              <AiOutlineTag size="20px" />
              {t("Ukupno")}: 0
            </Total>
            <Total>
              <HiOutlineCash size="20px" />
              {t("Vrijednost")}: 0 EUR
            </Total>
          </CounterWrapper> */}
          <BasicTable>
            <TableHead>
              <TableRow>
                <TableHeader>{t("Naziv Projekta")}</TableHeader>
                <TableHeader>{t("Naziv pozicije")}</TableHeader>
                <TableHeader>{t("Šifra pozicije")}</TableHeader>
                <TableHeader>{t("Količina")}</TableHeader>
                <TableHeader>{t("Datum slanja ponude")}</TableHeader>
                <TableHeader>{t("Ponuđena cijena")} (EUR)</TableHeader>
                <TableHeader>{t("Realno vrijeme isporuke")}</TableHeader>
                <TableHeader>{t("Dostavljeno")}</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((offer) => (
                <TableRow key={offer.quantityOffers._id}>
                  <TableCell>{offer.project.name}</TableCell>
                  <TableCell>{offer.position.name}</TableCell>
                  <TableCell>{offer.position.positionCode}</TableCell>
                  <TableCell>{offer.quantityOffers.quantity}</TableCell>
                  <TableCell>
                    {dayjs(offer.quantityOffers.dateOffered).format(
                      "YYYY-MM-DD"
                    )}
                  </TableCell>
                  <TableCell>
                    {offer.quantityOffers.offeredQuantityPrice}
                  </TableCell>
                  <TableCell>
                    {offer.quantityOffers.realisticDeliveryTime}
                  </TableCell>
                  <TableCell>
                    {offer.quantityOffers.delivered ? "Da" : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Wrapper>
      ) : (
        t("Nemate ponuda")
      )}
    </Wrapper>
  );
};

export default ManufacturersOffersList;
