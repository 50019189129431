import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import styled from "styled-components";
import ButtonDropdown from "../../components/elements/ButtonDropdown/ButtonDropdown.component";
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from "../../components/elements/BasicTable/BasicTable.component";
import { ModalContext } from "../../components/structure/Modal/modalContext";
import Container from "../../components/structure/wrappers/Container/Container.component";
import Main from "../../components/structure/wrappers/Main/Main.component";
import GET_TECHNOLOGY_PARAMETER from "../../apollo/queries/getTechnologyParameters";
import AddTechnologyParameterForm from "../../components/structure/Header/AddTechnologyParameter/AddTechnologyParameterForm";
import REMOVE_TECHNOLOGY_PARAMETER from "src/apollo/mutations/removeTechnologyParameter";
import toast from "react-hot-toast";
const Wrapper = styled.div``;

const TechnologyParameters = () => {
  const { handleModal } = useContext(ModalContext);
  const { loading, error, data, refetch } = useQuery(GET_TECHNOLOGY_PARAMETER, {
    fetchPolicy: "network-only",
  });
  const [removeParameterMutation] = useMutation(REMOVE_TECHNOLOGY_PARAMETER, {
    onCompleted: () => toast.success("Parametar obrisan"),
    onError: () => {},
  });

  const handleEditParameter = (data) => {
    handleModal(<AddTechnologyParameterForm prefillData={data} />);
  };
  const handleRemoveParameter = (id, name) => {
    if (
      prompt(
        `Upišite POTVRDA za brisanje parametra ${name} iz baze. Ovaj proces je ireverzibilan!`
      ).trim() === "POTVRDA"
    )
      removeParameterMutation({ variables: { technologyParameterId: id } });
  };

  return (
    <Wrapper>
      <Container>
        <Main>
          <h1>Parametri tehnologije</h1>
          <BasicTable layout="auto" loading={loading}>
            <TableHead>
              <TableRow>
                <TableHeader>Naziv parametra</TableHeader>
                <TableHeader>Oznaka min</TableHeader>
                <TableHeader>Oznaka max</TableHeader>
                <ActionHeader>Akcije</ActionHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getTechnologyParameters?.map((parameter) => (
                <TableRow key={parameter.id}>
                  <TableCell>{parameter.name}</TableCell>
                  <TableCell>{parameter.minValueLabel}</TableCell>
                  <TableCell>{parameter.maxValueLabel}</TableCell>
                  <ActionCell>
                    <ButtonDropdown
                      id={parameter.id}
                      actions={[
                        {
                          label: "Uredi parametar",
                          action: () => handleEditParameter(parameter),
                        },
                        {
                          label: "Obriši parametar",
                          action: () =>
                            handleRemoveParameter(parameter.id, parameter.name),
                        },
                      ]}
                    />
                  </ActionCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default TechnologyParameters;
