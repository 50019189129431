import { useQuery } from '@apollo/client';
import GET_SINGLE_USER from 'src/apollo/queries/getSingleUser';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Tabs, { TabItem } from 'src/components/elements/Tabs/Tabs.component';
import Container from 'src/components/structure/wrappers/Container/Container.component';
import Main from 'src/components/structure/wrappers/Main/Main.component';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import Manufacturing from './Manufacturing.tab';
import Materials from './Materials.tab';
import Technologies from './Technologies.tab';

const Wrapper = styled.div``;

const CompanyQualifications = () => {
  const { t } = useTranslation();

  const { loading, data } = useQuery(GET_SINGLE_USER, {
    variables: {
      userId: 'self',
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Wrapper>
      <Container>
        <Main>
          <h1>{t('Tehničke kvalifikacije firme')}</h1>
          {loading ? (
            <Spinner />
          ) : (
            <Tabs>
              <TabItem key="1" active label={t('Tehnologije')}>
                <Technologies
                  data={data?.getSingleUser?.company?.technologies}
                />
              </TabItem>
              <TabItem key="2" label={t('Proizvodnja')}>
                <Manufacturing
                  data={data?.getSingleUser?.company?.manufacturing}
                />
              </TabItem>
              <TabItem key="3" label={t('Materijali')}>
                <Materials data={data?.getSingleUser?.company?.materials} />
              </TabItem>
            </Tabs>
          )}
        </Main>
      </Container>
    </Wrapper>
  );
};

export default CompanyQualifications;
