import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../elements/Button/Button.component";
import Input from "../elements/Input/Input.component";
import Label from "../elements/Label/Label.component";
import Distributor from "../structure/wrappers/Distributor/Distributor.component";
import FormField from "../structure/wrappers/FormField/FormField.component";
import { updateLocale } from "moment";
import bsLocale from "moment/locale/bs";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { CloseButton } from "../structure/Modal/Modal.component";
import { MdClose } from "react-icons/md";
import { filterContext } from "./filterContext";
import { useParams } from "react-router-dom";
import countries from "../../util/countries";
import useTranslation from "src/hooks/useTranslation";
import CheckList from "../elements/CheckList/CheckList.component";
import Spinner from "../elements/Spinner/Spinner.component";
import CheckerBox from "../elements/CheckerBox/CheckerBox";
import DataSelector from "../DataSelector/DataSelector";
import ReactCountryFlag from "react-country-flag";
import { DataSelectorContext } from "../DataSelector/dataSelectorContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { additionalFiltersSchema } from "src/validation/schema";
import { uniqBy } from "lodash";
import Space from "../elements/Space/Space.component";
import useDetectOutsideClick from "src/hooks/useDetectOutsideClick";
import { sortNaturally } from "src/util/util";

// translate DateRangePicker
updateLocale("bs", bsLocale);

const Wrapper = styled.div`
  position: fixed;
  right: ${({ visible }) => (visible ? 0 : "-500px")};
  transition: all 0.5s ease;
  top: 0;
  height: 100vh;
  width: 500px;
  background: white;
  z-index: 15;
  box-shadow: -10px 0 10px 0px rgb(0 0 0 / 6%);
  padding: 2rem;
`;

const Search = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
const SearchFields = styled.div`
  overflow-y: scroll;
  padding-right: 2rem;
  margin-right: -2rem;
`;

const DatePickerWrapper = styled.div``;
const AccordionWrapper = styled.div`
  margin-bottom: 0.5rem;
`;
const AccordionLabel = styled.div`
  font-size: 0.8rem;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }
`;
const AccordionContent = styled.div`
  transition: max-height 0.5s ease;
  max-height: ${({ active }) => (active ? "1000px" : 0)};
  overflow: hidden;
`;

const AccordionGroup = ({ label, children }) => {
  const [active, setActive] = useState(false);
  const toggle = () => setActive(!active);

  return (
    <AccordionWrapper>
      <AccordionLabel onClick={toggle}>{label}</AccordionLabel>
      <AccordionContent active={active}>{children}</AccordionContent>
    </AccordionWrapper>
  );
};

const AdditionalFilters = () => {
  const additionalFiltersRef = useRef();
  const [distinctCountries, setDistinctCountries] = useState([]);
  const [distinctMaterials, setDistinctMaterials] = useState([]);
  const [distinctManufacturings, setDistinctManufacturings] = useState([]);

  const { register, getValues, reset } = useForm({
    resolver: yupResolver(additionalFiltersSchema),
  });

  const { dataSelectorValues } = useContext(DataSelectorContext);
  const { t } = useTranslation();
  const { showOnly, projectId } = useParams();
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const {
    resetFilters,
    filters,
    setFilters,
    getProjects,
    data,
    loading,
    filterPaneVisible,
    setFilterPaneVisible,
  } = useContext(filterContext);

  // useEffect(() => {
  //   if (user && user.id) {
  //     getSingleUser({ variables: { userId: user.id } });
  //   }
  // }, [user, getSingleUser]);

  const handleChangeSearchString = (e) => {
    const { value } = e.target;
    setSearchString(value);
  };

  const handleCheck = (e, filterCategory) => {
    const { name } = e.target;
    setFilters((curr) => {
      if (curr[filterCategory].indexOf(name) === -1) {
        return {
          ...filters,
          [filterCategory]: [...filters[filterCategory], name],
        };
      } else {
        return {
          ...filters,
          [filterCategory]: filters[filterCategory].filter((m) => m !== name),
        };
      }
    });
  };

  const handleSearch = () => {
    let quantityRange = {};
    if (getValues("quantityMin"))
      quantityRange.min = parseInt(getValues("quantityMin"));
    if (getValues("quantityMax"))
      quantityRange.max = parseInt(getValues("quantityMax"));

    const newFilters = {
      ...filters,
      searchString,
      countries: dataSelectorValues?.countries,
      projectsWithAimedPrices: !!getValues("projectsWithAimedPrices"),
      quantityRange,
      searchDateRange: [
        dateStart?.toISOString(),
        dateEnd?.toISOString(),
      ].filter(Boolean),
    };
    setFilters(newFilters);
    getProjects({
      variables: {
        queryParams: showOnly,
        filters: newFilters,
      },
    });
  };

  const handleDatePickerChange = (start, end) => {
    setDateStart(start);
    setDateEnd(end);
  };

  const getDistinctCountries = () => {
    setDistinctCountries(
      data?.getProjects?.reduce((acc, curr) => {
        const countryCode = curr?.createdBy?.company?.contactInfo?.country;
        const current = {
          id: countryCode,
          name: countries[countryCode?.toUpperCase()],
        };
        if (acc.findIndex((c) => c.id === countryCode) === -1) {
          return [...acc, current];
        } else {
          return acc;
        }
      }, [])
    );
  };

  // get distinct values from current filtered data
  const getDistinctManufacturings = () => {
    let manufacturings = [];
    data?.getProjects?.map(({ positions }) => {
      positions?.map(({ manufacturing }) => {
        if (!manufacturings.includes(manufacturing)) {
          manufacturings.push(manufacturing);
        }
      });
    });
    setDistinctManufacturings(manufacturings);
  };

  // get distinct values from current filtered data
  const getDistinctMaterials = () => {
    let materials = [];
    data?.getProjects?.map(({ positions }) => {
      positions?.map(({ material }) => {
        if (!materials.includes(material)) {
          materials.push(material);
        }
      });
      return false;
    });
    const categorized = sortNaturally(
      uniqBy(materials, "category._id").map((cat) => ({
        category: cat.category,
        items: materials.filter((i) => i.category._id === cat.category._id),
      })),
      "category.name"
    );
    setDistinctMaterials(categorized);
  };

  useEffect(() => {
    if (filterPaneVisible) {
      getDistinctCountries();
      getDistinctManufacturings();
      getDistinctMaterials();
    }
  }, [data, filterPaneVisible]);

  const handleResetFilters = () => {
    reset();
    getProjects({
      variables: {
        queryParams: showOnly,
      },
    });
    resetFilters();
  };

  useEffect(() => {
    resetFilters();
    setSearchString("");
    getProjects({
      variables: {
        queryParams: showOnly,
        filters: {
          search: projectId || "",
        },
      },
    });
  }, [showOnly, projectId]);

  // to enable previous dates on DateRangePicker

  const previousDates = () => false;
  useDetectOutsideClick(additionalFiltersRef, () =>
    setFilterPaneVisible(false)
  );
  return (
    <Wrapper visible={filterPaneVisible} ref={additionalFiltersRef}>
      <CloseButton onClick={() => setFilterPaneVisible(false)}>
        <MdClose size="20px" />
      </CloseButton>
      <Search>
        <SearchFields>
          <h3>{t("Napredna pretraga")}</h3>
          <FormField>
            <Label>{t("Pretraga po nazivu, šifri")}...</Label>
            <Input
              placeholder={t("Pretraga...")}
              onChange={handleChangeSearchString}
              value={searchString}
            />
          </FormField>

          <FormField>
            <Label>{t("Po datumu kreiranja")}</Label>
            <DatePickerWrapper>
              <DateRangePicker
                withPortal={true}
                startDatePlaceholderText="Od"
                startDate={dateStart}
                startDateId="dateStart"
                endDatePlaceholderText="Do"
                endDate={dateEnd}
                endDateId="dateEnd"
                isOutsideRange={previousDates}
                onDatesChange={({ startDate, endDate }) =>
                  handleDatePickerChange(startDate, endDate)
                }
                focusedInput={focusedInput}
                onFocusChange={(input) => setFocusedInput(input)}
                showClearDates={true}
                small={true}
                hideKeyboardShortcutsPanel={true}
                block={true}
              />
            </DatePickerWrapper>
          </FormField>

          <FormField>
            <Label>{t("Po državi")}</Label>
            <DataSelector
              params={{
                data: distinctCountries,
                chooseButtonLabel: "Odaberi države",
                name: "countries",
                valuesField: "id",
                handlerField: "name",
                noHeaders: true,
                sortBy: {
                  dataField: "name",
                  name: "name",
                  compare: "id",
                },
                icon: {
                  getter: (countryCode) => (
                    <ReactCountryFlag countryCode={countryCode} svg />
                  ),
                  field: "id",
                },
              }}
            />
          </FormField>

          <FormField>
            <Label>{t("Količina")}</Label>
            <Distributor col="1-1">
              <Input
                type="number"
                min="1"
                name="quantityMin"
                ref={register}
                placeholder="Min."
              />
              <Input
                type="number"
                min="1"
                name="quantityMax"
                ref={register}
                placeholder="Max."
              />
            </Distributor>
          </FormField>

          <FormField>
            <Label>{t("Samo s ciljanim cijenama")}</Label>
            <CheckerBox
              name="projectsWithAimedPrices"
              ref={register}
              value={t("Prikaži samo projekte s ciljanim cijenama")}
            />
          </FormField>

          <FormField>
            <Label>{t("Tehnologija")}</Label>
            <CheckList
              data={distinctManufacturings}
              checked={filters && filters?.manufacturings}
              name="_id"
              value="name"
              onCheck={(e) => handleCheck(e, "manufacturings")}
            />
          </FormField>
          <FormField>
            <Label>{t("Grupe materijala")}</Label>
            <Space vertical="1rem" />
            {distinctMaterials &&
              distinctMaterials.map((group) => (
                <AccordionGroup label={group?.category.name || "-"}>
                  <CheckList
                    data={group.items}
                    checked={filters && filters?.materials}
                    name="_id"
                    value="name"
                    additionalLabels={[
                      "materialStandard",
                      "materialDesignation",
                    ]}
                    onCheck={(e) => handleCheck(e, "materials")}
                  />
                </AccordionGroup>
              ))}
          </FormField>
        </SearchFields>
        <Distributor submitButtons stack="end">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button onClick={handleSearch}>{t("Pretraga")}</Button>
              <Button onClick={handleResetFilters} layout="hollow">
                {t("Poništi pretragu")}
              </Button>
            </>
          )}
        </Distributor>
      </Search>
    </Wrapper>
  );
};

export default AdditionalFilters;
