import gql from "graphql-tag";

const APPROVE_ORDER = gql`
  mutation approveOrder(
    $orderId: String!
    $manufacturersOrderNumbers: [ManufacturersOrderNumberInput]
  ) {
    approveOrder(
      orderId: $orderId
      manufacturersOrderNumbers: $manufacturersOrderNumbers
    )
  }
`;

export default APPROVE_ORDER;
