import React, { useContext, useEffect, useState } from "react";
import Input from "../../../elements/Input/Input.component";
import Label from "../../../elements/Label/Label.component";
import FormField from "../../wrappers/FormField/FormField.component";
import Distributor from "../../wrappers/Distributor/Distributor.component";
import Button from "../../../elements/Button/Button.component";
import Select from "../../../elements/Select/Select.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addTechnologyParameterSchema } from "../../../../validation/schema";
import { useMutation } from "@apollo/client";
import CREATE_TECHNOLOGY_PARAMETER from "../../../../apollo/mutations/createTechnologyParameter";
import { ModalContext } from "../../Modal/modalContext";
import toast from "react-hot-toast";
import { pickBy } from "lodash";

const AddTechnologyParameterForm = ({ prefillData }) => {
  const { handleModal } = useContext(ModalContext);

  const [createTechnologyParameterMutation, { loading, error }] = useMutation(
    CREATE_TECHNOLOGY_PARAMETER,
    { onError: () => {} }
  );
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(addTechnologyParameterSchema),
  });
  const onSubmit = (values) => {
    const sanitizedValues = pickBy(values, (value) => value.length > 0);
    createTechnologyParameterMutation({
      variables: { ...sanitizedValues, replaceParameterId: prefillData?.id },
    });
    if (!loading) {
      toast.success("Parametar uspješno dodan / ažuriran");
      handleModal();
    }
  };

  // prefill

  useEffect(() => {
    if (prefillData) {
      setValue("name", prefillData.name);
      setValue("minValueLabel", prefillData.minValueLabel);
      setValue("maxValueLabel", prefillData.maxValueLabel);
    }
  }, [prefillData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Dodaj novi parametar tehnologije</h2>
      <FormField>
        <Label error={errors.name?.message}>Naziv parametra</Label>
        <Input name="name" ref={register} />
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.minValueLabel?.message}>Min label</Label>
          <Input name="minValueLabel" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.maxValueLabel?.message}>Max label</Label>
          <Input name="maxValueLabel" ref={register} />
        </FormField>
      </Distributor>
      <Distributor stack="end" submitButtons>
        <Button>Sačuvaj parametar</Button>
      </Distributor>
    </form>
  );
};

export default AddTechnologyParameterForm;
