import gql from "graphql-tag";

const LATEST_PROJECTS = gql`
  query latestProjects {
    latestProjects {
      published {
        date
      }
    }
  }
`;

export default LATEST_PROJECTS;
