import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './components/structure/Layout/Layout.component';
import Projects from './pages/Projects/Projects.page';
import ProjectDetails from './pages/Projects/ProjectDetails.page';
import Users from './pages/Users/Users.page';
import EditProject from './pages/Projects/EditProject.page';
import UserDetails from './pages/Users/UserDetails.page';
import EditUser from './pages/Users/EditUser.page';
import NotFound from './pages/404/NotFound.page';
import Technology from './pages/Technology/Technology.page';
import Materials from './pages/Materials/Materials.page';
import Manufacturing from './pages/Manufacturing/Manufacturing.page';
import { ModalProvider } from './components/structure/Modal/modalContext';
import Login from './pages/Login/Login.page';
import ProtectedRoute from './components/Auth/ProtectedRoute/ProtectedRoute';
import Messages from './pages/Messages/Messages.page';
import BuyersOrders from './pages/Orders/Buyer/BuyersOrders.page';
import Orders from './pages/Orders/Administrator/Orders.page';
import ManufacturerOrders from './pages/Orders/Manufacturer/ManufacturerOrders.page';
import BuyerOrderDetails from './pages/Orders/Buyer/BuyerOrderDetails.page';
import MaterialCategories from './pages/Materials/MaterialCategories.page';
import ManufacturingCategories from './pages/Manufacturing/ManufacturingCategories.page';
import TechnologyCategories from './pages/Technology/TechnologyCategories.page';
import TechnologyParameters from './pages/Technology/TechnologyParameters.page';
import Dashboard from './pages/Dashboard/Dashboard';
import CompanyQualifications from './pages/CompanyQualifications/CompanyQualifications.page';
import CompanyAssociates from './pages/CompanyAssociates/CompanyAssociates.page';
import AdditionalCharacteristicsPage from './pages/AdditionalCharacteristics/AdditionalCharacteristics.page';
import LogoutOnIdle from './components/LogoutOnIdle/LogoutOnIdle';
import OtherSettings from './pages/OtherSettings/OtherSettings.page';

const AppRouter = () => {
  return (
    <Router>
      <LogoutOnIdle logoutOnHoursIdling={2} />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route>
          <ModalProvider>
            <Layout>
              <Switch>
                <ProtectedRoute exact path="/" component={Dashboard} />
                <ProtectedRoute exact path="/projects" component={Projects} />
                <ProtectedRoute
                  exact
                  path="/projects/s/:projectId"
                  component={Projects}
                />
                <ProtectedRoute
                  exact
                  path="/projects/:showOnly"
                  component={Projects}
                />
                <ProtectedRoute
                  exact
                  path="/projects/:id/details"
                  component={ProjectDetails}
                />
                <ProtectedRoute
                  exact
                  path="/projects/:id/details/:positionId"
                  component={ProjectDetails}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator', 'buyer']}
                  exact
                  path="/projects/:id/edit"
                  component={EditProject}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/users"
                  component={Users}
                  requiresReauthorization
                />
                <ProtectedRoute
                  roles={['manufacturer', 'buyer', 'moderator']}
                  exact
                  path="/users/:id/details"
                  component={UserDetails}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/users/:id/edit"
                  component={EditUser}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/technologies"
                  component={Technology}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/technology-categories"
                  component={TechnologyCategories}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/technology-parameters"
                  component={TechnologyParameters}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/materials"
                  component={Materials}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/material-categories"
                  component={MaterialCategories}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/manufacturing"
                  component={Manufacturing}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/manufacturing-categories"
                  component={ManufacturingCategories}
                />
                <ProtectedRoute exact path="/messages" component={Messages} />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/orders"
                  component={Orders}
                />
                <ProtectedRoute
                  roles={['buyer']}
                  exact
                  path="/buyers-orders"
                  component={BuyersOrders}
                />
                <ProtectedRoute
                  roles={['buyer']}
                  exact
                  path="/buyers-orders/:id"
                  component={BuyerOrderDetails}
                />
                <ProtectedRoute
                  roles={['manufacturer']}
                  exact
                  path="/manufacturer-orders"
                  component={ManufacturerOrders}
                />
                <ProtectedRoute
                  exact
                  path="/company-qualifications"
                  component={CompanyQualifications}
                />
                <ProtectedRoute
                  exact
                  path="/company-associates"
                  component={CompanyAssociates}
                />
                <ProtectedRoute
                  exact
                  path="/other-settings"
                  component={OtherSettings}
                />
                <ProtectedRoute
                  roles={['administrator', 'moderator']}
                  exact
                  path="/additional-characteristics"
                  component={AdditionalCharacteristicsPage}
                />
                <Route exact strict component={NotFound} />
              </Switch>
            </Layout>
          </ModalProvider>
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;
