import { GoDesktopDownload } from 'react-icons/go';
import { Link } from 'react-router-dom';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import { Item } from '../../../components/elements/Configurator/Configurator';
const Wrapper = styled.div``;
const Description = styled.div``;
const Download = styled(Link)`
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.white};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  :hover {
    background: ${({ theme }) => theme.colors.black};
  }
`;

const DocumentList = ({ files }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {files?.length ? (
        files.map((doc) => (
          <Item key={doc.filename}>
            <Description>{doc.fileDescription}</Description>
            <Download
              to={process.env.REACT_APP_S3_BUCKET + doc.filename}
              target="_blank"
            >
              <GoDesktopDownload size="20px" />
            </Download>
          </Item>
        ))
      ) : (
        <p>{t('Nema dodanih dokumenata')}.</p>
      )}
    </Wrapper>
  );
};

export default DocumentList;
