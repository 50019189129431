import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from '../../components/structure/wrappers/Container/Container.component';
import Main from '../../components/structure/wrappers/Main/Main.component';
import Spinner from '../../components/elements/Spinner/Spinner.component';
import { useHistory, useRouteMatch } from 'react-router-dom';
import GET_ALL_USERS from '../../apollo/queries/getAllUsers';
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from '../../components/elements/BasicTable/BasicTable.component';
import ButtonDropdown from '../../components/elements/ButtonDropdown/ButtonDropdown.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import { Search } from '../Materials/Materials.page';
import Select from 'src/components/elements/Select/Select.component';
import Input from 'src/components/elements/Input/Input.component';
import Button from 'src/components/elements/Button/Button.component';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import useQueryVars from 'src/hooks/useQueryVars';
import { USER_TYPES } from 'src/constants';
const Wrapper = styled.div``;

const Users = () => {
  const [prefilter, setPrefilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');

  const clearFilter = () => {
    setFilter('');
  };

  const history = useHistory();
  const { loading, data } = useQuery(GET_ALL_USERS);

  const query = useQueryVars();

  useEffect(() => {
    const type = query.get('type');
    if (!type) return setFilteredData(data?.getAllUsers);
    setFilteredData(
      data?.getAllUsers.filter((v) => {
        if (v.role.toLowerCase() === type) return v;
        return null;
      })
    );
  }, [query, data?.getAllUsers]);

  const handleEditUserDetails = (id) => {};

  useEffect(() => {
    if (data) {
      setFilteredData(data?.getAllUsers);
    }
  }, [data]);

  const handleChange = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  const handlePrefilter = (e) => {
    const { value } = e.target;
    setPrefilter(value);
    if (!value) return setFilteredData(data?.getAllUsers);
    setFilteredData(
      data?.getAllUsers.filter((v) => {
        if (v.role.toLowerCase() === value) return v;
        return null;
      })
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (filter?.length > 0) {
      if (loading) return;

      setFilteredData(
        filteredData.filter((v) => {
          const criteriumFields = ['name', 'username', 'company.name'];
          const matches = criteriumFields.some((field) => {
            const val = get(v, field)?.toLowerCase();
            return val && val?.indexOf(filter?.toLowerCase()) !== -1;
          });

          // return all matching entries
          return matches;
        })
      );
    } else {
      setFilteredData(data?.getAllUsers);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Main>
          <Distributor col="1-1" sticky>
            <h1>Korisnici</h1>
            <Search onSubmit={handleSearch}>
              <Input
                placeholder="Pretraga..."
                onChange={handleChange}
                value={filter}
              />
              <Select value={prefilter} onChange={handlePrefilter}>
                {Object.keys(USER_TYPES).map((i) => (
                  <option key={i} value={i}>
                    {USER_TYPES[i]}
                  </option>
                ))}
              </Select>
              <Button onClick={handleSearch}>Pretraga</Button>
              <Button onClick={clearFilter} layout="hollow">
                Poništi pretragu
              </Button>
            </Search>
          </Distributor>
          {loading ? (
            <Spinner />
          ) : (
            <BasicTable layout="auto" loading={loading}>
              <TableHead sticky="75.5px">
                <TableRow>
                  <TableHeader></TableHeader>
                  <TableHeader>Ime firme</TableHeader>
                  <TableHeader>Korisničko ime</TableHeader>
                  <TableHeader>Ime i prezime</TableHeader>
                  <TableHeader>Status</TableHeader>
                  <TableHeader>Datum pristupanja</TableHeader>
                  <TableHeader>Uloga</TableHeader>
                  <TableHeader># kreiranih projekata</TableHeader>
                  <TableHeader># proslijedjeno dobavljacima</TableHeader>
                  <ActionHeader></ActionHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.map((user) => (
                  <TableRow
                    key={user.id}
                    onClick={() =>
                      history.push(
                        `${history.location.pathname}/${user.id}/details`
                      )
                    }
                  >
                    <TableCell align="center">
                      {user.company.logo ? (
                        <img
                          src={
                            process.env.REACT_APP_S3_BUCKET + user.company.logo
                          }
                          alt="Company logo"
                        />
                      ) : (
                        'Nema slike'
                      )}
                    </TableCell>
                    <TableCell>{user.company.name}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.status}</TableCell>
                    <TableCell>{user.dateCreated}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{user.company?.projects?.length}</TableCell>
                    <TableCell>
                      {user?.role !== 'MANUFACTURER' &&
                        user?.company?.projects?.reduce((acc, curr) => {
                          if (
                            curr?.orders?.manufacturer?.recievedOrder === true
                          ) {
                            return acc + 1;
                          }
                          return acc;
                        }, 0)}
                    </TableCell>
                    <ActionCell>
                      <ButtonDropdown
                        id={user.id}
                        actions={[
                          {
                            label: 'Uredi profil',
                            action: () =>
                              history.push(`/users/${user.id}/edit`),
                          },
                        ]}
                      />
                    </ActionCell>
                  </TableRow>
                ))}
              </TableBody>
            </BasicTable>
          )}
        </Main>
      </Container>
    </Wrapper>
  );
};

export default Users;
