import gql from "graphql-tag";

const GET_TECHNOLOGY_PARAMETER = gql`
  query getTechnologyParameters {
    getTechnologyParameters {
      id
      name
      minValueLabel
      maxValueLabel
    }
  }
`;

export default GET_TECHNOLOGY_PARAMETER;
