import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalContext } from "../../structure/Modal/modalContext";
import Button from "../Button/Button.component";
import TechnologiesContainer from "./TechnologiesContainer";
import { FaChevronDown } from "react-icons/fa";
import { useLazyQuery } from "@apollo/client";
import GET_SINGLE_TECHNOLOGY from "../../../apollo/queries/getSingleTechnology";
import Spinner from "../Spinner/Spinner.component";
import Input from "../Input/Input.component";
import Label from "../Label/Label.component";
import Distributor from "../../structure/wrappers/Distributor/Distributor.component";
import { useForm } from "react-hook-form";

const Wrapper = styled.div``;

const Item = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 1rem;
  box-shadow: ${({ theme }) => theme.shadow.depth.pale};
  border-radius: 3px;
  :hover {
    background: ${({ theme }) => theme.colors.sidebarBorder};
    cursor: pointer;
  }
`;

const Title = styled.div`
  flex: 1;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.formPlaceholder};
`;

const Param = styled.div`
  margin: 0.5rem 0;
`;

const Params = styled.div`
  margin-bottom: 1rem;
  border-width: 1px 1px 1px 1px;
  border-radius: 3px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.sidebarBorder};
  padding: 2rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Name = styled.div``;
const Value = styled.div``;

const ItemWrapper = styled.div``;

const Items = styled.div``;

const Configurator = ({ data, technologyData }) => {
  const { handleModal, setData, data: modalData } = useContext(ModalContext);
  const [getSingleTechnology, { data: singleTechnologyData }] = useLazyQuery(
    GET_SINGLE_TECHNOLOGY,
    {
      onCompleted: () => {},
    }
  );

  /* params contain technology id */
  const [params, setParams] = useState("");

  const { handleSubmit, errors, setValue, register, getValues } = useForm();

  useEffect(() => {
    if (technologyData && params) {
      const companyTechData = technologyData.find(
        (d) => d.technology._id === params.id
      );
      if (companyTechData?.parameters.length) {
        /* if there are custom parameters */
        companyTechData.parameters.map((param) => {
          setValue(
            `minValue_${param.parameter._id}_${companyTechData.technology._id}`,
            param.minValue
          );
          setValue(
            `maxValue_${param.parameter._id}_${companyTechData.technology._id}`,
            param.maxValue
          );
        });
      } else {
        /* otherwise load default ones */
        companyTechData?.technology?.parameters.map((param) => {
          setValue(
            `minValue_${param.parameter.id}_${companyTechData.technology._id}`,
            param.minValue
          );
          setValue(
            `maxValue_${param.parameter.id}_${companyTechData.technology._id}`,
            param.maxValue
          );
        });
      }
    }
  }, [technologyData, params]);

  const updateModalData = (technologyId, values) => {
    /* find all values for current technologyId */
    const vals = {
      technology: technologyId,
      parameters: [],
    };

    Object.keys(values).map((param) => {
      const paramData = param.split("_");
      const [valueType, paramId, techId] = paramData;
      if (techId === technologyId) {
        // check if parameter exists
        const paramIndex = vals.parameters.findIndex(
          (p) => p.parameter === paramId
        );

        if (paramIndex === -1) {
          // doesn't exist add it
          vals.parameters.push({
            parameter: paramId,
            [valueType]: parseFloat(values[param]),
          });
        } else {
          vals.parameters[paramIndex][valueType] = parseFloat(values[param]);
        }
      }
    });

    setData((currentTechnologyData) => {
      const tIndex = currentTechnologyData.findIndex(
        (t) => t._id === technologyId
      );
      return [
        ...currentTechnologyData.slice(0, tIndex),
        { ...currentTechnologyData[tIndex], parameters: vals.parameters },
        ...currentTechnologyData.slice(tIndex + 1),
      ];
    });
  };

  const handleSaveParameters = (e, technologyId) => {
    e.preventDefault();
    const values = getValues();
    updateModalData(technologyId, values);
    setParams("");
  };

  const handleClick = (e, id) => {
    e.preventDefault();
    getSingleTechnology({ variables: { technologyId: id } });
    setParams((currentValue) => (currentValue.id === id ? "" : { id }));
  };

  const handleAddValues = (e) => {
    e.preventDefault();
    handleModal(<TechnologiesContainer values={data} />);
  };

  /*    every value served from ValuesLoaded component will have onClick ev. listener
        which will load parameters from the selected value's ID
   */

  return (
    <Wrapper>
      <Items>
        {modalData.map((item) => (
          <ItemWrapper key={item._id}>
            <form>
              <Item onClick={(e) => handleClick(e, item._id)}>
                <Title>{item.name}</Title>
                <Icon>
                  <FaChevronDown size="20px" />
                </Icon>
              </Item>
              {params.id === item._id && (
                <Params>
                  {!singleTechnologyData ? (
                    <Spinner />
                  ) : (
                    singleTechnologyData.getSingleTechnology.parameters.map(
                      ({ maxValue, minValue, parameter, units }) => (
                        <Param key={parameter._id}>
                          <Row>
                            <Value>
                              <Label>Naziv parametra</Label>
                              <Name>{parameter.name}</Name>
                            </Value>
                            <Value>
                              <Label>
                                {parameter.minValueLabel}
                                {minValue && ` (def. ${minValue} ${units})`}
                              </Label>
                              <Input
                                type="number"
                                name={`minValue_${parameter._id}_${item._id}`}
                                ref={register}
                              />
                            </Value>
                            <Value>
                              <Label>
                                {parameter.maxValueLabel}
                                {maxValue && ` (def. ${maxValue} ${units})`}
                              </Label>
                              <Input
                                type="number"
                                name={`maxValue_${parameter._id}_${item._id}`}
                                ref={register}
                              />
                            </Value>
                          </Row>
                        </Param>
                      )
                    )
                  )}
                  <Distributor stack="end" submitButtons>
                    <Button
                      layout="hollow"
                      onClick={(e) => handleSaveParameters(e, item._id)}
                    >
                      Snimi parametre
                    </Button>
                  </Distributor>
                </Params>
              )}
            </form>
          </ItemWrapper>
        ))}
      </Items>
      <Button onClick={handleAddValues} width="100%">
        Dodaj / Ukloni tehnologije
      </Button>
    </Wrapper>
  );
};

export { Item, Configurator as default };
