import gql from "graphql-tag";

const GET_MATERIAL_COMPANIES = gql`
  query getMaterialCompanies($materialId: ID) {
    getMaterialCompanies(materialId: $materialId) {
      _id
    }
  }
`;

export default GET_MATERIAL_COMPANIES;
