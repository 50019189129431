import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import GET_MATERIAL_CATEGORIES from "../../apollo/queries/getMaterialCategories";
import ButtonDropdown from "../../components/elements/ButtonDropdown/ButtonDropdown.component";
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from "../../components/elements/BasicTable/BasicTable.component";
import { ModalContext } from "../../components/structure/Modal/modalContext";
import Container from "../../components/structure/wrappers/Container/Container.component";
import Main from "../../components/structure/wrappers/Main/Main.component";
import AddMaterialCategoryForm from "src/components/structure/Header/AddMaterialCategory/AddMaterialCategoryForm";
import REMOVE_MATERIAL_CATEGORY from "src/apollo/mutations/removeMaterialCategory";
import toast from "react-hot-toast";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import { Search } from "./Materials.page";
import Select from "src/components/elements/Select/Select.component";
import { uniq, get } from "lodash";
import Input from "src/components/elements/Input/Input.component";
import Button from "src/components/elements/Button/Button.component";

const Wrapper = styled.div``;

const MaterialCategories = () => {
  const { handleModal } = useContext(ModalContext);
  const { loading, error, data, refetch } = useQuery(GET_MATERIAL_CATEGORIES, {
    fetchPolicy: "network-only",
  });

  const [removeMaterialCategoryMutation] = useMutation(
    REMOVE_MATERIAL_CATEGORY,
    {
      onCompleted: () => {
        toast.success("Kategorija uspješno obrisana");
        refetch();
      },
      onError: () => {},
    }
  );

  const handleEditCategory = (data) => {
    handleModal(<AddMaterialCategoryForm prefillData={data} />);
  };
  const handleRemoveCategory = (id, name) => {
    if (
      prompt(
        `Upišite POTVRDA za brisanje kategorije materijala ${name} iz baze. Ovaj proces je ireverzibilan!`
      ).trim() === "POTVRDA"
    )
      removeMaterialCategoryMutation({
        variables: {
          categoryId: id,
        },
      });
  };

  // filter
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("");

  const clearFilter = () => {
    setFilter("");
    setPrefilter("");
  };

  // prefilter (dropdown)

  const [prefilter, setPrefilter] = useState("");

  const handlePrefilter = (e) => {
    const { value } = e.target;
    setPrefilter(value);
    setFilteredData(
      data?.getMaterialCategories.filter((val) => val?.ISOdesignation === value)
    );
  };

  useEffect(() => {
    if (data) {
      setFilteredData(data?.getMaterialCategories);
    }
  }, [data]);

  const handleChange = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (filter.length) {
      setFilteredData(
        data?.getMaterialCategories.filter((v) => {
          const criteriumFields = ["name"];
          const matches = criteriumFields.some((field) => {
            const val = get(v, field)?.toLowerCase();
            return val && val?.indexOf(filter?.toLowerCase()) !== -1;
          });

          // if prefilter doesn't match return false
          if (prefilter && v?.ISOdesignation !== prefilter) return false;

          // return all matching entries
          return matches;
        })
      );
    } else {
      setFilteredData(data?.getMaterialCategories);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Main>
          <Distributor col="1-1" sticky>
            <h1>Kategorije materijala</h1>
            <Search onSubmit={handleSearch}>
              <Select value={prefilter} onChange={handlePrefilter}>
                {uniq(
                  data?.getMaterialCategories?.map(
                    (tech) => tech?.ISOdesignation
                  )
                )?.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </Select>
              <Input
                placeholder="Pretraga..."
                onChange={handleChange}
                value={filter}
              />
              <Button onClick={handleSearch}>Pretraga</Button>
              <Button onClick={clearFilter} layout="hollow">
                Poništi pretragu
              </Button>
            </Search>
          </Distributor>
          <BasicTable layout="auto" loading={loading}>
            <TableHead sticky="75.5px">
              <TableRow>
                <TableHeader>Naziv kategorije</TableHeader>
                <TableHeader>ISO oznaka kategorije</TableHeader>
                <ActionHeader>Akcije</ActionHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((category) => (
                <TableRow key={category.id}>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>{category.ISOdesignation}</TableCell>
                  <ActionCell>
                    <ButtonDropdown
                      id={category.id}
                      actions={[
                        {
                          label: "Uredi kategoriju",
                          action: () => handleEditCategory(category),
                        },
                        {
                          label: "Obriši kategoriju",
                          action: () =>
                            handleRemoveCategory(category.id, category.name),
                        },
                      ]}
                    />
                  </ActionCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default MaterialCategories;
