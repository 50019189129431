import gql from 'graphql-tag';

const CREATE_ADDITIONAL_CHARACTERISTICS = gql`
  mutation createAdditionalCharacteristics(
    $name: String!
    $category: String!
    $replaceAdditionalCharacteristicsId: String
    $description: String
  ) {
    createAdditionalCharacteristics(
      name: $name
      category: $category
      replaceAdditionalCharacteristicsId: $replaceAdditionalCharacteristicsId
      description: $description
    )
  }
`;

export default CREATE_ADDITIONAL_CHARACTERISTICS;
