import { useContext, useEffect, useState } from 'react';
import { currentUserContext } from '../components/Auth/currentUserContext/currentUser.context';
import translation from '../localization/translation';

const useTranslation = () => {
  /* fallback is bosnian */
  const fallback = 'bs';
  const [language, setLanguage] = useState(fallback);
  const {
    user: { user },
  } = useContext(currentUserContext);

  useEffect(() => {
    if (user?.language) {
      setLanguage(user.language);
    }
  }, [user, language]);

  const t = (translationString, forceLanguage) => {
    let toLanguage = fallback;

    // if there is forced language as on the login page use it
    if (forceLanguage) {
      toLanguage = forceLanguage;
      // otherwise use language from user context
    } else {
      toLanguage = language;
    }
    /* get string */
    let translated = translation[translationString];
    if (!translated?.[toLanguage]) {
      /* if string doesn't exist return the translation string */
      translated = translationString;
    } else {
      /* if it does exist return translation */
      translated = translated[toLanguage] || translated[fallback];
    }
    /* if there is no translation return translate string */
    return translated;
  };

  return { t, language };
};

export default useTranslation;
