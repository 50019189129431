import gql from "graphql-tag";

const GET_SINGLE_TECHNOLOGY = gql`
  query getSingleTechnology($technologyId: ID!) {
    getSingleTechnology(technologyId: $technologyId) {
      id
      name
      category {
        id
        name
      }
      parameters {
        minValue
        maxValue
        units
        parameter {
          _id
          minValueLabel
          maxValueLabel
          name
        }
      }
    }
  }
`;

export default GET_SINGLE_TECHNOLOGY;
