import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import GET_MANUFACTURERS_ORDERS from "../../../apollo/queries/getManufacturersOrders";
import Spinner from "../../../components/elements/Spinner/Spinner.component";
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/elements/BasicTable/BasicTable.component";
import { ModalContext } from "../../../components/structure/Modal/modalContext";
import ViewManufacturerOrder from "./ViewManufacturerOrder";
import { CounterWrapper, Total } from "../Administrator/OrdersList";
import useTranslation from "../../../hooks/useTranslation";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import { Search } from "src/pages/Materials/Materials.page";
import Input from "src/components/elements/Input/Input.component";
import Button from "src/components/elements/Button/Button.component";
import Space from "src/components/elements/Space/Space.component";
import { AiOutlineTag } from "react-icons/ai";
import { HiOutlineCash } from "react-icons/hi";

const Wrapper = styled.div``;

const ManufacturerOrdersList = ({ onlyDelivered }) => {
  const [basicFields, setBasicFields] = useState(true);
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const { data, loading } = useQuery(GET_MANUFACTURERS_ORDERS, {
    fetchPolicy: "no-cache",
  });
  const [orderData, setOrderData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [filter, setFilter] = useState("");
  const clearFilter = () => setFilter("");
  const handleChange = ({ target: { value } }) => {
    setFilter(value);
  };
  const [orderTotal, setOrderTotal] = useState(0);

  useEffect(() => {
    // set order total value
    if (orderData?.length) {
      setOrderTotal(
        orderData
          ?.reduce(
            (acc, currPosition) =>
              acc +
              currPosition.acceptedOffers.quantity *
                currPosition.acceptedOffers.refinedPrice,
            0
          )
          .toLocaleString("de", {
            minimumFractionDigits: 2,
          })
      );
    }
  }, [orderData]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchString(filter);
  };

  useEffect(() => {
    if (data) {
      // filter by search string first
      const filteredData = data?.getManufacturersOrders?.filter((order) => {
        return (
          order?.project?.name
            ?.toLowerCase()
            .indexOf(searchString?.toLowerCase()) !== -1
        );
      });

      if (onlyDelivered) {
        setBasicFields(true);
        setOrderData(
          filteredData?.filter((order) => order?.manufacturer?.recievedOrder)
        );
      } else {
        setBasicFields(false);
        setOrderData(filteredData);
      }
    }
  }, [onlyDelivered, data, searchString]);

  return (
    <Wrapper>
      <Distributor sticky stack="end">
        <Search onSubmit={handleSearch}>
          <Input
            placeholder="Pretraga..."
            onChange={handleChange}
            value={filter}
          />
          <Button onClick={handleSearch}>Pretraga</Button>
          <Button onClick={clearFilter} layout="hollow">
            Poništi pretragu
          </Button>
        </Search>
      </Distributor>
      <Space vertical="2rem" />
      {loading ? (
        <Spinner />
      ) : orderData && orderData.length ? (
        <Wrapper>
          <CounterWrapper>
            <Total>
              <AiOutlineTag size="20px" />
              {t("Ukupno")}: {orderData?.length}
            </Total>
            <Total>
              <HiOutlineCash size="20px" />
              {t("Vrijednost")}: {orderTotal} EUR
            </Total>
          </CounterWrapper>
          <BasicTable loading={loading}>
            <TableHead>
              <TableRow>
                <TableHeader>{t("Projekt")}</TableHeader>
                <TableHeader>{t("Naziv pozicije")}</TableHeader>
                {!basicFields && (
                  <TableHeader>{t("Šifra pozicije")}</TableHeader>
                )}
                {!basicFields && <TableHeader>{t("Datum upita")}</TableHeader>}
                <TableHeader>{t("Količina")}</TableHeader>
                <TableHeader>{t("Datum kreiranja ponude")}</TableHeader>
                {!basicFields && (
                  <TableHeader>{t("Primljena narudžba")}</TableHeader>
                )}
                {!basicFields && (
                  <TableHeader>{t("Datum narudžbe")}</TableHeader>
                )}
                <TableHeader>{t("Broj narudžbe")}</TableHeader>
                <TableHeader>{t("Rok za isporuku")}</TableHeader>
                <TableHeader>{t("Datum isporuke")}</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderData.map((order) => (
                <TableRow
                  key={order.acceptedOffers._id}
                  activeRow={!order?.acceptedOffers?.seen}
                  onClick={() =>
                    handleModal(
                      <ViewManufacturerOrder
                        seen={order.acceptedOffers.seen}
                        offerId={order.acceptedOffers._id}
                        positionId={order.acceptedOffers.position._id}
                        data={order}
                      />
                    )
                  }
                >
                  <TableCell>{order.project.name}</TableCell>
                  <TableCell>{order.acceptedOffers.position.name}</TableCell>
                  {!basicFields && (
                    <TableCell>
                      {order.acceptedOffers.position.positionCode}
                    </TableCell>
                  )}
                  {!basicFields && (
                    <TableCell>{order.project.published.date}</TableCell>
                  )}
                  <TableCell>{order.acceptedOffers.quantity}</TableCell>
                  <TableCell>{order.dateCreated}</TableCell>
                  {!basicFields && (
                    <TableCell>
                      {order.manufacturer.recievedOrder ? t("Da") : ""}
                    </TableCell>
                  )}
                  {!basicFields && (
                    <TableCell>{order.manufacturer.date}</TableCell>
                  )}
                  <TableCell>
                    {order.acceptedOffers.manufacturersOrderNumber}
                  </TableCell>
                  <TableCell>{order.project.deliveryDeadline}</TableCell>

                  <TableCell>
                    {order.acceptedOffers?.deliveryStatus
                      ?.deliveredByManufacturer?.status
                      ? order.acceptedOffers?.deliveryStatus
                          ?.deliveredByManufacturer?.date
                      : "Nije isporučeno"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Wrapper>
      ) : (
        t("Nemate ponuda")
      )}
    </Wrapper>
  );
};

export default ManufacturerOrdersList;
