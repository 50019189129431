import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const SidebarItem = styled(NavLink)`
  padding: 0.8rem;
  text-decoration: none;
  border-radius: 3px;
  margin: 2px 0;
  display: flex;
  align-items: center;
  ${({ modified, theme }) =>
    modified === 'true' && `background-color: ${theme.colors.greenHighlight}`};
  > svg {
    margin-right: 1rem;
    color: ${({ theme, modified }) =>
      modified === 'true' ? theme.colors.green : theme.colors.sidebarIconFaded};
  }
  &.active {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    > svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  :not(.active):hover {
    background: ${({ theme }) => theme.colors.menuHighlight};
  }
  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
  }
`;

const SidebarNavigation = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export { SidebarItem, SidebarNavigation as default };
