import gql from "graphql-tag";

const LATEST_ORDERS_VALUE = gql`
  query latestOrdersValue {
    latestOrdersValue {
      dateCreated
      value
    }
  }
`;

export default LATEST_ORDERS_VALUE;
