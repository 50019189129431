import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BiLockAlt } from 'react-icons/bi';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Acknowledge from 'src/components/Acknowledge/Acknowledge';
import styled from 'styled-components';
import EXTEND_OFFER_DEADLINE from '../../apollo/mutations/extendOfferDeadline';
import GET_SINGLE_PROJECT from '../../apollo/queries/getSingleProject';
import { currentUserContext } from '../../components/Auth/currentUserContext/currentUser.context';
import Button from '../../components/elements/Button/Button.component';
import Input from '../../components/elements/Input/Input.component';
import Spinner from '../../components/elements/Spinner/Spinner.component';
// import Breadcrumbs from "../../components/elements/Breadcrumbs/Breadcrumbs.component";
import Tabs, { TabItem } from '../../components/elements/Tabs/Tabs.component';
import Container from '../../components/structure/wrappers/Container/Container.component';
import Distributor from '../../components/structure/wrappers/Distributor/Distributor.component';
import Main from '../../components/structure/wrappers/Main/Main.component';
import useTranslation from '../../hooks/useTranslation';
import { pastDue } from '../../util/util';
import { ProjectDetailsProvider } from './projectDetailsContext';
import OrderManufacturersList from './tabsContent/OrderManufacturersList.tab';
import Orders from './tabsContent/Orders.tab';
import Positions from './tabsContent/Positions.tab';
import ProjectStats from './tabsContent/ProjectStats.tab';
import TagManager from 'react-gtm-module';
import FormField from 'src/components/structure/wrappers/FormField/FormField.component';
import Label from 'src/components/elements/Label/Label.component';
import Select from 'src/components/elements/Select/Select.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { toggleActiveProjectFormSchema } from 'src/validation/schema';
import { useForm } from 'react-hook-form';
import PROJECT_TOGGLE_ACTIVE from 'src/apollo/mutations/projectToggleActive';
// import DUPLICATE_AND_REACTIVATE_PROJECT from "src/apollo/mutations/duplicateAndReactivateProject";

const Wrapper = styled.div``;
const ProjectInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const TopActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & * {
    margin-top: 0;
  }
  & > * {
    margin-left: 1rem;
  }
`;

const HeadingWrapper = styled.div`
  span {
    background-color: ${({ theme }) => theme.colors.highlight};
    color: ${({ theme }) => theme.colors.accent};
    padding: 0.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    svg {
      margin-right: 0.5rem;
    }
  }
`;

const HeadingTop = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;

const ProjectDetails = () => {
  const [isProjectActive, setIsProjectActive] = useState(true);
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState(null);
  const {
    user: { user },
  } = useContext(currentUserContext);

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const { positionId } = match.params;
  const { positionIds } = location?.state || { positionIds: '' };

  const {
    register: registerToggleActive,
    handleSubmit: handleSubmitToggleActive,
    setValue: setValueToggleActive,
  } = useForm({ resolver: yupResolver(toggleActiveProjectFormSchema) });

  const { error, data, loading } = useQuery(GET_SINGLE_PROJECT, {
    variables: {
      projectId: match.params.id,
    },
    fetchPolicy: 'network-only',
  });

  const [projectToggleActive, { loading: loadingToggleActive }] = useMutation(
    PROJECT_TOGGLE_ACTIVE,
    {
      onError: () => {},
      onCompleted: () => toast.success('Status projekta izmijenjen'),
    }
  );

  const submitToggleActive = ({ toggleActive }) => {
    if (
      prompt(
        'Jeste li sigurni da želite ažurirati status projekta? Projekt neće biti vidljiv nikome osim administratoru. Unesite "DA" za potvrdu'
      ) !== 'DA'
    )
      return;

    projectToggleActive({
      variables: {
        projectId: match.params.id,
        projectActiveState: toggleActive,
      },
    });
  };

  useEffect(() => {
    if (data?.getSingleProject) {
      // add event to GA
      TagManager.dataLayer({
        dataLayer: {
          event: 'project_opened',
          username: user.name,
          userId: user.id,
          projectCode: data.getSingleProject.projectCode,
        },
      });

      const ids = positionIds ? positionIds : [positionId];
      if (ids) {
        setFilteredData(() => {
          const positions = data.getSingleProject.positions.filter((position) =>
            ids.includes(position.id)
          );
          return {
            ...data.getSingleProject,
            positions,
          };
        });
      }
    }
  }, [data, positionIds]);

  const [extendOfferDeadlineMutation, { loading: loadingExtendDeadline }] =
    useMutation(EXTEND_OFFER_DEADLINE, {
      onError: () => {},
      onCompleted: (data) => {
        toast.success(t('Rok za slanje ponuda pomjeren'));
      },
    });

  const handleChangeDeadline = (e) => {
    const { value } = e.target;
    setNewDeadline(value);
  };
  const [newDeadline, setNewDeadline] = useState();
  const handleExtendOfferDeadline = () => {
    if (!data?.getSingleProject?.approved)
      return toast.error(t('Projekt nije odobren od strane administratora'));

    if (newDeadline) {
      extendOfferDeadlineMutation({
        variables: {
          projectId: match.params.id,
          newOfferDeadline: new Date(newDeadline),
        },
      });
    }
  };

  const handleEditProject = () => {
    history.push(`/projects/${match.params.id}/edit`);
  };

  if (error) {
    return (
      <div>
        <h2>ID: {match.params.id}</h2>
        <p>
          Projekt nije moguće učitati, za više informacija kontaktirajte
          administraciju i proslijedite im iznad navedeni referentni broj
          projekta.
        </p>
      </div>
    );
  }

  /* CRITICAL:  Remove edit button if current user is not the owner of the project 
                + Project and it's data shouldn't be editable if there are offers or orders involved
  */
  if (!filteredData && !loading)
    return (
      <Wrapper>
        <Container>
          <Main>
            <Acknowledge layout="warning">
              Projekt uklonjen sa mreže. Ukoliko imate nekih pitanja ili ste
              vlasnik projekta, kontaktirajte administratora.
            </Acknowledge>
            .
          </Main>
        </Container>
      </Wrapper>
    );

  return (
    <Wrapper>
      <ProjectDetailsProvider>
        <Container>
          <Main>
            {!filteredData && loading ? (
              <Spinner />
            ) : (
              <Wrapper>
                <ProjectInfo>
                  <HeadingWrapper>
                    {((filteredData?.orders?.delivered?.toBuyers &&
                      filteredData?.orders?.accepted?.byBuyer) ||
                      pastDue(filteredData?.offerDeadline) <= 0) &&
                      user?.role !== 'BUYER' && (
                        <span>
                          <BiLockAlt size="20px" />
                          {t(
                            'Navedeni projekt je već obrađen od strane administratora, ponuđen kupcu te se na projekat više ne može aplicirati'
                          )}
                        </span>
                      )}
                    <HeadingTop>
                      {filteredData.name} | {filteredData.projectCode}
                    </HeadingTop>
                  </HeadingWrapper>
                  <TopActions>
                    {pastDue(filteredData.offerDeadline) <= 0 &&
                      ((user.role === 'BUYER' &&
                        filteredData.createdBy?.id === user.id) ||
                        ['ADMINISTRATOR', 'MODERATOR'].includes(
                          user?.role
                        )) && (
                        <Distributor col="1-1">
                          <Input
                            type="date"
                            name="newOfferDeadline"
                            onChange={handleChangeDeadline}
                            value={newDeadline}
                          />
                          <Button
                            onClick={handleExtendOfferDeadline}
                            loading={loadingExtendDeadline}
                          >
                            {t('Produži rok za ponude')}
                          </Button>
                        </Distributor>
                      )}
                    {user.role === 'ADMINISTRATOR' ? (
                      <form
                        onSubmit={handleSubmitToggleActive(submitToggleActive)}
                      >
                        <Distributor col="1-1">
                          <FormField>
                            <Select
                              name="toggleActive"
                              ref={registerToggleActive}
                              readOnly={isProjectActive}
                            >
                              <option key="1" value="true">
                                Aktivan
                              </option>
                              <option key="2" value="false">
                                Neaktivan
                              </option>
                            </Select>
                          </FormField>
                          <FormField>
                            <Button loading={loadingToggleActive}>
                              Promijeni status projekta
                            </Button>
                          </FormField>
                        </Distributor>
                      </form>
                    ) : null}
                  </TopActions>
                </ProjectInfo>
                <Tabs
                  onEdit={
                    ((filteredData?.createdBy?.id === user.id &&
                      !filteredData?.approved) ||
                      ['ADMINISTRATOR', 'MODERATOR', 'BUYER'].includes(
                        user?.role
                      )) &&
                    handleEditProject
                  }
                >
                  <TabItem
                    key="1"
                    active
                    label={t('ProjectDetails__tabs__Positions')}
                  >
                    <Positions
                      data={filteredData && filteredData}
                      contacted={data?.getSingleProject?.contactedManufacturers}
                    />
                  </TabItem>
                  <TabItem
                    key="2"
                    label={t('ProjectDetails__tabs__Orders')}
                    onlyFor={['ADMINISTRATOR', 'MODERATOR']}
                  >
                    <Orders
                      data={filteredData && filteredData}
                      deliveredToBuyers={
                        filteredData?.orders?.delivered?.toBuyers
                      }
                    />
                  </TabItem>
                  <TabItem
                    key="2"
                    label={t('Kontaktirani dobavljaci')}
                    onlyFor={['ADMINISTRATOR', 'MODERATOR']}
                  >
                    <OrderManufacturersList
                      data={data?.getSingleProject?.contactedManufacturers}
                      positionData={data?.getSingleProject?.positions}
                    />
                  </TabItem>
                  {(pastDue(filteredData?.offerDeadline) <= 0 ||
                    data?.getSingleProject?.orders?.manufacturer
                      ?.recievedOrder) && (
                    <TabItem
                      key="3"
                      label={t('ProjectDetails__tabs__Stats')}
                      onlyFor={['MANUFACTURER']}
                    >
                      <ProjectStats
                        data={filteredData && filteredData?.positions}
                      />
                    </TabItem>
                  )}
                </Tabs>
              </Wrapper>
            )}
          </Main>
        </Container>
      </ProjectDetailsProvider>
    </Wrapper>
  );
};

export { HeadingWrapper, HeadingTop, ProjectDetails as default };
