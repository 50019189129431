import React, { useState } from "react";
import styled from "styled-components";
import Container from "../../../components/structure/wrappers/Container/Container.component";
import Main from "../../../components/structure/wrappers/Main/Main.component";
import OrdersList from "./OrdersList";
import Tabs, {
  TabItem,
} from "../../../components/elements/Tabs/Tabs.component";

const Wrapper = styled.div``;

const Orders = () => {
  return (
    <Wrapper>
      <Container>
        <Main>
          <h1>Lista ponuda</h1>
          <Tabs>
            <TabItem key="1" active label="Statistika ponuda">
              <OrdersList />
            </TabItem>
            <TabItem key="2" label="Revizija ponuda za kupca">
              <OrdersList onlyUnapproved />
            </TabItem>
            <TabItem key="3" label="Primljene (otvorene) narudžbe od kupca">
              <OrdersList onlyUnrevised />
            </TabItem>
            <TabItem key="4" label="Poslate narudžbe prema dobavljačima">
              <OrdersList delivered />
            </TabItem>
          </Tabs>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default Orders;
