import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Container from "../../components/structure/wrappers/Container/Container.component";
import ButtonDropdown from "../../components/elements/ButtonDropdown/ButtonDropdown.component";
import GET_ALL_MANUFACTURING from "../../apollo/queries/getAllManufacturing";
import { useMutation, useQuery } from "@apollo/client";
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from "../../components/elements/BasicTable/BasicTable.component";
import Main from "../../components/structure/wrappers/Main/Main.component";
import { ModalContext } from "../../components/structure/Modal/modalContext";
import AddManufacturingForm from "../../components/structure/Header/AddManufacturing/AddManufacturingForm";
import toast from "react-hot-toast";
import REMOVE_MANUFACTURING from "../../apollo/mutations/removeManufacturing";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import { Search } from "../Materials/Materials.page";
import Select from "src/components/elements/Select/Select.component";
import Input from "src/components/elements/Input/Input.component";
import Button from "src/components/elements/Button/Button.component";
import { uniq, get } from "lodash";

const Wrapper = styled.div``;

const Materials = () => {
  const { handleModal } = useContext(ModalContext);
  const { loading, error, data, refetch } = useQuery(GET_ALL_MANUFACTURING);
  const [removeManufacturing] = useMutation(REMOVE_MANUFACTURING, {
    onCompleted: () => {
      toast.success("Proizvodnja uspješno obrisana");
      refetch();
    },
    onError: () => {},
  });

  const handleEditManufacturing = (manufacturingData) => {
    handleModal(<AddManufacturingForm prefillData={manufacturingData} />);
  };

  const handleRemoveManufacturing = (manufacturingId, manufacturingName) => {
    if (
      prompt(
        `Upišite POTVRDA za brisanje proizvodnje ${manufacturingName} iz baze. Ovaj proces je ireverzibilan!`
      ).trim() === "POTVRDA"
    )
      removeManufacturing({
        variables: { manufacturingId },
      });
  };

  // filter
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("");

  const clearFilter = () => {
    setFilter("");
    setPrefilter("");
  };

  // prefilter (dropdown)

  const [prefilter, setPrefilter] = useState("");

  const handlePrefilter = (e) => {
    const { value } = e.target;
    setPrefilter(value);
    setFilteredData(
      data?.getAllManufacturing.filter((val) => val?.category?.name === value)
    );
  };

  useEffect(() => {
    if (data) {
      setFilteredData(data?.getAllManufacturing);
    }
  }, [data]);

  const handleChange = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (filter.length) {
      setFilteredData(
        data?.getAllManufacturing.filter((v) => {
          const criteriumFields = ["name"];
          const matches = criteriumFields.some((field) => {
            const val = get(v, field)?.toLowerCase();
            return val && val?.indexOf(filter?.toLowerCase()) !== -1;
          });

          // if prefilter doesn't match return false
          if (prefilter && v?.category?.name !== prefilter) return false;

          // return all matching entries
          return matches;
        })
      );
    } else {
      setFilteredData(data?.getAllManufacturing);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Main>
          <Distributor col="1-1" sticky>
            <h1>Proizvodnja</h1>
            <Search onSubmit={handleSearch}>
              <Select value={prefilter} onChange={handlePrefilter}>
                {uniq(
                  data?.getAllManufacturing?.map((tech) => tech?.category?.name)
                )?.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </Select>
              <Input
                placeholder="Pretraga..."
                onChange={handleChange}
                value={filter}
              />
              <Button onClick={handleSearch}>Pretraga</Button>
              <Button onClick={clearFilter} layout="hollow">
                Poništi pretragu
              </Button>
            </Search>
          </Distributor>
          <BasicTable loading={loading} layout="auto">
            <TableHead>
              <TableRow>
                <TableHeader>Naziv proizvodnje</TableHeader>
                <TableHeader>Kategorija</TableHeader>
                <ActionHeader>Akcije</ActionHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((material) => (
                <TableRow key={material.id}>
                  <TableCell>{material.name}</TableCell>
                  <TableCell>{material.category.name}</TableCell>
                  <ActionCell>
                    <ButtonDropdown
                      id={material.id}
                      actions={[
                        {
                          label: "Uredi proizvodnju",
                          action: () => handleEditManufacturing(material),
                        },
                        {
                          label: "Obriši proizvodunju",
                          action: () =>
                            handleRemoveManufacturing(
                              material.id,
                              material.name
                            ),
                        },
                      ]}
                    />
                  </ActionCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default Materials;
