//import { FaChevronCircleLeft } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { FaRocket, FaTasks } from "react-icons/fa";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  withRouter,
} from "react-router-dom";
import useTranslation from "src/hooks/useTranslation";
import styled from "styled-components";
import GET_SINGLE_PROJECT from "../../apollo/queries/getSingleProject";
import { currentUserContext } from "../../components/Auth/currentUserContext/currentUser.context";
import ProtectedContent from "../../components/Auth/ProtectedContent/ProtectedContent";
import DynamicNavigation from "../../components/elements/DynamicNavigation/DynamicNavigation.component";
import SidebarNavigation, {
  SidebarItem,
} from "../../components/elements/SidebarNavigation/SidebarNavigation.component";
import Spinner from "../../components/elements/Spinner/Spinner.component";
import Sidebar from "../../components/structure/Sidebar/Sidebar.component";
import Container from "../../components/structure/wrappers/Container/Container.component";
import { Content } from "../Users/EditUser.page";
import BasicInfo from "./sidebarItems/BasicInfo";
import EditPosition from "./sidebarItems/EditPosition";
import RevisePrices from "./sidebarItems/RevisePrices";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row-reverse;
`;
const GoBack = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.accent};
  }
  > svg {
    margin-right: 0.8rem;
  }
`;

const EditProject = withRouter(({ match, history }) => {
  const { t } = useTranslation();
  const {
    user: { user },
  } = useContext(currentUserContext);

  const { loading, error, data } = useQuery(GET_SINGLE_PROJECT, {
    variables: {
      projectId: match.params.id,
    },
    fetchPolicy: "network-only",
  });

  // const handleGoBack = () => {
  //   history.push(`/projects/${match.params.id}/details`);
  // };

  useEffect(() => {
    if (data) {
      /* if project is approved, deny editing */
      if (
        data?.getSingleProject?.approved &&
        !["ADMINISTRATOR", "MODERATOR", "BUYER"].includes(user?.role)
      )
        history.push("/404");
      if (
        data?.getSingleProject?.createdBy?.id !== user.id &&
        !["ADMINISTRATOR", "MODERATOR"].includes(user?.role)
      )
        history.push("/404");
    }
  }, [data, user, history]);

  return loading ? (
    <Spinner />
  ) : (
    <Router>
      <Wrapper>
        <Sidebar>
          {/* {          <GoBack onClick={handleGoBack}>
            <FaChevronCircleLeft />
            <span>Vrati se nazad</span>
          </GoBack>} */}
          <SidebarNavigation>
            <SidebarItem exact to={`${match.url}/basic-info`}>
              <FaRocket size="20px" />
              {t("Osnovne informacije")}
            </SidebarItem>
            <ProtectedContent onlyFor={["ADMINISTRATOR", "MODERATOR"]}>
              <SidebarItem exact to={`${match.url}/revise-prices`}>
                <FaTasks size="20px" />
                Revidiraj / Odobri projekat
              </SidebarItem>
            </ProtectedContent>
          </SidebarNavigation>
          <DynamicNavigation
            data={data?.getSingleProject?.positions}
            relativeUrl={`${match.url}/position`}
            projectId={match.params.id}
            projectApproved={data?.getSingleProject?.approved}
          />
        </Sidebar>
        <Container size="sm" width="100%">
          <Content>
            <Route
              exact
              path={`/projects/:id/edit/basic-info`}
              render={() => <BasicInfo projectData={data?.getSingleProject} />}
            />
            <Route
              path={`/projects/:id/edit/position/:positionId`}
              render={({ match }) => (
                <EditPosition
                  projectApproved={data?.getSingleProject?.approved}
                  projectId={data?.getSingleProject?.id}
                  positionId={match.params.positionId}
                />
              )}
            />
            {
              <Route
                path={`/projects/:id/edit/revise-prices`}
                render={({ match }) => (
                  <RevisePrices
                    revised={data?.getSingleProject?.approved}
                    positionData={data?.getSingleProject?.positions}
                  />
                )}
              />
            }
            <Redirect to={`${match.url}/basic-info`} />
          </Content>
        </Container>
      </Wrapper>
    </Router>
  );
});

export { GoBack, EditProject as default };
