import gql from "graphql-tag";

const LATEST_MANUFACTURERS_ORDERS_VALUE = gql`
  query latestManufacturersOrdersValue {
    latestManufacturersOrdersValue {
      dateCreated
      value
    }
  }
`;

export default LATEST_MANUFACTURERS_ORDERS_VALUE;
