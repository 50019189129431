import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Wrapper = styled(NavLink)`
  height: ${({ theme }) => theme.sizes.elementHeight};
  width: ${({ theme }) => theme.sizes.elementHeight};
  ${({ offsetx }) =>
    offsetx &&
    `
    > svg {
      margin-left: ${offsetx}
    }
  `};
  ${({ offsety }) =>
    offsety &&
    `
    > svg {
      margin-top: ${offsety}
    }
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: ${({ margin }) => margin};
  a {
    display: block;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.highlight};
    > svg {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

const IconButton = ({ children, ...otherProps }) => {
  return <Wrapper {...otherProps}>{children}</Wrapper>;
};

export default IconButton;
