import gql from "graphql-tag";

const CREATE_TECHNOLOGY = gql`
  mutation createTechnology(
    $name: String!
    $category: ID!
    $parameters: [TechnologyParameterValueInput]
    $replaceTechnologyId: ID
  ) {
    createTechnology(
      name: $name
      category: $category
      parameters: $parameters
      replaceTechnologyId: $replaceTechnologyId
    )
  }
`;

export default CREATE_TECHNOLOGY;
