import React, { createContext, useState } from "react";

const DataSelectorContext = createContext();

const DataSelectorProvider = ({ children }) => {
  const [dataSelectorValues, setDataSelectorValues] = useState({});

  const resetSelectorValues = (name) => {
    // remove only the one whose component is unloaded
    const { [name]: omit, ...res } = dataSelectorValues;
    setDataSelectorValues(res);
  };

  const populateSelectorValues = (key, value) => {
    // if value is array, just populate
    setDataSelectorValues((values) => {
      if (Array.isArray(value)) {
        return { ...values, [key]: value };
      } else {
        return { ...values, [key]: [value] };
      }
    });
  };

  const removeSelectorValues = (key, value) => {
    setDataSelectorValues((values) => {
      console.log(values, key, value);
      return values;
    });
  };

  const updateSelectorValue = (key, value) => {
    setDataSelectorValues((values) => {
      // if value is array
      if (Array.isArray(value)) {
        // if key doesn't exist
        if (!values[key]) {
          return {
            ...values,
            [key]: value,
          };
        }
        // remove values if they exist
        if (value.every((val) => values[key].indexOf(val) !== -1)) {
          return {
            ...values,
            [key]: values[key].filter((val) => value.indexOf(val) === -1),
          };
        } else {
          return { ...values, [key]: [...values[key], ...value] };
        }
      }

      // if value doesn't exist create object with empty array
      if (!values[key]) {
        return { ...values, [key]: [value] };
      }

      const i = values[key]?.indexOf(value);
      if (i === -1) {
        // if value doesn't exist
        return {
          ...values,
          [key]: [...values[key], value],
        };
      } else {
        // if value exists
        return {
          ...values,
          [key]: [...values[key].slice(0, i), ...values[key].slice(i + 1)],
        };
      }
    });
  };

  return (
    <DataSelectorContext.Provider
      value={{
        dataSelectorValues,
        updateSelectorValue,
        populateSelectorValues,
        resetSelectorValues,
        removeSelectorValues,
      }}
    >
      {children}
    </DataSelectorContext.Provider>
  );
};

export { DataSelectorContext, DataSelectorProvider };
