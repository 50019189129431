import gql from "graphql-tag";

const ADD_DOCUMENT = gql`
  mutation addDocument(
    $companyId: String!
    $filename: String!
    $fileDescription: String!
  ) {
    addDocument(
      companyId: $companyId
      filename: $filename
      fileDescription: $fileDescription
    )
  }
`;

export default ADD_DOCUMENT;
