import gql from "graphql-tag";

const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      id
      username
      name
      role
      status
      dateCreated
      rating
      company {
        name
        logo
        projects {
          _id
          orders {
            delivered {
              toBuyers
            }
            manufacturer {
              recievedOrder
            }
          }
        }
      }
    }
  }
`;

export default GET_ALL_USERS;
