import React from 'react';
import AppRouter from './AppRouter';
import GlobalStyles from './theme/global';
import { Toaster } from 'react-hot-toast';
import DataProviders from './DataProviders';
import TagManager from 'react-gtm-module';

/* NOTE: Logrocket? */

// Analytics
TagManager.initialize({
  gtmId: 'GTM-W3WSSMPB',
});

const App = () => {
  return (
    <DataProviders>
      <Toaster
        toastOptions={{
          duration: 3000,
        }}
      />
      <GlobalStyles />
      <AppRouter />
    </DataProviders>
  );
};

export default App;
