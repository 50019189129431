import React from "react";
import styled from "styled-components";
import Tabs, {
  TabItem,
} from "../../../components/elements/Tabs/Tabs.component";
import Container from "../../../components/structure/wrappers/Container/Container.component";
import Main from "../../../components/structure/wrappers/Main/Main.component";
import useTranslation from "../../../hooks/useTranslation";
import ManufacturersOffersList from "./ManufacturersOffersList";
import ManufacturersOrdersList from "./ManufacturersOrdersList";

const Wrapper = styled.div``;

const ManufacturerOrders = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <Main>
          <h1>{t("Ponude")}</h1>
          <Tabs>
            <TabItem key="2" active label={t("Statistika upita i ponuda")}>
              <ManufacturersOrdersList />
            </TabItem>
            <TabItem key="1" active label={t("Poslate ponude")}>
              <ManufacturersOffersList />
            </TabItem>
            <TabItem key="3" label={t("Naručene")}>
              <ManufacturersOrdersList onlyDelivered />
            </TabItem>
          </Tabs>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default ManufacturerOrders;
