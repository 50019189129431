import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { BiPackage } from "react-icons/bi";
import GET_ALL_ORDERS from "src/apollo/queries/getAllOrders";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import { IconWrapper, Number } from "./Dashboard";

const AllSentOrders = () => {
  const { t } = useTranslation();
  const [sentOffers, setSentOffers] = useState(0);

  const { loading } = useQuery(GET_ALL_ORDERS, {
    onCompleted: (data) => {
      setSentOffers(
        data.getAllOrders?.reduce((totalCount, order) => {
          return totalCount + order.acceptedOffers?.length;
        }, 0)
      );
    },
  });

  return (
    <Segment hoverable>
      <IconWrapper>
        <BiPackage size="50px" />
      </IconWrapper>
      <h4>{t("Broj poslatih ponuda")}</h4>
      {loading ? <Spinner /> : <Number>{sentOffers}</Number>}
    </Segment>
  );
};

export default AllSentOrders;
