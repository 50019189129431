import React, { createContext, useState } from 'react';
import GET_PROJECTS from '../../apollo/queries/getProjects';
import { useLazyQuery } from '@apollo/client';

const filterContext = createContext();
const { Provider } = filterContext;

const FilterProvider = ({ children }) => {
  // NOTE: fetch policy changed, if data appears stale return to network-only

  const [getProjects, { loading, data }] = useLazyQuery(GET_PROJECTS);
  const initialState = {
    materials: [],
    technologies: [],
    manufacturings: [],
    search: '', // this is only for email, isolating 1 project
    countries: [],
    searchString: '', // this is search query
    searchDateRange: [],
    projectsWithAimedPrices: false,
    quantityRange: {},
    paginate: false,
    page: 1,
  };

  const [filters, setFilters] = useState(initialState);
  const [filterPaneVisible, setFilterPaneVisible] = useState(false);
  const resetFilters = () => setFilters(initialState);
  return (
    <Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        filterPaneVisible,
        setFilterPaneVisible,
        getProjects,
        loading,
        data,
      }}
    >
      {children}
    </Provider>
  );
};

export { filterContext, FilterProvider };
