import gql from "graphql-tag";

const GET_ALL_MATERIALS = gql`
  query getAllMaterials {
    getAllMaterials {
      id
      name
      category {
        id
        name
        ISOdesignation
      }
      materialDesignation
      materialStandard
      tensileStrength
      hardnessHB
      hardnessHRC
      materialUsage
      technicalSpecifications
    }
  }
`;

export default GET_ALL_MATERIALS;
