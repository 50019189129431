import gql from "graphql-tag";

const CREATE_MATERIAL_CATEGORY = gql`
  mutation createMaterialCategory(
    $name: String!
    $category: ID
    $ISOdesignation: String
    $replaceMaterialCategoryId: ID
  ) {
    createMaterialCategory(
      name: $name
      category: $category
      ISOdesignation: $ISOdesignation
      replaceMaterialCategoryId: $replaceMaterialCategoryId
    ) {
      id
      name
      category {
        name
      }
    }
  }
`;

export default CREATE_MATERIAL_CATEGORY;
