import gql from "graphql-tag";

const GET_BUYERS_ORDERS = gql`
  query getBuyersOrders {
    getBuyersOrders {
      _id
      project {
        id
        name
        projectCode
        positions {
          id
          name
          positionCode
        }
        published {
          date
        }
        offerDeadline
        createdBy {
          company {
            taxPercentage
          }
        }
      }
      acceptedOffers {
        _id
        user {
          id
          name
          attributes {
            revealManufacturersInfo
          }
        }
        position {
          id
          name
          positionCode
        }
        realisticDeliveryTime
        quantity
        aimedPrice
        acceptedPrice
        acceptedByBuyer
        refinedPrice
        physicalOrderNumber
        deliveryStatus {
          recievedByBuyer {
            status
            date
          }
          deliveredByManufacturer {
            status
            date
            confirmedDelivery {
              status
              date
            }
          }
        }
      }
      delivered {
        toBuyers
        date
      }
      accepted {
        byBuyer
        date
        physicalOrderNumber
      }
      dateCreated
      conditions {
        shipmentAddress
        transportExpenses
        paymentSchedule
        paymentMethod
        paymentTimeframe
        currency
      }
      note {
        message
      }
      seen {
        byBuyer {
          status
        }
      }
    }
  }
`;

export default GET_BUYERS_ORDERS;
