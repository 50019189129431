import React from "react";
import styled from "styled-components";
import { GoBack } from "../Projects/EditProject.page";
import SidebarNavigation, {
  SidebarItem,
} from "../../components/elements/SidebarNavigation/SidebarNavigation.component";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Sidebar from "../../components/structure/Sidebar/Sidebar.component";
import Container from "../../components/structure/wrappers/Container/Container.component";
import BasicInfo from "./sidebarItems/BasicInfo";
import CompanyInfo from "./sidebarItems/CompanyInfo";
import {
  FaChevronCircleLeft,
  FaIdCard,
  FaStore,
  FaImages,
  FaFileAlt,
  FaPencilRuler,
  FaIndustry,
  FaBook,
} from "react-icons/fa";
import GET_SINGLE_USER from "../../apollo/queries/getSingleUser";
import { useQuery } from "@apollo/client";
import Gallery from "./sidebarItems/Gallery";
import Documents from "./sidebarItems/Documents";
import Machines from "./sidebarItems/Machines";
import TechnicalQualifications from "./sidebarItems/TechnicalQualifications";
import ProtectedContent from "../../components/Auth/ProtectedContent/ProtectedContent";
import OtherInfo from "./sidebarItems/OtherInfo";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row-reverse;
`;

const Content = styled.div`
  margin: 60px 30px 30px 30px;
`;

const EditUser = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { data, loading, error } = useQuery(GET_SINGLE_USER, {
    variables: { userId: match.params.id },
    fetchPolicy: "network-only",
  });

  const handleGoBack = () => {
    history.push(`/users/${match.params.id}/details`);
  };

  return (
    <Router>
      <Wrapper>
        <Sidebar>
          {/* <GoBack onClick={handleGoBack}>
            <FaChevronCircleLeft />
            <span>Vrati se nazad</span>
          </GoBack> */}
          <SidebarNavigation>
            <SidebarItem exact to={`${match.url}/basic-info`}>
              <FaIdCard size="20px" />
              Korisničke informacije
            </SidebarItem>
            <SidebarItem exact to={`${match.url}/company-info`}>
              <FaStore size="20px" />
              Informacije firme
            </SidebarItem>
            {data?.getSingleUser?.role !== "BUYER" ? (
              <ProtectedContent onlyFor={["ADMINISTRATOR", "MODERATOR"]}>
                <SidebarItem exact to={`${match.url}/technical-qualifications`}>
                  <FaPencilRuler size="20px" />
                  Tehničke kompetencije
                </SidebarItem>
              </ProtectedContent>
            ) : null}
            <SidebarItem exact to={`${match.url}/gallery`}>
              <FaImages size="20px" />
              Galerija
            </SidebarItem>
            <SidebarItem exact to={`${match.url}/documents`}>
              <FaFileAlt size="20px" /> Dokumenti
            </SidebarItem>
            {data?.getSingleUser?.role !== "BUYER" ? (
              <SidebarItem exact to={`${match.url}/machines`}>
                <FaIndustry size="20px" />
                Mašine
              </SidebarItem>
            ) : null}
            <SidebarItem exact to={`${match.url}/other`}>
              <FaBook size="20px" />
              Ostalo
            </SidebarItem>
          </SidebarNavigation>
        </Sidebar>
        <Container size="sm" width="100%">
          <Content>
            <Route
              exact
              path={`/users/:id/edit/basic-info`}
              render={() => <BasicInfo userData={data?.getSingleUser} />}
            />
            <Route
              path={`/users/:id/edit/company-info`}
              render={() => (
                <CompanyInfo
                  companyData={data?.getSingleUser?.company}
                  companyId={match.params.id}
                />
              )}
            />
            <Route
              path={`/users/:id/edit/technical-qualifications`}
              render={() => (
                <TechnicalQualifications
                  companyData={data?.getSingleUser?.company}
                />
              )}
            />
            <Route
              path={`/users/:id/edit/gallery`}
              render={() => (
                <Gallery
                  companyId={data?.getSingleUser?.company?._id}
                  images={data?.getSingleUser?.company?.photos}
                />
              )}
            />
            <Route
              path={`/users/:id/edit/documents`}
              render={() => (
                <Documents
                  companyId={data?.getSingleUser?.company?._id}
                  documents={data?.getSingleUser?.company?.documents}
                />
              )}
            />
            <Route
              path={`/users/:id/edit/machines`}
              render={() => (
                <Machines
                  companyId={data?.getSingleUser?.company?._id}
                  machines={data?.getSingleUser?.company?.machines}
                />
              )}
            />
            <Route
              path={`/users/:id/edit/other`}
              render={() => (
                <OtherInfo
                  companyId={data?.getSingleUser?.company?._id}
                  data={data?.getSingleUser?.company?.other}
                />
              )}
            />
            <Redirect to={`${match.url}/basic-info`} />
          </Content>
        </Container>
      </Wrapper>
    </Router>
  );
};

export { Content, EditUser as default };
