import gql from "graphql-tag";

const GET_TECHNOLOGY_CATEGORIES = gql`
  query getTechnologyCategories {
    getTechnologyCategories {
      id
      name
      parent {
        id
        name
      }
    }
  }
`;

export default GET_TECHNOLOGY_CATEGORIES;
