import { uniqBy } from 'lodash';
import React from 'react';
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/elements/BasicTable/BasicTable.component';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const Technologies = ({ data }) => {
  const { t } = useTranslation();
  const parsedData = uniqBy(data, 'technology.category.id').map((cat) => ({
    category: cat.technology.category,
    items: data.filter(
      (i) => i.technology.category.id === cat.technology.category.id
    ),
  }));
  return parsedData.map((c) => (
    <Wrapper>
      <h3>{c.category.name}</h3>
      <BasicTable layout="auto">
        <TableHead>
          <TableRow>
            <TableHeader>{t('Naziv tehnologije')}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {c.items?.map(({ technology }) => (
            <TableRow key={technology.id}>
              <TableCell>{technology.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BasicTable>
    </Wrapper>
  ));
};

export default Technologies;
