import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import Spinner from "../elements/Spinner/Spinner.component";

const Wrapper = styled.div``;

const DownloadFile = ({ fileURL, filename, children }) => {
  const [loading, setLoading] = useState(false);
  const downloadFile = () => {
    if (fileURL && filename) {
      setLoading(true);
      axios({
        url: "https:" + fileURL,
        method: "GET",
        responseType: "blob",
        crossDomain: true,
        onDownloadProgress: (e) => {
          if (e.loaded === e.total) {
            setLoading(false);
          }
        },
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((e) => {
          setLoading(false);
          toast.error("Nemoguće preuzeti fajl");
        });
    }
  };

  return (
    <Wrapper onClick={downloadFile}>
      {loading ? <Spinner button /> : children}
    </Wrapper>
  );
};

export default DownloadFile;
