import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Label from '../../../components/elements/Label/Label.component';
import Select from '../../../components/elements/Select/Select.component';
import FormField from '../../../components/structure/wrappers/FormField/FormField.component';
import Configurator, {
  Item,
} from '../../../components/elements/Configurator/Configurator';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import GET_TECHNOLOGY_HIERARCHY from '../../../apollo/queries/getTechnologyHierarchy';
import GET_ALL_MATERIALS from '../../../apollo/queries/getAllMaterials';
import GET_ALL_MANUFACTURING from '../../../apollo/queries/getAllManufacturing';
import GET_COMPANIES from '../../../apollo/queries/getCompanies';
import UPDATE_TECHNICAL_QUALIFICATIONS from '../../../apollo/mutations/updateTechnicalQualifications';
import { ModalContext } from '../../../components/structure/Modal/modalContext';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { flatten, pick, set, union, uniq } from 'lodash';
import Spinner from '../../../components/elements/Spinner/Spinner.component';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import Button from '../../../components/elements/Button/Button.component';
import Acknowledge from '../../../components/Acknowledge/Acknowledge';
import countries from '../../../util/countries';
import Input from '../../../components/elements/Input/Input.component';
import DataSelector from 'src/components/DataSelector/DataSelector';
import { DataSelectorContext } from 'src/components/DataSelector/dataSelectorContext';
import GET_TECHNOLOGIES from 'src/apollo/queries/getTechnologies';
import ReactCountryFlag from 'react-country-flag';
import useTranslation from 'src/hooks/useTranslation';

const Wrapper = styled.div``;

const AddRemoveFields = styled.div`
  margin-top: 1rem;
`;

const TechnicalQualifications = ({ companyData }) => {
  const { t } = useTranslation();
  const [levels, setLevels] = useState({});
  const { data: selectedTechnologies, setData: setTechnologies } =
    useContext(ModalContext);
  const { dataSelectorValues, populateSelectorValues } =
    useContext(DataSelectorContext);
  const [maxProjectsRestriction, setMaxProjectsRestriction] = useState('');

  console.log(dataSelectorValues);

  const [
    updateQualificationsMutation,
    { loading: loadingUpdateQualifications },
  ] = useMutation(UPDATE_TECHNICAL_QUALIFICATIONS, {
    onError: () => {},
    onCompleted: () => {
      toast.success('Tehničke kvalifikacije ažurirane');
    },
  });

  const handleChange = (e) => {
    setMaxProjectsRestriction(parseInt(e.target.value));
  };

  // const { data } = useQuery(GET_TECHNOLOGY_HIERARCHY);

  const { data: technologies, loading: loadingTechnologies } =
    useQuery(GET_TECHNOLOGIES);

  const { data: materials, loading: materialsLoading } =
    useQuery(GET_ALL_MATERIALS);

  const { data: manufacturing, loading: manufacturingLoading } = useQuery(
    GET_ALL_MANUFACTURING
  );

  const { data: companies, loading: companiesLoading } =
    useQuery(GET_COMPANIES);

  const handleSelectLevels = (e) => {
    const { name, value } = e.target;
    setLevels({ ...levels, [name]: parseInt(value) });
  };

  /* load data upon component render */
  useEffect(() => {
    if (companyData) {
      setTechnologies(
        companyData?.technologies.map((tech) => ({
          _id: tech.technology._id,
          name: tech.technology.name,
        }))
      );
      setLevels({
        complexityLevel: companyData?.complexityLevel,
        toleranceLevel: companyData?.toleranceLevel,
      });
      setMaxProjectsRestriction(
        companyData?.restrictions?.maxProjectsRestriction
      );
    }
  }, [companyData]);

  const loadTechnologiesFromMachines = (e) => {
    e.preventDefault();
    const machineTechnologies = uniq(
      flatten(
        companyData.machines.map((machine) =>
          machine.technologies.map((t) => t._id)
        )
      )
    );
    const concatTechValues = union(
      dataSelectorValues.technologies,
      machineTechnologies
    );
    populateSelectorValues('technologies', concatTechValues);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      prompt(
        'Ako ste sigurni da želite da snimite tehnološke kvalifikacije upišite: POTVRDA'
      ).trim() === 'POTVRDA'
    ) {
      if (!levels.toleranceLevel || !levels.complexityLevel)
        return toast.error('Morate odabrati nivo složenosti i tolerancije');
      if (dataSelectorValues?.technologies?.length < 1)
        return toast.error('Niste odabrali nijednu tehnologiju');
      if (dataSelectorValues?.materials?.length < 1)
        return toast.error('Niste odabrali nijedan materijal');

      const parsedData = {
        manufacturing: dataSelectorValues?.manufacturing,
        technologies: dataSelectorValues?.technologies?.map((t) => ({
          technology: t,
        })),
        materials: dataSelectorValues?.materials,
      };

      const restrictionsAndExclusives = {
        restrictions: {
          maxProjectsRestriction,
          projectCountryRestriction:
            dataSelectorValues?.projectCountryRestriction,
        },
        exclusiveDirectives: {
          exclusiveManufacturersGroup:
            dataSelectorValues?.exclusiveManufacturersGroup,
          exclusiveCountriesGroup: dataSelectorValues?.exclusiveCountriesGroup,
        },
      };

      updateQualificationsMutation({
        variables: {
          companyId: companyData._id,
          ...levels,
          ...parsedData,
          ...restrictionsAndExclusives,
        },
        refetchQueries: ['getSingleUser'],
      });
    }
  };

  const parsedCountries = Object.keys(countries).map((key) => {
    return { id: key.toLowerCase(), name: countries[key] };
  });

  const technologiesData = useMemo(
    () => companyData?.technologies?.map((m) => m.technology._id),
    [companyData?.technologies]
  );
  const manufacturingData = useMemo(
    () => companyData?.manufacturing?.map((m) => m.id),
    [companyData?.manufacturing]
  );
  const materialsData = useMemo(
    () => companyData?.materials?.map((m) => m.id),
    [companyData?.materials]
  );

  const exclusiveManufacturersGroupData = useMemo(
    () =>
      companyData?.exclusiveDirectives?.exclusiveManufacturersGroup.map(
        (m) => m._id
      ),
    [companyData?.exclusiveDirectives?.exclusiveManufacturersGroup]
  );

  return (
    <Wrapper>
      <Acknowledge layout="warning">
        Svaki put kad snimite izmjene, sačuvaće se sve što je trenutno prikazano
        na ovoj stranici
      </Acknowledge>
      <FormField>
        <h2>Tehnološke kompetencije</h2>
      </FormField>
      <Distributor col="3-1">
        <FormField>
          <DataSelector
            params={{
              data: technologies?.getTechnologies,
              populateFrom: technologiesData,
              loading: loadingTechnologies,
              chooseButtonLabel: t('Odaberi tehnologije'),
              name: 'technologies',
              valuesField: 'id',
              handlerField: 'name',
              sortBy: {
                dataField: 'category',
                name: 'category.name',
                compare: 'category.id',
                overrideSortByField: 'category.id',
                sortDataBy: ['id', 'parameters[0].minValue'],
              },
              additionalFields: {
                'parameters[0].minValue': {
                  label: 'Min',
                },
                'parameters[0].maxValue': {
                  label: 'Max',
                },
              },
            }}
          />
        </FormField>
        <FormField>
          <Button
            width="100%"
            align="center"
            onClick={loadTechnologiesFromMachines}
          >
            Učitaj tehnologije s mašina
          </Button>
        </FormField>
      </Distributor>
      <FormField>
        <h3>Proizvodne kvalifikacije</h3>
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label>Kompleksnost izrade</Label>
          <Select
            name="complexityLevel"
            onChange={handleSelectLevels}
            value={levels.complexityLevel}
          >
            {Array(5)
              .fill()
              .map((p, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
          </Select>
        </FormField>
        <FormField>
          <Label>Nivo tolerancije</Label>
          <Select
            name="toleranceLevel"
            onChange={handleSelectLevels}
            value={levels.toleranceLevel}
          >
            {Array(5)
              .fill()
              .map((p, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
          </Select>
        </FormField>
      </Distributor>
      <FormField>
        <DataSelector
          params={{
            data: manufacturing?.getAllManufacturing,
            populateFrom: manufacturingData,
            loading: manufacturingLoading,
            chooseButtonLabel: 'Odaberi proizvodnju',
            name: 'manufacturing',
            valuesField: 'id',
            handlerField: 'name',
            noHeaders: true,
            sortBy: {
              dataField: 'name',
              name: 'name',
              compare: 'id',
            },
          }}
        />
      </FormField>
      <FormField>
        <h3>Kvalifikacije materijala</h3>
      </FormField>
      <FormField>
        <DataSelector
          params={{
            data: materials?.getAllMaterials,
            populateFrom: materialsData,
            loading: materialsLoading,
            chooseButtonLabel: t('Odaberi materijal'),
            name: 'materials',
            valuesField: 'id',
            handlerField: 'name',
            sortBy: {
              dataField: 'category',
              name: 'category.name',
              compare: 'category.id',
              overrideSortByField: 'category.id',
            },
            additionalFields: {
              materialStandard: {
                label: 'Standard',
              },
              materialDesignation: {
                label: 'Oznaka',
              },
            },
          }}
        />
      </FormField>

      <FormField>
        <h2>Restrikcije</h2>
      </FormField>
      <FormField>
        <h4>Državna restrikcija</h4>
        <p>
          Lista projekata će se ograničiti samo na države koje navedete ispod.
          Pravilo važi za sve korisnike pod ovom kompanijom
        </p>
        <DataSelector
          params={{
            data: parsedCountries,
            populateFrom: companyData?.restrictions?.projectCountryRestriction,
            chooseButtonLabel: 'Odaberi države',
            name: 'projectCountryRestriction',
            valuesField: 'id',
            handlerField: 'name',
            noHeaders: true,
            sortBy: {
              dataField: 'name',
              name: 'name',
              compare: 'id',
            },
            icon: {
              getter: (countryCode) => (
                <ReactCountryFlag countryCode={countryCode} svg />
              ),
              field: 'id',
            },
          }}
        />
      </FormField>
      <h4>Ograničenje broja projekata</h4>
      <p>
        Broj navedenih projekata koje kupac može unijeti za 1 mjesec. Odnosi se
        na ukupan broj projekata na nivou firme.
      </p>
      <FormField>
        <Label>Unesite broj projekata</Label>
        <Input
          type="number"
          step="1"
          name="maxProjectsRestriction"
          onChange={handleChange}
          value={maxProjectsRestriction}
        />
      </FormField>
      <FormField>
        <h2>Ekskluzivne direktive</h2>
      </FormField>
      <FormField>
        <h4>Lista ekskluzivnih dobavljača</h4>
        <p>
          Projekti će biti usmjereni samo prema dobavljačima koje odaberete iz
          liste. Ovo pravilo je najstarije, ukoliko je naveden ijedan korisnik
          zanemariće se državne restrikcije i ostala pravila.
        </p>
        <DataSelector
          params={{
            data: companies?.getCompanies,
            populateFrom: exclusiveManufacturersGroupData,
            loading: companiesLoading,
            chooseButtonLabel: 'Odaberi korisnike',
            name: 'exclusiveManufacturersGroup',
            valuesField: '_id',
            handlerField: 'name',
            noHeaders: true,
            sortBy: {
              dataField: 'name',
              name: 'name',
              compare: '_id',
            },
            icon: {
              getter: (img) => (
                <img
                  src={process.env.REACT_APP_S3_BUCKET + img}
                  alt="Company logo"
                />
              ),
              field: 'logo',
            },
          }}
        />
      </FormField>
      <FormField>
        <h4>Ekskluzivne države</h4>
        <p>
          Projekti kupca će biti samo usmjereni državama koje su navedene u
          listi ispod. Pravilo iznad je starije i u tom slučaju će ove države
          biti zanemarene
        </p>
        <DataSelector
          params={{
            data: parsedCountries,
            populateFrom:
              companyData?.exclusiveDirectives?.exclusiveCountriesGroup,
            chooseButtonLabel: 'Odaberi države',
            name: 'exclusiveCountriesGroup',
            valuesField: 'id',
            handlerField: 'name',
            noHeaders: true,
            sortBy: {
              dataField: 'name',
              name: 'name',
              compare: 'id',
            },
            icon: {
              getter: (countryCode) => (
                <ReactCountryFlag countryCode={countryCode} svg />
              ),
              field: 'id',
            },
          }}
        />
      </FormField>
      <FormField>
        <Distributor stack="end" submitButtons>
          <Button loading={loadingUpdateQualifications} onClick={onSubmit}>
            Sačuvaj izmjene
          </Button>
        </Distributor>
      </FormField>
    </Wrapper>
  );
};

export default TechnicalQualifications;
