import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChip } from 'react-icons/bi';
import LATEST_MANUFACTURERS_ORDERS from 'src/apollo/queries/dashboard/latestManufacturersOrders';
import ChartStats from 'src/components/ChartStats/ChartStats';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import { IconWrapper, Number } from './Dashboard';

const ManufacturerAcceptedOffersChart = () => {
  const { t } = useTranslation();
  // latest manufacturers orders
  const [lmo, setLmo] = useState([]);

  const {
    loading,
    data: { latestManufacturersOrders } = {},
    error: latestManufacturersOrdersError,
  } = useQuery(LATEST_MANUFACTURERS_ORDERS, {});

  useEffect(() => {
    if (latestManufacturersOrders?.length) {
      setLmo(groupBy(latestManufacturersOrders, 'dateCreated'));
    }
  }, [latestManufacturersOrders]);

  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t('Prihvaćene ponude (30 dana)')}</h4>
          {loading ? (
            <Spinner />
          ) : (
            <Number>{latestManufacturersOrders?.length}</Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats
            data={lmo}
            title={t('Prihvaćene ponude')}
            yAxisLabel={t('Ponude')}
          />
        )}
      </Segment>
    </Distributor>
  );
};

export default ManufacturerAcceptedOffersChart;
