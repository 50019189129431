import gql from 'graphql-tag';

const CREATE_USER = gql`
  mutation createUser(
    $username: String!
    $password: String!
    $companyName: String
    $initials: String
    $existingCompany: String
    $email: String!
    $phoneNumber: String
    $name: String!
    $role: String
    $status: String
    $language: String
    $attributes: UserAttributesInput
    $companySettingsId: String
  ) {
    createUser(
      username: $username
      password: $password
      companyName: $companyName
      initials: $initials
      existingCompany: $existingCompany
      email: $email
      phoneNumber: $phoneNumber
      name: $name
      role: $role
      status: $status
      language: $language
      attributes: $attributes
      companySettingsId: $companySettingsId
    ) {
      token
      user {
        name
      }
    }
  }
`;

export default CREATE_USER;
