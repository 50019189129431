import gql from "graphql-tag";

const REMOVE_REVISED_INFORMATION_DATA = gql`
  mutation removeRevisedInformationData(
    $positionId: String!
    $files: [String]
  ) {
    removeRevisedInformationData(positionId: $positionId, files: $files)
  }
`;

export default REMOVE_REVISED_INFORMATION_DATA;
