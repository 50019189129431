import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { pick } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Prompt } from 'react-router-dom';
import GET_ALL_ADDITIONAL_CHARACTERISTICS from 'src/apollo/queries/getAllAdditionalCharacteristics';
import ProtectedContent from 'src/components/Auth/ProtectedContent/ProtectedContent';
import DataSelector from 'src/components/DataSelector/DataSelector';
import { DataSelectorContext } from 'src/components/DataSelector/dataSelectorContext';
import Select from 'src/components/elements/Select/Select.component';
import { ModalContext } from 'src/components/structure/Modal/modalContext';
import { ADDITIONAL_CHARACTERISTICS_CATEGORIES } from 'src/constants';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import REMOVE_FILE from '../../../apollo/mutations/removeFile';
import REMOVE_POSITION from '../../../apollo/mutations/removePosition';
import REMOVE_REVISED_INFORMATION_DATA from '../../../apollo/mutations/removeRevisedInformationData';
import UPDATE_POSITION from '../../../apollo/mutations/updatePosition';
import GET_ALL_MANUFACTURING from '../../../apollo/queries/getAllManufacturing';
import GET_ALL_MATERIALS from '../../../apollo/queries/getAllMaterials';
import GET_SINGLE_POSITION from '../../../apollo/queries/getSinglePosition';
import GET_TECHNOLOGIES from '../../../apollo/queries/getTechnologies';
import Acknowledge from '../../../components/Acknowledge/Acknowledge';
import { currentUserContext } from '../../../components/Auth/currentUserContext/currentUser.context';
import Button from '../../../components/elements/Button/Button.component';
import Input from '../../../components/elements/Input/Input.component';
import InputTable from '../../../components/elements/InputTable/InputTable.component';
import Label from '../../../components/elements/Label/Label.component';
import Textarea from '../../../components/elements/Textarea/Textarea.component';
import UploadFile from '../../../components/elements/UploadFile/UploadFile.component';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import FormField from '../../../components/structure/wrappers/FormField/FormField.component';
import { addPositionFormSchema } from '../../../validation/schema';
import Preview from './EditPosition/Preview';

const AddRemoveFields = styled.div`
  margin-top: 1rem;
`;

const Image = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  border-radius: 3px;
  :hover {
    cursor: pointer;
  }
  > img {
    width: 100%;
    box-shadow: 0 5px 7px 4px hsl(0deg 0% 0% / 8%);
  }
`;

const Param = styled.div`
  span {
    width: 30%;
    display: inline-block;
    font-weight: bold;
  }
  padding: 0.4rem 1rem;
  border-bottom: 1px solid #edf1f5;
`;

const ImageContainer = styled.div`
  img {
    height: 700px;
    object-fit: contain;
  }
`;

const EditPosition = ({ projectApproved, positionId, projectId }) => {
  const { t } = useTranslation();
  const { dataSelectorValues } = useContext(DataSelectorContext);
  const { handleModal } = useContext(ModalContext);
  const {
    user: { user },
  } = useContext(currentUserContext);

  const [isProjectActive, setIsProjectActive] = useState(true);
  const [filesChanged, setFilesChanged] = useState('');
  const [positionImage, setPositionImage] = useState('');

  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(addPositionFormSchema),
  });

  const [removePositionMutation] = useMutation(REMOVE_POSITION, {
    onError: () => {},
    onCompleted: () => toast.success('Pozicija uspješno obrisana'),
  });

  const [removeFileMutation] = useMutation(REMOVE_FILE, {
    onError: () => {},
  });

  const {
    loading: loadingCharacteristics,
    error: errorCharacteristics,
    data: additionalCharacteristics,
  } = useQuery(GET_ALL_ADDITIONAL_CHARACTERISTICS);

  const [updatePositionMutation, { loading: updateLoading }] = useMutation(
    UPDATE_POSITION,
    {
      onError: () => {},
      onCompleted: () => {
        toast.success(t('Izmjene na poziciji uspješno sačuvane'));
        setFilesChanged(files);
      },
    }
  );

  const [removeRevisedInformationMutation] = useMutation(
    REMOVE_REVISED_INFORMATION_DATA,
    {
      onError: () => {},
      onCompleted: () => {
        setValue('revisedNote', '');
        setFiles({
          ...files,
          revisedJPG: '',
          revisedPDF: '',
          revisedSTEP: '',
          revisedAdditionalDocuments: '',
        });
        toast.success('Obrisane revidirane informacije i fajlovi');
      },
    }
  );

  const { data: positionData, loading: loadingPositionData } = useQuery(
    GET_SINGLE_POSITION,
    {
      variables: { positionId },
    }
  );

  const [files, setFiles] = useState({
    pdf: '',
    jpg: '',
    step: '',
    additionalDocuments: '',
    revisedPDF: '',
    revisedJPG: '',
    revisedSTEP: '',
    revisedAdditionalDocuments: '',
  });

  const { loading: loadingMaterials, data: dataMaterials } =
    useQuery(GET_ALL_MATERIALS);
  const { loading: loadingTech, data: dataTech } = useQuery(GET_TECHNOLOGIES);
  const { loading: loadingManufacturing, data: dataManufacturing } = useQuery(
    GET_ALL_MANUFACTURING
  );

  const [data, setData] = useState([]);

  const [values, setValues] = useState({
    quantity: '',
    aimedPrice: '',
  });

  const handleAddData = () => {
    if (!values.quantity || values.aimedPrice === '') return;
    setData([...data, values]);
    setValues({ quantity: '', aimedPrice: '' });
  };

  const handleRemoveData = (id) => {
    setData(data.filter((_, index) => index !== id));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: name === 'quantity' ? parseInt(value) : parseFloat(value),
    });
  };

  useEffect(() => {
    /* if user is admin */
    if (['ADMINISTRATOR', 'MODERATOR'].includes(user?.role)) {
      if (!projectApproved) {
        setIsProjectActive(false);
      } else {
        setIsProjectActive(true);
      }
    } else {
      /* if user's not admin but project approved */
      if (!projectApproved) {
        setIsProjectActive(false);
      } else {
        setIsProjectActive(true);
      }
    }

    if (positionData) {
      const { getSinglePosition } = positionData;
      Object.keys(getSinglePosition).map((key) => {
        return setValue(key, getSinglePosition[key], true);
      });

      const { additionalCharacteristics } = getSinglePosition || [];

      console.log('additionalCharacteristics', additionalCharacteristics);

      additionalCharacteristics?.forEach((characteristic) =>
        setValue(
          `additionalCharacteristics.${characteristic.category.toLowerCase()}`,
          characteristic.id
        )
      );

      const quantities = getSinglePosition.quantities.map((quantity) =>
        pick(quantity, ['quantity', 'aimedPrice'])
      );
      setData(quantities);
      const currentFiles = pick(getSinglePosition?.files, [
        'pdf',
        'jpg',
        'step',
        'additionalDocuments',
      ]);
      const revisedFiles = getSinglePosition?.revisedInformations?.files;
      const allFiles = {
        ...currentFiles,
        revisedJPG: revisedFiles?.jpg,
        revisedPDF: revisedFiles?.pdf,
        revisedSTEP: revisedFiles?.step,
        revisedAdditionalDocuments: revisedFiles?.additionalDocuments,
      };

      setFiles(allFiles);
      setFilesChanged(allFiles);
      setValue('revisedNote', getSinglePosition?.revisedInformations?.note);
    }
  }, [
    positionData,
    setValue,
    additionalCharacteristics?.getAllAdditionalCharacteristics,
    user?.role,
    projectApproved,
  ]);

  useEffect(() => {
    // set new filename
    if (files.jpg) {
      setPositionImage(process.env.REACT_APP_S3_BUCKET + files.jpg);
    }
  }, [files.jpg]);

  const handleFileUpload = (filename, field) => {
    // check if file exists in the database and is preloaded
    if (files[field]) {
      // FIXME: This should be done after saving position informations, so we can prevent files missing on operation interuption or not saving the changes

      // remove file from aws and db
      removeFileMutation({ variables: { filename: files[field] } });
    }
    setFiles((currentValues) => ({ ...currentValues, [field]: filename }));
  };

  const handleRemoveRevisedInformationMutation = (e) => {
    e.preventDefault();
    if (
      prompt(
        'Da biste vratili na zadane postavke i obrisali sve fajlove i revidirane informacije napišite: POTVRDA'
      ).trim() === 'POTVRDA'
    ) {
      const revisedFiles = [
        files?.revisedJPG,
        files?.revisedPDF,
        files?.revisedSTEP,
        files?.revisedAdditionalDocuments,
      ];
      removeRevisedInformationMutation({
        variables: {
          positionId,
          files: revisedFiles,
        },
        refetchQueries: ['getSinglePosition'],
      });
    }
  };

  const handleRemovePosition = (e) => {
    e.preventDefault();
    if (
      prompt('Da biste obrisali poziciju napišite: POTVRDA').trim() ===
      'POTVRDA'
    ) {
      removePositionMutation({
        variables: {
          positionId,
          projectId,
        },
        refetchQueries: ['getSingleProject'],
      });
    }
  };

  const onSubmit = (formValues) => {
    /* check if quantities and prices are empty */
    if (!data.length > 0) return;

    if (
      !dataSelectorValues?.material?.length ||
      !dataSelectorValues?.manufacturing?.length
    ) {
      return toast.error(t('Materijal i proizvodnja su obavezni'));
    }

    if (
      ['ADMINISTRATOR', 'MODERATOR'].includes(user?.role) &&
      !dataSelectorValues?.technology?.length
    ) {
      return toast.error(
        'Proizvodnja, materijal i tehnologija su obavezna polja!'
      );
    }

    // if (!Object.keys(files).some((file) => files[file]))
    //   return toast.error("Morate odabrati barem jedan fajl");

    const characteristics = formValues?.additionalCharacteristics
      ? Object?.values(formValues?.additionalCharacteristics).filter(Boolean)
      : [];

    if (!files?.jpg) return toast.error(t('Morate dodati jpg file'));

    updatePositionMutation({
      variables: {
        positionId,
        ...formValues,
        material: dataSelectorValues?.material[0],
        technology: dataSelectorValues?.technology,
        manufacturing: dataSelectorValues?.manufacturing[0],
        additionalManufacturing: dataSelectorValues?.additional_manufacturing,
        additionalCharacteristics: characteristics,
        quantities: data,
        files: pick(files, ['jpg', 'pdf', 'step', 'additionalDocuments']),

        /* revised informations by administrator */
        revisedInformations: {
          files: {
            jpg: files?.revisedJPG,
            pdf: files?.revisedPDF,
            step: files?.revisedSTEP,
            additionalDocuments: files?.revisedAdditionalDocuments,
          },
          note: formValues?.revisedNote,
        },
      },
      refetchQueries: ['getSinglePosition', 'getSingleProject'],
    });
  };

  const techIds = useMemo(
    () => positionData?.getSinglePosition?.technology.map((m) => m.id),
    [positionData?.getSinglePosition?.technology]
  );

  const manufacturingIds = useMemo(
    () =>
      positionData?.getSinglePosition?.additionalManufacturing?.map(
        (m) => m.id
      ),
    [positionData?.getSinglePosition?.additionalManufacturing]
  );

  const handleDraftPreview = (e) => {
    e.preventDefault();
    handleModal(
      <Preview
        data={{
          positionData: positionData?.getSinglePosition,
          files,
        }}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Prompt
        when={filesChanged !== files}
        message="Uploadali ste fajlove ali niste snimili izmjene?"
      />
      <h2>{t('Uređivanje pozicije')}</h2>
      {!positionImage ? (
        <Acknowledge layout="warning">Fotografija nije dodana</Acknowledge>
      ) : (
        <Image
          onClick={() =>
            handleModal(
              <ImageContainer>
                <img src={positionImage} alt="Position" />
              </ImageContainer>
            )
          }
        >
          <img src={positionImage} alt="Position" />
        </Image>
      )}
      <FormField>
        <Label error={errors.name?.message}>{t('Naziv pozicije')}</Label>
        <Input name="name" ref={register} readOnly={isProjectActive} />
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.positionCode?.message}>
            {t('Šifra pozicije')}
          </Label>
          <Input
            name="positionCode"
            ref={register}
            readOnly={isProjectActive}
          />
        </FormField>
        <FormField>
          <Label error={errors.drawingNumber?.message}>
            {t('Broj crteža')}
          </Label>
          <Input
            name="drawingNumber"
            ref={register}
            readOnly={isProjectActive}
          />
        </FormField>
      </Distributor>
      <FormField>
        <InputTable
          disabled={isProjectActive}
          data={data}
          addData={handleAddData}
          removeData={handleRemoveData}
        >
          <Input
            label={t('Količina')}
            name="quantity"
            type="number"
            value={values.quantity}
            onChange={handleChange}
          />
          <Input
            label={t('Ciljana cijena (EUR/kom.)')}
            type="number"
            name="aimedPrice"
            value={values.aimedPrice}
            onChange={handleChange}
          />
        </InputTable>
      </FormField>
      <FormField>
        <DataSelector
          params={{
            data: dataMaterials?.getAllMaterials,
            populateFrom: positionData?.getSinglePosition?.material?.id,
            loading: [loadingMaterials, loadingPositionData],
            chooseButtonLabel: t('Odaberi materijal'),
            name: 'material',
            valuesField: 'id',
            handlerField: 'name',
            multiSelection: false,
            sortBy: {
              dataField: 'category',
              name: 'category.name',
              compare: 'category.id',
              overrideSortByField: 'category.id',
            },
            additionalFields: {
              materialStandard: {
                label: 'Standard',
              },
              materialDesignation: {
                label: 'Oznaka',
              },
            },
          }}
        />
      </FormField>
      <FormField>
        <DataSelector
          params={{
            data: dataManufacturing?.getAllManufacturing,
            populateFrom: positionData?.getSinglePosition?.manufacturing?.id,
            loading: [loadingManufacturing, loadingPositionData],
            chooseButtonLabel: t('Odaberi glavnu proizvodnju'),
            name: 'manufacturing',
            valuesField: 'id',
            handlerField: 'name',
            multiSelection: false,
            noHeaders: true,
            sortBy: {
              dataField: 'category',
              name: 'name',
              compare: 'id',
            },
          }}
        />
      </FormField>
      <FormField>
        <DataSelector
          params={{
            data: dataManufacturing?.getAllManufacturing,
            populateFrom: manufacturingIds,
            loading: [loadingManufacturing, loadingPositionData],
            chooseButtonLabel: t('Odaberi dodatne proizvodnje'),
            name: 'additional_manufacturing',
            noHeaders: true,
            valuesField: 'id',
            handlerField: 'name',
            sortBy: {
              dataField: 'category',
              name: 'name',
              compare: 'id',
            },
          }}
        />
      </FormField>

      <ProtectedContent onlyFor={['BUYER']}>
        <FormField>
          <Label>PDF</Label>
          <UploadFile
            disabled={isProjectActive}
            directory="pdf"
            name="pdf"
            accept={['application/pdf']}
            maxMB="50"
            onSuccess={(filename) => handleFileUpload(filename, 'pdf')}
            current={files.pdf}
            removeHandler={setFiles}
          />
        </FormField>
        <FormField>
          <Label>JPG</Label>
          <UploadFile
            disabled={isProjectActive}
            directory="jpg"
            name="jpg"
            accept={['image/png', 'image/jpeg']}
            maxMB="50"
            onSuccess={(filename) => handleFileUpload(filename, 'jpg')}
            current={files.jpg}
            removeHandler={setFiles}
          />
        </FormField>
        <FormField>
          <Label>STEP</Label>
          <UploadFile
            disabled={isProjectActive}
            directory="step"
            acceptedformats={['model/step', 'application/step']}
            name="step"
            maxMB="50"
            onSuccess={(filename) => handleFileUpload(filename, 'step')}
            current={files.step}
            removeHandler={setFiles}
          />
        </FormField>
        <FormField>
          <Label>{t('Dodatni dokumenti (*.zip)')}</Label>
          <UploadFile
            disabled={isProjectActive}
            directory="zip"
            acceptedformats={[
              'application/zip',
              'application/octet-stream',
              'application/x-zip-compressed',
              'multipart/x-zip',
            ]}
            name="additionalDocuments"
            maxMB="50"
            onSuccess={(filename) =>
              setFiles((currentValues) => ({
                ...currentValues,
                additionalDocuments: filename,
              }))
            }
            current={files.additionalDocuments}
            removeHandler={setFiles}
          />
        </FormField>
      </ProtectedContent>
      <FormField>
        <Label error={errors.note?.message}>{t('Napomena')}</Label>
        <Textarea
          height="200px"
          name="note"
          ref={register}
          readOnly={isProjectActive}
        ></Textarea>
      </FormField>
      <FormField>
        <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
          <h3>Revizija informacija pozicije</h3>
          <Acknowledge layout="notice">
            Ukoliko postavite fajlove i napomenu ispod, biti će važeći za prikaz
            dobavljačima. Samo brisanje neće biti dovoljno da se informacije
            vrate na postavke koje postavio kupac, koristite dugme{' '}
            <strong>"Obriši revidirane vrijednosti"</strong> i nakon toga
            spremite izmjene pozicije.
          </Acknowledge>
          <Distributor repeat="2">
            {Object.keys(ADDITIONAL_CHARACTERISTICS_CATEGORIES).map((key) => (
              <FormField key={key}>
                <Label
                  error={
                    errors?.additionalCharacteristics?.[key.toLowerCase()]
                      ?.message
                  }
                >
                  {t(ADDITIONAL_CHARACTERISTICS_CATEGORIES[key])}
                </Label>
                <Select
                  name={`additionalCharacteristics.${key.toLowerCase()}`}
                  ref={register}
                >
                  {additionalCharacteristics?.getAllAdditionalCharacteristics
                    ?.filter((v) => v.category === key)
                    .map((v) => (
                      <select key={v.key} value={v.id}>
                        {v.name}
                      </select>
                    ))}
                </Select>
              </FormField>
            ))}
          </Distributor>
          <FormField>
            <DataSelector
              params={{
                data: dataTech?.getTechnologies,
                populateFrom: techIds,
                loading: [loadingTech, loadingPositionData],
                chooseButtonLabel: t('Odaberi tehnologiju'),
                name: 'technology',
                valuesField: 'id',
                handlerField: 'name',
                sortBy: {
                  dataField: 'category',
                  name: 'category.name',
                  compare: 'category.id',
                  overrideSortByField: 'category.id',
                  sortDataBy: ['id', 'parameters[0].minValue'],
                },
                additionalFields: {
                  'parameters[0].minValue': {
                    label: 'Min',
                  },
                  'parameters[0].maxValue': {
                    label: 'Max',
                  },
                },
              }}
            />
          </FormField>
          <FormField>
            <Label>PDF</Label>
            <UploadFile
              disabled={isProjectActive}
              directory="pdf"
              name="revisedPDF"
              accept={['application/pdf']}
              maxMB="50"
              onSuccess={(filename) => handleFileUpload(filename, 'revisedPDF')}
              current={files.revisedPDF}
              removeHandler={setFiles}
            />
          </FormField>
          <FormField>
            <Label>JPG</Label>
            <UploadFile
              disabled={isProjectActive}
              directory="jpg"
              name="revisedJPG"
              accept={['image/png', 'image/jpeg']}
              maxMB="50"
              onSuccess={(filename) => handleFileUpload(filename, 'revisedJPG')}
              current={files.revisedJPG}
              removeHandler={setFiles}
            />
          </FormField>
          <FormField>
            <Label>STEP</Label>
            <UploadFile
              disabled={isProjectActive}
              directory="step"
              acceptedformats={['model/step', 'application/step']}
              name="revisedSTEP"
              maxMB="50"
              onSuccess={(filename) =>
                handleFileUpload(filename, 'revisedSTEP')
              }
              current={files.revisedSTEP}
              removeHandler={setFiles}
            />
          </FormField>
          <FormField>
            <Label>{t('Dodatni dokumenti (*.zip)')}</Label>
            <UploadFile
              disabled={isProjectActive}
              directory="zip"
              acceptedformats={[
                'application/zip',
                'application/octet-stream',
                'application/x-zip-compressed',
                'multipart/x-zip',
              ]}
              name="revisedAdditionalDocuments"
              maxMB="50"
              onSuccess={(filename) =>
                handleFileUpload(filename, 'revisedAdditionalDocuments')
              }
              current={files.revisedAdditionalDocuments}
              removeHandler={setFiles}
            />
          </FormField>
          <FormField>
            <Label error={errors.revisedNote?.message}>{t('Napomena')}</Label>
            <Textarea
              height="200px"
              name="revisedNote"
              ref={register}
              readOnly={isProjectActive}
            ></Textarea>
          </FormField>
        </ProtectedContent>

        <Distributor stack="end" submitButtons>
          <Button
            layout="hollow"
            onClick={handleRemovePosition}
            disabled={isProjectActive}
          >
            {t('Obriši poziciju')}
          </Button>
          <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
            <Button
              disabled={isProjectActive}
              layout="hollow"
              onClick={handleRemoveRevisedInformationMutation}
            >
              Obriši revidirane informacije
            </Button>
            <Button loading={loadingPositionData} onClick={handleDraftPreview}>
              {t('Pretpregled')}
            </Button>
          </ProtectedContent>

          <Button disabled={isProjectActive} loading={updateLoading}>
            {t('Spremi izmjene')}
          </Button>
        </Distributor>
      </FormField>
    </form>
  );
};

export { Image, Param, EditPosition as default };
