import currencies from './currencies.json';

export const MAX_UPLOAD_FILE_SIZE_MB = 50;
export const ROLE_ENUMS = [
  'MANUFACTURER',
  'BUYER',
  'REGULAR_USER',
  'MODERATOR',
  'ADMINISTRATOR',
];
export const STATUS_ENUMS = ['BANNED', 'SUSPENDED', 'NOT_VERIFIED', 'ACTIVE'];
export const PAYMENT_TIMEFRAME = {
  INVOICE: 'Po predračunu', // po predracunu
  '15_DAYS': '15 dana',
  '30_DAYS': '30 dana',
  '30_DAYS_7D_2%_CASSA_SCONTO': '30 dana (7 dana - 2% Cassa Sconto)',
  '30_DAYS_15D_2%_CASSA_SCONTO': '30 dana (15 dana - 2% Cassa Sconto)',
  '45_DAYS': '45 dana',
  '45_DAYS_15D_2%_CASSA_SCONTO': '45 dana (15 dana - 2% Cassa Sconto)',
  '60_DAYS': '60 dana',
  '60_DAYS_15D_2%_CASSA_SCONTO': '60 dana (15 dana - 2% Cassa Sconto)',
  '60_DAYS_30D_2%_CASSA_SCONTO': '60 dana (30 dana - 2% Cassa Sconto)',
  '90_DAYS': '90 dana',
  '90_DAYS_30D_2%_CASSA_SCONTO': '90 dana (30 dana - 2% Cassa Sconto)',
  '120_DAYS': '120 dana',
  '120_DAYS_30D_2%_CASSA_SCONTO': '120 dana (30 dana - 2% Cassa Sconto)',
};

export const PAYMENT_METHOD = {
  ZIRALNO: 'Žiralno',
  VIRMANSKI: 'Virmanski',
  GOTOVINOM_PO_UZECU: 'Gotovinom po uzeću',
  ZIRALNO_S_POPUSTOM: 'Žiralno s popustom',
  VIRMANSKI_S_POPUSTOM: 'Virmanski s popustom',
};

export const CURRENCIES = currencies;

export const DELIVERY_CONDITIONS = {
  CFR: 'cijena i vozarina',
  CIF: 'cijena, osiguranje i vozarina',
  CIP: 'vozarina i osiguranje plaćeni do',
  CPT: 'vozarina plaćena do',
  DAP: 'isporučeno na mjesto',
  DAT: 'isporučeno na terminal',
  DDP: 'isporučeno ocarinjeno',
  EXW: 'franko fabrika',
  FAS: 'franco uz bok broda',
  FCA: 'franco prijevoznik',
  FOB: 'franco paluba broda',
};

export const LANGUAGES = {
  bs: 'Bosanski',
  de: 'Njemački',
  en: 'Engleski',
};

export const USER_TYPES = {
  buyer: 'Kupci',
  manufacturer: 'Proizvođači',
};

export const ADDITIONAL_CHARACTERISTICS_CATEGORIES = {
  THERMAL_THERMOCHEMICAL_TREATMENT: 'Termička obrada',
  SURFACE_PROTECTION: 'Površinska zaštita',
  FINISHING: 'Završna obraca',
  PIECES_MARKING: 'Označavanje komada',
  PACKING: 'Pakovanje',
  FREE_SURFACE_TOLERANCE: 'Tolerancija slobodnih površina',
  FREE_MEASURE_TOLERANCE: 'Tolerancija slobodnih mjera',
  FREE_EDGE_TOLERANCE: 'Tolerancija slobodnih ivica',
  MEASURE_REPORT: 'Mjerni izvještaj',
  MATERIAL_CERTIFICATE: 'Atest materijala',
};

export const ISO_DESIGNATIONS = ['ISO', 'DIN', 'ANSI', 'JUS', 'BAM'];
