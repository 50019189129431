import React, { useContext } from 'react';
import styled from 'styled-components';
import Logo from './Logo/Logo.component';
import Navigation from './Navigation/Navigation.component';
// import Search from "./Search/Search.component";
import AddProjectForm from './AddProject/AddProjectForm';
import AddUserForm from './AddUser/AddUserForm';
import ButtonDropdown from '../../elements/ButtonDropdown/ButtonDropdown.component';
import { ModalContext } from '../Modal/modalContext';
import UserMenu from './UserMenu/UserMenu.component';
import AddTechnologyForm from './AddTechnology/AddTechnologyForm';
import AddTechnologyParameterForm from './AddTechnologyParameter/AddTechnologyParameterForm';
import AddTechnologyCategory from './AddTechnologyCategory/AddTechnologyCategoryForm';
import AddMaterialCategoryForm from './AddMaterialCategory/AddMaterialCategoryForm';
import AddMaterialForm from './AddMaterial/AddMaterialForm';
import AddManufacturingCategoryForm from './AddManufacturingCategory/AddManufacturingCategoryForm';
import AddManufacturingForm from './AddManufacturing/AddManufacturingForm';
import ProtectedContent from '../../Auth/ProtectedContent/ProtectedContent';
import { DataSelectorContext } from 'src/components/DataSelector/dataSelectorContext';
import useTranslation from 'src/hooks/useTranslation';
import { GoPlus } from 'react-icons/go';
import AddAdditionalCharacteristics from './AddAdditionalCharacteristics/AddAdditionalCharacteristics';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadow.depth.one};
  position: relative;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  svg {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Header = () => {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  return (
    <Wrapper>
      <InnerWrapper>
        <Logo height="59px" />
      </InnerWrapper>
      <InnerWrapper>
        {/* {<Search />} */}
        <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
          <ButtonDropdown
            icon={<GoPlus size="20px" />}
            size="2rem"
            layout="white-shadow"
            round
            actions={[
              {
                section: t('Korisnici'),
              },
              {
                label: t('Novi korisnik'),
                action: () => handleModal(<AddUserForm />),
              },
              {
                section: t('Tehnologija'),
              },
              {
                label: t('Nova tehnologija'),
                action: () => handleModal(<AddTechnologyForm />),
              },
              {
                label: t('Nova kategorija'),
                action: () => handleModal(<AddTechnologyCategory />),
              },
              {
                label: t('Novi parametar'),
                action: () => handleModal(<AddTechnologyParameterForm />),
              },
              {
                section: t('Materijali'),
              },
              {
                label: t('Novi materijal'),
                action: () => handleModal(<AddMaterialForm />),
              },
              {
                label: t('Nova kategorija'),
                action: () => handleModal(<AddMaterialCategoryForm />),
              },
              {
                section: t('Proizvodnja'),
              },
              {
                label: t('Novi tip proizvodnje'),
                action: () => handleModal(<AddManufacturingForm />),
              },
              {
                label: t('Nova kategorija'),
                action: () => handleModal(<AddManufacturingCategoryForm />),
              },
              {
                section: t('Dodatne karakteristike'),
              },
              {
                label: t('Nova karakteristika'),
                action: () => handleModal(<AddAdditionalCharacteristics />),
              },
            ]}
          />
        </ProtectedContent>
        <ProtectedContent onlyFor={['BUYER']}>
          <ButtonDropdown
            icon={<GoPlus size="20px" />}
            size="2rem"
            layout="white-shadow"
            round
            actions={[
              {
                label: t('Novi projekt'),
                action: () => handleModal(<AddProjectForm />),
              },
            ]}
          />
        </ProtectedContent>
        <UserMenu />
      </InnerWrapper>
    </Wrapper>
  );
};

export default Header;
