import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { pickBy } from 'lodash';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import CREATE_ADDITIONAL_CHARACTERISTICS from 'src/apollo/mutations/createAdditionalCharacteristics';
import { ADDITIONAL_CHARACTERISTICS_CATEGORIES } from 'src/constants';
import { additionalCharacteristicsSchema } from '../../../../validation/schema';
import Button from '../../../elements/Button/Button.component';
import Input from '../../../elements/Input/Input.component';
import Label from '../../../elements/Label/Label.component';
import Select from '../../../elements/Select/Select.component';
import { ModalContext } from '../../Modal/modalContext';
import Distributor from '../../wrappers/Distributor/Distributor.component';
import FormField from '../../wrappers/FormField/FormField.component';
import Textarea from 'src/components/elements/Textarea/Textarea.component';

const AddAdditionalCharacteristics = ({ prefillData }) => {
  const { handleModal } = useContext(ModalContext);

  const [createAdditionalCharacteristics, { loading, error }] = useMutation(
    CREATE_ADDITIONAL_CHARACTERISTICS,
    {
      onError: () => {},
      refetchQueries: ['getAllAdditionalCharacteristics'],
    }
  );
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(additionalCharacteristicsSchema),
  });
  const onSubmit = (values) => {
    const sanitizedValues = pickBy(values, (value) => value.length > 0);
    createAdditionalCharacteristics({
      variables: {
        ...sanitizedValues,
        replaceAdditionalCharacteristicsId: prefillData?.id,
      },
    });
    if (!loading) {
      toast.success('Dodatna karakteristika uspješno dodana');
      handleModal();
    }
  };

  // prefill

  useEffect(() => {
    if (prefillData) {
      console.log(prefillData);
      setValue('name', prefillData?.name);
      setValue('category', prefillData?.category);
      setValue('description', prefillData?.description);
    }
  }, [prefillData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Dodaj novu karakteristiku</h2>
      <FormField>
        <Label error={errors.category?.message}>
          Kategorija karakteristike
        </Label>
        <Select name="category" ref={register}>
          {Object.keys(ADDITIONAL_CHARACTERISTICS_CATEGORIES).map((v) => (
            <option key={v} value={v}>
              {ADDITIONAL_CHARACTERISTICS_CATEGORIES[v]}
            </option>
          ))}
        </Select>
      </FormField>
      <FormField>
        <Label error={errors.name?.message}>Naziv dodatne karakteristike</Label>
        <Input name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.description?.message}>Opis karakteristike</Label>
        <Textarea rows="10" name="description" ref={register} />
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button>Sačuvaj karakteristiku</Button>
      </Distributor>
    </form>
  );
};

export default AddAdditionalCharacteristics;
