import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from '../../components/structure/wrappers/Container/Container.component';
import ButtonDropdown from '../../components/elements/ButtonDropdown/ButtonDropdown.component';
import GET_ALL_MATERIALS from '../../apollo/queries/getAllMaterials';
import { useMutation, useQuery } from '@apollo/client';
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from '../../components/elements/BasicTable/BasicTable.component';
import Main from '../../components/structure/wrappers/Main/Main.component';
import REMOVE_MATERIAL from '../../apollo/mutations/removeMaterial';
import toast from 'react-hot-toast';
import { ModalContext } from '../../components/structure/Modal/modalContext';
import AddMaterialForm from '../../components/structure/Header/AddMaterial/AddMaterialForm';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Input from 'src/components/elements/Input/Input.component';
import Button from 'src/components/elements/Button/Button.component';
import Select from 'src/components/elements/Select/Select.component';
import { ISO_DESIGNATIONS } from 'src/constants';
import { get } from 'lodash';

const Wrapper = styled.div``;
const Search = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Materials = () => {
  const { handleModal } = useContext(ModalContext);
  const { loading, error, data, refetch } = useQuery(GET_ALL_MATERIALS, {
    fetchPolicy: 'network-only',
  });

  // filter
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');

  const clearFilter = () => {
    setFilter('');
    setPrefilter('');
  };

  // prefilter (dropdown)

  const [prefilter, setPrefilter] = useState('');

  const handlePrefilter = (e) => {
    const { value } = e.target;
    setPrefilter(value);
    setFilteredData(
      data?.getAllMaterials.filter((val) => val?.materialStandard === value)
    );
  };

  useEffect(() => {
    if (data) {
      setFilteredData(data?.getAllMaterials);
    }
  }, [data]);

  const handleChange = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (filter.length) {
      setFilteredData(
        data?.getAllMaterials.filter((v) => {
          const criteriumFields = [
            'name',
            'hardnessHB',
            'hardnessHRC',
            'materialDesignation',
            'materialStandard',
            'materialUsage',
            'tensileStrength',
            'category.name',
          ];
          const matches = criteriumFields.some((field) => {
            const val = get(v, field)?.toLowerCase();
            return val && val?.indexOf(filter?.toLowerCase()) !== -1;
          });

          // if prefilter doesn't match return false
          if (prefilter && v.materialStandard !== prefilter) return false;

          // return all matching entries
          return matches;
        })
      );
    } else {
      setFilteredData(data?.getAllMaterials);
    }
  };

  const [removeMaterial] = useMutation(REMOVE_MATERIAL, {
    onCompleted: () => {
      refetch();
      toast.success('Materijal uspješno obrisan');
    },
    onError: () => toast.error('Neuspješno brisanje materijala'),
  });

  const handleEditMaterial = (materialData) => {
    handleModal(<AddMaterialForm prefillData={materialData} />);
  };
  const handleRemoveMaterial = (materialId, materialName) => {
    if (!materialId)
      return alert('Nemoguće obrisati!\nOdabrani materijal ne posjeduje Id');
    if (
      prompt(
        `Upišite POTVRDA za brisanje materijala ${materialName} iz baze. Ovaj proces je ireverzibilan!`
      )?.trim() === 'POTVRDA'
    )
      removeMaterial({ variables: { materialId } });
  };
  return (
    <Wrapper>
      <Container>
        <Main>
          <Distributor col="1-1" sticky>
            <h1>Materijali</h1>
            <Search onSubmit={handleSearch}>
              <Select value={prefilter} onChange={handlePrefilter}>
                {ISO_DESIGNATIONS.map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Select>
              <Input
                placeholder="Pretraga..."
                onChange={handleChange}
                value={filter}
              />
              <Button onClick={handleSearch}>Pretraga</Button>
              <Button onClick={clearFilter} layout="hollow">
                Poništi pretragu
              </Button>
            </Search>
          </Distributor>
          <BasicTable layout="auto" loading={loading}>
            <TableHead sticky="75.5px">
              <TableRow>
                <TableHeader>Naziv materijala</TableHeader>
                <TableHeader>Oznaka materijala</TableHeader>
                <TableHeader>Standard materijala</TableHeader>
                <TableHeader>ISO</TableHeader>
                <TableHeader>Kategorija</TableHeader>
                <TableHeader>Specifikacije (PDF)</TableHeader>
                <ActionHeader>Akcije</ActionHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((material) => (
                <TableRow key={material.id}>
                  <TableCell>{material.name}</TableCell>
                  <TableCell>{material.materialDesignation}</TableCell>
                  <TableCell>{material.materialStandard}</TableCell>
                  <TableCell>{material.category?.ISOdesignation}</TableCell>
                  <TableCell>{material.category?.name}</TableCell>
                  <TableCell>
                    {material?.technicalSpecifications ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_S3_BUCKET +
                          material?.technicalSpecifications
                        }
                      >
                        Otvori
                      </a>
                    ) : null}
                  </TableCell>
                  <ActionCell>
                    <ButtonDropdown
                      id={material.id}
                      actions={[
                        {
                          label: 'Uredi material',
                          action: () => handleEditMaterial(material),
                        },
                        {
                          label: 'Obriši materijal',
                          action: () =>
                            handleRemoveMaterial(material.id, material.name),
                        },
                      ]}
                    />
                  </ActionCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Main>
      </Container>
    </Wrapper>
  );
};

export { Search, Materials as default };
