import { useQuery } from "@apollo/client";
import { groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import { BiChip } from "react-icons/bi";
import LATEST_PROJECTS from "src/apollo/queries/dashboard/latestProjects";
import ChartStats from "src/components/ChartStats/ChartStats";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import { IconWrapper, Number } from "./Dashboard";

const AllProjectsChart = () => {
  const { t } = useTranslation();
  // latest projects
  const [lp, setLp] = useState([]);

  const {
    loading: latestProjectsLoading,
    data: { latestProjects } = {},
    error: latestProjectsError,
  } = useQuery(LATEST_PROJECTS, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (latestProjects?.length) {
      const parsedValues = latestProjects.map((p) => ({
        ...p,
        published: { date: new Date(p.published.date) },
      }));
      setLp(groupBy(latestProjects, "published.date"));
    }
  }, [latestProjects]);

  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t("Objavljeni projekti")}</h4>
          {latestProjectsLoading ? (
            <Spinner />
          ) : (
            <Number>{latestProjects?.length}</Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {latestProjectsLoading ? <Spinner /> : <ChartStats data={lp} />}
      </Segment>
    </Distributor>
  );
};

export default AllProjectsChart;
