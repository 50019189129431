import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChip } from 'react-icons/bi';
import DELIVERED_MANUFACTURERS_ORDERS_VALUE from 'src/apollo/queries/dashboard/deliveredManufacturersOrdersValue';
import ChartStats from 'src/components/ChartStats/ChartStats';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import { IconWrapper, Number } from './Dashboard';

const ManufacturerDeliveredOrdersValueChart = () => {
  const { t } = useTranslation();
  // latest manufacturers delivered orders value
  const [lmdov, setLmdov] = useState([]);

  const {
    loading,
    data: { deliveredManufacturersOrdersValue } = {},
    error: deliveredManufacturersOrdersValueError,
  } = useQuery(DELIVERED_MANUFACTURERS_ORDERS_VALUE, {});

  useEffect(() => {
    if (deliveredManufacturersOrdersValue?.length) {
      setLmdov(groupBy(deliveredManufacturersOrdersValue, 'dateCreated'));
    }
  }, [deliveredManufacturersOrdersValue]);
  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t('Isporučene narudžbe (30 dana)')}</h4>
          {loading ? (
            <Spinner />
          ) : (
            <Number>{deliveredManufacturersOrdersValue}</Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats
            data={lmdov}
            title={t('Ukupna vrijednost')}
            sumField="value"
            yAxisLabel={t('Vrijednost (€)')}
          />
        )}
      </Segment>
    </Distributor>
  );
};

export default ManufacturerDeliveredOrdersValueChart;
