import React, { useContext } from 'react';
import Input from '../../../elements/Input/Input.component';
import Label from '../../../elements/Label/Label.component';
import FormField from '../../wrappers/FormField/FormField.component';
import Distributor from '../../wrappers/Distributor/Distributor.component';
import Textarea from '../../../elements/Textarea/Textarea.component';
import Button from '../../../elements/Button/Button.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addProjectFormSchema } from '../../../../validation/schema';
import CREATE_PROJECT from '../../../../apollo/mutations/createProject';
import { useMutation, useQuery } from '@apollo/client';
import { ModalContext } from '../../Modal/modalContext';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { currentUserContext } from '../../../Auth/currentUserContext/currentUser.context';
import useTranslation from 'src/hooks/useTranslation';
import CheckerBox from 'src/components/elements/CheckerBox/CheckerBox';
import GET_CONDITIONS from 'src/apollo/queries/getConditions';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import TagManager from 'react-gtm-module';

const AddProjectForm = () => {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const {
    user: { user },
  } = useContext(currentUserContext);
  const history = useHistory();

  const {
    loading,
    data: { getConditions: data } = {},
    error,
  } = useQuery(GET_CONDITIONS);

  const [createProjectMutation] = useMutation(CREATE_PROJECT, {
    onCompleted: ({ createProject }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'project_created',
          username: user.name,
          userId: user.id,
          projectCode: getValues('projectCode'),
        },
      });
      toast.success(t('Projekt uspješno dodan'));
      history.push(`/projects/${createProject.id}/edit`);
      handleModal();
    },
    onError: (e) => {
      console.log('Error', e);
    },
  });

  const { register, handleSubmit, getValues, errors } = useForm({
    resolver: yupResolver(addProjectFormSchema),
  });
  const onSubmit = (values) => {
    createProjectMutation({
      variables: {
        companyId: user.company._id,
        ...values,
        urgent: !!values.urgent,
      },
      refetchQueries: ['getProjects'],
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>{t('Dodaj novi projekat')}</h2>
      <FormField>
        <Label error={errors.name?.message}>{t('Naziv projekta')}</Label>
        <Input name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.projectCode?.message}>{t('Šifra projekta')}</Label>
        <Input name="projectCode" ref={register} />
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.offerDeadline?.message}>
            {t('Rok za ponudu')}
          </Label>
          <Input type="date" name="offerDeadline" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.deliveryDeadline?.message}>
            {t('Rok za isporuku')}
          </Label>
          <Input type="date" name="deliveryDeadline" ref={register} />
        </FormField>
      </Distributor>
      <FormField>
        <Label error={errors.note?.message}>{t('Napomena')}</Label>
        <Textarea name="note" ref={register}></Textarea>
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <CheckerBox name="urgent" ref={register} value={t('Hitni zahtjev')} />
        </FormField>
      </Distributor>
      <FormField>
        <h3>{t('Plaćanje i transport')}</h3>
      </FormField>
      {loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <FormField>
            <Label>{t('Adresa isporuke')}</Label>
            <Input
              type="text"
              name="shipmentAddress"
              readOnly
              value={data?.paymentAndTransport?.shipmentAddress}
            />
          </FormField>
          <Distributor col="1-1-1">
            <FormField>
              <Label>{t('Način plaćanja')}</Label>
              <Input
                name="paymentMethod"
                value={data?.paymentAndTransport?.paymentMethod}
                readOnly
              />
            </FormField>
            <FormField>
              <Label>{t('Način isporuke')}</Label>
              <Input
                name="paymentSchedule"
                readOnly
                value={data?.paymentAndTransport?.paymentSchedule}
              />
            </FormField>
            <FormField>
              <Label>{t('Postotak poreza (npr. PDV)')}</Label>
              <Input
                type="number"
                step="1"
                name="taxPercentage"
                readOnly
                value={data?.taxPercentage}
              />
            </FormField>
          </Distributor>
          <Distributor col="1-1-1">
            <FormField>
              <Label>{t('Troškovi transporta')}</Label>
              <Input
                type="number"
                step="0.01"
                name="transportExpenses"
                readOnly
                value={data?.paymentAndTransport?.transportExpenses}
              />
            </FormField>
            <FormField>
              <Label>{t('Vrijeme za plaćanje')}</Label>
              <Input
                name="paymentTimeframe"
                readOnly
                value={data?.paymentAndTransport?.paymentTimeframe}
              />
            </FormField>
            <FormField>
              <Label>{t('Valuta')}</Label>
              <Input
                name="currency"
                readOnly
                value={data?.paymentAndTransport?.currency}
              />
            </FormField>
          </Distributor>
        </React.Fragment>
      )}
      <FormField>
        <Distributor stack="end" submitButtons>
          <Button>{t('Sačuvaj i dodaj pozicije')}</Button>
        </Distributor>
      </FormField>
    </form>
  );
};

export default AddProjectForm;
