import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Space from "../../../components/elements/Space/Space.component";
import Distributor from "../../../components/structure/wrappers/Distributor/Distributor.component";
import Segment from "../../../components/structure/wrappers/Segment/Segment.component";
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from "../../../components/elements/BasicTable/BasicTable.component";

import {
  PositionHeading,
  Preview,
  Module,
  Row,
  TableLabel,
  Value,
  RowItem,
  PositionIndex,
} from "./PositionDetails";
import { projectDetailsContext } from "../projectDetailsContext";
import Spinner from "../../../components/elements/Spinner/Spinner.component";
import ButtonDropdown from "../../../components/elements/ButtonDropdown/ButtonDropdown.component";
import useTranslation from "src/hooks/useTranslation";
import { ModalContext } from "src/components/structure/Modal/modalContext";
import Prices from "./Orders/Prices";
import Button from "src/components/elements/Button/Button.component";
import { Prompt } from "react-router-dom";

const TopControls = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  > span {
    margin-right: 1rem;
  }
  > div:not(:last-child) {
    margin-right: 1rem;
  }
`;

const Wrapper = styled.div`
  position: relative;
  ${TopControls} {
    justify-content: flex-start;
  }
  ${Preview} {
    height: 200px;
    border: none;
    > img {
      height: 100%;
    }
  }
`;

const QuantityTable = styled.div`
  margin-bottom: 4rem;
`;

const QuantityTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuantityAmount = styled.div`
  text-transform: uppercase;
  display: inline-flex;
  background: ${({ theme }) => theme.colors.menuHighlight};
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-bottom: 2rem;
  border-radius: 3px;
`;

const CommentButton = styled.div`
  font-size: 0.8rem;
  display: inline-block;
  padding: 0.3rem 0.4rem;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.highlight};
  color: ${({ theme }) => theme.colors.accent};
  text-transform: uppercase;
  font-weight: 600;
  :hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const Orders = ({ data, deliveredToBuyers }) => {
  const { t } = useTranslation();

  const { handleModal } = useContext(ModalContext);

  const {
    prices,
    setPrices,
    pricePanelVisible,
    setPricePanelVisible,
    togglePricePanel,
  } = useContext(projectDetailsContext);

  const handleSelectPrice = ({ offerId, ...other }) => {
    console.log(prices, offerId, other);
    setPrices((currentPrices) => {
      // find the quantity offer in prices
      const quantityOfferIndex = currentPrices.findIndex(
        (p) => p.quantity === other.quantity && p.position === other.position
      );
      if (quantityOfferIndex === -1) {
        // add new element to array
        return [...currentPrices, { offerId, ...other }];
      } else {
        // if exists replace it with new price
        const newArr = [...currentPrices];
        newArr[quantityOfferIndex] = { offerId, ...other };
        return newArr;
      }
    });
    setPricePanelVisible(true);
  };

  /* prefill */

  useEffect(() => {
    const acceptedOffers = data?.orders?.acceptedOffers;
    const positionData = data?.positions;
    if (positionData && acceptedOffers) {
      const offers = positionData.reduce((accPos, position) => {
        position?.quantities?.reduce((accQuantity, quantity) => {
          const offersData = quantity?.offers.reduce((acc, offer) => {
            // if this is accepted offer append to array otherwise skip
            if (
              acceptedOffers.findIndex(
                (o) =>
                  o.quantity === quantity.quantity &&
                  o.user.id === offer.userId.id
              ) === -1
            )
              return acc;
            return [
              ...acc,
              {
                offerId: offer.id,
                user: offer.userId.id,
                companyName: offer.userId.company.name,
                userName: offer.userId.name,
                acceptedPrice: parseFloat(offer.offeredQuantityPrice),
                aimedPrice: parseFloat(quantity.aimedPrice),
                administratorAimedPrice: parseFloat(
                  quantity.administratorAimedPrice
                ),
                quantityId: quantity.id,
                quantity: parseInt(quantity.quantity),
                positionName: position.name,
                positionCode: position.positionCode,
                position: position.id,
                realisticDeliveryTime: new Date(offer?.realisticDeliveryTime),
              },
            ];
          }, []);
          accPos.push(...offersData);
        }, []);
        return accPos;
      }, []);
      setPrices(offers);
    }
  }, [data, setPrices]);

  return !data ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Prompt
        when={prices.length > 0 && !deliveredToBuyers}
        message="U listi postoje odabrane ponude od dobavljača, da li želite da napustite kreiranje ponude?"
      />
      {prices?.length > 0 ? (
        <Distributor stack="end">
          <Button onClick={togglePricePanel}>Odabrane ponude</Button>
        </Distributor>
      ) : null}
      <Space vertical="2rem" />
      {data?.positions?.map((positionData, index) => (
        <Distributor col="1-2" gap="5rem" key={positionData.id}>
          <Segment verticalAlign="space-between" noBg padding="0">
            <Distributor col="1-5-5" spaceBetween>
              <PositionIndex>{index + 1}</PositionIndex>
              <PositionHeading>
                <span>{t("Naziv pozicije")}</span>
                <h3>{positionData.name}</h3>
              </PositionHeading>
              <PositionHeading>
                <span>{t("Šifra pozicije")}</span>
                <h3>{positionData.positionCode}</h3>
              </PositionHeading>
            </Distributor>
            <Space vertical="2rem" />
            <Preview>
              <img
                src={process.env.REACT_APP_S3_BUCKET + positionData.files.jpg}
                alt="Pozicija"
              />
            </Preview>
            <Module>
              <h3>Detalji izrade</h3>
              <Row>
                <RowItem>
                  <TableLabel>Proizvodnja</TableLabel>
                  <Value>{positionData.manufacturing.name}</Value>
                </RowItem>
                {positionData.technology.name && (
                  <RowItem>
                    <TableLabel>Tehnologija</TableLabel>
                    <Value>{positionData.technology.name}</Value>
                  </RowItem>
                )}
                <RowItem>
                  <TableLabel>Materijal</TableLabel>
                  <Value>{positionData.material.name}</Value>
                </RowItem>
              </Row>
              <Row></Row>
            </Module>
          </Segment>
          <Segment noBg padding="0">
            {positionData.quantities.map((quantity) => (
              <QuantityTable key={quantity.id}>
                <QuantityTop>
                  <QuantityAmount>{quantity.quantity} kom.</QuantityAmount>
                  <QuantityAmount>
                    Ciljana cijena: {quantity?.aimedPrice?.toFixed(2)} /{" "}
                    {quantity?.administratorAimedPrice?.toFixed(2)}
                  </QuantityAmount>
                </QuantityTop>
                <BasicTable>
                  <TableHead>
                    <TableRow>
                      <TableHeader>Ime firme</TableHeader>
                      <TableHeader>Ponuđena cijena</TableHeader>
                      <TableHeader>Uslovi isporuke</TableHeader>
                      <TableHeader>C.V.I.</TableHeader>
                      <TableHeader>R.V.I.</TableHeader>
                      <TableHeader>Komentar</TableHeader>
                      <ActionHeader></ActionHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quantity.offers.map((offer) => (
                      <TableRow
                        key={offer.id}
                        activeRow={
                          prices.findIndex((p) => p.offerId === offer.id) !== -1
                        }
                      >
                        <TableCell>{offer.userId.company.name}</TableCell>
                        <TableCell>
                          {offer.offeredQuantityPrice.toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {offer.userId?.company?.paymentAndTransport
                            ?.paymentTimeframe || "-"}
                        </TableCell>
                        <TableCell>{data?.deliveryDeadline || "-"}</TableCell>
                        <TableCell>
                          {offer?.realisticDeliveryTime || "-"}
                        </TableCell>
                        <TableCell>
                          {positionData?.comments?.find(
                            (c) => c?.user?.id === offer?.userId?.id
                          ) && (
                            <CommentButton
                              onClick={() =>
                                handleModal(
                                  positionData?.comments?.find(
                                    (c) => c?.user?.id === offer?.userId?.id
                                  )?.comment
                                )
                              }
                            >
                              Vidi komentar
                            </CommentButton>
                          )}
                        </TableCell>
                        <ActionCell>
                          <ButtonDropdown
                            id={{
                              offerId: offer.id,
                              user: offer.userId.id,
                              companyName: offer.userId.company.name,
                              userName: offer.userId.name,
                              acceptedPrice: parseFloat(
                                offer.offeredQuantityPrice
                              ),
                              aimedPrice: parseFloat(quantity.aimedPrice),
                              administratorAimedPrice: parseFloat(
                                quantity.administratorAimedPrice
                              ),
                              quantityId: quantity.id,
                              quantity: parseInt(quantity.quantity),
                              positionName: positionData.name,
                              positionCode: positionData.positionCode,
                              position: positionData.id,
                              realisticDeliveryTime: new Date(
                                offer?.realisticDeliveryTime
                              ),
                            }}
                            actions={[
                              {
                                label: "Odaberi cijenu",
                                action: handleSelectPrice,
                              },
                            ]}
                          />
                        </ActionCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </BasicTable>
              </QuantityTable>
            ))}
          </Segment>
        </Distributor>
      ))}
      {pricePanelVisible && prices?.length > 0 ? <Prices data={data} /> : null}
    </Wrapper>
  );
};

export { TopControls, Orders as default };
