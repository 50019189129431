import React, { useContext, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { SidebarItem } from '../SidebarNavigation/SidebarNavigation.component';
import { FaFolder, FaPlus } from 'react-icons/fa';
import { ModalContext } from '../../structure/Modal/modalContext';
import AddPositionForm from './AddPositionForm';
import Spinner from '../Spinner/Spinner.component';
import useTranslation from 'src/hooks/useTranslation';
import { currentUserContext } from 'src/components/Auth/currentUserContext/currentUser.context';
import { isEmpty, omit, some, values } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  height: 100%;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1rem;
`;
const Actions = styled.div``;
const ActionButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.sidebarBorder};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.formPlaceholder};
  :hover {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;

const SidebarItemText = styled.div`
  span {
    display: block;
  }
  span:first-child {
    font-weight: 700;
  }
  span:nth-child(2) {
    font-size: 0.8rem;
  }
`;

const Positions = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 400px);
`;

const DynamicNavigation = ({
  relativeUrl,
  projectId,
  data,
  projectApproved,
}) => {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const {
    user: { user },
  } = useContext(currentUserContext);

  const isProjectActive = () => {
    if (['ADMINISTRATOR', 'MODERATOR'].includes(user?.role)) return true;
    // TODO: return projectApproved;

    return false;
  };

  const isPositionRevised = (revisedInfo) => {
    return values(omit(revisedInfo.files, ['__typename'])).every(isEmpty) &&
      !revisedInfo.note
      ? false
      : true;
  };

  return (
    <Wrapper>
      <Divider>
        <span>{t('Pozicije')}</span>
        <Actions>
          <ActionButton
            onClick={useCallback(() => {
              handleModal(<AddPositionForm projectId={projectId} />);
            }, [handleModal])}
          >
            <FaPlus />
          </ActionButton>
        </Actions>
      </Divider>
      <Positions>
        {!data?.length ? (
          t('Nema pozicija')
        ) : data ? (
          data.map((position) => {
            const revised = isPositionRevised(position.revisedInformations);
            console.log('REV', revised);
            return (
              <SidebarItem
                key={position.id}
                to={`${relativeUrl}/${position.id}`}
                modified={revised.toString()}
              >
                <FaFolder size="20px" />
                <SidebarItemText>
                  <span>{position.name}</span>
                  <span>{position.positionCode}</span>
                </SidebarItemText>
              </SidebarItem>
            );
          })
        ) : (
          <Spinner />
        )}
      </Positions>
    </Wrapper>
  );
};

export { Divider, DynamicNavigation as default };
