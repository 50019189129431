import gql from 'graphql-tag';

const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $companyId: String!
    $name: String!
    $initials: String
    $logo: String
    $contactInfo: ContactInfoInput
    $paymentAndTransport: PaymentTransportInput
    $dateFounded: String
    $aboutCompany: String
    $employeesCount: Int
    $references: [String]
    $taxPercentage: Int
    $sendCopy: Boolean
  ) {
    updateCompany(
      companyId: $companyId
      name: $name
      initials: $initials
      logo: $logo
      contactInfo: $contactInfo
      paymentAndTransport: $paymentAndTransport
      dateFounded: $dateFounded
      aboutCompany: $aboutCompany
      employeesCount: $employeesCount
      references: $references
      taxPercentage: $taxPercentage
      sendCopy: $sendCopy
    )
  }
`;

export default UPDATE_COMPANY;
