import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from '../../components/structure/wrappers/Container/Container.component';
import ButtonDropdown from '../../components/elements/ButtonDropdown/ButtonDropdown.component';
import GET_TECHNOLOGIES from '../../apollo/queries/getTechnologies';
import REMOVE_TECHNOLOGY from '../../apollo/mutations/removeTechnology';
import { useMutation, useQuery } from '@apollo/client';
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from '../../components/elements/BasicTable/BasicTable.component';
import Main from '../../components/structure/wrappers/Main/Main.component';
import { ModalContext } from '../../components/structure/Modal/modalContext';
import AddTechnologyForm from '../../components/structure/Header/AddTechnology/AddTechnologyForm';
import toast from 'react-hot-toast';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import { Search } from '../Materials/Materials.page';
import Select from 'src/components/elements/Select/Select.component';
import Button from 'src/components/elements/Button/Button.component';
import Input from 'src/components/elements/Input/Input.component';
import { uniq, get } from 'lodash';
import useTranslation from 'src/hooks/useTranslation';

const Wrapper = styled.div``;

const Technology = () => {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const { loading, error, data } = useQuery(GET_TECHNOLOGIES);
  const [removeTechnology] = useMutation(REMOVE_TECHNOLOGY, {
    onCompleted: () => {
      toast.success('Tehnologija uspješno obrisana');
    },
    onError: () => toast.error('Neuspješno brisanje tehnologije'),
  });

  const handleEditTechnology = (techData) => {
    handleModal(<AddTechnologyForm prefillData={techData} />);
  };

  const handleRemoveTechnology = (techId, techName) => {
    if (
      prompt(
        `Upišite POTVRDA za brisanje tehnologije ${techName} iz baze. Ovaj proces je ireverzibilan!`
      ).trim() === 'POTVRDA'
    )
      removeTechnology({
        variables: { technologyId: techId },
        refetchQueries: ['getTechnologies'],
      });
  };

  // filter
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');

  const clearFilter = () => {
    setFilter('');
    setPrefilter('');
  };

  // prefilter (dropdown)

  const [prefilter, setPrefilter] = useState('');

  const handlePrefilter = (e) => {
    const { value } = e.target;
    setPrefilter(value);
    setFilteredData(
      data?.getTechnologies.filter((val) => val?.category?.name === value)
    );
  };

  useEffect(() => {
    if (data) {
      setFilteredData(data?.getTechnologies);
    }
  }, [data]);

  const handleChange = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (filter.length) {
      setFilteredData(
        data?.getTechnologies.filter((v) => {
          const criteriumFields = ['name'];
          const matches = criteriumFields.some((field) => {
            const val = get(v, field)?.toLowerCase();
            return val && val?.indexOf(filter?.toLowerCase()) !== -1;
          });

          // if prefilter doesn't match return false
          if (prefilter && v?.category?.name !== prefilter) return false;

          // return all matching entries
          return matches;
        })
      );
    } else {
      setFilteredData(data?.getTechnologies);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Main>
          <Distributor col="1-1" sticky>
            <h1>Tehnologije</h1>
            <Search onSubmit={handleSearch}>
              <Select value={prefilter} onChange={handlePrefilter}>
                {uniq(
                  data?.getTechnologies?.map((tech) => tech?.category?.name)
                )?.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </Select>
              <Input
                placeholder="Pretraga..."
                onChange={handleChange}
                value={filter}
              />
              <Button onClick={handleSearch}>Pretraga</Button>
              <Button onClick={clearFilter} layout="hollow">
                Poništi pretragu
              </Button>
            </Search>
          </Distributor>
          <BasicTable layout="auto" loading={loading}>
            <TableHead sticky="75.5px">
              <TableRow>
                <TableHeader>{t('Naziv tehnologije')}</TableHeader>
                <TableHeader>{t('Kategorija')}</TableHeader>
                <TableHeader>{t('Naziv parametra')}</TableHeader>
                <TableHeader>Min</TableHeader>
                <TableHeader>Max</TableHeader>
                <ActionHeader>{t('Akcije')}</ActionHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((tech) => (
                <TableRow key={tech.id}>
                  <TableCell>{tech.name}</TableCell>
                  <TableCell>{tech?.category?.name}</TableCell>
                  <TableCell>{tech?.parameters[0]?.parameter?.name}</TableCell>
                  <TableCell>{tech?.parameters[0]?.minValue}</TableCell>
                  <TableCell>{tech?.parameters[0]?.maxValue}</TableCell>
                  <ActionCell>
                    <ButtonDropdown
                      id={tech.id}
                      actions={[
                        {
                          label: 'Uredi tehnologiju',
                          action: () => handleEditTechnology(tech),
                        },
                        {
                          label: 'Obriši tehnologiju',
                          action: () =>
                            handleRemoveTechnology(tech.id, tech.name),
                        },
                      ]}
                    />
                  </ActionCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default Technology;
