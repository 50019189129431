import gql from 'graphql-tag';

const GET_PROJECTS = gql`
  query getProjects($queryParams: String, $filters: FilterInput) {
    getProjects(queryParams: $queryParams, filters: $filters) {
      id
      name
      projectCode
      createdBy {
        id
        name
        company {
          name
          initials
          contactInfo {
            country
          }
        }
      }
      positions {
        _id
        material {
          _id
          name
          materialDesignation
          materialStandard
          category {
            _id
            name
          }
        }
        manufacturing {
          _id
          name
        }
        files {
          jpg
        }
        quantities {
          quantity
          aimedPrice
          administratorAimedPrice
        }
        complexityLevel
        toleranceLevel
        revisedInformations {
          files {
            jpg
            pdf
            step
          }
          note
        }
      }
      deliveryDeadline
      offerDeadline
      dateExtendedDeadline
      note
      dateCreated
      approved
      published {
        status
        date
      }
      orders {
        delivered {
          toBuyers
        }
        manufacturer {
          recievedOrder
        }
      }
      countries
      urgent
      hasOffered
      count
      status {
        OFFER_SENT_TO_BUYER
        DISABLED
      }
    }
  }
`;

export default GET_PROJECTS;
