import { BsFileEarmark } from "react-icons/bs";

export const parseFileTag = (string) => {
  const tag = "[file]";
  return string.indexOf(tag) !== -1 ? (
    <a
      target="_blank"
      rel="noreferrer"
      href={
        process.env.REACT_APP_S3_BUCKET +
        string.slice(tag.length + 1, string.length - 1)
      }
    >
      <BsFileEarmark />
      Download
    </a>
  ) : (
    string
  );
};
