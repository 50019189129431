import gql from 'graphql-tag';

const GET_ALL_ORDERS = gql`
  query getAllOrders($span: Int) {
    getAllOrders(span: $span) {
      _id
      project {
        id
        name
        projectCode
        deliveryDeadline
        published {
          date
        }
        createdBy {
          name
          company {
            name
            taxPercentage
            paymentAndTransport {
              shipmentAddress
              transportExpenses
              paymentSchedule
              paymentMethod
              paymentTimeframe
              currency
            }
          }
        }
      }
      acceptedOffers {
        _id
        acceptedByBuyer
        user {
          id
          name
          email
          company {
            name
            paymentAndTransport {
              paymentTimeframe
            }
          }
        }
        position {
          id
          name
          positionCode
          quantities {
            quantity
            offers {
              offeredQuantityPrice
              userId {
                name
                company {
                  name
                }
              }
            }
          }
          revisedInformations {
            files {
              jpg
            }
          }
        }
        quantity
        aimedPrice
        acceptedPrice
        administratorAimedPrice
        refinedPrice
        realisticDeliveryTime
        physicalOrderNumber
        deliveryStatus {
          deliveredByManufacturer {
            status
            date
            confirmedDelivery {
              status
            }
          }
        }
      }
      delivered {
        toBuyers
        date
      }
      accepted {
        byBuyer
        date
        physicalOrderNumber
      }
      manufacturer {
        recievedOrder
        date
      }
      dateCreated
      conditions {
        shipmentAddress
        transportExpenses
        paymentSchedule
        paymentMethod
        paymentTimeframe
        currency
      }
      note {
        message
      }
    }
  }
`;

export default GET_ALL_ORDERS;
