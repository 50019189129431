import gql from "graphql-tag";

const LATEST_BUYERS_ORDERS = gql`
  query latestBuyersOrders {
    latestBuyersOrders {
      dateCreated
    }
  }
`;

export default LATEST_BUYERS_ORDERS;
