import { get, orderBy, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { BiSelectMultiple, BiSkipNext, BiSkipPrevious } from 'react-icons/bi';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import Button from '../elements/Button/Button.component';
import CheckerBox from '../elements/CheckerBox/CheckerBox';
import Input from '../elements/Input/Input.component';
import Spinner from '../elements/Spinner/Spinner.component';
import { ModalContext } from '../structure/Modal/modalContext';
import { DataSelectorContext } from './dataSelectorContext';

const Wrapper = styled.div``;
const IntroText = styled.div`
  margin-bottom: 1rem;
`;
const Section = styled.div`
  margin-bottom: ${({ noHeaders }) => (noHeaders ? 'auto' : '2rem')};
`;
const Header = styled.div`
  margin-bottom: 0.5rem;
  white-space: nowrap;
  border-bottom: 2px solid ${({ theme }) => theme.colors.menuHighlight};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SelectAll = styled.div`
  background: ${({ theme }) => theme.colors.menuHighlight};
  padding: 0.5rem;
  font-size: 0.8rem;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Data = styled.div``;

const Top = styled.div``;

const DataTable = styled.div`
  margin-top: 2rem;
  height: 400px;
  overflow-y: scroll;
`;

const ValueSelector = styled.div`
  text-align: center;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  ${({ width }) => width && `width: ${width}`};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '600')};
  font-size: ${({ size }) => (size ? size : 'inherit')};
  height: 100%;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.menuHighlight};
  border-radius: 3px;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Search = styled.div`
  display: flex;
  > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const InlineWrapper = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  margin: 1rem 0;
  padding: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.menuHighlight};
`;

const AField = styled.div`
  > span {
    margin-left: 0.5rem;
  }
`;

const AFWrapper = styled.div`
  display: flex;
  ${AField}:not(:last-child) {
    margin-right: 1rem;
    border-right: 2px solid ${({ theme }) => theme.colors.sidebarBorder};
    padding-right: 1rem;
  }
`;
const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.menuHighlight};
  margin-bottom: 2px;
  border-radius: 3px;
  padding-right: 1rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
`;

const Icon = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  img {
    width: 100px;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex: 1;
`;

// additional fields to show in the list
const AdditionalFields = ({ params, data }) => {
  return params ? (
    <AFWrapper>
      {Object.keys(params).map((k, i) => {
        return (
          <AField key={i}>
            {params[k].label ? `${params[k].label}:` : null}
            <span>{get(data, k)}</span>
          </AField>
        );
      })}
    </AFWrapper>
  ) : null;
};

const ModalData = ({ params }) => {
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { updateSelectorValue, dataSelectorValues } =
    useContext(DataSelectorContext);

  // go through additional fields and see if matches

  const additionalFieldsFilter = (val, v) => {
    if (!params?.additionalFields) return;
    if (filter) {
      return Object.keys(params?.additionalFields)?.some((k) => {
        const value = get(val, k)?.toString()?.toLowerCase();
        return value?.indexOf(filter) !== -1;
      });
      // if (values.includes(filter?.toLowerCase()))
      //   return values.includes(filter?.toLowerCase());
      // return Object.keys(params?.additionalFields)?.some(field => {
      //   const item = get(val, field)?.toString()?.toLowerCase();
      //   if (!item) return false;
      //   return item.indexOf(filter.toLowerCase()) !== -1
      // })
    }
  };

  // prepare data, get unique categories and extract data for every

  useEffect(() => {
    const data = orderBy(
      uniqBy(params.data, params.sortBy.name).map((v) => ({
        [params.sortBy.dataField]: get(v, params.sortBy.dataField),
        data: orderBy(
          params.data.filter((val) => {
            return (
              (get(val, params.sortBy.compare) ===
                get(v, params.sortBy.compare) && // compare category ids
                get(val, params.handlerField)
                  ?.toLowerCase()
                  ?.indexOf(filter?.toLowerCase()) !== -1) || // compare item name
              (get(val, params.sortBy.compare) ===
                get(v, params.sortBy.compare) &&
                additionalFieldsFilter(val, v))
            ); // compare additional filters
          }),
          params?.sortBy?.sortDataBy, // how to sort extracted data for every separate category
          ['asc']
        ),
      })),
      [params.sortBy.overrideSortByField || params.sortBy.name],
      ['asc']
    ).filter((val) => val.data.length > 0);
    console.log(
      params.sortBy.overrideSortByField,
      params.sortBy.name,
      filteredData
    );
    setFilteredData(data);
  }, [filter]);

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setFilter(inputValue);
  };

  const clearFilter = (e) => {
    e.preventDefault();
    setInputValue('');
    setFilter('');
  };

  const handleChange = (e, params) => {
    const { name, value, checked } = e.target;

    // if multiSelection is disabled, prevent from selecting more than 1 value
    if (
      typeof params?.multiSelection !== 'undefined' &&
      !params?.multiSelection &&
      dataSelectorValues[params.name]?.length > 0 &&
      checked
    ) {
      return;
    }

    // toggle values
    updateSelectorValue(params.name, name);
  };

  const handleSelectCategory = (paramsName, categoryId) => {
    // if multiSelection is disabled, prevent from selecting more than 1 value
    if (
      typeof params?.multiSelection !== 'undefined' &&
      !params?.multiSelection &&
      dataSelectorValues[params.name]?.length > 0
    ) {
      return;
    }
    const category = filteredData.find((c) => c.category.id === categoryId);
    if (category) {
      // check if item is not already in the array
      const checkThese = category.data.map((item) => item.id);

      updateSelectorValue(paramsName, checkThese);
    }
  };

  const selectAll = (e) => {
    e.preventDefault();
    // if multiSelection is disabled, prevent from selecting more than 1 value
    if (
      typeof params?.multiSelection !== 'undefined' &&
      !params?.multiSelection &&
      dataSelectorValues[params.name]?.length > 0
    ) {
      return;
    }

    const allIds = filteredData.reduce(
      (ids, group) =>
        group.data.reduce((i, item) => [...ids, item[params.valuesField]], []),
      []
    );

    updateSelectorValue(params.name, allIds);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSelectedItem = (e, direction) => {
    e.preventDefault();

    // return index of first selected item
    const anchor = dataSelectorValues?.[params.name]?.[currentIndex];

    if (!anchor) return;

    const el = document.getElementById(anchor);
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    if (direction === 'next') {
      if (dataSelectorValues[params.name]?.length !== currentIndex + 1) {
        setCurrentIndex(currentIndex + 1);
      }
    } else {
      if (currentIndex !== 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  return (
    <Wrapper>
      <Top>
        <IntroText>{t(params.chooseButtonLabel)}</IntroText>
        <Search>
          <Input
            placeholder={t('Pretraga...')}
            width="100%"
            onChange={handleSearchInput}
            value={inputValue}
          />
          <Button onClick={handleSearch}>{t('Pretraga')}</Button>
          <Button onClick={clearFilter} layout="hollow">
            {t('Poništi pretragu')}
          </Button>
          {params.selectAllButton ? (
            <Button layout="hollow" onClick={selectAll}>
              <BiSelectMultiple size="20px" />
            </Button>
          ) : null}
          <Button
            layout="hollow"
            onClick={(e) => goToNextSelectedItem(e, 'previous')}
          >
            <BiSkipPrevious size="20px" />
          </Button>
          <Button
            layout="hollow"
            onClick={(e) => goToNextSelectedItem(e, 'next')}
          >
            <BiSkipNext size="20px" />
          </Button>
        </Search>
      </Top>
      <DataTable>
        {filteredData?.length > 0
          ? filteredData.map((c) => (
              <Section
                noHeaders={params?.noHeaders}
                key={get(c, params.sortBy.compare)}
              >
                {!params?.noHeaders && (
                  <Header>
                    <span>{get(c, params.sortBy.name)}</span>
                    <SelectAll
                      onClick={() =>
                        handleSelectCategory(
                          params.name,
                          get(c, params.sortBy.compare)
                        )
                      }
                    >
                      {t('Odaberi sve iz kategorije')}
                    </SelectAll>
                  </Header>
                )}
                <Data>
                  {c?.data?.map((item) => {
                    return (
                      <DataWrapper key={item[params.valuesField]}>
                        <IconWrapper>
                          {params?.icon && (
                            <Icon>
                              {params?.icon?.getter(item[params?.icon?.field])}
                            </Icon>
                          )}
                          <CheckerBox
                            id={item[params.valuesField]}
                            key={item[params.valuesField]}
                            value={item[params.handlerField]}
                            name={item[params.valuesField]}
                            onChange={(e) => handleChange(e, params)}
                            checked={
                              dataSelectorValues[params.name]
                                ? dataSelectorValues[params.name]?.indexOf(
                                    item[params.valuesField]
                                  ) !== -1
                                : false
                            }
                          />
                        </IconWrapper>
                        <AdditionalFields
                          params={params?.additionalFields}
                          data={item}
                        />
                      </DataWrapper>
                    );
                  })}
                </Data>
              </Section>
            ))
          : t('Nema podataka po zadanom upitu')}
      </DataTable>
      {/* <ActionButtons>
        <Button onClick={handleModal}>Ok</Button>
      </ActionButtons> */}
    </Wrapper>
  );
};

const DataSelector = ({ params }) => {
  /*
   * Data Selector Component
   * @param {array} data - Data field
   * @param {array} populateFrom: Populate data from this field upon render
   * @param {boolean} loading: Loading indicator
   * @param {string} chooseButtonLabel: Button label
   * @param {string} name: Name of the DOM element
   * @param {string} valuesField: When selected this value will appear in the context.name array
   * @param {string} handlerField: Checkbox label field
   * @param {boolean} noHeaders: No grouping headers, just basic list
   * @param {object} additionalFields: Additional field to be added as a table
   * @param {string} [key]: Key to use as a name
   * @param {string} label: Label to use as header
   * @param {boolean} inline: To use inline without modal
   *
   * additionalFields:{ fieldName:{ label: "Label" }}
   *
   * @param {object} sortBy: Sorting and grouping
   * @param {string} sortBy.dataField: Make unique groups using this field (ex. name)
   * @param {string} sortBy.name: Group object key (ex. name)
   * @param {string} sortBy.compare: Compare field to get from same group (ex. id)
   * @param {string} sortBy.overrideSortByField: Override sorting by this field
   *
   * @param {object} icon: Icon prefix
   * @param {function} icon.getter: Function to call to render image (ex. (img) => <img src={img} />)
   * @param {string} icon.field: Name of the field from which to get the image
   */

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [inlineLayout, setInlineLayout] = useState(false);
  const { populateSelectorValues, dataSelectorValues, resetSelectorValues } =
    useContext(DataSelectorContext);
  const { handleModal } = useContext(ModalContext);

  const handleDataLayout = () => {
    if (!params.inline) {
      handleModal(<ModalData params={params} />);
    } else {
      setInlineLayout(!inlineLayout);
    }
  };

  useEffect(() => {
    if (Array.isArray(params?.loading)) {
      setLoading(params.loading.every((v) => v));
    } else {
      setLoading(params.loading);
    }
  }, [params.loading]);

  // populate values from params.populateFrom prop
  useEffect(() => {
    if (params?.populateFrom) {
      populateSelectorValues(params.name, params.populateFrom);
    }

    // reset the values when component is unloaded
    return () => {
      resetSelectorValues(params?.name);
    };
  }, [params?.populateFrom]);

  return (
    <Wrapper>
      {loading && !params?.populateFrom ? (
        <Spinner />
      ) : (
        <>
          <ValueSelector width="100%" onClick={handleDataLayout}>
            {t(params.chooseButtonLabel) || 'Odaberi'} (
            <span>{t('Odabrano')}:</span>
            {dataSelectorValues[params.name]?.length || 0})
          </ValueSelector>
          {inlineLayout && (
            <InlineWrapper>
              <ModalData params={params} />
            </InlineWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default DataSelector;
