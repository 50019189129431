import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ADD_OTHER_DOCUMENT from "src/apollo/mutations/addOtherDocument";
import SAVE_OTHER_NOTE from "src/apollo/mutations/saveOtherNote";
import Button from "src/components/elements/Button/Button.component";
import Input from "src/components/elements/Input/Input.component";
import Label from "src/components/elements/Label/Label.component";
import Textarea from "src/components/elements/Textarea/Textarea.component";
import UploadFile from "src/components/elements/UploadFile/UploadFile.component";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import FormField from "src/components/structure/wrappers/FormField/FormField.component";
import styled from "styled-components";
import DocumentList from "./DocumentList";

const Wrapper = styled.div``;

const OtherInfo = ({ companyId, data }) => {
  const [saveNoteMutation, { loading, error }] = useMutation(SAVE_OTHER_NOTE, {
    onCompleted: () => toast.success("Uspješno pohranjena zabilješljka"),
    onError: () => {},
  });

  const [
    addOtherDocumentMutation,
    { loading: documentLoading, error: documentError },
  ] = useMutation(ADD_OTHER_DOCUMENT, {
    onCompleted: () => toast.success("Uspješno dodan dokument"),
    onError: () => {},
  });

  const [fileDescription, setFileDescription] = useState("");
  const [filename, setFilename] = useState(null);
  const [files, setFiles] = useState([]);
  const [note, setNote] = useState("");

  // populating

  useEffect(() => {
    console.log(data);
    if (data) {
      setFiles(data?.documents);
      setNote(data?.notes);
    }
  }, [data]);

  const handleSaveNotes = () => {
    saveNoteMutation({
      variables: {
        companyId,
        note,
      },
    });
  };

  const handleAddDocument = () => {
    addOtherDocumentMutation({
      variables: {
        companyId,
        filename,
        fileDescription,
      },
      refetchQueries: ["getSingleUser"],
    });
  };

  const handleNoteChange = (e) => {
    const { value } = e.target;
    setNote(value);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFileDescription(value);
  };

  return (
    <Wrapper>
      <h2>Bilješke o firmi</h2>
      <FormField>
        <Label>Dodatne informacije / zabilješke</Label>
        <Textarea
          height="20rem"
          placeholder="Bilješke"
          onChange={handleNoteChange}
          value={note}
        ></Textarea>
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button onClick={handleSaveNotes} loading={loading}>
          Snimi bilješku
        </Button>
      </Distributor>
      <h2>Dodatna dokumentacija</h2>
      <FormField>
        <Label>Šta predstavlja fajl koji uploadate</Label>
        <Input
          type="text"
          name="fileDescription"
          onChange={handleChange}
          value={fileDescription}
        />
      </FormField>
      <FormField>
        <Label>Odaberite dokument s računara i kliknite upload</Label>
        <UploadFile
          directory="documents"
          name="document"
          acceptedformats={[
            "image/png",
            "image/jpeg",
            "application/pdf",
            "application/msword",
            "application/docx",
            "text/plain",
          ]}
          maxMB="50"
          onSuccess={(filename) => setFilename(filename)}
        />
      </FormField>
      <Distributor submitButtons stack="end">
        <Button onClick={handleAddDocument} loading={documentLoading}>
          Dodaj dokument
        </Button>
      </Distributor>
      <h3>Dodani fajlovi</h3>
      {files ? <DocumentList files={files} /> : <p>Nema dodanih fajlova.</p>}
    </Wrapper>
  );
};

export default OtherInfo;
