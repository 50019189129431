import gql from "graphql-tag";

const SENT_OFFERS_VALUE = gql`
  query sentOffersValue {
    sentOffersValue {
      dateCreated
      value
    }
  }
`;

export default SENT_OFFERS_VALUE;
