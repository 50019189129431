import React, { useContext, useEffect, useState } from "react";
import Input from "../../../elements/Input/Input.component";
import Label from "../../../elements/Label/Label.component";
import FormField from "../../wrappers/FormField/FormField.component";
import Distributor from "../../wrappers/Distributor/Distributor.component";
import Button from "../../../elements/Button/Button.component";
import Select from "../../../elements/Select/Select.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addTechnologyCategoryFormSchema } from "../../../../validation/schema";
import { useMutation, useQuery } from "@apollo/client";
import GET_TECHNOLOGY_CATEGORIES from "../../../../apollo/queries/getTechnologyCategories";
import CREATE_TECHNOLOGY_CATEGORY from "../../../../apollo/mutations/createTechnologyCategory";
import { ModalContext } from "../../Modal/modalContext";
import toast from "react-hot-toast";
import { pickBy } from "lodash";

const AddTechnologyCategory = ({ prefillData }) => {
  const { handleModal } = useContext(ModalContext);
  const { loading, error, data } = useQuery(GET_TECHNOLOGY_CATEGORIES);
  const [
    createTechnologyCategoryMutation,
    { loading: loadingCreateCats, error: errorCreateCats },
  ] = useMutation(CREATE_TECHNOLOGY_CATEGORY, {
    refetchQueries: ["getTechnologyCategories"],
    onError: () => {},
  });

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(addTechnologyCategoryFormSchema),
  });

  const onSubmit = (values) => {
    const sanitizedValues = pickBy(values, (value) => value.length > 0);
    createTechnologyCategoryMutation({
      variables: {
        ...sanitizedValues,
        replaceTechnologyCategoryId: prefillData?.id,
      },
    });
    if (!loading) {
      toast.success("Kategorija dodana");
      handleModal();
    }
  };

  // prefill

  useEffect(() => {
    if (prefillData) {
      setValue("name", prefillData?.name);
      setValue("parent", prefillData?.parent?.id);
    }
  }, [prefillData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Dodaj novu kategoriju tehnologije</h2>
      <FormField>
        <Label error={errors.name?.message}>Ime kategorije</Label>
        <Input name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.parent?.message}>Parent kategorija</Label>
        <Select name="parent" loading={loading} ref={register}>
          {data?.getTechnologyCategories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button>Sačuvaj kategoriju</Button>
      </Distributor>
    </form>
  );
};

export default AddTechnologyCategory;
