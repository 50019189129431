import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { BiChip } from 'react-icons/bi';
import GET_MANUFACTURERS_OFFERS_BY_QUANTITY from 'src/apollo/queries/getManufacturersOffersByQuantity';
import ChartStats from 'src/components/ChartStats/ChartStats';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import { IconWrapper, Number } from './Dashboard';
import dayjs from 'dayjs';

const ManufacturersOffersByQuantityChart = () => {
  const { t } = useTranslation();
  const [lov, setLov] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const { loading, data: { getManufacturersOffersByQuantity } = {} } = useQuery(
    GET_MANUFACTURERS_OFFERS_BY_QUANTITY,
    {}
  );
  const mappedData = () =>
    lov
      .map((item) => ({
        name: dayjs(item.datePublished).format('DD/MM/YY'),
        value: item.quantitiesCount,
        title: `(${item.quantitiesCount}) ${item.projectName}`,
        additionalData: item.offers
          .map((offer) => ({
            label: offer.user,
            value: offer.offers,
          }))
          .sort((a, b) => b.value - a.value),
      }))
      .reverse();

  useEffect(() => {
    if (getManufacturersOffersByQuantity?.length) {
      const totalNumberOfOffers = getManufacturersOffersByQuantity.reduce(
        (acc, item) => acc + item.offers.length,
        0
      );
      setTotalValue(totalNumberOfOffers);
      const formattedValues = getManufacturersOffersByQuantity.map((item) => ({
        ...item,
        name: item.projectName,
        value: item.projectName,
      }));

      setLov(formattedValues);
    }
  }, [getManufacturersOffersByQuantity]);

  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t('Broj ponuda od dobavljača')}</h4>
          {loading ? <Spinner /> : <Number>{totalValue}</Number>}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats
            data={lov}
            yAxisLabel={t('Broj raspoloživih količina')}
            title={t('Ukupan broj ponuda po količini')}
            sorter={mappedData}
          />
        )}
      </Segment>
    </Distributor>
  );
};

export default ManufacturersOffersByQuantityChart;
