import { useQuery } from "@apollo/client";
import React from "react";
import { BiChip } from "react-icons/bi";
import GET_PROJECTS from "src/apollo/queries/getProjects";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import { IconWrapper, Number } from "./Dashboard";

const AllProjects = () => {
  const { t } = useTranslation();
  const {
    loading: getProjectsLoading,
    data: { getProjects } = {},
    error: getProjectsError,
  } = useQuery(GET_PROJECTS);

  return (
    <Segment hoverable>
      <IconWrapper>
        <BiChip size="50px" />
      </IconWrapper>
      <h4>{t("Ukupan broj projekata")}</h4>
      {getProjectsLoading ? (
        <Spinner />
      ) : (
        <Number>{getProjects?.length}</Number>
      )}
    </Segment>
  );
};

export default AllProjects;
