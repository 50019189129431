import gql from "graphql-tag";

const LOGIN_USER = gql`
  mutation loginUser($username: String!, $password: String!) {
    loginUser(username: $username, password: $password) {
      token
      user {
        id
        username
        name
        role
        language
        attributes {
          reseller
        }
        company {
          _id
        }
      }
    }
  }
`;

export default LOGIN_USER;
