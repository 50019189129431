import { useApolloClient } from '@apollo/client';
import { useContext } from 'react';
import TagManager from 'react-gtm-module';
import toast from 'react-hot-toast';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { currentUserContext } from '../Auth/currentUserContext/currentUser.context';

const ToastStyle = styled.div`
  display: flex;
  span {
    display: block;
    margin-right: 1em;
  }
  button {
    background-color: ${({ theme }) => theme.colors.accent};
    color: white;
  }
`;

const LogoutOnIdle = ({ logoutOnHoursIdling }) => {
  const {
    user: { user },
  } = useContext(currentUserContext);

  const client = useApolloClient();
  const history = useHistory();
  useIdleTimer({
    timeout: 1000 * 60 * 60 * logoutOnHoursIdling, // 2 hours
    onIdle: logout,
    onPrompt: showToast,
    promptBeforeIdle: 20000,
  });

  function handleDismiss(toastId, idleTimer) {
    toast.dismiss(toastId);
    idleTimer.reset();
  }

  function showToast(e, idleTimer) {
    const user = localStorage.getItem('user');
    if (!user) return;

    toast.error(
      (t) => (
        <ToastStyle>
          <span>Sistem će vas izlogovati za 20s zbog neaktivnosti!</span>
          <button onClick={() => handleDismiss(t.id, idleTimer)}>
            Vratio sam se
          </button>
        </ToastStyle>
      ),
      {
        duration: 20000,
      }
    );
  }

  function logout() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'auto_logout',
        username: user?.name,
        userId: user?.id,
      },
    });
    client.clearStore();
    localStorage.removeItem('user');
    history.push('/login');
  }

  return null;
};

export default LogoutOnIdle;
