import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import styled from "styled-components";
import ButtonDropdown from "../../components/elements/ButtonDropdown/ButtonDropdown.component";
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from "../../components/elements/BasicTable/BasicTable.component";
import { ModalContext } from "../../components/structure/Modal/modalContext";
import Container from "../../components/structure/wrappers/Container/Container.component";
import Main from "../../components/structure/wrappers/Main/Main.component";
import GET_MANUFACTURING_CATEGORIES from "../../apollo/queries/getManufacturingCategories";
import AddManufacturingCategoryForm from "src/components/structure/Header/AddManufacturingCategory/AddManufacturingCategoryForm";
import REMOVE_MANUFACTURING_CATEGORY from "src/apollo/mutations/removeManufacturingCategory";
import toast from "react-hot-toast";

const Wrapper = styled.div``;

const ManufacturingCategories = () => {
  const { handleModal } = useContext(ModalContext);
  const { loading, error, data, refetch } = useQuery(
    GET_MANUFACTURING_CATEGORIES,
    {
      fetchPolicy: "network-only",
    }
  );
  const [removeManufacturingCategoryMutation] = useMutation(
    REMOVE_MANUFACTURING_CATEGORY,
    {
      onCompleted: () => {
        toast.success("Kategorija uspješno obrisana");
        refetch();
      },
      onError: () => {},
    }
  );

  const handleEditCategory = (data) => {
    handleModal(<AddManufacturingCategoryForm prefillData={data} />);
  };
  const handleRemoveCategory = (id, name) => {
    if (
      prompt(
        `Upišite POTVRDA za brisanje kategorije proizvodnje ${name} iz baze. Ovaj proces je ireverzibilan!`
      ).trim() === "POTVRDA"
    )
      removeManufacturingCategoryMutation({
        variables: {
          categoryId: id,
        },
      });
  };

  return (
    <Wrapper>
      <Container>
        <Main>
          <h1>Kategorije proizvodnje</h1>
          <BasicTable layout="auto" loading={loading}>
            <TableHead>
              <TableRow>
                <TableHeader>Naziv kategorije</TableHeader>
                <TableHeader>Pripadajuća kategorija</TableHeader>
                <ActionHeader>Akcije</ActionHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getManufacturingCategories?.map((category) => (
                <TableRow key={category.id}>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>{category?.parent?.name}</TableCell>
                  <ActionCell>
                    <ButtonDropdown
                      id={category.id}
                      actions={[
                        {
                          label: "Uredi kategoriju",
                          action: () => handleEditCategory(category),
                        },
                        {
                          label: "Obriši kategoriju",
                          action: () =>
                            handleRemoveCategory(category.id, category.name),
                        },
                      ]}
                    />
                  </ActionCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default ManufacturingCategories;
