import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import toast from "react-hot-toast";
import { ImBin } from "react-icons/im";
import REMOVE_PHOTO_FROM_GALLERY from "src/apollo/mutations/removePhotoFromGallery";
import ProtectedContent from "src/components/Auth/ProtectedContent/ProtectedContent";
import styled from "styled-components";
import { ModalContext } from "../../../components/structure/Modal/modalContext";

const LargeImage = styled.div`
  > img {
    width: 600px;
    object-fit: contain;
  }
`;

const RemoveImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.colors.accent};
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 0;
  color: ${({ theme }) => theme.colors.white};
  :hover {
    background: ${({ theme }) => theme.colors.black};
  }
`;

const Image = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadow.depth.pale};
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  :hover {
    cursor: pointer;
    ${RemoveImage} {
      display: flex;
    }
  }
`;
const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
`;

const Photos = ({ gallery, companyId, onRemove, previewOnly }) => {
  const { handleModal } = useContext(ModalContext);
  const [removePhotoMutation] = useMutation(REMOVE_PHOTO_FROM_GALLERY, {
    onCompleted: () => toast.success("Slika uspješno obrisana"),
    onError: () => {},
  });

  const handleRemoveImage = (e, filename) => {
    e.stopPropagation();
    if (
      window.confirm("Da li ste sigurni da želite obrišete ovu fotografiju?")
    ) {
      if (onRemove) {
        onRemove(filename);
      } else {
        removePhotoMutation({
          variables: { companyId, filename },
          refetchQueries: ["getSingleUser"],
        });
      }
    }
  };

  return (
    <Wrapper>
      {gallery?.length ? (
        gallery.map((image) => (
          <Image
            key={image}
            onClick={() =>
              !previewOnly
                ? handleModal(
                    <LargeImage>
                      <img
                        src={process.env.REACT_APP_S3_BUCKET + image}
                        alt="gallery item"
                      />
                    </LargeImage>
                  )
                : null
            }
          >
            <ProtectedContent onlyFor={["ADMINISTRATOR", "MODERATOR"]}>
              <RemoveImage onClick={(e) => handleRemoveImage(e, image)}>
                <ImBin size="20px" />
              </RemoveImage>
            </ProtectedContent>
            <img
              src={process.env.REACT_APP_S3_BUCKET + image}
              alt="gallery item"
            />
          </Image>
        ))
      ) : (
        <p>Nema dodanih fotografija.</p>
      )}
    </Wrapper>
  );
};

export default Photos;
