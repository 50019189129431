import gql from "graphql-tag";

const GET_MANUFACTURERS_DETAILS = gql`
  query getManufacturersDetails($manufacturersId: ID!) {
    getManufacturersDetails(manufacturersId: $manufacturersId) {
      photos
      technologies {
        name
        category {
          name
        }
      }
      machines {
        name
      }
      projects {
        _id
        name
        positions {
          _id
        }
      }
    }
  }
`;

export default GET_MANUFACTURERS_DETAILS;
