import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.highlight};
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.accent};
  border-style: dashed;
  border-radius: 5px;
`;

const Warning = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Warning;
