const strings = {
  ProjectsPage__Title: {
    bs: 'Lista projekata',
    de: 'Projektliste',
    en: 'Project list',
  },
  Inquiry__Subject: {
    bs: 'Naziv pozicije',
    de: 'Position Name',
    en: 'Position Name',
  },
  Inquiry__Content: {
    bs: 'Vaš upit',
    de: 'Your inquiry',
    en: 'Your inquiry',
  },
  'Statistika upita i ponuda': {
    de: 'Statistik der Anfragen und Angebote',
    en: 'Inquiry and offer statistics',
  },
  Inquiry__SendButton: {
    bs: 'Pošalji poruku',
    de: 'Send message',
    en: 'Send message',
  },
  ProjectDetails__tabs__SendInquiry: {
    bs: 'Pošalji poruku',
    de: 'Send inquiry',
    en: 'Send inquiry',
  },
  ProjectDetails__tabs__Stats: {
    bs: 'Statistika',
    de: 'Statistics',
    en: 'Statistics',
  },
  ProjectDetails__tabs__Orders: {
    bs: 'Primljene ponude od dobavljača',
    de: 'Orders / Offers',
    en: 'Orders / Offers',
  },
  ProjectDetails__tabs__Positions: {
    bs: 'Pozicije',
    de: 'Positions',
    en: 'Positions',
  },
  Projekti: {
    bs: 'Projekti',
    de: 'Projekte',
    en: 'Projects',
  },
  Ponude: {
    bs: 'Ponude',
    de: 'Angebote',
    en: 'Offers',
  },
  Administracija: {
    bs: 'Administracija',
    de: 'Administration',
    en: 'Administration',
  },
  Korisnici: {
    bs: 'Korisnici',
    de: 'Users',
    en: 'Users',
  },
  Materijali: {
    bs: 'Materijali',
    de: 'Materials',
    en: 'Materials',
  },
  Tehnologije: {
    bs: 'Tehnologije obrade',
    de: 'Processing technologies',
    en: 'Processing technologies',
  },
  Proizvodnja: {
    bs: 'Proizvodnja',
    de: 'Production',
    en: 'Production',
  },
  'O firmi': {
    bs: 'O firmi',
    de: 'Über das Unternehmen',
    en: 'About the company',
  },
  /* ProjectList.component */
  'Aktivni projekti': {
    bs: 'Aktivni projekti',
    de: 'Active projects',
    en: 'Active projects',
  },
  'Svi projekti': {
    de: 'All projects',
    en: 'All projects',
  },
  'Otvoreni za apliciranje': {
    de: 'Open for application',
  },
  Aplicirani_Projekti: {
    bs: 'Aplicirani projekti',
    de: 'Applied projects',
    en: 'Applied projects',
  },
  Filter_po_drzavi: {
    bs: 'Filtriranje po državi',
    de: 'Filter by country',
    en: 'Filter by country',
  },
  Sa_Ponudama: {
    bs: 'Projekti sa ponudama od dobavljača',
    de: 'Projects with offers from suppliers',
    en: 'Projects with offers from suppliers',
  },
  'Neobrađeni projekti': {
    bs: 'Neobrađeni projekti',
    de: 'Unprocessed projects',
    en: 'Unprocessed projects',
  },
  Nema_projekata_po_navedenom_filteru: {
    bs: 'Nema projekata po navedenom filteru',
    de: 'There are no projects for the specified filter',
    en: 'There are no projects for the specified filter',
  },
  'Bosnia and Herzegovina': {
    bs: 'Bosna i Hercegovina',
    de: 'Bosnia and Herzegovina',
    en: 'Bosnia and Herzegovina',
  },
  'Filteri pretrage': {
    bs: 'Filteri pretrage',
    de: 'Search filters',
    en: 'Search filters',
  },
  Tehnologija: {
    bs: 'Tehnologija',
    de: 'Technology',
    en: 'Technology',
  },
  'Grupe materijala': {
    bs: 'Grupe materijala',
    de: 'Material groups',
    en: 'Material groups',
  },
  Azuriraj: {
    bs: 'Ažuriraj - Osvjezi',
    de: 'Update - Refresh',
    en: 'Update - Refresh',
  },
  Reset: {
    bs: 'Reset',
    de: 'Reset',
    en: 'Reset',
  },
  Balzamiranje: {
    bs: 'Balzamiranje',
    de: 'Embalming',
    en: 'Embalming',
  },
  Odaberi: {
    bs: 'Odaberi',
    de: 'Wählen',
    en: 'Choose',
  },
  'Naziv projekta': {
    bs: 'Naziv projekta',
    de: 'Project name / Project number',
    en: 'Project name / Project number',
  },
  'Kratak opis / Napomena': {
    bs: 'Kratak opis / Napomena',
    de: 'Short description / Note',
    en: 'Short description / Note',
  },
  'Broj pozicija': {
    bs: 'Broj pozicija',
    de: 'Number of positions',
    en: 'Number of positions',
  },
  'Datum kreiranja ponude': {
    de: 'Angebotsdatum',
    en: 'Quote creation date',
  },
  kom: {
    bs: 'kom',
    de: 'pcs',
    en: 'pcs',
  },
  'Datum primanja ponude': {
    de: 'Angebotseingangs',
    en: 'Date of receiving the offer',
  },
  'Ukupna količina': {
    bs: 'Ukupna količina',
    de: 'Total quantity',
    en: 'Total quantity',
  },
  'Ciljana cijena projekta': {
    bs: 'Ciljana cijena projekta',
    de: 'Target project price',
    en: 'Target project price',
  },
  'Ciljana cijena': {
    de: 'Ziel Preis',
    en: 'Target price',
  },
  'Rok za ponudu': {
    bs: 'Rok za ponudu',
    de: 'Angebotsfrist',
    en: 'Quote deadline',
  },
  Status: {
    bs: 'Status',
    de: 'Status',
    en: 'Status',
  },
  'Završava za': {
    bs: 'Završava',
    de: 'Ends in',
    en: 'Ends in',
  },
  'Istekao upit': {
    bs: 'Istekao upit',
    de: 'Inquiry expired',
    en: 'Inquiry expired',
  },
  Pakovanje: {
    de: 'Verpackung',
    en: 'Packaging',
  },
  Kompleksnost: {
    bs: 'Kompleksnost',
    de: 'Complexity',
    en: 'Complexity',
  },
  Tolerancija: {
    bs: 'Tolerancije',
    de: 'Tolerances',
    en: 'Tolerances',
  },
  'Odaberite poziciju alata': {
    bs: 'Odaberite poziciju',
    de: 'Wählen Sie die Position',
    en: 'Choose position',
  },
  'Prikaži odabranu poziciju': {
    bs: 'Prikaži odabranu poziciju',
    de: 'Show selected position',
    en: 'Show selected position',
  },
  'Nije odabrana nijedna pozicija': {
    bs: 'Nije odabrana nijedna pozicija',
    de: 'No position selected',
    en: 'No position selected',
  },
  'Ime projekta': {
    bs: 'Ime projekta',
    de: 'Project Name',
    en: 'Project Name',
  },
  'Ime pozicije': {
    bs: 'Ime pozicije',
    de: 'Position Name',
    en: 'Position Name',
  },
  Količina: {
    bs: 'Količina',
    de: 'Menge',
    en: 'Quantity',
  },
  'Cilj. cijena': {
    bs: 'Ciljana cijena',
    de: 'Gezieltepreis',
    en: 'Target price',
  },
  'Vaša ponuda': {
    bs: 'Vaša ponuda',
    de: 'Ihr Angebot',
    en: 'Your offer',
  },
  'Detalji isporuke': {
    de: 'Lieferdetails',
    en: 'Delivery details',
  },
  'Ciljani termin': {
    bs: 'Ciljani termin isporuke',
    de: 'Zieltermin',
    en: 'Target delivery date',
  },
  'Realno vrijeme isporuke': {
    bs: 'Realno vrijeme isporuke',
    de: 'Realistische Liefertermin',
    en: 'Real delivery time',
  },
  Ponudi: {
    bs: 'Pošalji Ponudu',
    de: 'Angebot Senden',
    en: 'Submit Offer',
  },
  Dostava: {
    bs: 'Dostava',
    de: 'Lieferungfrist',
    en: 'Delivery',
  },
  'Detalji pozicije': {
    de: 'Positionsdetails',
    en: 'Position details',
  },
  'Detalji izrade': {
    bs: 'Detalji izrade',
    de: 'Herstellung Details',
    en: 'Manufacturing details',
  },
  Napomena: {
    bs: 'Napomena',
    de: 'Hinweis',
    en: 'Note',
  },
  Pozicija: {
    bs: 'Pozicija',
    de: 'Position',
    en: 'Position',
  },
  'Poslate ponude': {
    de: 'Angebot Liste',
    en: 'Sent offers',
  },
  Naručene: {
    bs: 'Naručene pozicije',
    de: 'Bestellung Liste',
    en: 'Ordered positions',
  },
  Ukupno: {
    bs: 'Ukupno',
    de: 'Insgesamt',
    en: 'Total',
  },
  Kreirana: {
    bs: 'Kreirano',
    de: 'Erstellt ',
    en: 'Created',
  },
  Dostavljena: {
    bs: 'Dostavljeno',
    de: 'Geliefert ',
    en: 'Delivered',
  },
  'Nemate ponuda': {
    bs: 'Nemate ponuda',
    de: 'Sie haben keine Angebote',
    en: 'You have no offers',
  },
  'Rok za isporuku': {
    bs: 'Rok za isporuku',
    de: 'Lieferung Frist',
    en: 'Delivery deadline',
  },
  'Naruči odabrane pozicije': {
    de: 'Ausgewählte Positionen Bestellen',
    en: 'Order selected positions',
  },
  /* 04/08/2021 */
  'Novi Unos': {
    bs: 'Novi Unos',
    de: 'Neues Projekt',
    en: 'New Entry',
  },
  'Projekti u pripremi': {
    de: 'Projekte in Vorbereitung',
    en: 'Projects in Preparation',
  },
  'Arhiva - istekli projekti': {
    de: 'Archiv - Abgelaufene Projekte',
    en: 'Archive - Expired Projects',
  },
  'Šifra projekta': {
    de: 'Projektcode',
    en: 'Project Code',
  },
  'Uslovi plaćanja': {
    de: 'Zahlungsbedingungen',
    en: 'Payment Terms',
  },
  'Sačuvaj i dodaj pozicije': {
    de: 'Projekt  hinzufügen',
    en: 'Save and Add Positions',
  },
  'Dodaj novu poziciju': {
    de: 'Fügen Sie eine neue Position hinzu',
    en: 'Add New Position',
  },
  'Odaberi tip proizvodnje': {
    de: 'Produktionstyp auswählen',
    en: 'Select Production Type',
  },
  'Dodatni dokumenti': {
    de: 'Dokumente hinzufügen',
    en: 'Additional Documents',
  },
  'Dodatni dokumenti (*.zip)': {
    de: 'Zusätzliche unterlagen (*.zip)',
    en: 'Additional Documents (*.zip)',
  },
  Dodaj: {
    de: 'Hinzufügen',
    en: 'Add',
  },
  'Obriši poziciju': {
    de: 'Position löschen',
    en: 'Delete Position',
  },
  'Spremi izmjene': {
    de: 'Änderungen speichern',
    en: 'Save Changes',
  },
  'Odaberi tehnologiju': {
    de: 'Wählen Sie die Technologie',
    en: 'Select Technology',
  },
  Odabrano: {
    de: 'Ausgewählt',
    en: 'Selected',
  },
  'Potvrdi cijenu': {
    de: 'Preis Bestätigung',
    en: 'Confirm Price',
  },
  'Prihvati najpovoljnije cijene': {},
  'Dodatni filteri': {
    de: 'Zusätzliche Filter',
    en: 'Additional Filters',
  },
  Objavljen: {
    de: 'Erschinen',
    en: 'Published',
  },
  Ističe: {
    de: 'Endet',
    en: 'Expires',
  },
  'Pretraga po nazivu, šifri': {
    de: 'Suche nach Name, Codes',
    en: 'Search by Name, Code',
  },
  'Po državi': {
    de: 'Nach Land',
    en: 'By Country',
  },
  'Naziv pozicije': {
    de: 'Positions Name',
    en: 'Position Name',
  },
  Uređivanje: {
    de: 'Bearbeitung',
    en: 'Editing',
  },
  'Produži rok za ponude': {
    de: 'Angebotsfrist verlängern',
    en: 'Extend Quote Deadline',
  },
  'Uređivanje projekta': {
    de: 'Project Bearbeitung',
    en: 'Editing Project',
  },
  'Osnovne informacije': {
    de: 'Allgemeine informationen',
    en: 'Basic Information',
  },
  Pozicije: {
    de: 'Positions',
    en: 'Positions',
  },
  'Nema pozicija': {
    de: 'No Positions',
    en: 'No Positions',
  },
  'Uređivanje pozicije': {
    de: 'Positionsbearbeitung',
    en: 'Editing Position',
  },
  'Šifra pozicije': {
    de: 'Positionscode ',
    en: 'Position Code',
  },
  'Broj crteža': {
    de: 'Zeiichnungsnummer ',
    en: 'Drawing Number',
  },
  'Ciljana cijena (EUR/kom.)': {
    de: 'Gezieltepreis (Eur / Stück ) ',
    en: 'Target Price (EUR/piece)',
  },
  'Odaberi dodatne proizvodnje': {
    de: 'Wählen Sie zusätzliche Produktionen',
    en: 'Select additional productions',
  },
  'Uploadani file': {
    de: 'Hochgeladene Datei',
    en: 'Uploaded File',
  },
  'Sačuvaj izmjene': {
    de: 'Änderungen speichern',
    en: 'Save Changes',
  },
  'Objavi projekt': {
    de: 'Veröffentlichen Sie das Projekt',
    en: 'Publish Project',
  },
  LoginIntroHeading: {
    ba: 'Pridružite se našem ON-LINE Component Providing Portalu',
    de: 'Verbinden Sie sich mit unserem ON-LINE Componenten Providing Portal',
    en: 'Join our ONLINE Component Providing Portal',
  },
  LoginIntroText: {
    ba: 'Prijavite se na portal i pogledajte paletu aktuelnih raspolozivih projekata . Ako ne posjedujete pristupne podatke , zatrazite pristup putem kontakata koji su navedeni ispod.',
    de: 'Melden Sie sich im Portal an und sehen Sie sich das Angebot aktuell verfügbarer Projekte an. Falls Sie keine Zugangsdaten haben, fordern Sie den Zugang über die unten aufgeführten Kontakte an.',
    en: 'Log in to the portal and see the range of currently available projects. If you do not have access data, request access via the contacts listed below.',
  },
  LoginExplanation: {
    ba: 'Za prijavu koristite vaše podatke koje ste dobili od administratora',
    de: 'Melden Sie sich mit den vom Administrator bereitgestellten Zugangsinformationen an',
    en: 'Use your login data provided by the administrator to log in',
  },
  'Više informacija': {
    de: 'Mehr Informationen',
    en: 'More Information',
  },
  'Korisničko ime': {
    de: 'Benutzername',
    en: 'Username',
  },
  Lozinka: {
    de: 'Kennwort',
    en: 'Password',
  },
  Prijava: {
    de: 'Anmeldung',
    en: 'Login',
  },
  Naslovna: {
    de: 'Startseite',
    en: 'Dashboard',
  },
  'Odaberi proizvodnju': {
    de: 'Technologie Auswählen',
    en: 'Select Production',
  },
  'Novi projekt': {
    de: 'Neues Projekt',
    en: 'New Project',
  },
  'Dobrodošli na Component Providing Portal, gdine': {
    de: 'Willkommen auf dem Komponentenbereitstellungsportal, Herr',
    en: 'Welcome to the Component Providing Portal, Mr.',
  },
  'Hitni zahtjev': {
    de: 'Dringende Anfrage',
    en: 'Urgent Request',
  },
  Sa_Ponudama_alt: {
    bs: 'Sa ponudama',
    de: 'Angeboten von Lieferanten',
    en: 'With offers',
  },
  'Obavezna autorizacija': {
    en: 'Mandatory authorization',
  },
  'Projekti s poslanim ponudama': {
    en: 'Projects with sent offers',
  },
  'Projekt zahtijeva hitnu izradu': {
    de: 'Das Projekt erfordert ein dringendes Angebot',
    en: 'The project requires urgent production',
  },
  Količine: {
    de: 'Mengen',
    en: 'Quantities',
  },
  'Šifra crteža': {
    de: 'Zeichnungscode',
    en: 'Drawing Code',
  },
  Materijal: {
    de: 'Werkstof',
    en: 'Material',
  },
  'Standard materijala': {
    de: 'Materialstandard',
    en: 'Material Standard',
  },
  'Oznaka materijala': {
    de: 'Materijal Bezeichnung',
    en: 'Material Label',
  },
  'Kategorija materijala': {
    de: 'Materialkategorie',
    en: 'Material Category',
  },
  Poruke: {
    de: 'Nachrichte',
    en: 'Messages',
  },
  Postavke: {
    de: 'Einstellungen',
    en: 'Settings',
  },
  Odjava: {
    de: 'Abmeldung',
    en: 'Logout',
  },
  'Profil firme': {
    de: 'Firmenprofil',
    en: 'Company Profile',
  },
  'Tehničke kvalifikacije': {
    de: 'Technische Qualifikationen',
    en: 'Technical Qualifications',
  },
  'Napredna pretraga': {
    de: 'Erweiterte Suche',
    en: 'Advanced Search',
  },
  'Po datumu kreiranja': {
    de: 'Nach Erstellungsdatum',
    en: 'By Creation Date',
  },
  'Pretraga...': {
    de: 'Suchen...',
    en: 'Search...',
  },
  'Odaberi države': {
    de: 'Länder auswählen',
    en: 'Select countries',
  },
  'Samo sa ciljanim cijenama': {
    de: 'Nur mit gezielten Preisen',
    en: 'Only with target prices',
  },
  'Prikaži samo projekte s ciljanim cijenama': {
    de: 'Nur Projekte mit Zielpreisen anzeigen',
    en: 'Show only projects with target prices',
  },
  'Poništi pretragu': {
    de: 'Suchen abbrechen',
    en: 'Cancel search',
  },
  'Unesite POTVRDA za slanje ponude': {
    de: 'Fügen Sie bitte das Wort BESTÄTIGUNG zum Bestätigung',
    en: 'Enter CONFIRMATION to send the offer',
  },
  POTVRDA: {
    de: 'BESTÄTIGUNG',
    en: 'CONFIRMATION',
  },
  'Datum kreiranja projekta': {
    de: 'Datum der Projekterstellung',
    en: 'Project Creation Date',
  },
  'Da biste prihvatili cijene upišite POTVRDA': {
    de: 'Um die Preise zu akzeptieren, einschreiben Sie die BESTÄTIGUNG',
    en: 'To accept prices, enter CONFIRMATION',
  },
  'Primljena narudžba': {
    de: 'Bestellt',
    en: 'Received Order',
  },
  'Da li ste sigurni da želite da objavite projekat? Upišite: POTVRDA': {
    de: 'Möchten Sie wirklich das Projekt veröffentlichen ?.   Einschreiben Sie bitte BESTÄTIGUNG',
    en: 'Are you sure you want to publish the project? Enter: CONFIRMATION',
  },
  'Obrađeni projekti': {
    de: 'Bearbeitete Projekte',
    en: 'Processed Projects',
  },
  Komentar: {
    de: 'Kommentar',
    en: 'Comment',
  },
  'Bitna napomena uz ponudu': {
    de: 'Ein wichtiger Hinweis mit dem Angebot',
    en: 'Important note with the offer',
  },
  'Dodaj novi projekat': {
    de: 'Neues Projekt hinzufügen',
    en: 'Add new project',
  },
  'Plaćanje i transport': {
    de: 'Zahlung und Lieferung',
    en: 'Payment and Delivery',
  },
  'Adresa isporuke': {
    de: 'Lieferadresse',
    en: 'Delivery Address',
  },
  'Način plaćanja': {
    de: 'Zahlungsart',
    en: 'Payment Method',
  },
  'Način isporuke': {
    de: 'Versandart',
    en: 'Delivery Method',
  },
  'Postotak poreza (npr. PDV)': {
    de: 'Steuer',
    en: 'Tax percentage (e.g. VAT)',
  },
  'Troškovi transporta': {
    de: 'Transportkosten',
    en: 'Transportation Costs',
  },
  'Vrijeme za plaćanje': {
    de: 'Zahlungszeit',
    en: 'Payment Time',
  },
  Valuta: {
    de: 'Währung',
    en: 'Currency',
  },
  'Prihvaćena cijena': {
    de: 'Akzeptierter Preis',
    en: 'Accepted Price',
  },
  Cijena: {
    de: 'Preis (Eur)',
    en: 'Price (EUR)',
  },
  'Metoda plaćanja': {
    de: 'Bezahlverfahren',
    en: 'Payment Method',
  },
  'Nema ponuda': {
    de: 'Keine Angebote',
    en: 'No offers',
  },
  'Primljene ponude': {
    de: 'Erhaltene Angebote',
    en: 'Received Offers',
  },
  'Nema podataka po zadanom upitu': {
    de: 'Das angeforderte Material wurde nicht gefunden',
    en: 'No data for the specified query',
  },
  Pretraga: {
    de: 'Suchen',
    en: 'Search',
  },
  'Odaberi sve iz kategorije': {
    de: 'Alles aus Kategorie auswählen',
    en: 'Select all from category',
  },
  'Brisanje projekta kao i deaktivacija može imati neželjene efekte. Ne brišite ukoliko niste sigurni šta radite. Čak i kad obrišete projekat podaci se neće ukloniti iz baze podataka, zbog povezanih stavki i nestabilnosti aplikacije uzrokovane tim postupkom':
    {
      de: 'Sowohl das Löschen als auch das Deaktivieren eines Projekts kann Nebenwirkungen haben. Löschen Sie nicht, wenn Sie sich nicht sicher sind, was Sie tun. Selbst wenn Sie ein Projekt löschen, werden die Daten aufgrund verwandter Elemente und der durch diesen Vorgang verursachten Anwendungsinstabilität nicht aus der Datenbank entfernt',
      en: 'Deleting a project as well as deactivating it can have unintended effects. Do not delete if you are not sure what you are doing. Even when you delete a project, the data will not be removed from the database due to related items and application instability caused by this process',
    },
  'Obriši projekat': {
    de: 'Projekt löschen',
    en: 'Delete Project',
  },
  'Br. narudžbe': {
    de: 'Bestellnummer',
    en: 'Order Number',
  },
  'Datum narudžbe': {
    de: 'Bestelldatum',
    en: 'Order Date',
  },
  'Isporučene pozicije': {
    de: 'Gelieferte Positionen',
    en: 'Delivered Positions',
  },
  'Ukupna cijena (Eur)': {
    de: 'Gesamtpreis (Eur)',
    en: 'Total Price (EUR)',
  },
  'Prikaži sve pozicije': {
    de: 'Alle Positionen anzeigen',
    en: 'Show all positions',
  },
  'Broj narudžbe': {
    de: 'Bestellnummer',
    en: 'Order Number',
  },
  'U tranzitu': {
    de: 'Im tranzit',
    en: 'In transit',
  },
  PDV: { de: 'Steuer', en: 'VAT' },
  'Ukupno s PDV-om': { de: 'Zusammen mit Steuer', en: 'Total with VAT' },
  'Ukupno bez PDV-a': { de: 'Zusammen ohne Steuer', en: 'Total without VAT' },
  'Troškovi isporuke': { de: 'Transportkosten', en: 'Delivery Costs' },
  'Pozicija naznačena kao dostavljena': {
    de: 'Position wie geliefert gezeigt',
    en: 'Position indicated as delivered',
  },
  'Ukupna vrijednost': {
    de: 'Gesamtwert',
    en: 'Total Value',
  },
  'Broj ponude': {
    de: 'Angebot nummer',
    en: 'Offer Number',
  },
  'Obriši fajl': {
    de: 'Datei löschen',
    en: 'Delete file',
  },
  Pošalji: {
    de: 'Nachricht Senden',
    en: 'Send',
  },
  Saradnici: {
    de: 'Benutzern',
    en: 'Collaborators',
  },
  'Morate unijeti broj narudžbe': {
    de: 'Sie müssen eine Bestellnummer eingeben',
    en: 'You must enter an order number',
  },
  'Cijene prihvaćene': {
    de: 'Preise akzeptiert',
    en: 'Prices accepted',
  },
  Naručeno: {
    de: 'Bestellt',
    en: 'Ordered',
  },
  'Morate dodati jpg file': {
    de: 'Es ist notwendig, das JPEG-Format hinzuzufügen',
    en: 'You must add a JPG file',
  },
  'Materijal i proizvodnja su obavezni': {
    de: 'Technologie müssen ausgewählt werden, um die Position zu erfassen',
    en: 'Material and production are mandatory',
  },
  'Dodaj poziciju': {
    de: 'Position hinzufügen',
    en: 'Add position',
  },
  'Upišite POTVRDA za brisanje projekta': {
    de: 'Schreiben Sie BESTÄTIGUNG ein, um das Projekt zu löschen',
    en: 'Enter CONFIRMATION to delete the project',
  },
  'Projekt uspješno obrisan': {
    de: 'Projekt erfolgreich gelöscht',
    en: 'Project successfully deleted',
  },
  'Projekt uspješno objavljen': {
    de: 'Das Projekt wurde erfolgreich auf dem Portal veröffentlicht',
    en: 'Project successfully published',
  },
  'Nemate poruka': {
    de: 'Keine Nachrichten',
    en: 'You have no messages',
  },
  'Pozicija uspješno dodana': {
    de: 'Produkt erfolgreich hinzugefügt',
    en: 'Position successfully added',
  },
  'Šifra kupca': {
    de: 'Kundencode',
    en: 'Customer code',
  },
  'Objavljeni projekti': {
    de: 'Veröffentlichte Projekte',
    en: 'Published projects',
  },
  'Broj poslatih narudžbi': {
    de: 'Anzahl der gesendeten Bestellungen',
    en: 'Number of sent orders',
  },
  'Vrijednost poslatih narudžbi': {
    de: 'Wert der gesendeten Bestellungen',
    en: 'Value of sent orders',
  },
  'Vrijednost poslovne realizacije': {
    de: 'Der Wert der Geschäftsrealisierung',
    en: 'Business realization value',
  },
  'Vrijednost poslatih ponuda': {
    de: 'Wert der gesendeten Angebote',
    en: 'Value of sent offers',
  },
  'Vrijednost neisporučene robe': {
    de: 'Wert der nicht gelieferten Waren',
    en: 'Value of undelivered goods',
  },
  'Ukupan broj projekata': {
    de: 'Gesamtzahl der Projekte',
    en: 'Total number of projects',
  },
  'Broj aktivnih projekata': {
    de: 'Anzahl aktiver Projekte',
    en: 'Number of active projects',
  },
  'Broj poslatih ponuda': {
    de: 'Anzahl der abgegebenen Gebote',
    en: 'Number of sent offers',
  },
  'Broj primljenih narudzbi': {
    de: 'Anzahl der gesendeten Bestellungen',
    en: 'Number of received orders',
  },
  'Neodgovoreni upiti': {
    de: 'Unbeantwortete Anfragen',
    en: 'Unanswered inquiries',
  },
  'Najnoviji projekti': {
    de: 'Neueste Projekte',
    en: 'Latest projects',
  },
  'Nema dodanih mašina': {
    de: 'Keine Maschinen hinzugefügt',
    en: 'No machines added',
  },
  'Nema dodanih dokumenata': {
    de: 'Keine Dokumente hinzugefügt',
    en: 'No documents added',
  },
  'Vrijednost (€)': {
    de: 'Wert (€)',
    en: 'Value (€)',
  },
  Reference: {
    de: 'Referenz',
    en: 'Reference',
  },
  Kontakt: {
    de: 'Kontakt',
    en: 'Contact',
  },
  'Opšte informacije': {
    de: 'Allgemeine Informationen',
    en: 'General information',
  },
  Dokumenti: {
    de: 'Unterlagen',
    en: 'Documents',
  },
  Galerija: {
    de: 'Galerie',
    en: 'Gallery',
  },
  Mašine: {
    de: 'Maschinen',
    en: 'Machines',
  },
  'Postavke projekta uspješno spremljene': {
    de: 'Projekteinstellungen erfolgreich gespeichert',
    en: 'Project settings successfully saved',
  },
  'Rok za slanje ponuda pomjeren': {
    de: 'Angebotfrist erfolgreich geändert',
    en: 'Offer deadline successfully changed',
  },
  'Ukupna vrijednost projekata': {
    de: 'Gesamtwert des Projekts',
    en: 'Total value of projects',
  },
  'Odaberi glavnu proizvodnju': {
    de: 'Wählen Sie die Hauptproduktion aus',
    en: 'Select main production',
  },
  'Izmjene na poziciji uspješno sačuvane': {
    de: 'Positionsänderungen erfolgreich gespeichert',
    en: 'Changes to position successfully saved',
  },
  'Ovdje možete vidjeti napredak isporuke i izrade vaše narudžbe': {
    de: 'Hier können Sie den Fortschritt der Lieferung und Erstellung Ihrer Bestellung verfolgen',
    en: 'Here you can see the progress of delivery and production of your order',
  },
  'Nalog za izradu komponenti poslan dobavljaču': {
    de: 'Komponentenbauauftrag an Lieferanten gesendet',
    en: 'Component manufacturing order sent to supplier',
  },
  'U procesu isporuke': {
    de: 'Im Lieferprozess',
    en: 'In delivery process',
  },
  'Pošiljka je u procesu izrade i pripreme za isporuku': {
    de: 'Die Sendung wird gerade erstellt und für die Lieferung vorbereitet',
    en: 'The shipment is in the process of production and preparation for delivery',
  },
  'Potvrđena isporuka': {
    de: 'Lieferung bestätigt',
    en: 'Delivery confirmed',
  },
  'Pošiljka je u tranzitu': {
    de: 'Die Sendung ist unterwegs',
    en: 'The shipment is in transit',
  },
  'Roba primljena': {
    de: 'Güter erhalten',
    en: 'Goods received',
  },
  'Roba je naznačena kao primljena': {
    de: 'Die Ware wird als erhalten gekennzeichnet',
    en: 'Goods indicated as received',
  },
  'Potvrdi isporuku': {
    de: 'Lieferung bestätigen',
    en: 'Confirm delivery',
  },
  'Termička obrada': {
    de: 'Thermische Behandlung',
    en: 'Heat treatment',
  },
  'Površinska zaštita': {
    de: 'Oberflächenschutz',
    en: 'Surface protection',
  },
  'Završna obraca': {
    de: 'Feine Verarbeitung',
    en: 'Finishing processing',
  },
  'Označavanje komada': {
    de: 'Markierung von Teilen',
    en: 'Piece marking',
  },
  'Saradnici unutar firme': {
    de: 'Mitarbeiter im Unternehmen',
    en: 'In-house collaborators',
  },
  'Naziv korisnika': {
    de: 'Nutzername',
    en: 'Username',
  },
  'bez ciljane cijene': {
    de: 'ohne Ziel Preis',
    en: 'without target price',
  },
  'Odaberi materijal': {
    de: 'Material auswählen',
    en: 'Select material',
  },
  Uloga: {
    de: 'Rolle',
    en: 'Role',
  },
  'Dodatne proizvodnje': {
    de: 'Zusätzliche Produktion',
    en: 'Additional production',
  },
  'Dodatne karakteristike': {
    de: 'Zusätzliche Eigenschaften',
    en: 'Additional features',
  },
  'Pošalji projekt na email nakon objave': {
    de: 'Projekt nach der Veröffentlichung per E-Mail senden',
    en: 'Send project to email after publication',
  },
  'Ostale postavke': {
    de: 'Andere Einstellungen',
    en: 'Other settings',
  },
  'Zahtjev za izmjenu lozinke': {
    de: 'Anforderung zur Passwortänderung',
    en: 'Request for password change',
  },
  'Nova lozinka': {
    de: 'Neues Passwort',
    en: 'New password',
  },
  'Ponovite lozinku': {
    de: 'Passwort wiederholen',
    en: 'Repeat password',
  },
  'Pošalji zahtjev za izmjenu lozinke': {
    de: 'Anforderung zur Passwortänderung senden',
    en: 'Send request for password change',
  },
  'Lozinku ćete dobiti na email nakon što administrator potvrdi. Nemojte pokušavati slati više od 1 zahtjeva, ukoliko ne dobijete email najkasnije u roku od 48h kontaktirajte administratora':
    {
      de: 'Das Passwort wird Ihnen per E-Mail zugesandt, nachdem der Administrator bestätigt hat. Versuchen Sie nicht, mehr als 1 Anfrage zu senden. Wenn Sie innerhalb von 48 Stunden keine E-Mail erhalten, wenden Sie sich an den Administrator',
      en: 'You will receive the password by email after the administrator confirms. Do not try to send more than 1 request, if you do not receive an email within 48 hours, contact the administrator',
    },
  'Tehničke kvalifikacije firme': {
    de: 'Technische Qualifikationen des Unternehmens',
    en: 'Technical qualifications of the company',
  },
  'Prihvaćene ponude (30 dana)': {
    de: 'Akzeptierte Angebote (30 Tage)',
    en: 'Accepted offers (30 days)',
  },
  'Vrijednost narudžbi (30 dana)': {
    de: 'Wert der Bestellungen (30 Tage)',
    en: 'Value of orders (30 days)',
  },
  'Isporučene narudžbe (30 dana)': {
    de: 'Gelieferte Bestellungen (30 Tage)',
    en: 'Delivered orders (30 days)',
  },
};

export default strings;
