import dayjs from 'dayjs';
import 'dayjs/locale/hr';
// import Pill from "../elements/Pill/Pill.component";
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useContext } from 'react';
import { BiCurrentLocation, BiHourglass, BiLockAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import usePlaceholder from '../../hooks/usePlaceholder';
import useTranslation from '../../hooks/useTranslation';
import { pastDue } from '../../util/util';
import { currentUserContext } from '../Auth/currentUserContext/currentUser.context';
import Bar from '../Bar/Bar';
import Distributor from '../structure/wrappers/Distributor/Distributor.component';

dayjs.extend(relativeTime);

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 0.5rem 0 0.5rem 0.5rem;
  height: 100%;
  > img {
    height: 180px;
    object-fit: cover;
  }
`;

const Info = styled.div`
  padding: 1rem;
  ${({ main }) => main && `flex: 1`};
`;

const Header = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Id = styled.div`
  font-size: 0.7rem;
  background: ${({ theme }) => theme.colors.menuHighlight};
  padding: 0 0.3rem 0 0;
  border-radius: 2px;
  overflow: hidden;
  > span {
    background: #464647;
    color: #fff;
    font-weight: 600;
    margin-right: 0.3rem;
    padding: 0 0.3rem;
  }
`;
const Footer = styled.div`
  /*border-top: 1px solid ${({ theme }) => theme.colors.sidebarBorder};*/
`;
const Ends = styled.div`
  display: flex;
  > span {
    font-weight: 700;
  }
`;

const Section = styled.div`
  display: flex;
  flex: 1;
  ${({ main }) => main && `flex: 3`}
`;

const SectionWrapper = styled.div`
  display: flex;
  flex: 1;
  > ${Section} {
    border-right: 1px solid ${({ theme }) => theme.colors.menuHighlight};
  }
`;

const Wrapper = styled(Link)`
  text-decoration: none;
  border-radius: 3px;
  box-shadow: ${({ theme }) => theme.shadow.depth.projectItem};
  border: 2px solid transparent;
  display: flex;
  justify-content: space-between;
  ${({ urgent, theme }) =>
    urgent &&
    `
    border-color: ${theme.colors.lime};
    background-color: ${theme.colors.limeLight};
  `}
  :hover {
    border-color: ${({ theme }) => theme.colors.accent};
  }
  &.grid {
    width: 250px;
  }
  &.list {
    display: flex;
    ${Image} {
      width: 200px;
      object-fit: cover;
      display: flex;
      align-items: center;
    }
    ${Info} {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    ${Footer} {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 200px;
    }
    ${Ends} {
      display: flex;
      justify-content: flex-start;
      > span {
        margin-left: 0.5rem;
      }
    }
    margin-bottom: 1rem;
  }
`;

const Item = styled.div`
  text-transform: uppercase;
  font-size: 0.6rem;
  margin-top: 0.5rem;
  > span {
    display: block;
    font-weight: 600;
  }
`;

const InfoItem = styled.div`
  ${({ alignRight }) =>
    alignRight &&
    `
  > * {
    text-align: right;
  }
`};
  ${({ large }) =>
    large &&
    `
    > ${Value} {
      font-size: 1.2rem;
      font-weight: 600;
    }
  `};
  ${({ bold }) =>
    bold &&
    `
    > ${Value} {
      font-weight: bold;
    }
  `};
`;
const Title = styled.div`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.darkerGrey};
`;
const Value = styled.div`
  ${({ price, theme }) =>
    price &&
    `background: ${theme.colors.accent};
      color: #fff;
      display: inline;
      padding: 0 0.2rem;
      font-weight: bold;
    `};
`;

const Indicators = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  display: flex;
`;
const Indicator = styled.div`
  background: ${({ theme }) => theme.colors.highlight};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.accent};
  border-radius: 5px;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  box-shadow: 2px 2px 0px 0px rgb(0 0 0 / 15%);
  border: 1px solid rgb(0 0 0 / 9%);
  ${({ color, theme }) => {
    if (color === 'secondary') {
      return `
    color: ${theme.colors.secondary};
    background: ${theme.colors.secondaryHighlight};
  `;
    }

    if (color === 'terciary') {
      return `
      color: ${theme.colors.white};
    background: ${theme.colors.black};      
      `;
    }
  }}
`;

// TODO: Lazy load the images

// const ProjectStatus = ({ date }) => {
//   if (pastDue(date) <= 0) return <Pill layout="fail">Istekao upit</Pill>;
//   if (pastDue(date) > 0 && pastDue(date) < 72)
//     return <Pill layout="warning">Uskoro ističe</Pill>;
//   return null;
// };

const ProjectItem = ({ data, layout }) => {
  const { t } = useTranslation();
  const {
    user: { user },
  } = useContext(currentUserContext);

  let imageFile = data?.positions[0]?.files.jpg;

  if (user && ['ADMINISTRATOR', 'MODERATOR'].includes(user?.role)) {
    imageFile = data?.positions[0]?.files.jpg
      ? data?.positions[0]?.files.jpg
      : data?.positions[0]?.revisedInformations?.files?.jpg;
  }

  if (user && user?.role === 'MANUFACTURER') {
    imageFile = data?.positions[0]?.revisedInformations?.files?.jpg
      ? data?.positions[0]?.revisedInformations?.files?.jpg
      : data?.positions[0]?.files.jpg;
  }
  const [image] = usePlaceholder(imageFile);

  const totalQuantity = data.positions.reduce((acc, curr) => {
    return (
      Math.max(
        ...curr.quantities.map((val) => {
          return val.quantity || 0;
        })
      ) + acc
    );
  }, 0);

  const totalProjectValue = data.positions.reduce((acc, curr) => {
    return (
      Math.max(
        ...curr.quantities.map((val) => {
          if (user?.role === 'BUYER') {
            return val?.aimedPrice ? val?.aimedPrice * val?.quantity : 0;
          } else {
            /* UNCLEAR: this is not tested. It should show admin aimed price total to admin
                        if present. Otherwise, it should show buyers aimed price total
            */
            if (val?.administratorAimedPrice) {
              return val?.administratorAimedPrice
                ? val?.administratorAimedPrice * val?.quantity
                : 0;
            } else {
              return val?.aimedPrice ? val?.aimedPrice * val?.quantity : 0;
            }
          }
        })
      ) + acc
    );
  }, 0);

  return (
    <Wrapper
      to={{
        pathname: `/projects/${data.id}/details`,
        state: { positionIds: data?.positions.map((p) => p._id) },
      }}
      className={layout}
      urgent={data?.urgent ? 'true' : undefined}
    >
      <SectionWrapper>
        <Section main>
          <Image>
            <Indicators>
              {data?.dateExtendedDeadline && pastDue(data.offerDeadline) > 0 ? (
                <Indicator title="Rok za ponudu produžen" color="terciary">
                  <BiHourglass size="20px" />
                </Indicator>
              ) : null}
              {data?.orders?.accepted?.byBuyer ||
              pastDue(data.offerDeadline) < 0 ? (
                <Indicator title="Zaključan projekt">
                  <BiLockAlt size="20px" />
                </Indicator>
              ) : null}
              {data?.hasOffered || data?.status?.OFFER_SENT_TO_BUYER ? (
                <Indicator color="secondary" title="Poslata ponuda">
                  <BiCurrentLocation size="20px" />
                </Indicator>
              ) : null}
            </Indicators>
            {/* {<ProjectStatus date={data.offerDeadline} />} */}
            {image}
          </Image>
          <Info main>
            <Header>
              <Id>
                <span>RFQ</span>
                {data.id.toUpperCase()}
              </Id>
              <Distributor col="1-1" stack="end">
                {['ADMINISTRATOR', 'MODERATOR'].includes(user?.role) ? (
                  <InfoItem alignRight>
                    <Title>{t('Naziv kupca')}</Title>
                    <Value>{data?.createdBy?.company?.name}</Value>
                  </InfoItem>
                ) : null}
                <InfoItem alignRight>
                  <Title>{t('Šifra kupca')}</Title>
                  <Value>{data?.createdBy?.company?.initials}</Value>
                </InfoItem>
              </Distributor>
            </Header>
            <Distributor col="1-1">
              <InfoItem large>
                <Title>{t('Naziv projekta')}</Title>
                <Value>{data.name}</Value>
              </InfoItem>
              <InfoItem large>
                <Title>{t('Šifra projekta')}</Title>
                <Value>{data?.projectCode}</Value>
              </InfoItem>
            </Distributor>
            <InfoItem>
              <Title>{t('Kratak opis / Napomena')}</Title>
              <Value>{data?.note ? data.note.slice(0, 60) + '...' : '-'}</Value>
            </InfoItem>
          </Info>
        </Section>
        <Section>
          <Info>
            <InfoItem>
              <Title>{t('Broj pozicija')}</Title>
              <Value>{data.positions.length}</Value>
            </InfoItem>
            <InfoItem>
              <Title>{t('Ukupna količina')}</Title>
              <Value>{`${totalQuantity} ${t('kom')}.`}</Value>
            </InfoItem>
            {totalProjectValue ? (
              <InfoItem>
                <Title>{t('Ciljana cijena projekta')}</Title>
                <Value price>{`${totalProjectValue?.toLocaleString('de', {
                  minimumFractionDigits: 2,
                })} EUR`}</Value>
              </InfoItem>
            ) : null}
          </Info>
        </Section>
        <Section>
          <Info>
            <InfoItem>
              <Title>{t('Rok za ponudu')}</Title>
              <Value>{dayjs(data.offerDeadline).format('DD.MM.YYYY')}</Value>
            </InfoItem>
            <InfoItem>
              <Title>{t('Rok za isporuku')}</Title>
              <Value>{dayjs(data.deliveryDeadline).format('DD.MM.YYYY')}</Value>
            </InfoItem>
            <InfoItem>
              <Title>{t('Objavljen')}</Title>
              <Value>{dayjs(data.published.date).from().toString()}</Value>
            </InfoItem>
          </Info>
        </Section>
      </SectionWrapper>
      <Footer>
        <Info>
          {pastDue(data.offerDeadline) >= 0 &&
          !data?.orders?.manufacturer?.recievedOrder ? (
            <React.Fragment>
              <Title>{t('Status')}</Title>
              <Value>
                {t('Ističe')} {dayjs().to(dayjs(data.offerDeadline)).toString()}
              </Value>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Title>{t('Status')}</Title>
              <Value>{t('Istekao upit')}</Value>
            </React.Fragment>
          )}
          {data.dateExtendedDeadline ? (
            <div>
              <Title>{t('Produžen')}</Title>
              <Value>
                {dayjs(data.dateExtendedDeadline).format('DD.MM.YYYY')}
              </Value>
            </div>
          ) : null}
        </Info>
        <Info>
          <Item>
            <span>{t('Kompleksnost')}</span>
            <Bar
              max="5"
              length={data.positions[0]?.complexityLevel}
              color="#e1e1e1"
            />
          </Item>
          <Item>
            <span>{t('Tolerancija')}</span>
            <Bar
              max="5"
              length={data.positions[0]?.toleranceLevel}
              color="#e1e1e1"
            />
          </Item>
        </Info>
      </Footer>
    </Wrapper>
  );
};

export default ProjectItem;
