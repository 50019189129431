import gql from "graphql-tag";

const DELIVERED_POSITION = gql`
  mutation deliveredPosition(
    $orderId: ID!
    $positionId: ID!
    $quantity: Int!
    $date: Date!
  ) {
    deliveredPosition(
      orderId: $orderId
      positionId: $positionId
      quantity: $quantity
      date: $date
    )
  }
`;

export default DELIVERED_POSITION;
