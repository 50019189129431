import { useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { BsFileEarmarkPlus, BsImageFill } from 'react-icons/bs';
import { FiMessageSquare, FiSearch } from 'react-icons/fi';
import { FaEyeSlash } from 'react-icons/fa';
import { IoMdFlag } from 'react-icons/io';
import { MdSend } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Preview } from 'src/pages/Projects/tabsContent/PositionDetails';
import styled from 'styled-components';
import CREATE_MESSAGE from '../../apollo/mutations/createMessage';
import MARK_READ_CONVERSATION from '../../apollo/mutations/markReadConversation';
import GET_USER_CONVERSATIONS from '../../apollo/queries/getUserConversations';
import useTranslation from '../../hooks/useTranslation';
import { parseFileTag } from '../../util/tags';
import { currentUserContext } from '../Auth/currentUserContext/currentUser.context';
import Button from '../elements/Button/Button.component';
import Input from '../elements/Input/Input.component';
import Spinner from '../elements/Spinner/Spinner.component';
import Textarea from '../elements/Textarea/Textarea.component';
import { ModalContext } from '../structure/Modal/modalContext';
import Container from '../structure/wrappers/Container/Container.component';
import Main from '../structure/wrappers/Main/Main.component';
import SendFile from './SendFile';
import IconButton from '../elements/IconButton/IconButton.component';
import Distributor from '../structure/wrappers/Distributor/Distributor.component';
import MARK_UNREAD_CONVERSATION from 'src/apollo/mutations/markUnreadConversation';
import TOGGLE_CONVERSATION_PRIORITY from 'src/apollo/mutations/toggleConversationPriority';

const Wrapper = styled.div`
  height: calc(100vh - 70px);
  display: flex;
`;

const ContenxtBox = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  > div {
    flex: 1;
  }
`;

const Search = styled.div`
  padding: 0.5rem;
  display: flex;
  > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopInfo = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 7%);s
`;

const OtherInfo = styled.div``;

const ConversationContent = styled.div`
  padding: 3.5rem 3.5rem 2rem 3rem;
  flex: 1;
  overflow-y: scroll;
`;

const MessageReply = styled.div`
  padding: 0.3rem;
  border-top: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Occured = styled.div`
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  top: -18px;
`;

const Avatar = styled.div`
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 0;
  position: absolute;
  font-weight: 600;
`;

const Name = styled.div`
  font-weight: 700;
  display: flex;
  position: relative;
  align-items: center;
`;

const Sender = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0.5rem 0;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Message = styled.div`
  margin-left: 60px;
  margin-bottom: 40px;
  ${({ theme, myown }) =>
    myown
      ? `
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 60px;
      ${Occured} {
        right: 0;
      }
      ${Sender} {
        display: inline;
      }
      ${Avatar} {
        background: ${theme.colors.accent};
        right: -3.5rem;
      }
  `
      : `
      ${Avatar}{
        background: ${theme.colors.secondary};
          left: -3.5rem;
      }
      `};
  :hover {
    cursor: pointer;
    ${Occured} {
      opacity: 1;
    }
  }
`;

const MessageContent = styled.div`
  line-height: 1.8;
  background: linear-gradient(
    0,
    ${({ theme }) => theme.colors.chatMessageBackground},
    transparent
  );
  padding: 0.8rem 1.3rem;
  border-radius: 10px;
  display: inline-block;
  max-width: 45%;
  white-space: pre-wrap;
  a {
    text-decoration: none;
    background: ${({ theme }) => theme.colors.black};
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 2rem;
    padding: 0.3rem 1rem;
    display: flex;
    align-items: center;
    & svg {
      margin-right: 0.5rem;
    }
  }
`;

const ConversationList = styled.div`
  width: 350px;
  border-right: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  height: 100%;
  overflow: hidden;
`;

const Item = styled.div`
  padding: 1rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.chatMessageBackground};
  }
  ${({ unread }) => unread && `font-weight: 700;`}
  &.activeConversation {
    background: ${({ theme }) => theme.colors.chatMessageBackground};
  }
`;

const OtherInfoHeading = styled(NavLink)`
  font-weight: 600;
  font-size: 1.2rem;
  display: block;
`;

const Title = styled.div`
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Bottom = styled.div``;

const SenderName = styled.div`
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: capitalize;
`;

const Rfq = styled.div`
  text-transform: uppercase;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  span {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const Excerpt = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.5rem;
`;
const ExcerptWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.accent};
    transform: scaleX(-1);
  }
`;
const ExcerptContainer = styled.div``;

const PositionWrapper = styled.div`
  margin-bottom: 1rem;
  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    svg {
      margin-right: 0.5rem;
    }
  }
`;

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ConversationListWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const TopConversationInfo = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.sidebarBorder};
  }
`;
const UnreadConversation = styled.div`
  svg:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const TciContainer = styled.div`
  display: flex;
  align-items: center;
  .priority__1 {
    &.active {
      color: ${({ theme }) => theme.colors.green};
    }
    :hover {
      color: ${({ theme }) => theme.colors.green};
    }
  }
  .priority__2 {
    &.active {
      color: ${({ theme }) => theme.colors.yellowish};
    }
    :hover {
      color: ${({ theme }) => theme.colors.yellowish};
    }
  }
  .priority__3 {
    &.active {
      color: ${({ theme }) => theme.colors.accent};
    }
    :hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

const Conversation = () => {
  const [priorities, setPriorities] = useState([]);
  const [showPositionImage, setShowPositionImage] = useState(true);
  const { t } = useTranslation();
  const messagesEnd = useRef(null);
  const [activeConversation, setActiveConversation] = useState(null);
  const [message, setMessage] = useState('');
  const [receiver, setReceiver] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [filter, setFilter] = useState('');
  const [filterString, setFilterString] = useState('');

  const [toggleConversationPriorityMutation] = useMutation(
    TOGGLE_CONVERSATION_PRIORITY,
    {
      onError: () =>
        toast.error(
          'Nemoguće promijeniti prioritet. Kontaktirajte administratora'
        ),
    }
  );

  const [createMessageMutation] = useMutation(CREATE_MESSAGE, {
    onError: () => {},
    onCompleted: () => setMessage(''),
    refetchQueries: ['getUserConversations'],
  });

  const {
    user: { user },
  } = useContext(currentUserContext);

  const [markAsRead] = useMutation(MARK_READ_CONVERSATION, {
    /* TODO: If appears expensive, modify fetch policy */
    onError: () => {},
  });

  const [markAsUnread] = useMutation(MARK_UNREAD_CONVERSATION, {
    /* TODO: If appears expensive, modify fetch policy */
    onError: () =>
      toast.error(
        'Nemoguće označiti poruku kao nepročitanu. Kontaktirajte administratora'
      ),
  });

  const toggleConversationPriority = async (conversationId, priority) => {
    await toggleConversationPriorityMutation({
      variables: { conversationId, priority },
    });
    setPriorities((prev) => {
      const newPriorities = prev.map((p) => {
        if (p.conversationId === conversationId) {
          return { ...p, priority };
        }
        return p;
      });
      return newPriorities;
    });
  };

  const { handleModal, data: file } = useContext(ModalContext);

  const [getConversations, { loading, data }] = useLazyQuery(
    GET_USER_CONVERSATIONS
  );

  // apply priorities so we can optimistically change it without having to wait for the server
  useEffect(() => {
    if (data) {
      const prioritiesData = data?.getUserConversations?.map((c) => ({
        conversationId: c._id,
        priority: c.priority,
      }));

      setPriorities(prioritiesData);
    }
  }, [data]);

  useEffect(() => {
    getConversations({ variables: { filter: filterString } });
    if (data) {
      setConversations(data?.getUserConversations);
    }
  }, [data, filterString]);

  const scrollToBottom = () => {
    messagesEnd?.current?.scrollIntoView({ behaviour: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [activeConversation]);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const createInitials = (name) => {
    const split = name.split(' ');
    return split.length > 1
      ? split
          .map((s) => s[0]?.toUpperCase())
          .join('')
          .slice(0, 2)
      : name.slice(0, 2).toUpperCase();
  };

  const handleSendMessage = () => {
    if (!receiver || !message || !activeConversation._id)
      return toast.error('Nema primaoca ili poruke');
    const values = {
      conversationId: activeConversation._id,
      receiver,
      message,
    };

    createMessageMutation({
      variables: {
        ...values,
        otherInfo: {
          projectName: activeConversation?.project?.name,
          projectCode: activeConversation?.project?.id,
          positionName: activeConversation?.position?.name,
          positionCode: activeConversation?.position?.positionCode,
        },
      },
    });
  };

  /* file sending */
  useEffect(() => {
    /* if there is a file send it as a message */
    if (file.length) {
      createMessageMutation({
        variables: {
          conversationId: activeConversation?._id,
          receiver,
          message: `[file](${file})`,
          otherInfo: {
            projectName: activeConversation?.project?.name,
            projectCode: activeConversation?.project?.id,
            positionName: activeConversation?.position?.name,
            positionCode: activeConversation?.position?.positionCode,
          },
        },
      });
    }
  }, [file]);

  useEffect(() => {
    if (conversations?.length && user?.id) {
      handleReadConversation(null, conversations[0]._id);
    }
  }, [conversations, user?.id]);

  const handleChangeFilter = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  const handleSearch = () => {
    setFilterString(filter);
  };

  const handleUnreadConversation = (e, conversationId) => {
    markAsUnread({
      variables: { conversationId },
      refetchQueries: ['getUserConversations'],
    });
  };

  const handleReadConversation = (e, conversationId, isRead) => {
    if (!isRead) {
      markAsRead({
        variables: { conversationId },
        refetchQueries: ['getUserConversations'],
      });
      if (e) {
        markAsRead({ variables: { conversationId } });
        e.target.style.fontWeight = 'normal';
      }
    }
    const active = conversations?.find((c) => c._id === conversationId);

    /* set receiver */
    const messageIndex = active.messages.findIndex(
      (message) => message.sender.id !== user.id
    );
    const receiverId = active?.messages[messageIndex]?.sender?.id;
    if (receiverId) setReceiver(receiverId);

    setActiveConversation(active);
  };

  return loading ? (
    <Spinner />
  ) : conversations.length ? (
    <Wrapper>
      <ConversationList>
        <Search>
          <Input
            placeholder="Pretraga..."
            onChange={handleChangeFilter}
            value={filter}
            width="100%"
          />
          <Button onClick={handleSearch} loading={loading}>
            <FiSearch size="20px" />
          </Button>
        </Search>
        <ConversationListWrapper>
          {conversations?.map(
            (conversation) =>
              conversation?.project?.id && (
                <Item
                  unread={!conversation?.read}
                  key={conversation._id}
                  onClick={(e) =>
                    handleReadConversation(
                      e,
                      conversation._id,
                      conversation?.read
                    )
                  }
                  className={
                    activeConversation &&
                    conversation._id === activeConversation._id &&
                    'activeConversation'
                  }
                >
                  <TopConversationInfo>
                    <TciContainer>
                      <UnreadConversation
                        onClick={(e) =>
                          handleUnreadConversation(e, conversation._id)
                        }
                      >
                        <FaEyeSlash />
                      </UnreadConversation>
                      <Rfq>
                        <span>{conversation?.project?.id}</span>
                      </Rfq>
                    </TciContainer>
                    <TciContainer>
                      {[1, 2, 3].map((i) => (
                        <IoMdFlag
                          onClick={() =>
                            toggleConversationPriority(conversation._id, i)
                          }
                          key={i}
                          className={`priority__${i} ${
                            priorities?.find(
                              (p) => p.conversationId === conversation._id
                            )?.priority === i
                              ? `active`
                              : null
                          }`}
                        />
                      ))}
                    </TciContainer>
                  </TopConversationInfo>
                  <Title>{conversation?.project?.name}</Title>
                  <PositionWrapper>
                    <span>{conversation?.position?.name}</span>
                    <span>{conversation?.position?.positionCode}</span>
                  </PositionWrapper>
                  <ExcerptWrapper>
                    <FiMessageSquare size="20px" />
                    <ExcerptContainer>
                      <SenderName>
                        {conversation.messages[0].sender.name}
                      </SenderName>
                      <SenderName>
                        {conversation.messages[0]?.sender?.company?.name}
                      </SenderName>
                      <Excerpt>
                        <span>{conversation.messages[0].message}</span>
                      </Excerpt>
                    </ExcerptContainer>
                  </ExcerptWrapper>
                </Item>
              )
          )}
        </ConversationListWrapper>
      </ConversationList>
      {activeConversation && (
        <Content>
          <TopInfo>
            <OtherInfo>
              <OtherInfoHeading
                to={`/projects/${activeConversation.project.id}/details`}
              >
                {activeConversation.project.name}
              </OtherInfoHeading>
              <span>{`${dayjs(
                activeConversation.dateCreated
              ).fromNow()} / ${dayjs(activeConversation.dateCreated).format(
                'DD.MM.YYYY @ HH:mm'
              )}`}</span>
            </OtherInfo>
            <Distributor stack align="center">
              <OtherInfo>
                <OtherInfoHeading
                  to={`/projects/${activeConversation.project.id}/details/${activeConversation.position.id}`}
                >
                  {activeConversation.position.name}
                </OtherInfoHeading>
                <div>{activeConversation.position.positionCode}</div>
              </OtherInfo>
              <IconButton
                to="#"
                onClick={() => setShowPositionImage(!showPositionImage)}
              >
                <BsImageFill size="25px" />
              </IconButton>
            </Distributor>
          </TopInfo>
          <ContenxtBox>
            <ChatWrapper>
              <ConversationContent>
                {activeConversation.messages.map((c) => (
                  <Message key={c._id} myown={c.sender.id === user.id}>
                    <Sender>
                      <Name>
                        <Avatar>{createInitials(c.sender.name)}</Avatar>
                        <span>{c.sender.name}</span>
                      </Name>
                      <Occured>{dayjs(c.timestamp).fromNow()}</Occured>
                    </Sender>
                    <MessageContent>{parseFileTag(c.message)}</MessageContent>
                  </Message>
                ))}
                <Bottom ref={messagesEnd} />
              </ConversationContent>
              <MessageReply>
                <Textarea
                  name="message"
                  transparent="true"
                  width="100%"
                  onChange={handleChange}
                  value={message}
                  rows={1}
                />
                <ButtonContainer>
                  <Button
                    layout="hollow"
                    onClick={() => handleModal(<SendFile />)}
                  >
                    <span>File</span>
                    <BsFileEarmarkPlus size="20px" />
                  </Button>
                  <Button onClick={handleSendMessage}>
                    <span>{t('Pošalji')}</span>
                    <MdSend />
                  </Button>
                </ButtonContainer>
              </MessageReply>
            </ChatWrapper>
            {showPositionImage ? (
              <Preview>
                <img
                  src={
                    user?.role === 'MANUFACTURER'
                      ? activeConversation.position?.revisedInformations?.files
                          ?.jpg
                        ? process.env.REACT_APP_S3_BUCKET +
                          activeConversation.position?.revisedInformations
                            ?.files?.jpg
                        : process.env.REACT_APP_S3_BUCKET +
                          activeConversation.position?.files?.jpg
                      : process.env.REACT_APP_S3_BUCKET +
                        activeConversation.position?.files?.jpg
                  }
                  alt="Position preview"
                />
              </Preview>
            ) : null}
          </ContenxtBox>
        </Content>
      )}
    </Wrapper>
  ) : (
    <Container>
      <Main>
        <p>{t('Nemate poruka')}.</p>
      </Main>
    </Container>
  );
};

export default Conversation;
