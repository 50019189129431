import React from "react";
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/elements/BasicTable/BasicTable.component";

const Manufacturing = ({ data }) => {
  return (
    <BasicTable layout="auto">
      <TableHead>
        <TableRow>
          <TableHeader>Naziv proizvodnje</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((manufacturing) => (
          <TableRow key={manufacturing.id}>
            <TableCell>{manufacturing.name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </BasicTable>
  );
};

export default Manufacturing;
