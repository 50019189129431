import { useMutation } from '@apollo/client';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import REMOVE_FILE from 'src/apollo/mutations/removeFile';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import SIGN_S3 from '../../../apollo/mutations/signS3';
import { MAX_UPLOAD_FILE_SIZE_MB } from '../../../constants';
import { formatFilename } from '../../../util/util';
import Button from '../Button/Button.component';
import Input from '../Input/Input.component';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`;

const Progress = styled.div`
  position: absolute;
  background: ${({ theme, progress, uploading }) =>
    progress < 100 && uploading ? theme.colors.accent : theme.colors.green};
  width: ${({ progress }) => `${progress}%`};
  height: 5px;
  border-radius: 3px;
  z-index: 1;
  bottom: 0;
  left: 0;
`;
const InputWrapper = styled.div`
  flex: 1;
  position: relative;
  input {
    opacity: 1;
    border: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
    background: ${({ theme }) => theme.colors.white};
  }
`;
const UploadControls = styled.div`
  display: flex;
  flex: 1;
  button {
    margin-left: 0.5rem;
  }
`;

const RemoveFile = styled.div`
  background: ${({ theme }) => theme.colors.accent}!important;
  :hover {
    cursor: pointer;
  }
`;

const File = styled.div`
  background: ${({ theme }) => theme.colors.menuHighlight};
  border-radius: 3px;
  padding: 0 0.4rem;
`;
const Current = styled.div`
  padding: 0.7rem;
  font-size: 0.8rem;
  display: flex;
  > span {
    margin-right: 0.8rem;
    font-weight: 600;
  }
  > a,
  ${RemoveFile} {
    text-decoration: none;
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 3px;
    margin-left: 0.5rem;
    padding: 0 0.4rem;
  }
`;

const UploadFile = ({
  directory,
  onSuccess,
  onDelete,
  maxMB,
  name,
  current,
  acceptedformats,
  noReplace,
  disabled,
  removeHandler,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [fileObject, setFileObject] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [removeFileMutation] = useMutation(REMOVE_FILE, {
    onError: () => {},
  });

  const ref = useRef();

  useEffect(() => {
    setCurrentFile(current);
  }, [current]);

  const [signS3Mutation, { loading, error }] = useMutation(SIGN_S3, {
    onCompleted: async ({ signS3 }) => {
      setUploading(true);
      setProgress(30);
      const options = {
        onUploadProgress: ({ loaded, total }) => {
          let percentCompleted = Math.round((loaded / total) * 100);
          setProgress(percentCompleted);
        },
        headers: {
          'Content-Type': fileObject.type,
        },
      };
      try {
        await axios.put(signS3.signedRequest, fileObject, options);
        setUploading(false);
        ref.current.value = '';
        setCurrentFile(fileUploaded);
        onSuccess && onSuccess(fileUploaded);
      } catch (e) {
        console.log(e);
        toast.error('Nemoguće uploadati fajl. Kontaktirajte administratora');
      }
    },
    onError: () => {},
  });

  const handleChange = (e) => {
    setUploading(false);

    const file = e.target.files[0];
    if (!file) return;

    const maxFileSize = maxMB || MAX_UPLOAD_FILE_SIZE_MB;
    if (file.size / 1000000 > maxFileSize) {
      e.target.value = '';
      return toast.error(
        `Fajl je prevelik za upload. Pokušali ste uploadati ${Math.round(
          file.size / 1000000
        )}MB, a dozvoljeno je ${maxFileSize}MB`
      );
    }

    if (acceptedformats && !acceptedformats?.includes(file.type)) {
      e.target.value = '';
      return toast.error('Nedozvoljen format fajla');
    }

    setFileObject(file);
  };

  const handleUpload = (e) => {
    e.preventDefault();

    // if there is another file uploaded, delete it before uploading
    if (currentFile && !noReplace) {
      removeFileMutation({ variables: { filename: currentFile } });
    }

    if (!directory)
      return toast.error(
        'Nije naveden upload direktorij. Kontaktirajte administratora.'
      );
    if (!fileObject) return toast.error('Niste odabrali fajl');
    const filename = formatFilename(directory, fileObject.name);
    signS3Mutation({
      variables: {
        filename,
        filetype: fileObject.type,
      },
    });
    if (!loading) setFileUploaded(filename);
  };

  const handleRemoveFile = () => {
    if (currentFile) {
      if (
        window.confirm(
          'Da li ste sigurni da želite da obrišete navedeni fajl? Napomena: Nemojte zaboraviti snimite postavke, jer u protivnom će referenca na obrisani file ostati u memoriji.'
        )
      ) {
        removeFileMutation({ variables: { filename: currentFile } });
        removeHandler((currentData) => ({ ...currentData, [name]: '' }));
        setCurrentFile('');
      }
    }
  };

  return (
    <Wrapper>
      <UploadControls>
        <InputWrapper>
          {uploading && <Progress progress={progress} uploading={uploading} />}
          <Input
            type="file"
            onChange={handleChange}
            ref={ref}
            {...otherProps}
            disabled={uploading || disabled}
          />
        </InputWrapper>
        <Button
          onClick={handleUpload}
          disabled={!ref?.current?.value || uploading}
          loading={uploading}
        >
          Upload
        </Button>
      </UploadControls>
      {currentFile && (
        <Current>
          <span>{t('Uploadani file')}:</span>
          <File>{currentFile}</File>
          <a
            href={process.env.REACT_APP_S3_BUCKET + currentFile}
            rel="noreferrer"
            target="_blank"
          >
            LINK
          </a>
          {!disabled && (
            <RemoveFile onClick={handleRemoveFile}>
              {t('Obriši fajl')}
            </RemoveFile>
          )}
        </Current>
      )}
    </Wrapper>
  );
};
export default UploadFile;
