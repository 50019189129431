import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Placeholder = styled.div`
  display: grid;
  padding: 1rem;
  place-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
`;

const usePlaceholder = (imageUrl) => {
  const [image, setImage] = useState(<Placeholder>Nema slike</Placeholder>);

  useEffect(() => {
    if (imageUrl) {
      setImage(
        <img
          alt="project thumbnail"
          src={process.env.REACT_APP_S3_BUCKET + imageUrl}
        />
      );
    }
  }, [imageUrl]);

  return [image];
};

export default usePlaceholder;
