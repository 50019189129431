import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChip } from 'react-icons/bi';
import LATEST_BUYERS_OFFERS from 'src/apollo/queries/dashboard/latestBuyersOffers';
import ChartStats from 'src/components/ChartStats/ChartStats';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import { IconWrapper, Number } from './Dashboard';

const BuyersOffersCountChart = () => {
  const { t } = useTranslation();
  // latest buyers offers
  const [lboff, setLboff] = useState([]);

  const {
    loading,
    data: { latestBuyersOffers } = {},
    error: latestBuyersOffersError,
  } = useQuery(LATEST_BUYERS_OFFERS, {});

  useEffect(() => {
    if (latestBuyersOffers?.length) {
      setLboff(groupBy(latestBuyersOffers, 'dateCreated'));
    }
  }, [latestBuyersOffers]);

  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t('Broj poslatih narudžbi')}</h4>
          {loading ? (
            <Spinner />
          ) : (
            <Number>{latestBuyersOffers?.length}</Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats
            data={lboff}
            title={t('Broj ponuda')}
            yAxisLabel={t('Broj ponuda')}
          />
        )}
      </Segment>
    </Distributor>
  );
};

export default BuyersOffersCountChart;
