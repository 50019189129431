import React, { useContext, useEffect, useState } from "react";
import Input from "../../../elements/Input/Input.component";
import Label from "../../../elements/Label/Label.component";
import FormField from "../../wrappers/FormField/FormField.component";
import Distributor from "../../wrappers/Distributor/Distributor.component";
import Button from "../../../elements/Button/Button.component";
import Select from "../../../elements/Select/Select.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addMaterialCategoryFormSchema } from "../../../../validation/schema";
import { useMutation, useQuery } from "@apollo/client";
import CREATE_MATERIAL_CATEGORY from "../../../../apollo/mutations/createMaterialCategory";
import GET_MATERIAL_CATEGORIES from "../../../../apollo/queries/getMaterialCategories";
import { ModalContext } from "../../Modal/modalContext";
import toast from "react-hot-toast";
import { pickBy } from "lodash";

const AddMaterialCategoryForm = ({ prefillData }) => {
  const { handleModal } = useContext(ModalContext);

  const {
    loading: getCategoriesLoading,
    error: getCategoriesError,
    data,
  } = useQuery(GET_MATERIAL_CATEGORIES, { fetchPolicy: "no-cache" });
  const [createMaterialCategoryMutation, { loading, error }] = useMutation(
    CREATE_MATERIAL_CATEGORY,
    {
      onCompleted: () => {
        toast.success("Kategorija uspješno dodana");
        handleModal();
      },
      onError: () => {},
    }
  );
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(addMaterialCategoryFormSchema),
  });
  const onSubmit = (values) => {
    const sanitizedValues = pickBy(values, (value) => value.length > 0);
    createMaterialCategoryMutation({
      variables: {
        ...sanitizedValues,
        replaceMaterialCategoryId: prefillData?.id,
      },
    });
  };

  // prefill

  useEffect(() => {
    if (prefillData) {
      setValue("name", prefillData?.name);
      setValue("ISOdesignation", prefillData?.ISOdesignation);
      setValue("parent", prefillData?.parent?.id);
    }
  }, [prefillData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Dodaj novu grupu materijala</h2>
      <FormField>
        <Label error={errors.name?.message}>Naziv grupe materijala</Label>
        <Input name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.ISOdesignation?.message}>ISO oznaka</Label>
        <Input name="ISOdesignation" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.parent?.message}>
          Pripada kategoriji (parent)
        </Label>
        <Select name="parent" loading={loading} ref={register}>
          {data?.getMaterialCategories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
      </FormField>
      <Distributor stack="end" submitButtons>
        <Button>Sačuvaj kategoriju materijala</Button>
      </Distributor>
    </form>
  );
};

export default AddMaterialCategoryForm;
