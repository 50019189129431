import gql from "graphql-tag";

const CREATE_TECHNOLOGY_CATEGORY = gql`
  mutation createTechnologyCategory(
    $name: String!
    $parent: ID
    $replaceTechnologyCategoryId: ID
  ) {
    createTechnologyCategory(
      name: $name
      parent: $parent
      replaceTechnologyCategoryId: $replaceTechnologyCategoryId
    ) {
      name
    }
  }
`;

export default CREATE_TECHNOLOGY_CATEGORY;
