import React from "react";
import styled from "styled-components";
import useTranslation from "../../../hooks/useTranslation";
import Spinner from "../Spinner/Spinner.component";

const Wrapper = styled.div``;

const Select = React.forwardRef(({ children, loading, ...otherProps }, ref) => {
  const { t } = useTranslation();
  const options = children?.length ? children : [children];
  return (
    <Wrapper>
      {loading ? (
        <Spinner button />
      ) : (
        <select {...otherProps} ref={ref}>
          <option defaultValue value="">
            {t("Odaberi")}...
          </option>
          {children?.length &&
            options &&
            options.map((child) => (
              <option key={child?.key} {...child?.props}>
                {t(child?.props?.children)}
              </option>
            ))}
        </select>
      )}
    </Wrapper>
  );
});

export default Select;
