import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import GET_MANUFACTURERS_DETAILS from 'src/apollo/queries/getManufacturersDetails';
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
} from 'src/components/elements/BasicTable/BasicTable.component';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Tabs, { TabItem } from 'src/components/elements/Tabs/Tabs.component';
import useTranslation from 'src/hooks/useTranslation';
import MachineList from 'src/pages/Users/sidebarItems/MachineList';
import Photos from 'src/pages/Users/sidebarItems/Photos';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 750px;
`;

const ManufacturerDetailsPreview = ({ manufacturersId }) => {
  const { t } = useTranslation();
  const { data: { getManufacturersDetails } = {}, loading } = useQuery(
    GET_MANUFACTURERS_DETAILS,
    {
      variables: { manufacturersId },
    }
  );

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <h2>Kvalifikacije proizvođača</h2>
      <Tabs>
        <TabItem key="1" active label="Projekti">
          <BasicTable layout="auto" loading={loading}>
            <TableHead>
              <TableRow>
                <TableHeader>Naziv projekta</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {getManufacturersDetails.map(({ projects: project }) => (
                <TableRow key={project._id}>
                  <TableCell>
                    <Link
                      to={`/projects/${project._id}/details/${project.positions[0]._id}`}
                    >
                      {project.name}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </TabItem>
        <TabItem key="2" label="Tehnologije">
          <BasicTable layout="auto" loading={loading}>
            <TableHead>
              <TableRow>
                <TableHeader>{t('Naziv tehnologije')}</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {getManufacturersDetails[0].technologies.map((tech) => (
                <TableRow key={tech.id}>
                  <TableCell>{tech.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </TabItem>
        <TabItem key="3" label="Galerija">
          <Photos gallery={getManufacturersDetails[0].photos} previewOnly />
        </TabItem>
        <TabItem key="4" label="Mašine">
          <MachineList data={getManufacturersDetails[0].machines} />
        </TabItem>
      </Tabs>
    </Wrapper>
  );
};

export default ManufacturerDetailsPreview;
