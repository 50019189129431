import React, { useContext } from 'react';
import Button from 'src/components/elements/Button/Button.component';
import { ModalContext } from 'src/components/structure/Modal/modalContext';
import styled from 'styled-components';
import AddMachineForm from './AddMachineForm';
import MachineList from './MachineList';
import useTranslation from 'src/hooks/useTranslation';

const Wrapper = styled.div``;

const Machines = ({ companyId, machines }) => {
  const { handleModal } = useContext(ModalContext);

  const { t } = useTranslation();
  return (
    <Wrapper>
      <h2>Administracija mašina</h2>
      <Button
        onClick={() => handleModal(<AddMachineForm companyId={companyId} />)}
      >
        Dodaj mašinu
      </Button>
      <h3>Dodane mašine</h3>
      {machines ? (
        <MachineList data={machines} companyId={companyId} />
      ) : (
        <p>{t('Nema dodanih mašina')}.</p>
      )}
    </Wrapper>
  );
};

export default Machines;
