import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  > textarea {
    width: ${({ width }) => (width ? width : "100%")};
  }
`;

const Textarea = React.forwardRef(
  ({ children, height, width, ...otherProps }, ref) => {
    return (
      <Wrapper height={height} width={width}>
        <textarea {...otherProps} ref={ref} maxLength="5000">
          {children}
        </textarea>
      </Wrapper>
    );
  }
);

export default Textarea;
