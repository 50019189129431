import React, { useContext, useEffect, useState } from 'react';
import Container from 'src/components/structure/wrappers/Container/Container.component';
import Main from 'src/components/structure/wrappers/Main/Main.component';
import styled from 'styled-components';
import BasicTable, {
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
  ActionCell,
  ActionHeader,
} from '../../components/elements/BasicTable/BasicTable.component';
import GET_ALL_ADDITIONAL_CHARACTERISTICS from 'src/apollo/queries/getAllAdditionalCharacteristics';
import { ModalContext } from 'src/components/structure/Modal/modalContext';
import { useMutation, useQuery } from '@apollo/client';
import { ADDITIONAL_CHARACTERISTICS_CATEGORIES } from 'src/constants';
import ButtonDropdown from 'src/components/elements/ButtonDropdown/ButtonDropdown.component';
import useQueryVars from 'src/hooks/useQueryVars';
import AddAdditionalCharacteristics from 'src/components/structure/Header/AddAdditionalCharacteristics/AddAdditionalCharacteristics';
import REMOVE_ADDITIONAL_CHARACTERISTIC from 'src/apollo/mutations/removeAdditionalCharacteristic';
import toast from 'react-hot-toast';

// TODO: Show these properties on position details page

const Wrapper = styled.div``;

const AdditionalCharacteristicsPage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const { handleModal } = useContext(ModalContext);

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_ADDITIONAL_CHARACTERISTICS,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [removeCharacteristic] = useMutation(REMOVE_ADDITIONAL_CHARACTERISTIC, {
    onCompleted: () => {
      toast.success('Karakteristika uspješno obrisana');
    },
    onError: () => {
      toast.error(`Neuspješno brisanje karakteristike`);
    },
  });

  const handleEditCharacteristic = (data) => {
    handleModal(<AddAdditionalCharacteristics prefillData={data} />);
  };

  const handleRemoveCharacteristic = (characteristicId, characteristicName) => {
    if (!characteristicId)
      return alert(
        'Nemoguće obrisati\nOdabrana karakteristika ne posjeduje Id'
      );

    if (
      prompt(
        `Upišite POTVRDA za brisanje karakteristike ${characteristicName}\nProces je ireverzibilan`
      )?.trim() === 'POTVRDA'
    ) {
      removeCharacteristic({
        variables: {
          characteristicId,
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      setFilteredData(data?.getAllAdditionalCharacteristics);
    }
  }, [data]);

  const query = useQueryVars();

  useEffect(() => {
    const type = query.get('type');
    if (!type) return setFilteredData(data?.getAllAdditionalCharacteristics);
    setFilteredData(
      data?.getAllAdditionalCharacteristics.filter((v) => {
        if (v.category.toLowerCase() === type) return v;
        return null;
      })
    );
  }, [query, data?.getAllAdditionalCharacteristics]);

  return (
    <Wrapper>
      <Container>
        <Main>
          <h1>Dodatne karakteristike</h1>
          <BasicTable layout="auto" loading={loading}>
            <TableHead sticky="75.5px">
              <TableRow>
                <TableHeader>Kategorija</TableHeader>
                <TableHeader>Naziv karakteristike</TableHeader>
                <TableHeader>Opis karakteristike</TableHeader>
                <ActionHeader>Akcije</ActionHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((characteristic) => (
                <TableRow key={characteristic.id}>
                  <TableCell>
                    {
                      ADDITIONAL_CHARACTERISTICS_CATEGORIES[
                        characteristic.category
                      ]
                    }
                  </TableCell>
                  <TableCell>{characteristic.name}</TableCell>
                  <TableCell>{characteristic.description}</TableCell>
                  <ActionCell>
                    <ButtonDropdown
                      id={characteristic.id}
                      actions={[
                        {
                          label: 'Uredi karakteristiku',
                          action: () =>
                            handleEditCharacteristic(characteristic),
                        },
                        {
                          label: 'Obriši karakteristiku',
                          action: () =>
                            handleRemoveCharacteristic(
                              characteristic.id,
                              characteristic.name
                            ),
                        },
                      ]}
                    />
                  </ActionCell>
                </TableRow>
              ))}
            </TableBody>
          </BasicTable>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default AdditionalCharacteristicsPage;
