import React, { useContext, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ProtectedContent from '../../../Auth/ProtectedContent/ProtectedContent';
import useTranslation from '../../../../hooks/useTranslation';
import {
  BsGrid,
  BsCollection,
  BsGear,
  BsFolder,
  BsBoxArrowDownLeft,
  BsController,
  BsGem,
  BsChatDots,
  BsChevronRight,
} from 'react-icons/bs';
import { currentUserContext } from '../../../Auth/currentUserContext/currentUser.context';
import { useApolloClient } from '@apollo/client';
import { ADDITIONAL_CHARACTERISTICS_CATEGORIES } from 'src/constants';
import TagManager from 'react-gtm-module';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  padding: 3rem 0;
  overflow: hidden;
  background: linear-gradient(
      137deg,
      rgb(255 255 255) 0%,
      rgb(239 239 239) 100%
    )
    rgb(255 255 255);
`;
const NavItemWrapper = styled.div``;

const Section = styled.div``;
const Title = styled(NavLink)`
  position: relative;
  font-weight: 600;
  padding: 0.7rem 2rem;
  display: flex;
  align-items: center;
  text-decoration: inherit;
  ${({ $active, theme }) =>
    $active &&
    `
    border-right: 3px solid ${theme.colors.accent};
    background-color: ${theme.colors.menuHighlight};
  `}
  :hover {
    background-color: ${({ theme }) => theme.colors.menuHighlight};
  }
  svg {
    margin-right: 2rem;
  }
`;
const SubMenu = styled.div`
  margin: 1rem 0;
`;
const SubItem = styled(NavLink)`
  padding: 0.4rem 2rem;
  text-decoration: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
  display: inline-flex;
  width: 100%;
  > span {
    width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  svg {
    margin-right: 1rem;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.sidebarNavigationBorder};
  }
  &.active {
    ${({ indent, theme }) =>
      !indent &&
      `
    font-weight: 700;
    color: ${theme.colors.accent};
    `}
  }
  ${({ indent }) => indent && `margin-left: ${indent}px`};
`;

const ChildWrapper = styled.div``;
const SubItemWrapper = ({ children }) => {
  const handlePropagation = (e) => {
    e.stopPropagation();
  };
  return <ChildWrapper onClick={handlePropagation}>{children}</ChildWrapper>;
};

const Unresolved = styled.div`
  position: absolute;
  right: 22px;
  top: 9px;
  width: 2rem;
  height: 2rem;
  background: ${({ theme }) => theme.colors.accent};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 0.8rem;
`;

const NavItem = ({
  title,
  submenu,
  active,
  icon,
  unresolved,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const {
    user: { user },
  } = useContext(currentUserContext);

  const isAuthorized = (role) => {
    if (user) {
      if (!role) return true;
      if (Array.isArray(role)) {
        return role.includes(user.role);
      } else {
        return role === user.role;
      }
    }
  };

  return (
    <NavItemWrapper>
      <Title active={active} {...otherProps}>
        {icon}
        {t(title)}
        {!!unresolved && <Unresolved>{unresolved}</Unresolved>}
      </Title>
      {active && submenu && (
        <SubMenu active={active}>
          {submenu?.map(
            (item, i) =>
              isAuthorized(item?.onlyFor) && (
                <SubItemWrapper key={i}>
                  <SubItem to={item.linkTo}>
                    <BsFolder />
                    <span>{t(item.title)}</span>
                  </SubItem>
                  {item?.submenu?.length > 0 &&
                    item.submenu.map((item, i) => (
                      <SubItem to={item.linkTo} key={i} indent={20}>
                        <BsChevronRight /> <span>{item.title}</span>
                      </SubItem>
                    ))}
                </SubItemWrapper>
              )
          )}
        </SubMenu>
      )}
    </NavItemWrapper>
  );
};

const SideNavigation = () => {
  const client = useApolloClient();
  const history = useHistory();

  const { t } = useTranslation();
  const [activeMenu, setActive] = useState(1);
  const {
    unreadMessages,
    user: { user },
  } = useContext(currentUserContext);

  const handleLogout = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'logout',
        username: user.name,
        userId: user.id,
      },
    });
    client.clearStore();
    localStorage.removeItem('user');
    history.push('/login');
  };

  const toggleActive = (id) => {
    setActive(id);
  };
  return (
    <Wrapper>
      <Section>
        <NavItem
          title={t('Naslovna')}
          exact
          to="/"
          icon={<BsGrid size="25px" />}
          onClick={() => toggleActive(1)}
          active={activeMenu === 1}
        />
        <NavItem
          title={t('Poruke')}
          to="/messages"
          unresolved={unreadMessages}
          icon={<BsChatDots size="25px" />}
          onClick={() => toggleActive(8)}
          active={activeMenu === 8}
        />
        <NavItem
          title={t('Projekti')}
          to="/projects"
          icon={<BsCollection size="25px" />}
          onClick={() => toggleActive(2)}
          active={activeMenu === 2}
          submenu={[
            {
              title: t('Projekti u pripremi'),
              linkTo: '/projects/drafts',
              onlyFor: 'BUYER',
            },
            {
              title: t('Neobrađeni projekti'),
              linkTo: '/projects/unapproved',
              onlyFor: ['ADMINISTRATOR', 'MODERATOR'],
            },
            {
              title: t('Obrađeni projekti'),
              linkTo: '/projects/processed-by-buyer',
              onlyFor: ['BUYER'],
            },
            {
              title: t('Aktivni projekti'),
              linkTo: '/projects/active',
            },
            {
              title: t('Sa_Ponudama_alt'),
              linkTo: '/projects/has-offers',
              onlyFor: ['ADMINISTRATOR', 'MODERATOR', 'BUYER'],
            },
            {
              title: t('Sa poslanim ponudama'),
              linkTo: '/projects/sent-to-buyers',
              onlyFor: ['ADMINISTRATOR', 'MODERATOR'],
            },
            {
              title: t('Aplicirani_Projekti'),
              linkTo: '/projects/applied',
              onlyFor: 'MANUFACTURER',
            },
            {
              title: t('Arhiva - istekli projekti'),
              linkTo: '/projects/archive',
            },
            {
              title: t('Onemogućeni projekti'),
              linkTo: '/projects/disabled',
              onlyFor: ['ADMINISTRATOR'],
            },
          ]}
        />
        <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
          <NavItem
            title={t('Ponude')}
            to="/orders"
            icon={<BsGem size="25px" />}
            onClick={() => toggleActive(3)}
            active={activeMenu === 3}
          />
        </ProtectedContent>
        <ProtectedContent onlyFor={['BUYER']}>
          <NavItem
            title={t('Ponude')}
            to="/buyers-orders"
            icon={<BsGem size="25px" />}
            onClick={() => toggleActive(4)}
            active={activeMenu === 4}
          />
        </ProtectedContent>
        <ProtectedContent onlyFor={['MANUFACTURER']}>
          <NavItem
            title={t('Ponude')}
            to="/manufacturer-orders"
            icon={<BsGem size="25px" />}
            onClick={() => toggleActive(5)}
            active={activeMenu === 5}
          />
        </ProtectedContent>
        <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
          <NavItem
            title={t('Administracija')}
            to="/users"
            icon={<BsController size="25px" />}
            onClick={() => toggleActive(6)}
            active={activeMenu === 6}
            submenu={[
              {
                title: 'Korisnici',
                linkTo: '/users',
                submenu: [
                  {
                    title: 'Kupci',
                    linkTo: '/users?type=buyer',
                  },
                  {
                    title: 'Dobavljači',
                    linkTo: '/users?type=manufacturer',
                  },
                ],
              },
              {
                title: 'Materijali',
                linkTo: '/materials',
              },
              {
                title: 'Kategorije materijala',
                linkTo: '/material-categories',
              },
              {
                title: 'Tehnologije',
                linkTo: '/technologies',
              },
              {
                title: 'Kategorije tehnologije',
                linkTo: '/technology-categories',
              },
              {
                title: 'Parametri tehnologije',
                linkTo: '/technology-parameters',
              },
              {
                title: 'Proizvodnja',
                linkTo: '/manufacturing',
              },
              {
                title: 'Kategorije proizvodnje',
                linkTo: '/manufacturing-categories',
              },
              {
                title: 'Dodatne karakteristike',
                linkTo: '/additional-characteristics',
                submenu: Object.keys(ADDITIONAL_CHARACTERISTICS_CATEGORIES).map(
                  (v) => ({
                    title: ADDITIONAL_CHARACTERISTICS_CATEGORIES[v],
                    linkTo: `/additional-characteristics?type=${v.toLowerCase()}`,
                  })
                ),
              },
            ]}
          />
        </ProtectedContent>
      </Section>
      <Section>
        <NavItem
          title={t('Postavke')}
          to={`/users/${user?.id}/details`}
          icon={<BsGear size="25px" />}
          onClick={() => toggleActive(7)}
          active={activeMenu === 7}
          submenu={[
            {
              title: t('Profil firme'),
              linkTo: `/users/${user?.id}/details`,
            },
            {
              title: t('Tehničke kvalifikacije'),
              linkTo: '/company-qualifications',
              onlyFor: ['ADMINISTRATOR', 'MODERATOR', 'MANUFACTURER'],
            },
            {
              title: t('Saradnici'),
              linkTo: '/company-associates',
            },
            {
              title: t('Ostale postavke'),
              linkTo: '/other-settings',
            },
          ]}
        />
        <NavItem
          title={t('Odjava')}
          to="/"
          icon={<BsBoxArrowDownLeft size="25px" />}
          onClick={handleLogout}
        />
      </Section>
    </Wrapper>
  );
};

export default SideNavigation;
