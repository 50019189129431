import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChip } from 'react-icons/bi';
import BUYERS_UNDELIVERED_ORDERS_VALUE from 'src/apollo/queries/dashboard/buyersUndeliveredOrdersValue';
import ChartStats from 'src/components/ChartStats/ChartStats';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import { IconWrapper, Number } from './Dashboard';

const BuyersUndeliveredOrdersValue = () => {
  const { t } = useTranslation();
  // latest buyers orders value
  const [lbov, setLbov] = useState([]);

  const {
    loading,
    data: { buyersUndeliveredOrdersValue } = {},
    error: latestBuyersOrdersValueError,
  } = useQuery(BUYERS_UNDELIVERED_ORDERS_VALUE, {});

  useEffect(() => {
    if (buyersUndeliveredOrdersValue?.length) {
      setLbov(groupBy(buyersUndeliveredOrdersValue, 'dateCreated'));
    }
  }, [buyersUndeliveredOrdersValue]);

  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t('Vrijednost neisporučene robe')}</h4>
          {loading ? (
            <Spinner />
          ) : (
            <Number>
              {buyersUndeliveredOrdersValue
                ?.reduce((acc, curr) => acc + curr.value, 0)
                ?.toLocaleString('de', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                })}
            </Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats
            data={lbov}
            title={t('Ukupna vrijednost')}
            sumField="value"
            yAxisLabel={t('Vrijednost (€)')}
          />
        )}
      </Segment>
    </Distributor>
  );
};

export default BuyersUndeliveredOrdersValue;
