import gql from "graphql-tag";

const ADD_OTHER_DOCUMENT = gql`
  mutation addOtherDocument(
    $companyId: String!
    $filename: String!
    $fileDescription: String!
  ) {
    addOtherDocument(
      companyId: $companyId
      filename: $filename
      fileDescription: $fileDescription
    )
  }
`;

export default ADD_OTHER_DOCUMENT;
