import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import CREATE_CONVERSATION from '../../apollo/mutations/createConversation';
import useTranslation from '../../hooks/useTranslation';
import { sendInquiryFormSchema } from '../../validation/schema';
import { currentUserContext } from '../Auth/currentUserContext/currentUser.context';
import DataSelector from '../DataSelector/DataSelector';
import { DataSelectorContext } from '../DataSelector/dataSelectorContext';
import Button from '../elements/Button/Button.component';
import CheckerBox from '../elements/CheckerBox/CheckerBox';
import Input from '../elements/Input/Input.component';
import Label from '../elements/Label/Label.component';
import Textarea from '../elements/Textarea/Textarea.component';
import UploadFile from '../elements/UploadFile/UploadFile.component';
import { ModalContext } from '../structure/Modal/modalContext';
import Distributor from '../structure/wrappers/Distributor/Distributor.component';
import FormField from '../structure/wrappers/FormField/FormField.component';

const Inquiry = ({ receiver, positionData, projectId, contacted }) => {
  const { dataSelectorValues } = useContext(DataSelectorContext);

  const [files, setFiles] = useState({
    pdf: '',
    jpg: '',
    step: '',
    additionalDocuments: '',
  });

  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const {
    user: { user },
  } = useContext(currentUserContext);

  const [createConversationMutation, { loading }] = useMutation(
    CREATE_CONVERSATION,
    {
      onCompleted: () => {
        toast.success('Upit uspješno poslan.');
        handleModal();
      },
    }
  );
  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(sendInquiryFormSchema),
    defaultValues: {
      attachFileTable: 'true',
    },
  });

  useEffect(() => {
    if (positionData?.name) {
      setValue('title', positionData.name);
    }
  }, [positionData, setValue]);

  const onSubmit = (values) => {
    const manufacturerIds = dataSelectorValues.manufacturerIds;

    if (!values.contactBuyer && manufacturerIds?.length === 0) {
      return toast.error('Nema odabranih korisnika');
    }

    // add files to message as tags using parseFileTag for every file in values.files object

    const fileTags = Object.keys(files)
      .map((key) => {
        if (files[key]) {
          // concatenate file tag with values.message string
          const tag = `[file](${files[key]})`;
          return tag;
        }
        return false;
      })
      .filter(Boolean);

    const messageWithTags =
      fileTags.length > 0
        ? `${values.message} ${fileTags.join(' ')}`
        : values.message;

    // TODO: dodaj mogucnost dodavanja vise fajlova
    const variables = {
      ...values,
      projectId,
      message: messageWithTags,
      position: positionData.id,
      receiver,
      contactBuyer: values.contactBuyer ? true : false,
      sendAsEmail: values.sendAsEmail ? true : false,
      attachAllPositions: values.attachAllPositions ? true : false,
      attachFileTable: values.attachFileTable ? true : false,
      contactManufacturersIds: manufacturerIds,
      files,
    };

    createConversationMutation({
      variables,
    });
  };

  if (!receiver)
    return (
      <p>
        Nemoguće poslati upit. <br />
        Err. Nema primaoca
      </p>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Distributor cols={2}>
        <div>
          <FormField>
            <Label error={errors.title?.message}>{t('Inquiry__Subject')}</Label>
            <Input name="title" height="10rem" ref={register} readOnly />
          </FormField>
          <FormField>
            <Label error={errors.message?.message}>
              {t('Inquiry__Content')}
            </Label>
            <Textarea name="message" rows="10" ref={register}></Textarea>
          </FormField>
          {['MODERATOR', 'ADMINISTRATOR'].includes(user?.role) ? (
            <>
              <Distributor repeat="2">
                <FormField>
                  <CheckerBox
                    value="Kontaktiraj kupca"
                    name="contactBuyer"
                    ref={register}
                  />
                </FormField>
                <FormField>
                  <CheckerBox
                    value="Proslijedi podatke na email"
                    name="sendAsEmail"
                    ref={register}
                  />
                </FormField>
                <FormField>
                  <CheckerBox
                    value="Priloži podatke od svih pozicija"
                    name="attachAllPositions"
                    ref={register}
                  />
                </FormField>
                <FormField>
                  <CheckerBox
                    value="Priloži tabelu s fajlovima"
                    name="attachFileTable"
                    ref={register}
                  />
                </FormField>
              </Distributor>
              <FormField>
                <DataSelector
                  params={{
                    selectAllButton: true,
                    loading: false,
                    inline: true,
                    data: contacted,
                    chooseButtonLabel: 'Kontaktiraj dobavljače',
                    name: 'manufacturerIds',
                    valuesField: '_id',
                    handlerField: 'name',
                    noHeaders: true,
                    sortBy: {
                      dataField: 'company',
                      name: 'company.name',
                      compare: 'company._id',
                    },
                    additionalFields: {
                      'company.name': {
                        label: '',
                      },
                    },
                  }}
                />
              </FormField>
            </>
          ) : null}
        </div>
        <div>
          <FormField>
            <Label>PDF</Label>
            <UploadFile
              directory="pdf"
              name="pdf"
              acceptedformats={['application/pdf']}
              maxMB="50"
              onSuccess={(filename) =>
                setFiles((currentValues) => ({
                  ...currentValues,
                  pdf: filename,
                }))
              }
            />
          </FormField>
          <FormField>
            <Label>JPG</Label>
            <UploadFile
              directory="jpg"
              name="jpg"
              acceptedformats={['image/png', 'image/jpeg']}
              maxMB="50"
              onSuccess={(filename) =>
                setFiles((currentValues) => ({
                  ...currentValues,
                  jpg: filename,
                }))
              }
            />
          </FormField>
          <FormField>
            <Label>STEP</Label>
            <UploadFile
              directory="step"
              name="step"
              acceptedformats={['model/step', 'application/step']}
              maxMB="50"
              onSuccess={(filename) =>
                setFiles((currentValues) => ({
                  ...currentValues,
                  step: filename,
                }))
              }
            />
          </FormField>
          <FormField>
            <Label>{t('Dodatni dokumenti (*.zip)')}</Label>
            <UploadFile
              directory="zip"
              acceptedformats={[
                'application/zip',
                'application/octet-stream',
                'application/x-zip-compressed',
                'multipart/x-zip',
              ]}
              name="additionalDocuments"
              maxMB="50"
              onSuccess={(filename) =>
                setFiles((currentValues) => ({
                  ...currentValues,
                  additionalDocuments: filename,
                }))
              }
            />
          </FormField>
        </div>
      </Distributor>
      <FormField>
        <Distributor stack="end">
          <Button>{t('Inquiry__SendButton')}</Button>
        </Distributor>
      </FormField>
    </form>
  );
};

export default Inquiry;
