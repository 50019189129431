import React from "react";
import Acknowledge from "src/components/Acknowledge/Acknowledge";
import Space from "src/components/elements/Space/Space.component";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import styled from "styled-components";

const Wrapper = styled.div``;
const Image = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 500px;
  }
`;
const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
`;
const Label = styled.div`
  font-size: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgb(0 0 0 / 68%);
  color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 1rem;
  border-radius: 3px;
`;
const PositionNumber = styled.div``;

const Preview = ({ data: { positionData, files } }) => {
  return (
    <Wrapper>
      <Title>Naziv pozicije: {positionData.name}</Title>
      {!files?.revisedJPG || files.jpg === files.revisedJPG ? (
        <Acknowledge title="Slika pozicije" layout="warning">
          Nerevidirano ili je odabran isti fajl
        </Acknowledge>
      ) : null}
      <Distributor col="1-1">
        <PositionNumber>
          Broj pozicije: {positionData.positionCode}
        </PositionNumber>
        <PositionNumber>
          Broj crteža: {positionData.drawingNumber}
        </PositionNumber>
      </Distributor>
      <Space vertical="3rem" />
      <Distributor col="1-1">
        <Image>
          <Label>Original</Label>
          {files.jpg ? (
            <img
              src={process.env.REACT_APP_S3_BUCKET + files.jpg}
              alt="Original"
            />
          ) : (
            <span>Nije navedeno</span>
          )}
        </Image>
        <Image>
          <Label>Revidirano</Label>
          {files.revisedJPG ? (
            <img
              src={process.env.REACT_APP_S3_BUCKET + files.revisedJPG}
              alt="Original"
            />
          ) : (
            <span>Nije navedeno</span>
          )}
        </Image>
      </Distributor>
    </Wrapper>
  );
};

export default Preview;
