import gql from 'graphql-tag';

const CREATE_POSITION = gql`
  mutation createPosition(
    $projectId: ID!
    $name: String!
    $positionCode: String
    $drawingNumber: String
    $quantities: [QuantityInput]
    $manufacturing: ID
    $material: ID
    $technology: [ID]
    $additionalCharacteristics: [ID]
    $files: FilesInput
    $note: String
  ) {
    createPosition(
      projectId: $projectId
      name: $name
      positionCode: $positionCode
      drawingNumber: $drawingNumber
      quantities: $quantities
      manufacturing: $manufacturing
      material: $material
      technology: $technology
      additionalCharacteristics: $additionalCharacteristics
      files: $files
      note: $note
    ) {
      id
    }
  }
`;

export default CREATE_POSITION;
