import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import REMOVE_FILE from 'src/apollo/mutations/removeFile';
import DataSelector from 'src/components/DataSelector/DataSelector';
import { DataSelectorContext } from 'src/components/DataSelector/dataSelectorContext';
import Space from 'src/components/elements/Space/Space.component';
import { ModalContext } from 'src/components/structure/Modal/modalContext';
import CREATE_MACHINE from '../../../apollo/mutations/createMachine';
import GET_TECHNOLOGIES from '../../../apollo/queries/getTechnologies';
import Button from '../../../components/elements/Button/Button.component';
import Input from '../../../components/elements/Input/Input.component';
import Label from '../../../components/elements/Label/Label.component';
import Select from '../../../components/elements/Select/Select.component';
import UploadFile from '../../../components/elements/UploadFile/UploadFile.component';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import FormField from '../../../components/structure/wrappers/FormField/FormField.component';
import { addMachineFormSchema } from '../../../validation/schema';
import Photos from './Photos';
import useTranslation from 'src/hooks/useTranslation';

const AddMachineForm = ({ companyId, prefillData }) => {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const { dataSelectorValues } = useContext(DataSelectorContext);
  const [machineImage, setMachineImage] = useState('');
  const [imageGallery, setImageGallery] = useState([]);
  const { handleSubmit, errors, register, setValue, getValues } = useForm({
    resolver: yupResolver(addMachineFormSchema),
  });

  const [removeFileMutation] = useMutation(REMOVE_FILE, {
    onError: () => {},
  });

  const [createMachineMutation, { loading: createMachineLoading }] =
    useMutation(CREATE_MACHINE, {
      onCompleted: () => {
        toast.success('Mašina dodana');
        handleModal();
      },
      onError: () => {},
    });

  const { data, loading } = useQuery(GET_TECHNOLOGIES);

  const handleImageUpload = (filename) => {
    if (machineImage) {
      // remove the file first
      removeFileMutation({
        variables: { filename: machineImage },
      });
    }
    setMachineImage(filename);
  };

  const handleAddImageToGallery = (filename) => {
    setImageGallery([...imageGallery, filename]);
  };

  const handleRemovePhotoFromGallery = (filename) => {
    // remove the file first
    removeFileMutation({
      variables: { filename: filename },
    });
    setImageGallery(imageGallery.filter((i) => i !== filename));
  };

  const onSubmit = (values) => {
    if (!machineImage) return toast.error('Morate dodati fotografiju mašine');
    if (!dataSelectorValues?.technologies?.length)
      return toast.error('Morate odabrati barem jednu tehnologiju');

    createMachineMutation({
      variables: {
        ...values,
        companyId,
        image: machineImage,
        gallery: imageGallery,
        processingDimension: {
          D: parseInt(values?.D),
          L: parseInt(values?.L),
          X: parseInt(values?.X),
          Y: parseInt(values?.Y),
          Z: parseInt(values?.Z),
        },
        technologies: dataSelectorValues.technologies,
        numberOfWorkSpindles: parseInt(values?.numberOfWorkSpindles),
        spindlePowerKW: parseInt(values?.spindlePowerKW),
        numberOfRevolverHeads: parseInt(values?.numberOfRevolverHeads),
        numberOfTools: parseInt(values?.numberOfTools),
        numberOfDrivenTools: parseInt(values?.numberOfDrivenTools),
        numberOfAxis: parseInt(values?.numberOfAxis),
        replaceMachineId: prefillData?._id,
      },
      refetchQueries: ['getSingleUser'],
    });
  };

  // prefill

  useEffect(() => {
    if (prefillData) {
      const keys = Object.keys(getValues());
      keys.map((k) => setValue(k, prefillData[k]));
      ['D', 'L', 'X', 'Y', 'Z'].map((v) =>
        setValue(v, prefillData.processingDimension[v])
      );
      setValue(
        'technologies',
        prefillData.technologies.map((t) => t._id)
      );
      setMachineImage(prefillData?.image);
      setImageGallery(prefillData?.gallery || []);
    }
  }, [prefillData, getValues]);

  const techIds = useMemo(
    () => prefillData?.technologies.map((t) => t._id),
    [prefillData]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Dodaj novu mašinu</h2>
      <Distributor col="2-1">
        <FormField>
          <Label error={errors.name?.message}>Naziv mašine</Label>
          <Input type="text" name="name" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.idNumber?.message}>Id broj</Label>
          <Input type="text" name="idNumber" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.manufacturer?.message}>Proizvođač mašine</Label>
          <Input type="text" name="manufacturer" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.type?.message}>Tip mašine</Label>
          <Input type="text" name="type" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="1-1-1">
        <FormField>
          <Label error={errors.control?.message}>Upravljanje</Label>
          <Input type="text" name="control" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.manufacturedYear?.message}>
            Godina proizvodnje
          </Label>
          <Input type="number" name="manufacturedYear" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.quantity?.message}>Količina</Label>
          <Input type="number" name="quantity" ref={register} />
        </FormField>
      </Distributor>

      <Distributor col="1-1">
        <FormField>
          <Label error={errors.numberOfWorkSpindles?.message}>
            Broj radnih vretena
          </Label>
          <Input type="number" name="numberOfWorkSpindles" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.spindlePowerKW?.message}>
            Snaga vretena (kW)
          </Label>
          <Input type="number" name="spindlePowerKW" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.numberOfRevolverHeads?.message}>
            Broj revolver glava
          </Label>
          <Input type="number" name="numberOfRevolverHeads" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.numberOfTools?.message}>Ukupan broj alata</Label>
          <Input type="number" name="numberOfTools" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.drivenTools?.message}>Pogonjeni alati</Label>
          <Select name="drivenTools" ref={register}>
            <option key="1" value={true}>
              Da
            </option>
            <option key="2" value={false}>
              Ne
            </option>
          </Select>
        </FormField>
        <FormField>
          <Label error={errors.numberOfDrivenTools?.message}>
            Broj pogonjenih alata
          </Label>
          <Input type="number" name="numberOfDrivenTools" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="3-1-1-1-1-1">
        <FormField>
          <Label error={errors.numberOfAxis?.message}>Broj osa</Label>
          <Input type="number" name="numberOfAxis" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.D?.message}>D (mm)</Label>
          <Input type="number" name="D" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.L?.message}>L (mm)</Label>
          <Input type="number" name="L" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.X?.message}>X (mm)</Label>
          <Input type="number" name="X" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.Y?.message}>Y (mm)</Label>
          <Input type="number" name="Y" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.Z?.message}>Z (mm)</Label>
          <Input type="number" name="Z" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.materialAdder?.message}>
            Dodavač materijala
          </Label>
          <Select name="materialAdder" ref={register}>
            <option key="1" value={true}>
              Da
            </option>
            <option key="2" value={false}>
              Ne
            </option>
          </Select>
        </FormField>
        <FormField>
          <Label error={errors.machinePurpose?.message}>Primjena mašine</Label>
          <Input type="text" name="machinePurpose" ref={register} />
        </FormField>
      </Distributor>
      <FormField>
        <Label>Fotografija mašine</Label>
        <UploadFile
          directory="machines"
          name="image"
          acceptedformats={['image/png', 'image/jpeg']}
          maxMB="50"
          onSuccess={(filename) => handleImageUpload(filename)}
          current={machineImage}
        />
      </FormField>
      <FormField>
        <Label>Dodatne fotografije</Label>
        <UploadFile
          directory="machines"
          name="additionalImages"
          acceptedformats={['image/png', 'image/jpeg']}
          maxMB="50"
          onSuccess={(filename) => handleAddImageToGallery(filename)}
          noReplace
        />
      </FormField>
      <Space vertical="1rem" />
      <Photos
        gallery={imageGallery}
        companyId={companyId}
        onRemove={handleRemovePhotoFromGallery}
      />
      <FormField>
        <DataSelector
          params={{
            data: data?.getTechnologies,
            populateFrom: techIds,
            loading: loading,
            chooseButtonLabel: t('Odaberi tehnologiju'),
            name: 'technologies',
            valuesField: 'id',
            handlerField: 'name',
            inline: true,
            sortBy: {
              dataField: 'category',
              name: 'category.name',
              compare: 'category.id',
              overrideSortByField: 'category.id',
              sortDataBy: ['id', 'parameters[0].minValue'],
            },
            additionalFields: {
              'parameters[0].minValue': {
                label: 'Min',
              },
              'parameters[0].maxValue': {
                label: 'Max',
              },
            },
          }}
        />
      </FormField>
      <Distributor submitButtons stack="end">
        <Button loading={createMachineLoading}>Sačuvaj mašinu</Button>
      </Distributor>
    </form>
  );
};

export default AddMachineForm;
