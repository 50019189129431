import gql from "graphql-tag";

const PROJECT_TOGGLE_ACTIVE = gql`
  mutation projectToggleActive(
    $projectId: String!
    $projectActiveState: Boolean!
  ) {
    projectToggleActive(
      projectId: $projectId
      projectActiveState: $projectActiveState
    )
  }
`;

export default PROJECT_TOGGLE_ACTIVE;
