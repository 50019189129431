import React from "react";
import styled, { withTheme } from "styled-components";
import Spinner from "../Spinner/Spinner.component";

const Wrapper = styled.div`
  text-align: center;
  white-space: nowrap;
  & > button {
    ${({ align }) => align && `justify-content: ${align}`};
    ${({ width }) => width && `width: ${width}`};
    ${({ layout }) => layout};
    padding: 0.5rem 1rem;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "600")};
    font-size: ${({ size }) => (size ? size : "inherit")};
    height: 100%;
    display: flex;
    align-items: center;
    > *:first-child {
      margin-right: 0.5rem;
    }
    > *:last-child {
      margin-right: 0;
    }
    :disabled {
      background: ${({ theme }) => theme.colors.sidebarBorder};
    }
    :not(:disabled):hover {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Button = ({
  children,
  layout,
  align,
  loading,
  theme,
  width,
  ...otherProps
}) => {
  const style = {
    hollow: `
      background: ${theme.colors.menuHighlight}
        :hover {
        background: ${theme.colors.black};
        }
    `,
    default: `
      background-color: ${theme.colors.accent};
      color: ${theme.colors.white};
    `,
  };

  return (
    <Wrapper
      width={width}
      align={align}
      layout={layout ? style[layout] : style.default}
    >
      <button {...otherProps}>{loading ? <Spinner button /> : children}</button>
    </Wrapper>
  );
};

export default withTheme(Button);
