import React from "react";
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/elements/BasicTable/BasicTable.component";
import styled from "styled-components";

const Wrapper = styled.div``;

const OrderManufacturersList = ({ data, positionData }) => {
  console.log("Contacted manufacturers", data);

  const hasOffered = (manufacturerId) => {
    if (positionData) {
      return positionData.some((position) =>
        position.quantities.some((quantity) =>
          quantity.offers.some((offer) => offer.userId.id === manufacturerId)
        )
      )
        ? "Da"
        : "";
    }
  };

  return (
    <Wrapper>
      <BasicTable layout="auto">
        <TableHead>
          <TableRow>
            <TableHeader>Ime firme</TableHeader>
            <TableHeader>Korisničko ime</TableHeader>
            <TableHeader>Ime</TableHeader>
            <TableHeader>Poslao ponudu</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((manufacturer) => (
            <TableRow key={manufacturer._id}>
              <TableCell>{manufacturer.company.name}</TableCell>
              <TableCell>{manufacturer.username}</TableCell>
              <TableCell>{manufacturer.name}</TableCell>
              <TableCell>{hasOffered(manufacturer._id)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BasicTable>
    </Wrapper>
  );
};

export default OrderManufacturersList;
