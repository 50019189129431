import gql from "graphql-tag";

const RECIEVED_POSITION = gql`
  mutation recievedPosition(
    $orderId: ID!
    $positionId: ID!
    $quantity: Int!
    $date: Date!
  ) {
    recievedPosition(
      orderId: $orderId
      positionId: $positionId
      quantity: $quantity
      date: $date
    )
  }
`;

export default RECIEVED_POSITION;
