import gql from "graphql-tag";

const ACTIVE_PROJECTS = gql`
  query activeProjects {
    activeProjects {
      id
      name
      projectCode
      published {
        date
      }
      positions {
        _id
        files {
          jpg
        }
        revisedInformations {
          files {
            jpg
          }
        }
      }
    }
  }
`;

export default ACTIVE_PROJECTS;
