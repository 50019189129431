import React, { useContext, useEffect, useState } from 'react';
import { currentUserContext } from 'src/components/Auth/currentUserContext/currentUser.context';
import ProtectedContent from 'src/components/Auth/ProtectedContent/ProtectedContent';
import Space from 'src/components/elements/Space/Space.component';
import Container from 'src/components/structure/wrappers/Container/Container.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Main from 'src/components/structure/wrappers/Main/Main.component';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import ActiveProjects from './ActiveProjects';
import ActiveProjectsSlider from './ActiveProjectsSlider';
import AllProjects from './AllProjects';
import AllProjectsChart from './AllProjectsChart';
import AllProjectsValue from './AllProjectsValue';
import AllReceivedOrders from './AllReceivedOrders';
import AllSentOffersChart from './AllSentOffersChart';
import AllSentOffersValueChart from './AllSentOffersValueChart';
import AllSentOrders from './AllSentOrders';
import BuyersOffersCountChart from './BuyersOffersCountChart';
import BuyersOrdersValueChart from './BuyersOrdersValueChart';
import BuyersUndeliveredOrdersValue from './BuyersUndeliveredOrdersValue';
import LatestBuyersOrdersChart from './LatestBuyersOrdersChart';
import LatestProjectsSlider from './LatestProjectsSlider';
import ManufacturerAcceptedOffersChart from './ManufacturerAcceptedOffersChart';
import ManufacturerDeliveredOrdersValueChart from './ManufacturerDeliveredOrdersValueChart';
import ManufacturerOrdersValueChart from './ManufacturerOrdersValueChart';
import ManufacturerUndeliveredOrdersValueChart from './ManufacturerUndeliveredOrdersValueChart';
import OrdersCountChart from './OrdersCountChart';
import OrdersValueChart from './OrdersValueChart';
import RealisationValueChart from './RealisationValueChart';
import ReceivedBuyersOrders from './ReceivedBuyersOrders';
import ReceivedManufacturersOrders from './ReceivedManufacturersOrders';
import SentBuyersOrders from './SentBuyersOrders';
import SentManufacturersOrders from './SentManufacturersOrders';
import ManufacturersOffersByQuantityChart from './ManufacturersOffersByQuantityChart';

const Wrapper = styled.div``;
const Number = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`;

const Charts = styled.div`
  > div:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const IconContainer = styled.div`
  position: relative;
  > svg {
    position: relative;
    z-index: 2;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Blob = styled.div`
  position: absolute;
  width: 123px;
  top: -42px;
  left: -22px;
  transform: rotate(134deg);
  svg {
    fill: ${({ theme }) => theme.colors.accent};
  }
`;

const IconWrapper = ({ children }) => {
  return (
    <IconContainer>
      <Blob>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M31.6,-16.3C36.5,4.7,32.8,22.4,20.8,31.8C8.9,41.2,-11.3,42.3,-28.6,31.6C-45.9,20.9,-60.3,-1.7,-55,-23.2C-49.8,-44.7,-24.9,-65.2,-5.8,-63.3C13.4,-61.4,26.8,-37.2,31.6,-16.3Z"
            transform="translate(100 100)"
          />
        </svg>
      </Blob>
      {children}
    </IconContainer>
  );
};

const Dashboard = () => {
  const [name, setName] = useState('');
  const {
    user: { user },
  } = useContext(currentUserContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      if (user?.name) {
        const helloName = user?.name?.split(' ');
        if (helloName[1]) {
          setName(helloName[1].trim());
        } else {
          setName(user?.name?.trim());
        }
      }
    }
  }, [user]);

  return (
    <Wrapper>
      <Container>
        <Main>
          <h3>
            {t(`Dobrodošli na Component Providing Portal, gdine`) + ' ' + name}
          </h3>
          <Space vertical="2rem" />
          <Distributor col="1-1-1-1-1">
            <AllProjects />
            <ActiveProjects />
            <ProtectedContent onlyFor={['MANUFACTURER']}>
              <SentManufacturersOrders />
              <ReceivedManufacturersOrders />
            </ProtectedContent>
            <ProtectedContent onlyFor={['BUYER']}>
              <SentBuyersOrders />
              <ReceivedBuyersOrders />
            </ProtectedContent>
            <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
              <AllSentOrders />
              <AllReceivedOrders />
            </ProtectedContent>
            <AllProjectsValue />
          </Distributor>

          <Space vertical="3rem" />
          <ActiveProjectsSlider />
          <Space vertical="3rem" />

          <Charts>
            <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
              <AllProjectsChart />
              <ManufacturersOffersByQuantityChart />
              <AllSentOffersChart />
              <AllSentOffersValueChart />
              <OrdersCountChart />
              <OrdersValueChart />
              <RealisationValueChart />
            </ProtectedContent>

            <ProtectedContent onlyFor={['BUYER']}>
              <AllProjectsChart />
              <LatestBuyersOrdersChart />
              <BuyersOffersCountChart />
              <BuyersOrdersValueChart />
              <RealisationValueChart />
              <BuyersUndeliveredOrdersValue />
            </ProtectedContent>

            <ProtectedContent onlyFor={['MANUFACTURER']}>
              <AllProjectsChart />
              <ManufacturerAcceptedOffersChart />
              <ManufacturerOrdersValueChart />
              <ManufacturerDeliveredOrdersValueChart />
              <ManufacturerUndeliveredOrdersValueChart />
              <RealisationValueChart />
            </ProtectedContent>
          </Charts>

          <Space vertical="3rem" />
          <LatestProjectsSlider />
        </Main>
      </Container>
    </Wrapper>
  );
};

export { IconWrapper, Number, Dashboard as default };
