import gql from 'graphql-tag';

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $userId: String!
    $password: String!
    $sendCredentials: Boolean
  ) {
    changePassword(
      userId: $userId
      password: $password
      sendCredentials: $sendCredentials
    )
  }
`;

export default CHANGE_PASSWORD;
