import React, { useState } from 'react';
import styled from 'styled-components';
import { HiChevronLeft } from 'react-icons/hi';
/*import { GrContact } from "react-icons/gr";
import { NavLink } from "react-router-dom";*/

const HideSidebar = styled.div`
  top: 24px;
  position: absolute;
  right: -1rem;
  background: ${({ theme }) => theme.colors.sidebarBorder};
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.1s ease;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.black};
    border-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Collapse = ({ onClick }) => {
  return (
    <HideSidebar onClick={onClick}>
      <HiChevronLeft size="20px" />
    </HideSidebar>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 70px);
  width: 400px;
  transition: all 0.5s ease;
  ${({ hidden }) => (hidden ? 'margin-left: -260px' : 'margin-left: 0')};
  box-shadow: ${({ theme }) => theme.shadow.depth.half};
  background-color: ${({ theme }) => theme.colors.sidebarBackground};
  padding: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
  :hover ${HideSidebar} {
    opacity: 1;
  }
  ${HideSidebar} {
    ${({ hidden }) => hidden && `transform: rotate(180deg)`}
  }
`;

const Content = styled.div`
  overflow: hidden;
  margin: 0 0 3rem 0;
  position: sticky;
  top: 0;
  height: 100vh;
`;

// const Footer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 1rem;
//   > svg {
//     color: ${({ theme }) => theme.colors.sidebarBorder};
//   }
// `;

// const Links = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
// `;

const Sidebar = ({ children }) => {
  const [hidden, setHidden] = useState(false);
  const toggleCollapse = () => {
    setHidden(!hidden);
  };
  return (
    <Wrapper hidden={hidden}>
      {/* <Collapse onClick={toggleCollapse} /> */}
      <Content>{children}</Content>
      {/*<Footer>
        <GrContact size="2rem" />
        <p>Za sve informacije kontaktirajte nas preko linkova ispod</p>
        <Links>
          <NavLink to="/contakt">Kontakt</NavLink>
          <NavLink to="/feedback">Pošalji prijedlog</NavLink>
        </Links>
      </Footer>*/}
    </Wrapper>
  );
};

export default Sidebar;
