import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Error = styled.div`
  font-size: 12px;
  color: red;
  font-weight: 600;
`;

const Label = ({ children, error, ...otherProps }) => {
  return (
    <Wrapper>
      <label {...otherProps}>{children}</label>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default Label;
