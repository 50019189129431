import React from "react";
import styled from "styled-components";
import Segment from "../../../components/structure/wrappers/Segment/Segment.component";
import Distributor from "../../../components/structure/wrappers/Distributor/Distributor.component";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaGlobeEurope,
  FaCalendarCheck,
} from "react-icons/fa";
import countries from "../../../util/countries";
import useTranslation from "src/hooks/useTranslation";

const Wrapper = styled.div`
  p {
    white-space: pre-wrap;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;
  }
  svg {
    color: ${({ theme }) => theme.colors.infoIcons};
    margin-right: 2rem;
  }
  margin-bottom: 1rem;
  span:nth-child(2) {
    text-align: left;
  }
`;

const Module = styled.div`
  margin-bottom: 2rem;
`;

const BasicInfo = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Distributor col="2-5" gap="4rem">
      <Segment>
        <Module>
          <h3>{t("Kontakt")}</h3>
          <InfoRow>
            <span>
              <FaPhoneAlt size="16px" />
            </span>
            <span>
              <a href={`tel:${data?.contactInfo.phone}`}>
                {data?.contactInfo.phone}
              </a>
            </span>
          </InfoRow>
          <InfoRow>
            <span>
              <FaEnvelope size="16px" />
            </span>
            <span>
              <a href={`mailto:${data?.contactInfo.email}`}>
                {data?.contactInfo.email}
              </a>
            </span>
          </InfoRow>
          <InfoRow>
            <span>
              <FaGlobeEurope size="16px" />
            </span>
            <span>
              {data?.contactInfo.street}, {data?.contactInfo.city} <br />
              {countries[data?.contactInfo.country]}
              <br />
              {data?.contactInfo.zipCode}
            </span>
          </InfoRow>
          <InfoRow>
            <span>
              <FaCalendarCheck size="16px" />
            </span>
            <span>{data?.dateFounded}</span>
          </InfoRow>
        </Module>
        <Module>
          <h3>{t("Reference")}</h3>
          {data?.references.map((reference, i) => (
            <p key={i}>{reference}</p>
          ))}
        </Module>
      </Segment>
      <Wrapper>
        <Module>
          <h2>{t("O firmi")}</h2>
          <p>{data?.aboutCompany}</p>
        </Module>
      </Wrapper>
    </Distributor>
  );
};

export default BasicInfo;
