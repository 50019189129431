import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  padding: 0.2rem;
  border: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  border-radius: 3px;
  overflow: hidden;
  height: 1rem;
`;

const Progress = styled.div`
  width: ${({ length }) => `${length}%`};
  height: 100%;
  background: ${({ color, theme }) => (color ? color : theme.colors.accent)};
`;

const Bar = ({ length, color, max }) => {
  return (
    <Wrapper>
      {length && (
        <Progress length={(length / max) * 100} color={color} max={max} />
      )}
    </Wrapper>
  );
};

export default Bar;
