import dayjs from 'dayjs';
import { createContext, useEffect, useState } from 'react';

import('dayjs/locale/bs');

const locales = {
  bs: import('dayjs/locale/hr'),
  de: import('dayjs/locale/de'),
};

const currentUserContext = createContext({
  user: {},
  setCurrentUser: () => {},
  authorized: false,
});

const CurrentUserProvider = ({ children }) => {
  const [user, setCurrentUser] = useState({});
  const [authorized, setAuthorized] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(null);

  const setDayJsLocale = (language) => {
    locales[language].then((e) => {
      const altLang = language === 'bs' ? 'hr' : language;
      dayjs.locale(altLang);
    });
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('user'));
    if (token) {
      setCurrentUser(token);
    }
  }, []);

  return (
    <currentUserContext.Provider
      value={{
        setCurrentUser,
        user,
        authorized,
        setAuthorized,
        unreadMessages,
        setUnreadMessages,
        setDayJsLocale,
      }}
    >
      {children}
    </currentUserContext.Provider>
  );
};

export { currentUserContext, CurrentUserProvider as default };
