import React, { useContext, useState } from 'react';
import Input from '../../../elements/Input/Input.component';
import Label from '../../../elements/Label/Label.component';
import FormField from '../../wrappers/FormField/FormField.component';
import Distributor from '../../wrappers/Distributor/Distributor.component';
import Button from '../../../elements/Button/Button.component';
import Select from '../../../elements/Select/Select.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addUserFormSchema } from '../../../../validation/schema';
import CREATE_USER from '../../../../apollo/mutations/createUser';
import GET_COMPANIES from '../../../../apollo/queries/getCompanies';
import { useMutation, useQuery } from '@apollo/client';
import { ModalContext } from '../../Modal/modalContext';
import toast from 'react-hot-toast';
import { LANGUAGES, ROLE_ENUMS, STATUS_ENUMS } from '../../../../constants';

const AddUserForm = ({ onSuccess }) => {
  const { handleModal } = useContext(ModalContext);
  const [existingCompany, setExistingCompany] = useState('');
  const [companySettingsId, setCompanySettingsId] = useState('');

  const { data, loading: loadingCompanies } = useQuery(GET_COMPANIES);
  const [createUserMutation, { loading, error }] = useMutation(CREATE_USER, {
    refetchQueries: ['getAllUsers'],
    onCompleted: () => {
      toast.success('Korisnik uspješno dodan');
      handleModal();
    },
    onError: () => {},
  });

  /* data validation */
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    resolver: yupResolver(addUserFormSchema),
  });

  const onSubmit = (data) => {
    if (!getValues('companyName') && !existingCompany)
      return toast.error('Niste unijeli niti odabrali naziv firme');

    const variables = {
      ...data,
      attributes: {
        reseller: data?.reseller,
      },
      existingCompany,
      companySettingsId,
    };

    console.log(variables);

    createUserMutation({
      variables: variables,
    });
  };

  const assignExistingCompany = (e) => {
    const { value } = e.target;
    setExistingCompany(value);
    setValue('companyName', '');
  };

  const assignCompanySettingsId = (e) => {
    const { value } = e.target;
    setCompanySettingsId(value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <h2>Dodaj novog korisnika</h2>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.username?.message}>Korisničko ime</Label>
          <Input
            type="text"
            name="username"
            ref={register}
            autocomplete="off"
          />
        </FormField>
        <FormField>
          <Label error={errors.password?.message}>Password</Label>
          <Input
            type="password"
            name="password"
            ref={register}
            autocomplete="new-password"
          />
        </FormField>
      </Distributor>
      <FormField>
        <Label error={errors.email?.message}>Email korisnika</Label>
        <Input type="email" name="email" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.name?.message}>Puno ime i prezime</Label>
        <Input type="text" name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.phoneNumber?.message}>Broj telefona</Label>
        <Input
          type="tel"
          pattern="+[0-9]{3}-[0-9]{2}-[0-9]{3}-[0-9]{3}"
          placeholder="+387-61-221-223"
          name="phoneNumber"
          ref={register}
        />
      </FormField>
      <FormField>
        <Label error={errors.language?.message}>
          Jezik sučelja (def. bosanski)
        </Label>
        <Select name="language" ref={register}>
          {Object.keys(LANGUAGES).map((key) => (
            <option value={key}>{LANGUAGES[key]}</option>
          ))}
        </Select>
      </FormField>
      {!existingCompany && (
        <React.Fragment>
          <FormField>
            <Label>Ime firme</Label>
            <Input type="text" name="companyName" ref={register} />
          </FormField>
          <Distributor col="1-2">
            <FormField>
              <Label>Inicijali firme</Label>
              <Input type="text" name="initials" ref={register} />
            </FormField>
            <FormField>
              <Label>Učitaj postavke sa firme</Label>
              <Select
                loading={loadingCompanies}
                name="copySettingsFromCompany"
                value={companySettingsId}
                onChange={assignCompanySettingsId}
              >
                {data &&
                  data?.getCompanies?.map((company) => (
                    <option key={company._id} value={company._id}>
                      {company.name}
                    </option>
                  ))}
              </Select>
            </FormField>
          </Distributor>
        </React.Fragment>
      )}
      <FormField>
        <Label>Pridruži postojećoj firmi</Label>
        <Select
          loading={loadingCompanies}
          name="existingCompany"
          value={existingCompany}
          onChange={assignExistingCompany}
        >
          {data &&
            data.getCompanies.map((company) => (
              <option key={company._id} value={company._id}>
                {company.name}
              </option>
            ))}
        </Select>
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.role?.message}>Uloga korisnika</Label>
          <Select name="role" ref={register}>
            {ROLE_ENUMS.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label error={errors.status?.message}>
            Status korisničkog računa
          </Label>
          <Select name="status" ref={register}>
            {STATUS_ENUMS.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Select>
        </FormField>
      </Distributor>
      <FormField>
        <Label error={errors.reseller?.message}>
          Dopusti samo ciljane cijene (preprodavač)
        </Label>
        <Select name="reseller" ref={register}>
          <option value={true}>Da</option>
          <option value={false}>Ne</option>
        </Select>
      </FormField>
      <FormField>
        <Distributor stack="end" submitButtons>
          <Button>Sačuvaj i nastavi</Button>
        </Distributor>
      </FormField>
    </form>
  );
};

export default AddUserForm;
