import gql from 'graphql-tag';

const GET_ALL_ADDITIONAL_CHARACTERISTICS = gql`
  query getAllAdditionalCharacteristics {
    getAllAdditionalCharacteristics {
      id
      category
      name
      description
    }
  }
`;

export default GET_ALL_ADDITIONAL_CHARACTERISTICS;
