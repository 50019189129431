import React from "react";
import styled from "styled-components";
import Spinner from "../Spinner/Spinner.component";

const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: ${({ layout }) => (layout ? layout : "auto")};
  th,
  td {
    padding: 0.8rem;
    line-height: 1;
  }
  th {
    background: ${({ theme }) => theme.colors.white};
    text-align: left;
    font-size: 0.8rem;
  }
`;

const TableCell = styled.td`
  text-align: ${({ align }) => (align ? align : "left")};
  > img {
    width: 130px;
    height: 50px;
    object-fit: contain;
  }
`;
const TableRow = styled.tr`
  ${({ activeRow, theme }) =>
    activeRow &&
    `
      background: #daffde;
      border: 2px solid #adf4b5;
      color: #3c7742;
    `};
  :hover td {
    background-color: ${({ theme }) => theme.colors.menuHighlight};
    cursor: pointer;
  }
  :not(:last-child) td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  }
`;
const TableBody = styled.tbody``;
const TableHead = styled.thead`
  > tr {
    position: relative;
    :after {
      ${({ theme }) => theme.shadow.depth.sticky};
    }
  }
  ${({ sticky }) =>
    sticky &&
    `
    position: sticky;
    top: ${sticky};
    z-index: 2;
  `};
`;
const TableHeader = styled.th``;
const ActionHeader = styled.th`
  width: 4rem;
`;
const ActionCell = styled.td`
  width: 4rem;
  svg {
    color: ${({ theme }) => theme.colors.formPlaceholder};
  }
`;

const BasicTable = ({ children, layout, loading }) => {
  return loading ? <Spinner /> : <Wrapper layout={layout}>{children}</Wrapper>;
};

export {
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableHeader,
  ActionCell,
  ActionHeader,
  BasicTable as default,
};
