import gql from "graphql-tag";

const CREATE_MACHINE = gql`
  mutation createMachine(
    $companyId: String!
    $name: String!
    $replaceMachineId: ID
    $manufacturer: String!
    $control: String
    $manufacturedYear: Int
    $technologies: [String]
    $quantity: Int
    $image: String!
    $gallery: [String]
    $type: String
    $idNumber: String
    $numberOfWorkSpindles: Int
    $spindlePowerKW: Int
    $numberOfRevolverHeads: Int
    $numberOfTools: Int
    $drivenTools: Boolean
    $numberOfDrivenTools: Int
    $numberOfAxis: Int
    $processingDimension: PDTypeInput
    $materialAdder: Boolean
    $machinePurpose: String
  ) {
    createMachine(
      companyId: $companyId
      name: $name
      replaceMachineId: $replaceMachineId
      manufacturer: $manufacturer
      control: $control
      manufacturedYear: $manufacturedYear
      technologies: $technologies
      quantity: $quantity
      image: $image
      gallery: $gallery
      type: $type
      idNumber: $idNumber
      numberOfWorkSpindles: $numberOfWorkSpindles
      spindlePowerKW: $spindlePowerKW
      numberOfRevolverHeads: $numberOfRevolverHeads
      numberOfTools: $numberOfTools
      drivenTools: $drivenTools
      numberOfDrivenTools: $numberOfDrivenTools
      numberOfAxis: $numberOfAxis
      processingDimension: $processingDimension
      materialAdder: $materialAdder
      machinePurpose: $machinePurpose
    ) {
      _id
    }
  }
`;

export default CREATE_MACHINE;
