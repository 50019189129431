/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/

import dayjs from 'dayjs';
import { get } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

export const formatFilename = (folder) => {
  return `${folder}/${uuidv4()}`;
};

export const pastDue = (date) => {
  const today = dayjs();
  const deadline = dayjs(date);
  return deadline.diff(today, 'hour');
};

export const sortNaturally = (arr, compare) => {
  if (!arr) return;

  return arr.sort((a, b) =>
    get(a, compare).localeCompare(get(b, compare), undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  );
};
