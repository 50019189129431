import { uniqBy } from "lodash";
import React from "react";
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/elements/BasicTable/BasicTable.component";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const Materials = ({ data }) => {
  const parsedData = uniqBy(data, "category.id").map((cat) => ({
    category: cat.category,
    items: data.filter((i) => i.category.id === cat.category.id),
  }));
  console.log(parsedData);
  return parsedData.map((c) => (
    <Wrapper>
      <h3>{c.category.name}</h3>
      <BasicTable layout="auto">
        <TableHead>
          <TableRow>
            <TableHeader>Naziv materijala</TableHeader>
            <TableHeader>Oznaka materijala</TableHeader>
            <TableHeader>Standard materijala</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {c.items?.map((material) => (
            <TableRow key={material.id}>
              <TableCell>{material.name}</TableCell>
              <TableCell>{material.materialDesignation}</TableCell>
              <TableCell>{material.materialStandard}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BasicTable>
    </Wrapper>
  ));
};

export default Materials;
