import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineTag } from 'react-icons/ai';
import { HiOutlineCash } from 'react-icons/hi';
import Button from 'src/components/elements/Button/Button.component';
import Input from 'src/components/elements/Input/Input.component';
import Space from 'src/components/elements/Space/Space.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import useTranslation from 'src/hooks/useTranslation';
import { Search } from 'src/pages/Materials/Materials.page';
import styled from 'styled-components';
import GET_ALL_ORDERS from '../../../apollo/queries/getAllOrders';
import BasicTable, {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/elements/BasicTable/BasicTable.component';
import Spinner from '../../../components/elements/Spinner/Spinner.component';
import { ModalContext } from '../../../components/structure/Modal/modalContext';
import ApproveOfferForBuyer from './ApproveOfferForBuyer';
import ApproveOrder from './ApproveOrder';

const Wrapper = styled.div``;
const CounterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

const Total = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
`;

const OrdersList = ({ onlyUnrevised, onlyUnapproved, delivered, stats }) => {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const { data, loading } = useQuery(GET_ALL_ORDERS);
  const [orders, setOrders] = useState([]);
  const [basicFields, setBasicFields] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [filter, setFilter] = useState('');
  const clearFilter = () => setFilter('');
  const handleChange = ({ target: { value } }) => {
    setFilter(value);
  };
  const [orderTotal, setOrderTotal] = useState(0);

  useEffect(() => {
    // set order total value
    setOrderTotal(
      orders
        ?.reduce(
          (acc, currPosition) =>
            currPosition.acceptedOffers.reduce(
              (accOffer, currOffer) =>
                acc + currOffer.quantity * currOffer.refinedPrice,
              0
            ),
          0
        )
        .toLocaleString('de', {
          minimumFractionDigits: 2,
        })
    );
  }, [orders]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchString(filter);
  };

  // TODO: Update cache after mutation - "delivered.toBuyers = TRUE" so user can't click and send again

  const handleModalContent = (data) => {
    if (onlyUnapproved) {
      handleModal(<ApproveOrder data={data} />);
    } else if (onlyUnrevised) {
      handleModal(<ApproveOfferForBuyer data={data} />);
    } else {
      handleModal(<ApproveOrder data={data} readOnly delivered={delivered} />);
    }
  };

  useEffect(() => {
    if (data && data?.getAllOrders.length) {
      // filter by search string first
      const filteredData = data?.getAllOrders?.filter((order) => {
        return (
          order?.project?.name
            ?.toLowerCase()
            .indexOf(searchString?.toLowerCase()) !== -1 ||
          order?.project?.projectCode
            ?.toLowerCase()
            .indexOf(searchString?.toLowerCase()) !== -1
        );
      });

      if (onlyUnapproved) {
        /* these are orders that needs to be revised by administrator FOR BUYER */
        setBasicFields(true);
        setOrders(
          filteredData?.filter(
            (order) =>
              !order?.approved?.byAdministrator &&
              !order?.delivered?.toBuyers &&
              !order?.manufacturer?.recievedOrder
          )
        );
      } else if (onlyUnrevised) {
        /* these are orders that needs to be revised  */
        setBasicFields(true);
        setOrders(
          filteredData?.filter(
            (order) =>
              order?.accepted?.byBuyer &&
              order?.delivered?.toBuyers &&
              !order?.manufacturer?.recievedOrder
          )
        );
      } else if (delivered) {
        setBasicFields(true);
        setOrders(
          filteredData?.filter((order) => order?.manufacturer?.recievedOrder)
        );
      } else {
        setBasicFields(false);
        setOrders(filteredData);
      }
    }
  }, [data, onlyUnapproved, onlyUnrevised, delivered, searchString]);

  return (
    <Wrapper>
      <Distributor sticky stack="end">
        <Search onSubmit={handleSearch}>
          <Input
            placeholder="Pretraga..."
            onChange={handleChange}
            value={filter}
          />
          <Button onClick={handleSearch}>Pretraga</Button>
          <Button onClick={clearFilter} layout="hollow">
            Poništi pretragu
          </Button>
        </Search>
      </Distributor>
      <Space vertical="2rem" />
      {loading ? (
        <Spinner />
      ) : orders.length ? (
        <Wrapper>
          <CounterWrapper>
            <Total>
              <AiOutlineTag size="20px" />
              {t('Ukupno')}: {orders?.length}
            </Total>
            <Total>
              <HiOutlineCash size="20px" />
              {t('Vrijednost')}: {orderTotal} EUR
            </Total>
          </CounterWrapper>
          <BasicTable>
            <TableHead sticky="0">
              <TableRow>
                <TableHeader>Firma kupca</TableHeader>
                <TableHeader>Ime kupca</TableHeader>
                <TableHeader>Naziv projekta</TableHeader>
                <TableHeader>Šifra projekta</TableHeader>
                {!basicFields && <TableHeader>Datum upita</TableHeader>}
                <TableHeader>Broj stavki</TableHeader>
                {!basicFields && <TableHeader>Urađena ponuda</TableHeader>}
                {!basicFields && <TableHeader>Datum ponude</TableHeader>}
                {!basicFields && <TableHeader>Prim. narudžba</TableHeader>}
                <TableHeader>Br. narudžbe</TableHeader>
                <TableHeader>Prim. datum</TableHeader>
                {!basicFields && <TableHeader>Pos. narudžba</TableHeader>}
                {!basicFields && <TableHeader>Pos. datum</TableHeader>}
                {!basicFields && <TableHeader>Datum isporuke</TableHeader>}
                <TableHeader>Isporučeno kom.</TableHeader>
                <TableHeader>Potvrđeno kom.</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders &&
                orders.map((order) => (
                  <TableRow
                    key={order._id}
                    onClick={() => handleModalContent(order)}
                  >
                    <TableCell>
                      {order.project.createdBy.company.name}
                    </TableCell>
                    <TableCell>{order.project.createdBy.name}</TableCell>
                    <TableCell>{order.project.name}</TableCell>
                    <TableCell>{order.project.projectCode}</TableCell>
                    {!basicFields && (
                      <TableCell>{order.project.published.date}</TableCell>
                    )}
                    <TableCell>{order.acceptedOffers.length}</TableCell>
                    {!basicFields && (
                      <TableCell>
                        {order.delivered.toBuyers === false ? 'Ne' : 'Da'}
                      </TableCell>
                    )}
                    {!basicFields && <TableCell>{order.dateCreated}</TableCell>}
                    {!basicFields && (
                      <TableCell>
                        {order.accepted.byBuyer ? t('Da') : ''}
                      </TableCell>
                    )}

                    <TableCell>{order.accepted.physicalOrderNumber}</TableCell>

                    <TableCell>{order.accepted.date}</TableCell>

                    {!basicFields && (
                      <TableCell>
                        {order.manufacturer.recievedOrder ? t('Da') : ''}
                      </TableCell>
                    )}
                    {!basicFields && (
                      <TableCell>{order.manufacturer.date}</TableCell>
                    )}
                    {!basicFields && (
                      <TableCell>{order.project.deliveryDeadline}</TableCell>
                    )}

                    <TableCell>{`${order.acceptedOffers.reduce(
                      (a, c) =>
                        c?.deliveryStatus?.deliveredByManufacturer?.status
                          ? a + 1
                          : a,
                      0
                    )}/${order.acceptedOffers.reduce(
                      (a, c) => (c.acceptedByBuyer ? a + 1 : a),
                      0
                    )}`}</TableCell>

                    <TableCell>{`${order.acceptedOffers.reduce(
                      (a, c) =>
                        c?.deliveryStatus?.deliveredByManufacturer
                          ?.confirmedDelivery?.status
                          ? a + 1
                          : a,
                      0
                    )}/${order.acceptedOffers.reduce(
                      (a, c) => (c.acceptedByBuyer ? a + 1 : a),
                      0
                    )}`}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </BasicTable>
        </Wrapper>
      ) : (
        'Nemate ponuda'
      )}
    </Wrapper>
  );
};

export { CounterWrapper, Total, OrdersList as default };
