import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { FaEnvelope, FaInfoCircle, FaPhone } from 'react-icons/fa';
import { Redirect, useLocation } from 'react-router-dom';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import LOGIN_USER from '../../apollo/mutations/loginUser';
import backgroundImage from '../../assets/images/login/background.jpeg';
import balkanFlag from '../../assets/images/login/balkan-flag.svg';
import enFlag from '../../assets/images/login/en-flag.svg';
import deFlag from '../../assets/images/login/de-flag.svg';
import loginImage from '../../assets/images/login/image.jpg';
import { currentUserContext } from '../../components/Auth/currentUserContext/currentUser.context';
import Button from '../../components/elements/Button/Button.component';
import Input from '../../components/elements/Input/Input.component';
import Label from '../../components/elements/Label/Label.component';
import Space from '../../components/elements/Space/Space.component';
import Logo from '../../components/structure/Header/Logo/Logo.component';
import Distributor from '../../components/structure/wrappers/Distributor/Distributor.component';
import FormField from '../../components/structure/wrappers/FormField/FormField.component';
import { loginSchema } from '../../validation/schema';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
      to bottom,
      rgb(255 255 255),
      rgb(231 231 231 / 80%)
    ),
    url(${backgroundImage});
  background-size: cover;
`;

const LoginForm = styled.div`
  h2 {
    font-weight: 700;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 60px;
  top: 60px;
`;

const ContentWrapper = styled.div`
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 20px 20px 0px rgb(0 0 0 / 9%);
`;
const Module = styled.div`
  width: 400px;
  padding: 4rem;
  background: white;
  ${({ left }) =>
    left &&
    `
  color: #fff;
  background-image: linear-gradient(90deg, rgb(0 0 0 / 95%) 0%, rgb(61 61 61 / 70%) 100%),
    url(${loginImage});
  width: 400px;
  h1 {
    font-weight: 700;
    position: relative;
    :after {
    content: '';
    display: block;
    height: 7px;
    width: 3rem;
    position: absolute;
    background: #ff5d38;
    bottom: -20px;
    border-radius: 5px;
    }
  }
  `}
`;

const Contact = styled.div`
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.7rem;
  }
`;

const Flags = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > img {
    height: 30px;
    width: 30px;
    :hover {
      cursor: pointer;
    }
  }
  > img:not(:last-child) {
    margin-right: 1rem;
  }
`;

const Login = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState('de');
  const { setCurrentUser, setDayJsLocale } = useContext(currentUserContext);
  const location = useLocation();
  const { from } = location?.state || { from: { pathname: '/' } };
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const [loginMutation, { loading }] = useMutation(LOGIN_USER, {
    onCompleted: ({ loginUser: { token, user } }) => {
      localStorage.setItem('user', JSON.stringify({ token, user }));
      setCurrentUser({ token, user });
      setRedirectToReferrer(true);
      setDayJsLocale(user.language);
      // add user to GA event
      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          username: user.name,
          userId: user.id,
        },
      });
    },
    onError: () => {},
  });

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  const onSubmit = (values) => {
    loginMutation({ variables: { ...values } });
  };

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo height="120px" />
      </LogoWrapper>
      <ContentWrapper>
        <Module left>
          <h1>{t('LoginIntroHeading', language)}</h1>
          <Space vertical="20px" />
          <p>{t('LoginIntroText', language)}</p>
          <Space vertical="20px" />
          <Contact>
            <FaPhone />
            <a href="tel:+49 (0) 1766 411 9520">+49 (0) 1766 411 9520</a>
          </Contact>
          <Contact>
            <FaEnvelope />
            <a href="mailto:engineering@innotech-solutions.de">
              engineering@innotech-solutions.de
            </a>
          </Contact>
          <Contact>
            <FaEnvelope />
            <a href="mailto:info@innotech-solutions.de">
              info@innotech-solutions.de
            </a>
          </Contact>
          <Contact>
            <FaInfoCircle />
            <a
              href="https://component-providing.com"
              target="_blank"
              rel="noreferrer"
            >
              {t('Više informacija', language)}
            </a>
          </Contact>
        </Module>
        <Module>
          <Flags>
            <img
              src={deFlag}
              alt="German flag"
              title="Deutschland"
              onClick={() => setLanguage('de')}
            />
            <img
              src={balkanFlag}
              title="Balkan"
              alt="Balkan flag"
              onClick={() => setLanguage('ba')}
            />
            <img
              src={enFlag}
              title="English"
              alt="England flag"
              onClick={() => setLanguage('en')}
            />
          </Flags>
          <LoginForm>
            <h2>On-Line Component Providing Portal</h2>
            <p>{t('LoginExplanation', language)}</p>
            <Space vertical="10px" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormField>
                <Label error={errors.username?.message}>
                  {t('Korisničko ime', language)}
                </Label>
                <Input type="text" name="username" ref={register} autoFocus />
              </FormField>
              <FormField>
                <Label error={errors.password?.message}>
                  {t('Lozinka', language)}
                </Label>
                <Input
                  type="password"
                  name="password"
                  autoComplete="off"
                  ref={register}
                />
              </FormField>
              <Space vertical="30px" />
              <Distributor stack="end">
                <Button loading={loading}>{t('Prijava', language)}</Button>
              </Distributor>
            </form>
            <Space vertical="20px" />
          </LoginForm>
        </Module>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Login;
