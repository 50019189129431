import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { pick, pickBy } from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Prompt } from 'react-router';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import UPDATE_COMPANY from '../../../apollo/mutations/updateCompany';
import Button from '../../../components/elements/Button/Button.component';
import { Item } from '../../../components/elements/Configurator/Configurator';
import Input from '../../../components/elements/Input/Input.component';
import Label from '../../../components/elements/Label/Label.component';
import Select from '../../../components/elements/Select/Select.component';
import Spinner from '../../../components/elements/Spinner/Spinner.component';
import Textarea from '../../../components/elements/Textarea/Textarea.component';
import UploadFile from '../../../components/elements/UploadFile/UploadFile.component';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import FormField from '../../../components/structure/wrappers/FormField/FormField.component';
import {
  CURRENCIES,
  DELIVERY_CONDITIONS,
  PAYMENT_METHOD,
  PAYMENT_TIMEFRAME,
} from '../../../constants';
import usePlaceholder from '../../../hooks/usePlaceholder';
import countries from '../../../util/countries';
import { updateCompanyFormSchema } from '../../../validation/schema';
import { Image } from '../../Projects/sidebarItems/EditPosition';
import CheckerBox from 'src/components/elements/CheckerBox/CheckerBox';
import { currentUserContext } from 'src/components/Auth/currentUserContext/currentUser.context';

const AddRemoveFields = styled.div`
  margin-top: 1rem;
`;

const CompanyInfo = ({ companyData }) => {
  const {
    user: { user },
  } = useContext(currentUserContext);
  const { t } = useTranslation();
  const [image] = usePlaceholder(companyData?.logo);

  const [references, setReferences] = useState([]);

  /* data validation */
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(updateCompanyFormSchema),
  });

  const [updateCompanyMutation] = useMutation(UPDATE_COMPANY, {
    onCompleted: () => toast.success('Kompanija uspješno ažurirana'),
    onError: () => {},
  });

  const [logo, setLogo] = useState('');

  const handleAddReference = (e) => {
    e.preventDefault();
    if (!referenceInput) return;
    setReferences([...references, referenceInput]);
    setReferenceInput('');
  };
  const handleRemoveReference = (id) => {
    setReferences(references.filter((_, index) => index !== id));
  };
  const [referenceInput, setReferenceInput] = useState('');
  const handleReferenceInputChange = (e) => {
    const { value } = e.target;
    setReferenceInput(value);
  };

  const onSubmit = (values) => {
    if (!logo) return toast.error('Niste odabrali logo kompanije');

    console.log(values);

    const sanitizedValues = pickBy(values, (value) => value.length > 0);

    const contactInfo = pick(sanitizedValues, [
      'phone',
      'email',
      'website',
      'street',
      'city',
      'country',
      'zipCode',
    ]);
    const other = pick(sanitizedValues, [
      'name',
      'initials',
      'dateFounded',
      'aboutCompany',
      'employeesCount',
    ]);

    const paymentAndTransport = pick(sanitizedValues, [
      'shipmentAddress',
      'paymentSchedule',
      'paymentMethod',
      'paymentTimeframe',
      'currency',
    ]);

    const parsedData = {
      ...other,
      sendCopy: !!values.sendCopy,
      taxPercentage: values?.taxPercentage,
      paymentAndTransport: {
        transportExpenses: parseFloat(values?.transportExpenses),
        ...paymentAndTransport,
      },
      employeesCount: parseInt(other?.employeesCount),
      contactInfo: {
        ...contactInfo,
        zipCode: parseInt(contactInfo?.zipCode),
      },
      logo,
      references: references,
    };
    updateCompanyMutation({
      variables: { companyId: companyData._id, ...parsedData },
      refetchQueries: ['getSingleUser'],
    });
    setUnsaved(false);
  };

  /* load data upon component render */
  useEffect(() => {
    if (companyData) {
      setLogo(companyData.logo);
      ['phone', 'email', 'website', 'street', 'city', 'country', 'zipCode'].map(
        (val) => setValue(val, companyData.contactInfo[val])
      );

      [
        'shipmentAddress',
        'transportExpenses',
        'paymentSchedule',
        'paymentMethod',
        'paymentTimeframe',
        'currency',
      ].map((val) => setValue(val, companyData?.paymentAndTransport[val]));
      Object.keys(companyData).map((val) => setValue(val, companyData[val]));
      setReferences(companyData?.references);
    }
  }, [companyData]);

  const [unsaved, setUnsaved] = useState(true);

  return !companyData ? (
    <Spinner />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Uređivanje podataka firme</h2>
      <Prompt
        message="Da li ste sigurni da želite da pređete na novi tab, sve postavke koje niste snimili će biti izgubljene"
        when={unsaved}
      />
      <Image>{image}</Image>
      <FormField>
        <Label error={errors.logo?.message}>Logo firme</Label>
        <UploadFile
          directory="logos"
          name="logo"
          acceptedformats={['image/png', 'image/jpeg']}
          maxMB="50"
          onSuccess={(filename) => setLogo(filename)}
          current={logo}
        />
      </FormField>
      <FormField>
        <Label error={errors.name?.message}>Ime firme</Label>
        <Input type="text" name="name" ref={register} />
      </FormField>
      <FormField>
        <Label error={errors.initials?.message}>Inicijali firme</Label>
        <Input type="text" name="initials" ref={register} />
      </FormField>
      {['BUYER', 'ADMINISTRATOR', 'MODERATOR'].includes(user?.role) ? (
        <FormField>
          <CheckerBox
            name="sendCopy"
            ref={register}
            value={t('Pošalji projekt na email nakon objave')}
            defaultChecked="false"
          />
        </FormField>
      ) : null}
      <FormField>
        <h3>Kontakt info</h3>
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.phone?.message}>Broj telefona</Label>
          <Input
            type="tel"
            pattern="+[0-9]{3}-[0-9]{2}-[0-9]{3}-[0-9]{3}"
            placeholder="+387-61-221-223"
            name="phone"
            ref={register}
          />
        </FormField>
        <FormField>
          <Label error={errors.email?.message}>Email</Label>
          <Input type="email" name="email" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.street?.message}>Ulica</Label>
          <Input name="street" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.city?.message}>Grad</Label>
          <Input name="city" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.zipCode?.message}>Poštanski broj</Label>
          <Input type="number" name="zipCode" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.country?.message}>Država</Label>
          <Select name="country" ref={register}>
            {Object.keys(countries).map((key) => (
              <option key={key} value={key}>
                {countries[key]}
              </option>
            ))}
          </Select>
        </FormField>
      </Distributor>
      <FormField>
        <Label error={errors.website?.message}>Web stranica</Label>
        <Input type="text" name="website" ref={register} />
      </FormField>
      <FormField>
        <h3>{t('Plaćanje i transport')}</h3>
      </FormField>
      <FormField>
        <Label error={errors.shipmentAddress?.message}>
          {t('Adresa isporuke')}
        </Label>
        <Input type="text" name="shipmentAddress" ref={register} />
      </FormField>
      <Distributor col="1-1-1">
        <FormField>
          <Label error={errors.paymentMethod?.message}>
            {t('Način plaćanja')}
          </Label>
          <Select name="paymentMethod" ref={register}>
            {Object.keys(PAYMENT_METHOD).map((val) => (
              <option key={val} value={val}>
                {PAYMENT_METHOD[val]}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label error={errors.paymentSchedule?.message}>
            {t('Način isporuke')}
            {t('Troškovi transporta')}
          </Label>
          <Select name="paymentSchedule" ref={register}>
            {Object.keys(DELIVERY_CONDITIONS).map((val, i) => (
              <option key={val} value={val}>
                {`${val} - ${DELIVERY_CONDITIONS[val]}`}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label error={errors.taxPercentage?.message}>
            {t('Postotak poreza (npr. PDV)')}
          </Label>
          <Input type="number" step="1" name="taxPercentage" ref={register} />
        </FormField>
      </Distributor>
      <Distributor col="1-1-1">
        <FormField>
          <Label error={errors.transportExpenses?.message}>
            {t('Troškovi transporta')}
          </Label>
          <Input
            type="number"
            step="0.01"
            name="transportExpenses"
            ref={register}
          />
        </FormField>
        <FormField>
          <Label error={errors.paymentTimeframe?.message}>
            {t('Vrijeme za plaćanje')}
          </Label>
          <Select name="paymentTimeframe" ref={register}>
            {Object.keys(PAYMENT_TIMEFRAME).map((p, i) => (
              <option key={i} value={p}>
                {PAYMENT_TIMEFRAME[p]}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label error={errors.currency?.message}>{t('Valuta')}</Label>
          <Select name="currency" ref={register}>
            {Object.keys(CURRENCIES).map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </Select>
        </FormField>
      </Distributor>
      <FormField>
        <h3>{t('O firmi')}</h3>
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.dateFounded?.message}>Datum osnivanja</Label>
          <Input type="date" name="dateFounded" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.employeesCount?.message}>Broj zaposlenih</Label>
          <Input type="number" name="employeesCount" ref={register} />
        </FormField>
      </Distributor>
      <FormField>
        <Label error={errors.aboutCompany?.message}>Ukratko o firmi</Label>
        <Textarea name="aboutCompany" ref={register}></Textarea>
      </FormField>
      <FormField>
        <h3>Reference</h3>
      </FormField>
      <FormField>
        <Distributor col="4-1">
          <FormField>
            <Label>Unesi referencu</Label>
            <Input
              name="reference"
              value={referenceInput}
              onChange={handleReferenceInputChange}
            />
          </FormField>
          <FormField>
            <Button onClick={(e) => handleAddReference(e)}>
              Dodaj referencu
            </Button>
          </FormField>
        </Distributor>

        <AddRemoveFields>
          {references.map((m, i) => (
            <Item key={i} onClick={() => handleRemoveReference(i)}>
              {m}
            </Item>
          ))}
        </AddRemoveFields>
      </FormField>
      <FormField>
        <Distributor stack="end" submitButtons>
          <Button>Sačuvaj izmjene</Button>
        </Distributor>
      </FormField>
    </form>
  );
};

export default CompanyInfo;
