import { useQuery } from "@apollo/client";
import React from "react";
import { BiCoin } from "react-icons/bi";
import GET_PROJECTS from "src/apollo/queries/getProjects";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import { IconWrapper, Number } from "./Dashboard";

const AllProjectsValue = () => {
  const { t } = useTranslation();
  const {
    loading: getProjectsLoading,
    data: { getProjects } = {},
    error: getProjectsError,
  } = useQuery(GET_PROJECTS);

  return (
    <Segment hoverable>
      <IconWrapper>
        <BiCoin size="50px" />
      </IconWrapper>
      <h4>{t("Ukupna vrijednost projekata")}</h4>

      {getProjectsLoading ? (
        <Spinner />
      ) : (
        <Number>
          {getProjects
            ?.reduce((projectValue, project) => {
              const positionsValue = project.positions.reduce(
                (positionValue, position) => {
                  return (
                    positionValue +
                    position.quantities.reduce((quantityValue, quantity) => {
                      return (
                        quantityValue + quantity.aimedPrice * quantity.quantity
                      );
                    }, 0)
                  );
                },
                0
              );
              return projectValue + positionsValue;
            }, 0)
            .toLocaleString("de", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            })}
        </Number>
      )}
    </Segment>
  );
};

export default AllProjectsValue;
