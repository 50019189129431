import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/elements/Button/Button.component';
import Input from '../../../components/elements/Input/Input.component';
import Label from '../../../components/elements/Label/Label.component';
import Spinner from '../../../components/elements/Spinner/Spinner.component';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import FormField from '../../../components/structure/wrappers/FormField/FormField.component';
import Segment from '../../../components/structure/wrappers/Segment/Segment.component';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import REVISE_PRICES from '../../../apollo/mutations/revisePrices';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Select from '../../../components/elements/Select/Select.component';
import Space from '../../../components/elements/Space/Space.component';
import { HeadingTop, HeadingWrapper } from '../ProjectDetails.page';
import { BiLockAlt } from 'react-icons/bi';
import useTranslation from 'src/hooks/useTranslation';
import Acknowledge from 'src/components/Acknowledge/Acknowledge';
import { Preview } from '../tabsContent/PositionDetails';

const Wrapper = styled.div``;
const PositionHeadingWrapper = styled.div``;

const RevisePrices = ({ revised, positionData }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { errors, handleSubmit, register, setValue } = useForm();

  const [revisePricesMutation, { loading }] = useMutation(REVISE_PRICES, {
    onError: () => {},
    onCompleted: () => {
      toast.success('Uspješno revidirano, projekt odobren');
      history.push('/projects');
    },
  });

  const [levels, setLevels] = useState({});
  const handleLevels = (e) => {
    const { name, value } = e.target;
    setLevels({ ...levels, [name]: parseInt(value) });
  };

  useEffect(() => {
    if (positionData) {
      /* auto fill prices */
      positionData.map((position) => {
        /* levels */
        setLevels((currentLevels) => {
          return {
            ...currentLevels,
            [`complexityLevel_${position.id}`]: position.complexityLevel,
            [`toleranceLevel_${position.id}`]: position.toleranceLevel,
          };
        });
        return position.quantities.map((quantity) => {
          /* user prices */
          setValue(
            `aimedPrice_${quantity.id}_${position.id}`,
            quantity.aimedPrice
          );
          /* revised prices */
          const administratorPrice = quantity?.administratorAimedPrice;
          setValue(
            `administratorAimedPrice_${quantity.id}_${position.id}`,
            administratorPrice
          );
          return quantity;
        });
      });
    }
  }, [positionData]);

  const onSubmit = (values) => {
    let positions = {};
    Object.keys(values).map((key) => {
      const map = key.split('_');
      positions[map[2]] = {
        ...positions[map[2]],
        [map[1]]: {
          aimedPrice: '',
          administratorAimedPrice: '',
        },
      };
      return key;
    });

    Object.keys(values).map((key) => {
      const map = key.split('_');
      positions[map[2]][map[1]][map[0]] = values[key];
      return key;
    });

    const parsed = Object.keys(positions).map((position) => ({
      position,
      complexityLevel: levels[`complexityLevel_${position}`],
      toleranceLevel: levels[`toleranceLevel_${position}`],
      quantities: Object.keys(positions[position]).map((quantity) => ({
        _id: quantity,
        aimedPrice: parseFloat(positions[position][quantity].aimedPrice),
        administratorAimedPrice: parseFloat(
          positions[position][quantity].administratorAimedPrice
        ),
      })),
    }));

    if (parsed.some((val) => !val.complexityLevel || !val.toleranceLevel))
      return toast.error('Niste odabrali nivoe tolerancije i složenosti');

    revisePricesMutation({
      variables: { data: parsed, projectId: id },
      refetchQueries: ['getSingleProject', 'getProjects'],
    });
  };

  return positionData?.length ? (
    <Wrapper>
      <HeadingWrapper>
        {revised && (
          <span>
            <BiLockAlt size="20px" />
            {t(
              'Revizija zaključana. Navedeni projekt je već obrađen od strane administratora, te proslijeđen dobavljačima.'
            )}
          </span>
        )}
        <h1>Revidiranje projekta</h1>
      </HeadingWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        {positionData.map((position) => (
          <>
            <Segment key={position.id}>
              <PositionHeadingWrapper>
                <Distributor col="3-4">
                  <FormField>
                    <Label>{t('Naziv pozicije')}</Label>
                    <h2>{position.name}</h2>
                  </FormField>
                  <Distributor col="1-1">
                    <FormField>
                      <Label>Kompleksnost izrade</Label>
                      <Select
                        name={`complexityLevel_${position.id}`}
                        value={levels[`complexityLevel_${position.id}`]}
                        onChange={handleLevels}
                        disabled={revised}
                      >
                        {Array(5)
                          .fill()
                          .map((p, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                      </Select>
                    </FormField>
                    <FormField>
                      <Label>Nivo tolerancije</Label>
                      <Select
                        name={`toleranceLevel_${position.id}`}
                        value={levels[`toleranceLevel_${position.id}`]}
                        onChange={handleLevels}
                        disabled={revised}
                      >
                        {Array(5)
                          .fill()
                          .map((p, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                      </Select>
                    </FormField>
                  </Distributor>
                </Distributor>
                <Space vertical="20px" />
                <Preview>
                  <img
                    src={process.env.REACT_APP_S3_BUCKET + position.files.jpg}
                    alt="Pozicija"
                  />
                </Preview>
              </PositionHeadingWrapper>
              <Space vertical="50px" />
              {position.quantities.map((quantity) => (
                <Distributor col="1-2-2" key={quantity.id}>
                  <FormField>
                    <Label>Količina</Label>
                    <div>{quantity.quantity}</div>
                  </FormField>
                  <FormField>
                    <Label
                      error={
                        errors[`aimedPrice_${quantity.id}_${position.id}`]
                          ?.mesage
                      }
                    >
                      Ciljana cijena od kupca
                    </Label>
                    <Input
                      readOnly
                      name={`aimedPrice_${quantity.id}_${position.id}`}
                      ref={register({
                        number: 'Mora biti broj',
                        required: 'Neophodno polje',
                      })}
                    />
                  </FormField>
                  <FormField>
                    <Label
                      error={
                        errors[
                          `administratorAimedPrice_${quantity.id}_${position.id}`
                        ]?.mesage
                      }
                    >
                      Naša ciljana cijena
                    </Label>
                    <Input
                      readOnly={revised}
                      type="number"
                      step="0.01"
                      name={`administratorAimedPrice_${quantity.id}_${position.id}`}
                      ref={register({
                        number: 'Mora biti broj',
                        required: 'Neophodno polje',
                      })}
                    />
                  </FormField>
                </Distributor>
              ))}
            </Segment>
            <Space vertical="20px" />
          </>
        ))}
        {!revised && (
          <Distributor submitButtons stack="end">
            <Button loading={loading}>Odobri projekt</Button>
          </Distributor>
        )}
      </form>
    </Wrapper>
  ) : loading ? (
    <Spinner />
  ) : (
    <Acknowledge layout="information" title="Nema podataka za revidiranje">
      Vjerovatno nemate navedenu nijednu poziciju, slučaju da nije tako,
      obratite se administratoru
    </Acknowledge>
  );
};

export default RevisePrices;
