import gql from 'graphql-tag';

const GET_USER_CONVERSATIONS = gql`
  query getUserConversations($filter: String) {
    getUserConversations(filter: $filter) {
      _id
      title
      project {
        id
        name
        createdBy {
          id
        }
      }
      position {
        id
        name
        positionCode
        revisedInformations {
          files {
            jpg
          }
        }
        files {
          jpg
        }
      }
      messages {
        _id
        sender {
          id
          name
          company {
            name
          }
        }
        message
        timestamp
      }
      dateCreated
      read
      priority
    }
  }
`;

export default GET_USER_CONVERSATIONS;
