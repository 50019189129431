import gql from "graphql-tag";

const GET_MANUFACTURING_CATEGORIES = gql`
  query getManufacturingCategories {
    getManufacturingCategories {
      id
      name
      parent {
        id
        name
      }
    }
  }
`;

export default GET_MANUFACTURING_CATEGORIES;
