import React from "react";
import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import Authorization from "../Authorization/Authorization";
import { currentUserContext } from "../currentUserContext/currentUser.context";

const ProtectedRoute = ({
  component: Component,
  roles,
  requiresReauthorization,
  ...otherProps
}) => {
  const {
    authorized,
    user: { user },
  } = useContext(currentUserContext);

  if (
    requiresReauthorization &&
    !authorized &&
    !["ADMINISTRATOR", "MODERATOR"].includes(user?.role)
  ) {
    return <Authorization onlyForRoles={roles} />;
  } else {
    return (
      <Route
        {...otherProps}
        render={(props) =>
          auth.isAuthenticated(roles) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
};

const auth = {
  isAuthenticated(roles) {
    const token = localStorage.getItem("user");
    if (token) {
      const {
        user: { role },
      } = JSON.parse(token);

      if (["ADMINISTRATOR", "MODERATOR"].includes(role)) return true;

      if (roles) {
        if (roles.indexOf(role.toLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
};

export default ProtectedRoute;
