import React, { useContext } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Container from '../../components/structure/wrappers/Container/Container.component';
import Main from '../../components/structure/wrappers/Main/Main.component';
import Tabs, { TabItem } from '../../components/elements/Tabs/Tabs.component';
import BasicInfo from './tabsContent/BasicInfo.tab';
import { currentUserContext } from '../../components/Auth/currentUserContext/currentUser.context';
import MachineList from './sidebarItems/MachineList';
import { useQuery } from '@apollo/client';
import GET_SINGLE_USER from '../../apollo/queries/getSingleUser';
import Spinner from '../../components/elements/Spinner/Spinner.component';
import Photos from './sidebarItems/Photos';
import DocumentList from './sidebarItems/DocumentList';
import usePlaceholder from '../../hooks/usePlaceholder';
import useTranslation from 'src/hooks/useTranslation';
import PasswordChangeRequest from './sidebarItems/PasswordChangeRequest';

const Wrapper = styled.div``;

const Titlebar = styled.div`
  ${({ theme }) => theme.gradients.titlebar};
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 4.5rem;
  position: relative;
  z-index: -1;
`;

const Logo = styled.div`
  top: -40px;
  height: 200px;
  width: 200px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  box-shadow: ${({ theme }) => theme.shadow.depth.modal};
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  padding: 1rem;
  overflow: hidden;
  & img {
    width: 100%;
  }
`;

const Info = styled.div`
  position: relative;
  left: 250px;
  padding: 1.5rem 0;
  > h1,
  h4 {
    margin: 0;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  position: relative;
`;

const Technology = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.white};
  padding: 0.3rem 0.8rem;
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
`;

const Technologies = styled.div`
  margin-top: 1rem;
  ${Technology}:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const UserDetails = ({ match, history }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_SINGLE_USER, {
    variables: { userId: match.params.id },

    // TODO: Check why is this needed, parameters cannot be loaded in
    // company.machines.technologies.parameters unless it loads from server directly

    fetchPolicy: 'network-only',
  });
  const [image] = usePlaceholder(data?.getSingleUser?.company?.logo);

  const {
    user: { user },
  } = useContext(currentUserContext);

  const handleEditUser = () => {
    history.push(`/users/${match.params.id}/edit`);
  };
  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Titlebar>
        <Container size="lg">
          <InfoWrapper>
            <Logo>{image}</Logo>
            <Info>
              <h1>{data?.getSingleUser?.company?.name}</h1>
              {/* {data?.getSingleUser?.company?.technologies.length > 0 && (
                <Technologies>
                  {data?.getSingleUser?.company?.technologies?.map((t) => (
                    <Technology key={t._id}>{t.technology.name}</Technology>
                  ))}
                </Technologies>
              )} */}
            </Info>
          </InfoWrapper>
        </Container>
      </Titlebar>
      <Container size="lg">
        <Main>
          <Tabs
            onEdit={
              ['ADMINISTRATOR', 'MODERATOR'].includes(user?.role) &&
              handleEditUser
            }
          >
            <TabItem key="1" active label={t('Opšte informacije')}>
              <BasicInfo data={data?.getSingleUser?.company} />
            </TabItem>
            <TabItem key="2" label={t('Dokumenti')}>
              <DocumentList files={data?.getSingleUser?.company?.documents} />
            </TabItem>
            <TabItem key="3" label={t('Galerija')}>
              <Photos
                gallery={data?.getSingleUser?.company?.photos}
                companyId={data?.getSingleUser?.company?._id}
              />
            </TabItem>
            <TabItem key="4" label={t('Mašine')}>
              <Container size="sm">
                <MachineList data={data?.getSingleUser?.company?.machines} />
              </Container>
            </TabItem>
          </Tabs>
        </Main>
      </Container>
    </Wrapper>
  );
};

export default withRouter(UserDetails);
