import React from "react";
import useTranslation from "src/hooks/useTranslation";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  > span {
    margin-left: 1rem;
  }
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 33px;
    height: 20px;
    background: ${({ theme }) => theme.colors.sidebarBorder};
    display: block;
    border-radius: 100px;
    position: relative;
    margin: 0;
  }

  label:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: ${({ theme }) => theme.colors.accent};
  }

  input:checked + label:after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 20px;
  }
`;

const CheckerBox = React.forwardRef(({ value, name, ...otherProps }, ref) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        ref={ref}
        {...otherProps}
      />
      <label htmlFor={name}></label>
      <span>{t(value)}</span>
    </Wrapper>
  );
});

export default CheckerBox;
