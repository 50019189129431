import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { pick, pickBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Prompt, useParams } from 'react-router-dom';
import CheckerBox from 'src/components/elements/CheckerBox/CheckerBox';
import styled from 'styled-components';
import CHANGE_PASSWORD from '../../../apollo/mutations/changePassword';
import UPDATE_USER from '../../../apollo/mutations/updateUser';
import Button from '../../../components/elements/Button/Button.component';
import Input from '../../../components/elements/Input/Input.component';
import Label from '../../../components/elements/Label/Label.component';
import Select from '../../../components/elements/Select/Select.component';
import Spinner from '../../../components/elements/Spinner/Spinner.component';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import FormField from '../../../components/structure/wrappers/FormField/FormField.component';
import { LANGUAGES, ROLE_ENUMS, STATUS_ENUMS } from '../../../constants';
import {
  changeUserPasswordFormSchema,
  updateUserFormSchema,
} from '../../../validation/schema';

const Wrapper = styled.div``;

const BasicInfo = ({ userData }) => {
  const { id: userId } = useParams();

  const [updateUserMutation, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => toast.success('Korisnički podaci ažurirani'),
  });

  const [changePasswordMutation, { loading: changePasswordLoading }] =
    useMutation(CHANGE_PASSWORD, {
      onCompleted: () => toast.success('Lozinka uspješno promijenjena'),
    });

  /* data validation */
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    resolver: yupResolver(updateUserFormSchema),
  });

  /* change password data validation */
  const {
    register: changePasswordRegister,
    handleSubmit: handleSubmitChangePassword,
    errors: changePasswordErrors,
  } = useForm({
    resolver: yupResolver(changeUserPasswordFormSchema),
  });

  // TODO: Isn't done...

  const onSubmit = (values) => {
    const sanitizedValues = pickBy(values, (value) => value.length > 0);
    if (!sanitizedValues) return;

    updateUserMutation({
      variables: {
        userId,
        ...sanitizedValues,
        attributes: {
          reseller: Boolean(values?.reseller),
          revealManufacturersInfo: Boolean(values?.revealManufacturersInfo),
        },
      },
      refetchQueries: ['getSingleUser'],
    });
    setUnsaved(false);
  };
  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    if (userData) {
      const vals = pick(userData, [
        'name',
        'username',
        'email',
        'phoneNumber',
        'role',
        'status',
        'language',
      ]);
      Object.keys(vals).map((val) => setValue(val, vals[val]));

      // attributes
      if (userData?.attributes) {
        const attr = pick(userData.attributes, [
          'reseller',
          'revealManufacturersInfo',
        ]);
        Object.keys(attr).map((val) => setValue(val, attr[val]));
      }
      setInitialValues(getValues());
    }
  }, [userData]);

  const onSubmitChangePassword = (values) => {
    if (
      prompt(
        'Ako ste sigurni da želite da izmijenite lozinku korisnika upišite: POTVRDA'
      )?.trim() === 'POTVRDA'
    ) {
      changePasswordMutation({
        variables: {
          userId,
          password: values.password,
        },
        refetchQueries: ['getSingleUser'],
      });
    }
  };

  const onSubmitChangePasswordAndSendCredentials = (values) => {
    if (
      prompt(
        'Ako ste sigurni da želite da izmijenite lozinku i pošaljete podatke na email korisnika upišite: POTVRDA'
      )?.trim() === 'POTVRDA'
    ) {
      changePasswordMutation({
        variables: {
          userId,
          password: values.password,
          sendCredentials: true,
        },
        refetchQueries: ['getSingleUser'],
      });
    }
  };

  const [unsaved, setUnsaved] = useState(true);

  return !userData ? (
    <Spinner />
  ) : (
    <Wrapper>
      <Prompt
        message="Da li ste sigurni da želite da pređete na novi tab, sve postavke koje niste snimili će biti izgubljene"
        when={unsaved}
      />
      <h2>Uređivanje podataka korisnika</h2>
      <form>
        <Distributor col="2-2-1-1">
          <FormField>
            <Label error={changePasswordErrors.password?.message}>
              Nova lozinka
            </Label>
            <Input
              type="password"
              name="password"
              autoComplete="on"
              ref={changePasswordRegister}
            />
          </FormField>
          <FormField>
            <Label error={changePasswordErrors.passwordRepeat?.message}>
              Ponovite lozinku
            </Label>
            <Input
              type="password"
              name="passwordRepeat"
              autoComplete="on"
              ref={changePasswordRegister}
            />
          </FormField>
          <FormField>
            <Button
              onClick={handleSubmitChangePassword(onSubmitChangePassword)}
            >
              Izmjena lozinke
            </Button>
          </FormField>
          <FormField>
            <Button
              layout="hollow"
              onClick={handleSubmitChangePassword(
                onSubmitChangePasswordAndSendCredentials
              )}
            >
              Izmjena / Slanje podataka
            </Button>
          </FormField>
        </Distributor>
      </form>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Distributor col="1-1">
          <FormField>
            <Label error={errors.username?.message}>Korisničko ime</Label>
            <Input type="text" name="username" ref={register} />
          </FormField>
          <FormField>
            <Label error={errors.email?.message}>Email korisnika</Label>
            <Input type="text" name="email" ref={register} />
          </FormField>
        </Distributor>
        <Distributor col="1-1">
          <FormField>
            <Label error={errors.name?.message}>Puno ime i prezime</Label>
            <Input type="text" name="name" ref={register} />
          </FormField>
          <FormField>
            <Label error={errors.phoneNumber?.message}>Broj telefona</Label>
            <Input
              type="tel"
              pattern="+[0-9]{3}-[0-9]{2}-[0-9]{3}-[0-9]{3}"
              placeholder="+387-61-221-223"
              name="phoneNumber"
              ref={register}
            />
          </FormField>
        </Distributor>
        <Distributor col="1-1-1">
          <FormField>
            <Label error={errors.role?.message}>Uloga korisnika</Label>
            <Select name="role" ref={register}>
              {ROLE_ENUMS.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField>
            <Label error={errors.status?.message}>
              Status korisničkog računa
            </Label>
            <Select name="status" ref={register}>
              {STATUS_ENUMS.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField>
            <Label error={errors.language?.message}>
              Jezik sučelja (def. bosanski)
            </Label>
            <Select name="language" ref={register}>
              {Object.keys(LANGUAGES).map((key) => (
                <option key={key} value={key}>
                  {LANGUAGES[key]}
                </option>
              ))}
            </Select>
          </FormField>
        </Distributor>
        <Distributor col="1-1">
          <FormField>
            <Label error={errors.reseller?.message}></Label>
            <CheckerBox
              name="reseller"
              ref={register}
              value="Dopusti samo ciljane cijene (preprodavač)"
            />
          </FormField>
          <FormField>
            <Label error={errors.revealManufacturersInfo?.message}></Label>
            <CheckerBox
              name="revealManufacturersInfo"
              ref={register}
              value="Prikaži kvalifikacije proizvođača kupcu"
            />
          </FormField>
        </Distributor>
        <FormField>
          <Distributor stack="end" submitButtons>
            <Button loading={loading}>Sačuvaj izmjene</Button>
          </Distributor>
        </FormField>
      </form>
    </Wrapper>
  );
};

export default BasicInfo;
