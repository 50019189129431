import gql from "graphql-tag";

const CREATE_MATERIAL = gql`
  mutation createMaterial(
    $name: String!
    $replaceMaterialId: ID
    $category: ID!
    $materialDesignation: String
    $materialStandard: String
    $tensileStrength: String
    $hardnessHB: String
    $hardnessHRC: String
    $materialUsage: String
    $companies: [ID]
    $technicalSpecifications: String
  ) {
    createMaterial(
      name: $name
      replaceMaterialId: $replaceMaterialId
      category: $category
      materialDesignation: $materialDesignation
      materialStandard: $materialStandard
      tensileStrength: $tensileStrength
      hardnessHB: $hardnessHB
      hardnessHRC: $hardnessHRC
      materialUsage: $materialUsage
      companies: $companies
      technicalSpecifications: $technicalSpecifications
    )
  }
`;

export default CREATE_MATERIAL;
