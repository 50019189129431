import gql from "graphql-tag";

const LATEST_BUYERS_OFFERS = gql`
  query latestBuyersOffers {
    latestBuyersOffers {
      dateCreated
    }
  }
`;

export default LATEST_BUYERS_OFFERS;
