import { useQuery } from "@apollo/client";
import React from "react";
import { GET_LATEST_PROJECTS } from "src/apollo/queries/getDashboardData";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import Slider from "./Slider";

const LatestProjectsSlider = () => {
  const { t } = useTranslation();
  const {
    loading,
    data: { getLatestProjects } = {},
    error: getLatestError,
  } = useQuery(GET_LATEST_PROJECTS, {
    fetchPolicy: "cache-first",
  });

  return (
    <Segment padding="0" noBg>
      <h2>{t("Najnoviji projekti")}</h2>
      {loading ? (
        <Spinner />
      ) : (
        <Slider
          data={getLatestProjects}
          slides={4}
          fixedWidth="300px"
          skipRow={true}
        />
      )}
    </Segment>
  );
};

export default LatestProjectsSlider;
