import styled from "styled-components";

const ContentWrapper = styled.div`
  max-width: 100%;
  height: calc(100vh - 70px);
  overflow-y: scroll;
  flex: 1;
`;

export default ContentWrapper;
