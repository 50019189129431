import gql from "graphql-tag";

const CONFIRM_DELIVERY = gql`
  mutation confirmDelivery(
    $orderId: ID!
    $acceptedOfferId: ID!
    $delivered: Boolean
  ) {
    confirmDelivery(
      orderId: $orderId
      acceptedOfferId: $acceptedOfferId
      delivered: $delivered
    )
  }
`;

export default CONFIRM_DELIVERY;
