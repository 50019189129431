import gql from "graphql-tag";

const LATEST_BUYERS_ORDERS_VALUE = gql`
  query latestBuyersOrdersValue {
    latestBuyersOrdersValue {
      dateCreated
      value
    }
  }
`;

export default LATEST_BUYERS_ORDERS_VALUE;
