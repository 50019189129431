import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import RECIEVED_POSITION from 'src/apollo/mutations/recievedPosition';
import Button from 'src/components/elements/Button/Button.component';
import Input from 'src/components/elements/Input/Input.component';
import Space from 'src/components/elements/Space/Space.component';
import { ModalContext } from 'src/components/structure/Modal/modalContext';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.almostWhite};
  border-radius: 5px;
  padding: 2rem;
`;

const DeliveryDetails = styled.ul`
  padding-left: 2rem;
  list-style: none;
  position: relative;
  :before {
    display: inline-block;
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    background: ${({ theme }) => theme.colors.almostWhite};
    left: 2rem;
    top: 0;
  }
  li:not(:last-child) {
    padding-bottom: 2rem;
  }
`;
const DeliveryRow = styled.li`
  counter-increment: list;
  padding-left: 2rem;
  position: relative;
  :after {
    left: -1rem;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.almostWhite};
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: inline-block;
    content: '';
    top: -0.3rem;
  }
  ${({ done, theme }) =>
    done &&
    `
      :after {
        border-color: ${theme.colors.accent};
        content: "✔";
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.colors.accent};
        font-size: 1.3rem;
      }
      :before {
        display: inline-block;
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        background: ${theme.colors.accent};
        left: 0;
        top: 0;
      }
  `}
`;
const DeliveryRowLabel = styled.div`
  font-weight: 600;
`;
const DeliveryRowInfo = styled.div`
  font-size: 0.8rem;
  margin-top: 1rem;
  span {
    margin-right: 1rem;
    color: ${({ theme }) => theme.colors.darkerGrey};
  }
`;

const PositionDeliveryProgress = ({ orderId, positionId, data }) => {
  const { t } = useTranslation();
  const { register, getValues } = useForm();
  const { handleModal } = useContext(ModalContext);
  const [recievedPositionMutation, { loading: recievedPositionLoading }] =
    useMutation(RECIEVED_POSITION, {
      onCompleted: () => {
        toast.success(t('Pozicija označena kao isporučena'));
        handleModal();
      },
      onError: () => {},
    });

  const handleRecievePosition = () => {
    if (getValues('date') && positionId) {
      if (
        prompt(t('Za potvrdu primanja robe upišite POTVRDA')).trim() ===
        t('POTVRDA')
      ) {
        recievedPositionMutation({
          variables: {
            orderId,
            positionId,
            quantity: data.quantity,
            date: getValues('date'),
          },
          refetchQueries: ['getBuyersOrders'],
        });
      }
    }
  };

  console.log(data);
  return (
    <Wrapper>
      <p>
        {t('Ovdje možete vidjeti napredak isporuke i izrade vaše narudžbe')}
      </p>
      <Space vertical="2rem" />
      <Segment border="1px" radius="5px">
        <DeliveryDetails>
          <DeliveryRow done={data.acceptedByBuyer}>
            <DeliveryRowLabel>{t('Prihvaćena cijena')}</DeliveryRowLabel>
            {data.acceptedByBuyer ? (
              <DeliveryRowInfo>
                <span>{dayjs(new Date()).format('DD.MM.YYYY | HH:mm')}</span>
                {t('Nalog za izradu komponenti poslan dobavljaču')}
              </DeliveryRowInfo>
            ) : null}
          </DeliveryRow>
          <DeliveryRow
            done={data.deliveryStatus.deliveredByManufacturer.status}
          >
            <DeliveryRowLabel>{t('U procesu isporuke')}</DeliveryRowLabel>
            {data.deliveryStatus.deliveredByManufacturer.status ? (
              <DeliveryRowInfo>
                <span>
                  {dayjs(
                    data.deliveryStatus.deliveredByManufacturer.date
                  ).format('DD.MM.YYYY')}
                </span>
                {t('Pošiljka je u procesu izrade i pripreme za isporuku')}
              </DeliveryRowInfo>
            ) : null}
          </DeliveryRow>
          <DeliveryRow
            done={
              data.deliveryStatus.deliveredByManufacturer.confirmedDelivery
                .status
            }
          >
            <DeliveryRowLabel>{t('Potvrđena isporuka')}</DeliveryRowLabel>
            {data.deliveryStatus.deliveredByManufacturer.confirmedDelivery
              .status ? (
              <DeliveryRowInfo>
                <span>
                  {dayjs(
                    data.deliveryStatus.deliveredByManufacturer
                      .confirmedDelivery.date
                  ).format('DD.MM.YYYY')}
                </span>
                {t('Pošiljka je u tranzitu')}
              </DeliveryRowInfo>
            ) : null}
          </DeliveryRow>
          <DeliveryRow done={data.deliveryStatus.recievedByBuyer.status}>
            <DeliveryRowLabel>{t('Roba primljena')}</DeliveryRowLabel>
            {data.deliveryStatus.recievedByBuyer.status ? (
              <DeliveryRowInfo>
                <span>
                  {dayjs(data.deliveryStatus.recievedByBuyer.date).format(
                    'DD.MM.YYYY'
                  )}
                </span>
                {t('Roba je naznačena kao primljena')}
              </DeliveryRowInfo>
            ) : null}
          </DeliveryRow>
        </DeliveryDetails>
      </Segment>
      <Space vertical="2rem" />
      <Distributor col="1-1" stack="end">
        <Input
          type="date"
          name="date"
          width="100%"
          ref={register}
          readOnly={
            !(
              data?.acceptedByBuyer &&
              !data?.deliveryStatus?.recievedByBuyer?.status &&
              data?.deliveryStatus?.deliveredByManufacturer?.confirmedDelivery
                ?.status
            )
          }
        />
        <Button
          onClick={handleRecievePosition}
          loading={recievedPositionLoading}
          disabled={
            !(
              data?.acceptedByBuyer &&
              !data?.deliveryStatus?.recievedByBuyer?.status &&
              data?.deliveryStatus?.deliveredByManufacturer?.confirmedDelivery
                ?.status
            )
          }
        >
          {t('Potvrdi isporuku')}
        </Button>
      </Distributor>
    </Wrapper>
  );
};

export default PositionDeliveryProgress;
