import gql from "graphql-tag";

const GET_MANUFACTURERS_OFFERS = gql`
  query getManufacturersOffers {
    getManufacturersOffers {
      project {
        _id
        name
        projectCode
      }
      position {
        name
        positionCode
        drawingNumber
      }
      quantityOffers {
        quantity
        offeredQuantityPrice
        realisticDeliveryTime
        dateOffered
        seen
      }
    }
  }
`;

export default GET_MANUFACTURERS_OFFERS;
