import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BiChip } from 'react-icons/bi';
import LATEST_MANUFACTURERS_ORDERS_VALUE from 'src/apollo/queries/dashboard/latestManufacturersOrdersValue';
import ChartStats from 'src/components/ChartStats/ChartStats';
import Spinner from 'src/components/elements/Spinner/Spinner.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import Segment from 'src/components/structure/wrappers/Segment/Segment.component';
import useTranslation from 'src/hooks/useTranslation';
import { IconWrapper, Number } from './Dashboard';

const ManufacturerOrdersValueChart = () => {
  const { t } = useTranslation();
  // latest manufacturers orders value
  const [lmov, setLmov] = useState([]);

  const {
    loading,
    data: { latestManufacturersOrdersValue } = {},
    error: latestManufacturersOrdersValueError,
  } = useQuery(LATEST_MANUFACTURERS_ORDERS_VALUE, {});

  useEffect(() => {
    if (latestManufacturersOrdersValue?.length) {
      setLmov(groupBy(latestManufacturersOrdersValue, 'dateCreated'));
    }
  }, [latestManufacturersOrdersValue]);
  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>{t('Vrijednost narudžbi (30 dana)')}</h4>
          {loading ? (
            <Spinner />
          ) : (
            <Number>{latestManufacturersOrdersValue}</Number>
          )}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats
            data={lmov}
            title={t('Ukupna vrijednost')}
            sumField="value"
            yAxisLabel={t('Vrijednost (€)')}
          />
        )}
      </Segment>
    </Distributor>
  );
};

export default ManufacturerOrdersValueChart;
