import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/elements/Button/Button.component";
import Input from "../../../components/elements/Input/Input.component";
import Label from "../../../components/elements/Label/Label.component";
import FormField from "../../../components/structure/wrappers/FormField/FormField.component";
import { useForm } from "react-hook-form";
import {
  addProjectFormSchema,
  toggleActiveProjectFormSchema,
} from "../../../validation/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GET_SINGLE_PROJECT from "../../../apollo/queries/getSingleProject";
import UPDATE_PROJECT from "../../../apollo/mutations/updateProject";
import PROJECT_TOGGLE_ACTIVE from "../../../apollo/mutations/projectToggleActive";
import Distributor from "../../../components/structure/wrappers/Distributor/Distributor.component";
import Select from "../../../components/elements/Select/Select.component";
import Textarea from "../../../components/elements/Textarea/Textarea.component";
import toast from "react-hot-toast";
import Acknowledge from "../../../components/Acknowledge/Acknowledge";
import ProtectedContent from "../../../components/Auth/ProtectedContent/ProtectedContent";
import useTranslation from "../../../hooks/useTranslation";
import PUBLISH_PROJECT from "../../../apollo/mutations/publishProject";
import REMOVE_PROJECT from "src/apollo/mutations/removeProject";
import Space from "src/components/elements/Space/Space.component";
import Warning from "src/components/elements/Warning/Warning";
import CheckerBox from "src/components/elements/CheckerBox/CheckerBox";
import { currentUserContext } from "src/components/Auth/currentUserContext/currentUser.context";

const Wrapper = styled.div``;

const BasicInfo = ({ projectData }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isProjectActive, setIsProjectActive] = useState(true);
  const { register, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(addProjectFormSchema),
  });

  const {
    register: registerToggleActive,
    handleSubmit: handleSubmitToggleActive,
    setValue: setValueToggleActive,
  } = useForm({ resolver: yupResolver(toggleActiveProjectFormSchema) });

  const {
    user: { user },
  } = useContext(currentUserContext);

  const {
    params: { id },
  } = useRouteMatch();

  const [updateProjectMutation, { loading: loadingUpdate }] = useMutation(
    UPDATE_PROJECT,
    {
      onError: () => {},
      onCompleted: () =>
        toast.success(t("Postavke projekta uspješno spremljene")),
    }
  );

  const [publishProject, { loading: loadingPublishProject }] = useMutation(
    PUBLISH_PROJECT,
    {
      onError: () => {},
      onCompleted: () => {
        setIsProjectActive(true);
        toast.success(t("Projekt uspješno objavljen"));
      },
    }
  );

  const [projectToggleActive, { loading: loadingToggleActive }] = useMutation(
    PROJECT_TOGGLE_ACTIVE,
    {
      onError: () => {},
      onCompleted: () => toast.success("Status projekta izmijenjen"),
    }
  );

  const [removeProjectMutation] = useMutation(REMOVE_PROJECT, {
    onCompleted: () => {
      toast.success(t("Projekt uspješno obrisan"));
      history.push("/");
    },
    onError: () => {},
  });

  const submitToggleActive = ({ toggleActive }) => {
    projectToggleActive({
      variables: {
        projectId: projectData?.id,
        projectActiveState: toggleActive,
      },
    });
  };

  const handleRemoveProject = () => {
    if (
      prompt(t("Upišite POTVRDA za brisanje projekta")).trim() === t("POTVRDA")
    ) {
      removeProjectMutation({
        variables: { projectId: id },
        refetchQueries: ["getProjects"],
      });
    }
  };

  const handlePublishProject = () => {
    if (
      prompt(
        t("Da li ste sigurni da želite da objavite projekat? Upišite: POTVRDA")
      ).trim() === t("POTVRDA")
    ) {
      publishProject({ variables: { projectId: id } });
    }
  };

  useEffect(() => {
    /* if user is admin */
    if (["ADMINISTRATOR", "MODERATOR"].includes(user?.role)) {
      if (!projectData?.approved) {
        setIsProjectActive(false);
      } else {
        setIsProjectActive(true);
      }
    } else {
      /* if user's not admin but project approved */
      if (!projectData?.approved) {
        setIsProjectActive(false);
      } else {
        setIsProjectActive(true);
      }
    }

    if (projectData) {
      Object.keys(projectData).map((key) => {
        if (projectData[key]?.id) {
          return setValue(key, projectData[key].id, true);
        }
        return setValue(key, projectData[key], true);
      });

      // project active status
      setValueToggleActive("toggleActive", projectData?.approved);
    }
  }, [projectData]);

  const onSubmit = (values) => {
    updateProjectMutation({
      variables: { projectId: id, ...values, urgent: !!values.urgent },
    });
  };

  return (
    <Wrapper>
      <h2>{t("Uređivanje projekta")}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <Label error={errors.name?.message}>{t("Naziv projekta")}</Label>
          <Input name="name" ref={register} readOnly={isProjectActive} />
        </FormField>
        <FormField>
          <Label error={errors.projectCode?.message}>
            {t("Šifra projekta")}
          </Label>
          <Input name="projectCode" ref={register} readOnly={isProjectActive} />
        </FormField>
        <Distributor col="1-1">
          <FormField>
            <Label error={errors.offerDeadline?.message}>
              {t("Rok za ponudu")}
            </Label>
            <Input
              type="date"
              name="offerDeadline"
              ref={register}
              readOnly={isProjectActive}
            />
          </FormField>
          <FormField>
            <Label error={errors.deliveryDeadline?.message}>
              {t("Rok za isporuku")}
            </Label>
            <Input
              type="date"
              name="deliveryDeadline"
              ref={register}
              readOnly={isProjectActive}
            />
          </FormField>
        </Distributor>
        <FormField>
          <Label error={errors.note?.message}>{t("Napomena")}</Label>
          <Textarea
            name="note"
            ref={register}
            readOnly={isProjectActive}
          ></Textarea>
        </FormField>
        <FormField>
          <CheckerBox
            name="urgent"
            ref={register}
            disabled={isProjectActive}
            value={t("Projekt zahtijeva hitnu izradu")}
          />
        </FormField>
        <FormField>
          <Distributor stack="end" submitButtons>
            <Button
              layout="hollow"
              loading={loadingUpdate}
              disabled={isProjectActive}
            >
              {t("Sačuvaj izmjene")}
            </Button>
          </Distributor>
        </FormField>
      </form>
      {!projectData?.published?.status && (
        <ProtectedContent onlyFor={["BUYER"]}>
          <Space vertical="2rem" />
          <Button
            loading={loadingPublishProject}
            onClick={handlePublishProject}
            disabled={isProjectActive ? "true" : null}
          >
            {t("Objavi projekt")}
          </Button>
        </ProtectedContent>
      )}
      <Space vertical="2rem" />
      <ProtectedContent onlyFor={["ADMINISTRATOR", "MODERATOR"]}>
        <h2>Status projekta</h2>
        <Acknowledge layout="warning">
          Gašenje projekta može izazvati pometnju u akcijama koje su već
          pokrenute, kao što su ponude. Ne gasite projekat ukoliko baš nije
          neophodno.
        </Acknowledge>
        <form onSubmit={handleSubmitToggleActive(submitToggleActive)}>
          <Distributor col="4-1">
            <FormField>
              <Label error={errors.toggleActive?.message}>
                Aktiviraj / Deaktiviraj projekt
              </Label>
              <Select
                name="toggleActive"
                ref={registerToggleActive}
                readOnly={isProjectActive}
              >
                <option key="1" value="true">
                  Aktivan
                </option>
                <option key="2" value="false">
                  Neaktivan
                </option>
              </Select>
            </FormField>
            <FormField>
              <Button loading={loadingToggleActive}>Ažuriraj</Button>
            </FormField>
          </Distributor>
        </form>
        <Space vertical="3rem" />
      </ProtectedContent>
      {!projectData?.approved ? (
        <ProtectedContent onlyFor={["BUYER", "ADMINISTRATOR", "MODERATOR"]}>
          <Warning>
            <Distributor col="4-1">
              <span>
                {t(
                  "Brisanje projekta kao i deaktivacija može imati neželjene efekte. Ne brišite ukoliko niste sigurni šta radite. Čak i kad obrišete projekat podaci se neće ukloniti iz baze podataka, zbog povezanih stavki i nestabilnosti aplikacije uzrokovane tim postupkom"
                )}
              </span>
              <Button
                onClick={handleRemoveProject}
                disabled={isProjectActive ? "true" : null}
              >
                {t("Obriši projekat")}
              </Button>
            </Distributor>
          </Warning>
        </ProtectedContent>
      ) : null}
    </Wrapper>
  );
};

export default BasicInfo;
