import gql from 'graphql-tag';

const GET_SINGLE_POSITION = gql`
  query getSinglePosition($positionId: ID!) {
    getSinglePosition(positionId: $positionId) {
      id
      name
      positionCode
      drawingNumber
      quantities {
        quantity
        aimedPrice
      }
      manufacturing {
        id
        name
      }
      material {
        id
        name
      }
      technology {
        id
        name
      }
      additionalManufacturing {
        id
        name
      }
      additionalCharacteristics {
        id
        name
        category
      }
      files {
        jpg
        pdf
        step
        additionalDocuments
      }
      note
      revisedInformations {
        files {
          jpg
          pdf
          step
          additionalDocuments
        }
        note
      }
    }
  }
`;

export default GET_SINGLE_POSITION;
