import { useQuery } from "@apollo/client";
import { groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import { BiChip } from "react-icons/bi";
import GET_ALL_ORDERS from "src/apollo/queries/getAllOrders";
import ChartStats from "src/components/ChartStats/ChartStats";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Distributor from "src/components/structure/wrappers/Distributor/Distributor.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import { IconWrapper, Number } from "./Dashboard";

const AllSentOffersChart = () => {
  const { t } = useTranslation();
  const [sentOffers, setSentOffers] = useState(0);

  const { loading, data: { getAllOrders } = {} } = useQuery(GET_ALL_ORDERS, {
    variables: {
      span: 100,
    },
    onCompleted: (data) => {
      setSentOffers(
        data.getAllOrders?.reduce((totalCount, order) => {
          return totalCount + order.acceptedOffers?.length;
        }, 0)
      );
    },
  });

  const [lp, setLp] = useState([]);

  useEffect(() => {
    if (getAllOrders?.length) {
      setLp(groupBy(getAllOrders, "dateCreated"));
    }
  }, [getAllOrders]);

  return (
    <Distributor col="3-10">
      <Distributor align="center">
        <Segment hoverable>
          <IconWrapper>
            <BiChip size="50px" />
          </IconWrapper>
          <h4>Poslate ponude (100 dana)</h4>
          {loading ? <Spinner /> : <Number>{sentOffers}</Number>}
        </Segment>
      </Distributor>
      <Segment padding="0" noBg>
        {loading ? (
          <Spinner />
        ) : (
          <ChartStats data={lp} title={t("Broj ponuda")} />
        )}
      </Segment>
    </Distributor>
  );
};

export default AllSentOffersChart;
