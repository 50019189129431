import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authorizeUserSchema } from "src/validation/schema";
import Input from "src/components/elements/Input/Input.component";
import useTranslation from "src/hooks/useTranslation";
import Button from "src/components/elements/Button/Button.component";
import { useMutation } from "@apollo/client";
import USER_AUTHORIZATION from "src/apollo/mutations/authorize";
import toast from "react-hot-toast";
import FormField from "src/components/structure/wrappers/FormField/FormField.component";
import Label from "src/components/elements/Label/Label.component";
import { useContext } from "react";
import { currentUserContext } from "../currentUserContext/currentUser.context";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Login = styled.div`
  width: 300px;
`;

const Authorization = ({ onlyForRoles }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(authorizeUserSchema),
  });
  const { setAuthorized } = useContext(currentUserContext);

  const [userAuthMutation, { loading }] = useMutation(USER_AUTHORIZATION, {
    onCompleted: () => {
      toast.success("Autorizacija uspješna");
      setAuthorized(true);
      reset();
    },
    onError: () => {
      setAuthorized(false);
    },
  });

  const onSubmit = (values) => {
    userAuthMutation({
      variables: {
        password: values.password,
        onlyForRoles,
      },
    });
  };

  return (
    <Wrapper>
      <Login>
        <h3>{t("Obavezna autorizacija")}</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Label error={errors.password?.message}></Label>
            <Input
              name="password"
              type="password"
              disabled={loading}
              ref={register}
              placeholder={t("Lozinka")}
            />
          </FormField>
          <FormField>
            <Button loading={loading} width="100%" align="center">
              {t("Ulaz")}
            </Button>
          </FormField>
        </form>
      </Login>
    </Wrapper>
  );
};

export default Authorization;
