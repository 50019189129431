import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import client from './apollo/client';
import CurrentUserProvider from './components/Auth/currentUserContext/currentUser.context';
import { DataSelectorProvider } from './components/DataSelector/dataSelectorContext';
import theme from './theme/theme';

const DataProviders = ({ children }) => {
  return (
    <CurrentUserProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <DataSelectorProvider>{children}</DataSelectorProvider>
        </ThemeProvider>
      </ApolloProvider>
    </CurrentUserProvider>
  );
};

export default DataProviders;
