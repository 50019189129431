import gql from "graphql-tag";

const CREATE_ORDER = gql`
  mutation createOrder(
    $project: String!
    $acceptedOffers: [AcceptedOfferInput]
  ) {
    createOrder(project: $project, acceptedOffers: $acceptedOffers) {
      _id
    }
  }
`;

export default CREATE_ORDER;
