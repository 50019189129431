import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { BiChat } from "react-icons/bi";
import GET_BUYERS_ORDERS from "src/apollo/queries/getBuyersOrders";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import { IconWrapper, Number } from "./Dashboard";

const ReceivedBuyersOrders = () => {
  const { t } = useTranslation();
  const [receivedOrders, setReceivedOrders] = useState(0);

  const { loading } = useQuery(GET_BUYERS_ORDERS, {
    onCompleted: (data) => {
      setReceivedOrders(
        data.getBuyersOrders?.reduce((totalCount, order) => {
          return (
            totalCount +
            order.acceptedOffers?.reduce((t, offer) => {
              return offer.acceptedByBuyer ? t + 1 : t;
            }, 0)
          );
        }, 0)
      );
    },
  });

  return (
    <Segment hoverable>
      <IconWrapper>
        <BiChat size="50px" />
      </IconWrapper>
      <h4>{t("Broj primljenih narudzbi")}</h4>
      {loading ? <Spinner /> : <Number>{receivedOrders}</Number>}
    </Segment>
  );
};

export default ReceivedBuyersOrders;
