import gql from 'graphql-tag';

const CREATE_PROJECT = gql`
  mutation createProject(
    $companyId: ID!
    $name: String!
    $projectCode: String
    $deliveryDeadline: String!
    $offerDeadline: String!
    $note: String
    $urgent: Boolean
  ) {
    createProject(
      companyId: $companyId
      name: $name
      projectCode: $projectCode
      deliveryDeadline: $deliveryDeadline
      offerDeadline: $offerDeadline
      note: $note
      urgent: $urgent
    ) {
      id
      name
    }
  }
`;

export default CREATE_PROJECT;
