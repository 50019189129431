import gql from "graphql-tag";

const GET_ASSOCIATES = gql`
  query getAssociates {
    getAssociates {
      id
      username
      name
      role
      status
    }
  }
`;

export default GET_ASSOCIATES;
