import gql from "graphql-tag";

const SEND_OFFER_TO_BUYER = gql`
  mutation sendOfferToBuyer(
    $orderId: String!
    $refinedAcceptedOffers: [AcceptedRefinedOfferInput]
    $conditions: ConditionsInput
    $note: String
  ) {
    sendOfferToBuyer(
      orderId: $orderId
      refinedAcceptedOffers: $refinedAcceptedOffers
      conditions: $conditions
      note: $note
    )
  }
`;

export default SEND_OFFER_TO_BUYER;
