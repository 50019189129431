import gql from "graphql-tag";

const UPDATE_USER = gql`
  mutation updateUser(
    $userId: String!
    $username: String
    $email: String
    $phoneNumber: String
    $name: String
    $role: String
    $status: String
    $language: String
    $attributes: UserAttributesInput
  ) {
    updateUser(
      userId: $userId
      username: $username
      email: $email
      phoneNumber: $phoneNumber
      name: $name
      role: $role
      status: $status
      language: $language
      attributes: $attributes
    )
  }
`;
export default UPDATE_USER;
