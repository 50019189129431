import gql from "graphql-tag";

const DELIVERED_ORDERS_VALUE = gql`
  query deliveredOrdersValue {
    deliveredOrdersValue {
      dateCreated
      value
    }
  }
`;

export default DELIVERED_ORDERS_VALUE;
