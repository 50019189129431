import gql from "graphql-tag";

const GET_MANUFACTURERS_ORDERS = gql`
  query getManufacturersOrders {
    getManufacturersOrders {
      _id
      project {
        _id
        name
        deliveryDeadline
        published {
          date
        }
      }
      acceptedOffers {
        _id
        seen
        quantity
        position {
          _id
          name
          positionCode
        }
        refinedPrice
        acceptedByBuyer
        deliveryStatus {
          deliveredByManufacturer {
            status
            date
          }
        }
        manufacturersOrderNumber
      }
      delivered {
        toBuyers
        date
      }
      accepted {
        byBuyer
      }
      approved {
        byAdministrator
      }
      manufacturer {
        recievedOrder
        date
      }
      dateCreated
    }
  }
`;

export default GET_MANUFACTURERS_ORDERS;
