import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import CHANGE_PASSWORD_REQUEST from 'src/apollo/mutations/changePasswordRequest';
import Acknowledge from 'src/components/Acknowledge/Acknowledge';
import Button from 'src/components/elements/Button/Button.component';
import Input from 'src/components/elements/Input/Input.component';
import Label from 'src/components/elements/Label/Label.component';
import Distributor from 'src/components/structure/wrappers/Distributor/Distributor.component';
import FormField from 'src/components/structure/wrappers/FormField/FormField.component';
import useTranslation from 'src/hooks/useTranslation';
import { changeUserPasswordFormSchema } from 'src/validation/schema';
import styled from 'styled-components';

const Wrapper = styled.div``;

const PasswordChangeRequest = () => {
  const { id: userId } = useParams();
  /* change password data validation */
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(changeUserPasswordFormSchema),
  });

  const [changePasswordMutation, { loading: changePasswordLoading }] =
    useMutation(CHANGE_PASSWORD_REQUEST, {
      onCompleted: () => {
        toast.success('Zahtjev za izmjenu lozinke poslan');
        reset();
      },
      onError: () =>
        toast.error(
          'Došlo je do greške prilikom slanja zahtjeva za izmjenu lozinke'
        ),
    });

  const onSubmitRequestPasswordChange = (values) => {
    if (
      prompt(
        'Ako ste sigurni da želite da izmijenite lozinku korisnika upišite: POTVRDA'
      )?.trim() === 'POTVRDA'
    ) {
      changePasswordMutation({
        variables: {
          password: values.password,
          userId,
        },
      });
    } else {
      return;
    }
  };

  const { t } = useTranslation();
  return (
    <Wrapper>
      <FormField>
        <h3>{t('Zahtjev za izmjenu lozinke')}</h3>
        <form>
          <Distributor col="2-2-1">
            <FormField>
              <Label error={errors.password?.message}>
                {t('Nova lozinka')}
              </Label>
              <Input
                type="password"
                name="password"
                autoComplete="on"
                ref={register}
              />
            </FormField>
            <FormField>
              <Label error={errors.passwordRepeat?.message}>
                {t('Ponovite lozinku')}
              </Label>
              <Input
                type="password"
                name="passwordRepeat"
                autoComplete="on"
                ref={register}
              />
            </FormField>
            <FormField>
              <Button
                onClick={handleSubmit(onSubmitRequestPasswordChange)}
                loading={changePasswordLoading}
              >
                {t('Pošalji zahtjev za izmjenu lozinke')}
              </Button>
            </FormField>
          </Distributor>
        </form>
      </FormField>
      <Acknowledge layout="information" title={t('Napomena')}>
        {t(
          'Lozinku ćete dobiti na email nakon što administrator potvrdi. Nemojte pokušavati slati više od 1 zahtjeva, ukoliko ne dobijete email najkasnije u roku od 48h kontaktirajte administratora'
        )}
      </Acknowledge>
    </Wrapper>
  );
};

export default PasswordChangeRequest;
