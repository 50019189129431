import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import Distributor from '../../../components/structure/wrappers/Distributor/Distributor.component';
import Select from '../../../components/elements/Select/Select.component';
import Button from '../../../components/elements/Button/Button.component';
import { useParams } from 'react-router-dom';
import PositionDetails from './PositionDetails';
import { projectDetailsContext } from '../projectDetailsContext';
import useTranslation from '../../../hooks/useTranslation';
import { currentUserContext } from '../../../components/Auth/currentUserContext/currentUser.context';
import { useMutation } from '@apollo/client';
import CREATE_MANUFACTURER_OFFER from '../../../apollo/mutations/createManufacturerOffer';
import toast from 'react-hot-toast';
import { pastDue } from 'src/util/util';
import ProtectedContent from 'src/components/Auth/ProtectedContent/ProtectedContent';

const Wrapper = styled.div``;
const TopControls = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    align-items: center;
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const PositionsWrapper = styled.div`
  > div:not(:last-child) {
    padding-bottom: 4rem;
    margin-bottom: 4rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
  }
`;

const Positions = ({ data: project, contacted }) => {
  const [createManufacturerOfferMutation, { loading }] = useMutation(
    CREATE_MANUFACTURER_OFFER,
    {
      onCompleted: () => toast.success('Ponuda uspješno poslana.'),
      onError: () => {},
    }
  );
  const {
    user: { user },
  } = useContext(currentUserContext);
  const { t } = useTranslation();
  const { selectedPosition, setSelectedPosition } = useContext(
    projectDetailsContext
  );

  const { positionId } = useParams();

  useEffect(() => {
    if (positionId) {
      setSelectedPosition(positionId);
    }
  }, [positionId, setSelectedPosition]);

  useEffect(() => {
    if (selectedPosition) {
      handleGetPositionData();
    }
  }, [selectedPosition]);

  const [positionData, setPositionData] = useState(null);

  const handleGetPositionData = () => {
    if (project && selectedPosition) {
      const pos = project.positions.find(
        (position) => position.id === selectedPosition
      );
      setPositionData({ ...pos, deliveryDeadline: project.deliveryDeadline });
    }
  };

  const handleShowAllPositions = () => {
    if (project) {
      setPositionData(project);
      setSelectedPosition('');
    }
  };

  const handleShowOnlyPositionsWithOffers = () => {
    if (project) {
      const isolatedPositionsData = {
        ...project,
        positions: project.positions.filter((position) =>
          position.quantities.some((quantity) => quantity.offers?.length !== 0)
        ),
      };
      setPositionData(isolatedPositionsData);
      setSelectedPosition('');
    }
  };

  const handlePositionSelection = (e) => {
    setSelectedPosition(e.target.value);
  };

  const handleCreateManufacturerOffer = () => {
    if (prompt(t('Unesite POTVRDA za slanje ponude')).trim() === t('POTVRDA')) {
      createManufacturerOfferMutation({ variables: { projectId: project.id } });
    }
  };

  return (
    <Wrapper>
      <TopControls>
        <div>
          <span>{t('Odaberite poziciju alata')}</span>
          <Select
            name="positionList"
            onChange={handlePositionSelection}
            value={selectedPosition}
          >
            {project.positions.map((position) => (
              <option key={position.id} value={position.id}>
                {position.name}
              </option>
            ))}
          </Select>
          <Distributor stack="end">
            <Button layout="hollow" onClick={handleShowAllPositions}>
              {t('Prikaži sve pozicije')}
            </Button>
            <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
              <Button
                layout="hollow"
                onClick={handleShowOnlyPositionsWithOffers}
              >
                {t('Prikaži samo pozicije s ponudama')}
              </Button>
            </ProtectedContent>
          </Distributor>
        </div>

        {user &&
          user?.role === 'MANUFACTURER' &&
          pastDue(project.offerDeadline) > 0 && (
            <Button loading={loading} onClick={handleCreateManufacturerOffer}>
              {t('Pošalji ponudu')}
            </Button>
          )}
      </TopControls>
      {positionData?.positions?.length > 0 ||
      !Array.isArray(positionData?.positions) ? (
        <PositionsWrapper>
          {!positionData?.positions?.length && positionData ? (
            <PositionDetails
              contacted={contacted}
              orders={project?.orders}
              data={positionData}
              deliveredToBuyers={project?.orders?.delivered?.toBuyers}
              ownedBy={project?.createdBy.id}
              offerDeadline={project?.offerDeadline}
              deliveryDeadline={project?.deliveryDeadline}
              positionFromURL={positionId}
              projectId={project?.id}
              project={project}
              commentsEnabled={
                !project?.orders?.delivered?.toBuyers &&
                !(pastDue(project?.offerDeadline) <= 0)
              }
            />
          ) : (
            positionData?.positions?.map((position, index) => (
              <PositionDetails
                contacted={contacted}
                projectData={project}
                orders={project?.orders}
                index={index + 1}
                data={position}
                deliveredToBuyers={positionData?.delivered?.toBuyers}
                ownedBy={positionData?.createdBy.id}
                offerDeadline={positionData?.offerDeadline}
                deliveryDeadline={project?.deliveryDeadline}
                positionFromURL={positionId}
                projectId={positionData?.id}
                project={project}
                commentsEnabled={
                  !positionData?.delivered?.toBuyers ||
                  !pastDue(positionData?.offerDeadline)
                }
              />
            ))
          )}
        </PositionsWrapper>
      ) : (
        'Nema pozicija po navedenom kriterijumu'
      )}
    </Wrapper>
  );
};

export default Positions;
