import gql from "graphql-tag";

const GET_MATERIAL_CATEGORIES = gql`
  query getMaterialCategories {
    getMaterialCategories {
      id
      name
      ISOdesignation
      parent {
        id
        name
      }
    }
  }
`;

export default GET_MATERIAL_CATEGORIES;
