import React, { useContext } from 'react';
import styled from 'styled-components';
import { currentUserContext } from '../../../components/Auth/currentUserContext/currentUser.context';
import Spinner from '../../../components/elements/Spinner/Spinner.component';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import useTranslation from 'src/hooks/useTranslation';
const Wrapper = styled.div``;

const Stats = ({ data, userId }) => {
  let series = [];
  data &&
    data.map((quantity, i) => {
      return (
        quantity.offers.some((offer) => offer.userId.id === userId) &&
        series.push({
          name: `Količina: ${quantity.quantity}`,
          data: [
            0,
            ...quantity.offers.map((offer) => offer.offeredQuantityPrice),
          ].sort((a, b) => a - b),
          times: [
            0,
            ...quantity.offers.map((offer) => offer?.dateOffered),
          ].sort((a, b) => a - b),
          userMarker: quantity.offers.find((o) => o.userId.id === userId)
            ?.offeredQuantityPrice,
        })
      );
    });

  const chartOptions = {
    chart: {
      id: 'price-stats-chart',
      fontFamily: 'inherit',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: true,
          zoom: false,
          zoomout: false,
          zoomin: false,
          pan: false,
          reset: false,
        },
      },
    },
    tooltip: {
      custom: function ({
        series: seriesData,
        seriesIndex,
        dataPointIndex,
        w,
      }) {
        return `<div class="stats-tooltip">
          <span class="stats-price">${seriesData[seriesIndex][
            dataPointIndex
          ].toFixed(2)}</span>
          <span>${dayjs(series[seriesIndex].times[dataPointIndex]).format(
            'DD/MM/YY @ hh:mm'
          )}</span>
          </div>`;
      },
    },
    markers: {
      size: 3,
      discrete: series.map((s, i) => {
        return {
          seriesIndex: i,
          dataPointIndex: s.data.indexOf(s.userMarker),
          fillColor: '#FF5D38',
          strokeColor: '#fff',
          size: 7,
        };
      }),
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    fill: {
      opacity: 0.3,
    },
    yaxis: {
      title: {
        text: 'Cijene',
        style: {
          color: '#000',
          fontWeight: 'normal',
        },
      },
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
  };

  return (
    <Chart
      options={chartOptions}
      height="100%"
      type="area"
      width="700"
      series={series}
    />
  );
};

const Position = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0px 0px 20px #0000001a;
  border-radius: 5px;
`;
const Image = styled.div`
  margin-right: 2rem;
  overflow: hidden;
  img {
    border-radius: 5px;
    height: 150px;
    width: 150px;
    object-fit: cover;
    display: block;
  }
`;
const Info = styled.div`
  height: 100%;
`;

const Other = styled.div`
  > p {
    ${({ theme }) => `color: ${theme.colors.menuHighlightBorder};`}
  }
  margin: 1rem 0 0 0;
  p {
    margin: 0;
  }
  h4 {
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
  }
  table {
    margin-top: 1rem;
    tr td:first-child {
      font-weight: 600;
      padding-right: 1rem;
    }
  }
`;
const Statistics = styled.div``;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const ProjectStats = ({ data }) => {
  const { t } = useTranslation();
  const {
    user: { user },
  } = useContext(currentUserContext);

  const positions = data.filter((position) =>
    position.quantities.some((quantity) =>
      quantity.offers.some((offer) => offer.userId.id === user.id)
    )
  );

  return positions ? (
    <Wrapper>
      {positions.map((position) => (
        <Position key={position.id}>
          <Image>
            <img
              src={
                position?.revisedInformations?.files?.jpg
                  ? process.env.REACT_APP_S3_BUCKET +
                    position?.revisedInformations?.files?.jpg
                  : process.env.REACT_APP_S3_BUCKET + position?.files?.jpg
              }
              alt={position.name}
            />
          </Image>
          <InfoWrapper>
            <Info>
              <Other>
                <p>{t('Naziv pozicije')}</p>
                <h4>{position.name}</h4>
                <table>
                  <tbody>
                    <tr>
                      <td>{t('RFQ')}</td>
                      <td>{position.id}</td>
                    </tr>
                    <tr>
                      <td>{t('Pozicija')}</td>
                      <td>{position.positionCode}</td>
                    </tr>
                    <tr>
                      <td>{t('Materijal')}</td>
                      <td>{position.material.name}</td>
                    </tr>
                  </tbody>
                </table>
              </Other>
            </Info>
            <Statistics>
              <Stats data={position?.quantities} userId={user.id} />
            </Statistics>
          </InfoWrapper>
        </Position>
      ))}
    </Wrapper>
  ) : (
    <Spinner />
  );
};

export default ProjectStats;
