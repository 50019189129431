import gql from 'graphql-tag';

const GET_SINGLE_PROJECT = gql`
  query getSingleProject($projectId: ID!) {
    getSingleProject(projectId: $projectId) {
      id
      name
      projectCode
      createdBy {
        id
      }
      deliveryDeadline
      offerDeadline
      note
      positions {
        id
        name
        positionCode
        drawingNumber
        additionalCharacteristics {
          id
          name
          category
          description
        }
        technology {
          name
          parameters {
            id
            minValue
            maxValue
            units
            parameter {
              name
            }
          }
        }
        material {
          name
          materialStandard
          materialDesignation
          category {
            name
          }
        }
        manufacturing {
          name
        }
        quantities {
          id
          quantity
          aimedPrice
          administratorAimedPrice
          offers {
            id
            offeredQuantityPrice
            realisticDeliveryTime
            dateOffered
            userId {
              id
              name
              company {
                name
                contactInfo {
                  email
                }
                paymentAndTransport {
                  paymentTimeframe
                }
              }
            }
          }
        }
        files {
          jpg
          pdf
          step
          additionalDocuments
        }
        note
        complexityLevel
        toleranceLevel
        revisedInformations {
          files {
            jpg
            pdf
            step
            additionalDocuments
          }
          note
        }
        comments {
          user {
            id
          }
          comment
        }
      }
      approved
      published {
        status
      }
      orders {
        delivered {
          toBuyers
        }
        manufacturer {
          recievedOrder
        }
        acceptedOffers {
          _id
          acceptedPrice
          position {
            id
          }
          realisticDeliveryTime
          refinedPrice
          quantity
          user {
            id
            username
            company {
              name
            }
          }
        }
      }
      urgent
      contactedManufacturers {
        _id
        username
        name
        company {
          _id
          name
          contactInfo {
            email
          }
        }
      }
    }
  }
`;

export default GET_SINGLE_PROJECT;
