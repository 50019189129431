import { useRef } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { DownloadButton } from '../PositionDetails';
import useTranslation from 'src/hooks/useTranslation';

const Wrapper = styled.div`
  width: 800px;
`;

const Content = styled.div`
  white-space: pre-wrap;
`;

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  white-space: pre-wrap;
  break-word: break-all;
  word-wrap: break-word;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.sidebarBorder};
`;

const Info = styled.div`
  strong {
    margin-right: 0.5rem;
  }
  &.stretch-this {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &.button-here {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 2rem;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 1rem;
`;

const ModalWrapper = ({ title, content, position, project }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  return (
    <Wrapper ref={ref}>
      <TopWrapper>
        <InfoContainer>
          <Info className="stretch-this">
            <div>
              <strong>{t('Ime pozicije')}:</strong>
              {position.name}
            </div>
            <div>
              <strong>{t('Ime projekta')}:</strong>
              {project.name}
            </div>
          </Info>
          <Info>
            <div>
              <strong>{t('Šifra pozicije')}:</strong>
              {position.positionCode}
            </div>
            <div>
              <strong>{t('Šifra projekta')}:</strong>
              {project.projectCode}
            </div>
          </Info>
        </InfoContainer>
        <Info className="button-here">
          <DownloadButton onClick={() => handlePrint()}>
            <AiOutlinePrinter size="20px" />
            <span>PRINT</span>
          </DownloadButton>
        </Info>
      </TopWrapper>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Wrapper>
  );
};

export default ModalWrapper;
