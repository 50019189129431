import React, { useContext, useState } from "react";
import styled from "styled-components";
import Button from "../elements/Button/Button.component";
import UploadFile from "../elements/UploadFile/UploadFile.component";
import { ModalContext } from "../structure/Modal/modalContext";
import Distributor from "../structure/wrappers/Distributor/Distributor.component";
import FormField from "../structure/wrappers/FormField/FormField.component";

const Wrapper = styled.div``;

const SendFile = () => {
  const { setData, handleModal } = useContext(ModalContext);
  const [file, setFile] = useState(null);

  const handleSendFile = () => {
    file && setData(file);
    handleModal();
  };

  return (
    <Wrapper>
      <h3>Odaberite fajl za slanje</h3>
      <p>
        Podržane ekstenzije su pdf, jpg, png, a maksimalna dozvoljena veličina
        je 10MB
      </p>
      <FormField>
        <UploadFile
          directory="chat"
          name="file"
          acceptedformats={["image/png", "image/jpeg", "application/pdf"]}
          maxMB="50"
          onSuccess={(filename) => setFile(filename)}
        />
      </FormField>
      <Distributor submitButtons stack="end">
        <Button disabled={!file && true} onClick={handleSendFile}>
          Pošalji fajl
        </Button>
      </Distributor>
    </Wrapper>
  );
};

export default SendFile;
