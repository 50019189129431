import React, { createContext, useState } from "react";

const projectDetailsContext = createContext();
const { Provider } = projectDetailsContext;

const ProjectDetailsProvider = ({ children }) => {
  const [selectedPosition, setSelectedPosition] = useState("");
  const [prices, setPrices] = useState([]);
  const [pricePanelVisible, setPricePanelVisible] = useState(false);

  const togglePricePanel = () => setPricePanelVisible(!pricePanelVisible);

  return (
    <Provider
      value={{
        selectedPosition,
        setSelectedPosition,
        prices,
        setPrices,
        pricePanelVisible,
        togglePricePanel,
        setPricePanelVisible,
      }}
    >
      {children}
    </Provider>
  );
};

export { projectDetailsContext, ProjectDetailsProvider };
