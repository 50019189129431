import { useContext } from "react";
import { currentUserContext } from "../currentUserContext/currentUser.context";

const ProtectedContent = ({ onlyFor, children }) => {
  const {
    user: { user },
  } = useContext(currentUserContext);

  const isAuthorized = onlyFor?.includes(user?.role?.toUpperCase());

  return isAuthorized ? children : null;
};

export default ProtectedContent;
