import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import GET_ALL_ADDITIONAL_CHARACTERISTICS from 'src/apollo/queries/getAllAdditionalCharacteristics';
import DataSelector from 'src/components/DataSelector/DataSelector';
import { DataSelectorContext } from 'src/components/DataSelector/dataSelectorContext';
import { ADDITIONAL_CHARACTERISTICS_CATEGORIES } from 'src/constants';
import useTranslation from 'src/hooks/useTranslation';
import CREATE_POSITION from '../../../apollo/mutations/createPosition';
import GET_ALL_MANUFACTURING from '../../../apollo/queries/getAllManufacturing';
import GET_ALL_MATERIALS from '../../../apollo/queries/getAllMaterials';
import GET_TECHNOLOGIES from '../../../apollo/queries/getTechnologies';
import { addPositionFormSchema } from '../../../validation/schema';
import { currentUserContext } from '../../Auth/currentUserContext/currentUser.context';
import { ModalContext } from '../../structure/Modal/modalContext';
import Distributor from '../../structure/wrappers/Distributor/Distributor.component';
import FormField from '../../structure/wrappers/FormField/FormField.component';
import Button from '../Button/Button.component';
import Input from '../Input/Input.component';
import InputTable from '../InputTable/InputTable.component';
import Label from '../Label/Label.component';
import Select from '../Select/Select.component';
import Textarea from '../Textarea/Textarea.component';
import UploadFile from '../UploadFile/UploadFile.component';
import ProtectedContent from 'src/components/Auth/ProtectedContent/ProtectedContent';

const AddPositionForm = ({ projectId }) => {
  const { t } = useTranslation();
  const { dataSelectorValues } = useContext(DataSelectorContext);
  const { handleModal } = useContext(ModalContext);
  const {
    user: { user },
  } = useContext(currentUserContext);

  const [files, setFiles] = useState({
    pdf: '',
    jpg: '',
    step: '',
    additionalDocuments: '',
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(addPositionFormSchema),
  });
  const {
    loading: loadingMaterials,
    error: errorMaterials,
    data: dataMaterials,
  } = useQuery(GET_ALL_MATERIALS);
  const {
    loading: loadingTech,
    error: errorTech,
    data: dataTech,
  } = useQuery(GET_TECHNOLOGIES);
  const {
    loading: loadingManufacturing,
    error: errorManufacturing,
    data: dataManufacturing,
  } = useQuery(GET_ALL_MANUFACTURING);

  const [createPositionMutation, { loading, error }] = useMutation(
    CREATE_POSITION,
    { onError: () => {} }
  );

  const {
    loading: loadingCharacteristics,
    error: errorCharacteristics,
    data: additionalCharacteristics,
  } = useQuery(GET_ALL_ADDITIONAL_CHARACTERISTICS);

  const [data, setData] = useState([]);

  const [values, setValues] = useState({
    quantity: '',
    aimedPrice: '',
  });

  const handleAddData = () => {
    if (!values.quantity) return;
    // if reseller prevent from adding if aimed price is null or NaN
    if (user?.attributes?.reseller) {
      if (values.aimedPrice === 0 || values.aimedPrice === '') {
        return toast.error('Morate unijeti realnu ciljanu cijenu!');
      }
    }

    setData([
      ...data,
      {
        quantity: values.quantity,
        aimedPrice: values.aimedPrice || 0,
      },
    ]);
    setValues({ quantity: '', aimedPrice: '' });
  };

  const handleRemoveData = (id) => {
    setData(data.filter((_, index) => index !== id));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: name === 'quantity' ? parseInt(value) : parseFloat(value),
    });
  };

  const onSubmit = (formValues) => {
    if (!data.length > 0) return;

    const material = dataSelectorValues?.material_modal;
    const technology = dataSelectorValues?.technology_modal;
    const manufacturing = dataSelectorValues?.manufacturing_modal;
    const additionalManufacturing =
      dataSelectorValues?.additional_manufacturing_modal;

    if (!material?.length || !manufacturing?.length)
      return toast.error(t('Materijal i proizvodnja su obavezni'));
    // if (!Object.keys(files).some((file) => files[file]))
    //   return toast.error("Morate dodati barem jedan fajl");

    if (!files?.jpg) return toast.error(t('Morate dodati jpg file'));

    if (
      ['ADMINISTRATOR', 'MODERATOR'].includes(user?.role) &&
      !technology?.length
    )
      return toast.error('Tehnologija je obavezno polje');

    const characteristics = formValues?.additionalCharacteristics
      ? Object?.values(formValues?.additionalCharacteristics).filter(Boolean)
      : [];

    createPositionMutation({
      variables: {
        projectId,
        ...formValues,
        material: material[0],
        technology,
        manufacturing: manufacturing[0],
        additionalManufacturing,
        quantities: data,
        files,
        additionalCharacteristics: characteristics,
      },
      refetchQueries: ['getSingleProject'],
    });
    if (!loading && !error) {
      toast.success(t('Pozicija uspješno dodana'));
      handleModal();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>{t('Dodaj novu poziciju')}</h2>
      <FormField>
        <Label error={errors.name?.message}>{t('Naziv pozicije')}</Label>
        <Input name="name" ref={register} />
      </FormField>
      <Distributor col="1-1">
        <FormField>
          <Label error={errors.positionCode?.message}>
            {t('Šifra pozicije')}
          </Label>
          <Input name="positionCode" ref={register} />
        </FormField>
        <FormField>
          <Label error={errors.drawingNumber?.message}>
            {t('Broj crteža')}
          </Label>
          <Input name="drawingNumber" ref={register} />
        </FormField>
      </Distributor>
      <FormField>
        <InputTable
          data={data}
          addData={handleAddData}
          removeData={handleRemoveData}
        >
          <Input
            label={t('Količina')}
            name="quantity"
            type="number"
            value={values.quantity}
            onChange={handleChange}
          />
          <Input
            label={`${t('Ciljana cijena')} (EUR/kom.)`}
            type="number"
            name="aimedPrice"
            value={values.aimedPrice}
            onChange={handleChange}
          />
        </InputTable>
      </FormField>
      <FormField>
        <DataSelector
          params={{
            data: dataMaterials?.getAllMaterials,
            loading: loadingMaterials,
            chooseButtonLabel: t('Odaberi materijal'),
            name: 'material_modal',
            valuesField: 'id',
            handlerField: 'name',
            inline: true,
            multiSelection: false,
            sortBy: {
              dataField: 'category',
              name: 'category.name',
              compare: 'category.id',
              overrideSortByField: 'category.id',
            },
            additionalFields: {
              materialStandard: {
                label: 'Standard',
              },
              materialDesignation: {
                label: 'Oznaka',
              },
            },
          }}
        />
      </FormField>
      <FormField>
        <DataSelector
          params={{
            data: dataManufacturing?.getAllManufacturing,
            loading: loadingManufacturing,
            chooseButtonLabel: t('Odaberi glavnu proizvodnju'),
            name: 'manufacturing_modal',
            valuesField: 'id',
            handlerField: 'name',
            inline: true,
            multiSelection: false,
            noHeaders: true,
            sortBy: {
              dataField: 'name',
              name: 'name',
              compare: 'id',
            },
          }}
        />
      </FormField>
      <FormField>
        <DataSelector
          params={{
            data: dataManufacturing?.getAllManufacturing,
            loading: loadingManufacturing,
            chooseButtonLabel: t('Dodatne proizvodnje'),
            name: 'additional_manufacturing_modal',
            valuesField: 'id',
            handlerField: 'name',
            inline: true,
            multiSelection: true,
            noHeaders: true,
            sortBy: {
              dataField: 'name',
              name: 'name',
              compare: 'id',
            },
          }}
        />
      </FormField>
      {['ADMINISTRATOR', 'MODERATOR'].includes(user?.role) && (
        <FormField>
          <DataSelector
            params={{
              data: dataTech?.getTechnologies,
              loading: loadingTech,
              chooseButtonLabel: t('Odaberi tehnologiju'),
              name: 'technology_modal',
              valuesField: 'id',
              handlerField: 'name',
              inline: true,
              sortBy: {
                dataField: 'category',
                name: 'category.name',
                compare: 'category.id',
                overrideSortByField: 'category.id',
                sortDataBy: ['id', 'parameters[0].minValue'],
              },
              additionalFields: {
                'parameters[0].minValue': {
                  label: 'Min',
                },
                'parameters[0].maxValue': {
                  label: 'Max',
                },
              },
            }}
          />
        </FormField>
      )}
      <ProtectedContent onlyFor={['ADMINISTRATOR', 'MODERATOR']}>
        <Distributor repeat="2">
          {Object.keys(ADDITIONAL_CHARACTERISTICS_CATEGORIES).map((key) => (
            <FormField key={key}>
              <Label
                error={
                  errors?.additionalCharacteristics?.[key.toLowerCase()]
                    ?.message
                }
              >
                {t(ADDITIONAL_CHARACTERISTICS_CATEGORIES[key])}
              </Label>
              <Select
                name={`additionalCharacteristics.${key.toLowerCase()}`}
                ref={register}
              >
                {additionalCharacteristics?.getAllAdditionalCharacteristics
                  ?.filter((v) => v.category === key)
                  .map((v) => (
                    <select key={v.key} value={v.id}>
                      {v.name}
                    </select>
                  ))}
              </Select>
            </FormField>
          ))}
        </Distributor>
      </ProtectedContent>
      <FormField>
        <Label>PDF</Label>
        <UploadFile
          directory="pdf"
          name="pdf"
          acceptedformats={['application/pdf']}
          maxMB="50"
          onSuccess={(filename) =>
            setFiles((currentValues) => ({ ...currentValues, pdf: filename }))
          }
        />
      </FormField>
      <FormField>
        <Label>JPG</Label>
        <UploadFile
          directory="jpg"
          name="jpg"
          acceptedformats={['image/png', 'image/jpeg']}
          maxMB="50"
          onSuccess={(filename) =>
            setFiles((currentValues) => ({ ...currentValues, jpg: filename }))
          }
        />
      </FormField>
      <FormField>
        <Label>STEP</Label>
        <UploadFile
          directory="step"
          acceptedformats={['model/step', 'application/step']}
          name="step"
          maxMB="50"
          onSuccess={(filename) =>
            setFiles((currentValues) => ({ ...currentValues, step: filename }))
          }
        />
      </FormField>
      <FormField>
        <Label>{t('Dodatni dokumenti (*.zip)')}</Label>
        <UploadFile
          directory="zip"
          acceptedformats={[
            'application/zip',
            'application/octet-stream',
            'application/x-zip-compressed',
            'multipart/x-zip',
          ]}
          name="additionalDocuments"
          maxMB="50"
          onSuccess={(filename) =>
            setFiles((currentValues) => ({
              ...currentValues,
              additionalDocuments: filename,
            }))
          }
        />
      </FormField>
      <FormField>
        <Label error={errors.note?.message}>{t('Napomena')}</Label>
        <Textarea name="note" height="200px" ref={register}></Textarea>
      </FormField>
      <FormField>
        <Distributor stack="end" submitButtons>
          <Button loading={loading}>{t('Dodaj poziciju')}</Button>
        </Distributor>
      </FormField>
    </form>
  );
};

export default AddPositionForm;
