import { useQuery } from "@apollo/client";
import React from "react";
import { BiCollection } from "react-icons/bi";
import ACTIVE_PROJECTS from "src/apollo/queries/dashboard/activeProjects";
import Spinner from "src/components/elements/Spinner/Spinner.component";
import Segment from "src/components/structure/wrappers/Segment/Segment.component";
import useTranslation from "src/hooks/useTranslation";
import { IconWrapper, Number } from "./Dashboard";

const ActiveProjects = () => {
  const { t } = useTranslation();
  const {
    loading: activeProjectsLoading,
    data: { activeProjects } = {},
    error: activeProjectsError,
  } = useQuery(ACTIVE_PROJECTS, {
    fetchPolicy: "cache-first",
  });

  return (
    <Segment hoverable>
      <IconWrapper>
        <BiCollection size="50px" />
      </IconWrapper>
      <h4>{t("Broj aktivnih projekata")}</h4>

      {activeProjectsLoading ? (
        <Spinner />
      ) : (
        <Number>{activeProjects?.length}</Number>
      )}
    </Segment>
  );
};

export default ActiveProjects;
