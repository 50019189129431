import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const FormField = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default FormField;
