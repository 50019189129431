import gql from "graphql-tag";

const UPDATE_PROJECT = gql`
  mutation updateProject(
    $projectId: ID!
    $name: String!
    $projectCode: String
    $deliveryDeadline: String!
    $offerDeadline: String!
    $note: String
    $urgent: Boolean
  ) {
    updateProject(
      projectId: $projectId
      name: $name
      projectCode: $projectCode
      deliveryDeadline: $deliveryDeadline
      offerDeadline: $offerDeadline
      note: $note
      urgent: $urgent
    )
  }
`;

export default UPDATE_PROJECT;
